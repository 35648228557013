<template>
  <v-container class="pb-6 px-0">
    <v-row class="text-left ma-0">
      <!-- left column -->
      <v-col cols="12" sm="3" class="pa-0">
        <div class="overline">{{ hardwareInfo["DeviceBrand"] }}</div>
        <h2>{{ hardwareInfo["DeviceModel"] }}</h2>
        <v-chip class="my-5">
          {{ hardwareInfo["DeviceCategory"] }}
        </v-chip>
        <div>
          <!-- Device Serial Number -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Serial Number</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              hardwareInfo["DeviceSerialNumber"]
            }}</v-col>
          </v-row>

          <!-- User -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >User</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              hardwareInfo["User"]
            }}</v-col>
          </v-row>

          <!-- Hardware User Agreement -->
          <v-row class="mx-0">
            <v-col
              cols="12"
              sm="6"
              class="py-1 px-0 font-weight-bold d-flex align-center"
              >Hardware User Agreement</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">
              <v-btn
              v-if="hardwareInfo && hardwareInfo.HardwareUserAgreement && hardwareInfo.HardwareUserAgreement.url"
                color="accent"
                :loading="HardwareUserAgreementLoading"
                @click="
                  downloadFile(
                    hardwareInfo.HardwareUserAgreement.url,
                    'HardwareUserAgreement'
                  )
                "
              >
                Download<v-icon right>mdi-download</v-icon> </v-btn
              ><v-icon v-else color="secondary" :disabled="!hardwareInfo.OnHand"
                >mdi-file-document-remove-outline</v-icon
              >
            </v-col>
          </v-row>

          <!-- Project 1 -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Project 1</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              hardwareInfo["Project1"]
            }}</v-col>
          </v-row>

          <!-- Project 2 -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Project 2</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              hardwareInfo["Project2"]
            }}</v-col>
          </v-row>

          <!-- Notes -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Notes</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              hardwareInfo["Notes"]
            }}</v-col>
          </v-row>
        </div>
      </v-col>
      <!-- right column -->
      <v-col cols="12" sm="9">
        <v-row class="table-bg text-center pa-0">
          <v-col cols="12" sm="6">
            <h3 class="pb-2">On Hand Info</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">On Hand</th>
                    <th class="text-center">On Hand Start Date</th>
                    <th class="text-center">On Hand End Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-icon v-if="hardwareInfo['OnHand']">mdi-check</v-icon>
                    </td>
                    <td>
                      {{ formatDate(hardwareInfo["OnHandStartDate"]) }}
                    </td>
                    <td>
                      {{ formatDate(hardwareInfo["OnHandEndDate"]) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" sm="6">
            <h3 class="pb-2">Purchase Info</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">Date</th>
                    <th class="text-center">Price</th>
                    <th class="text-center">Receipt</th>
                    <th class="text-center">Purchased By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{ formatDate(hardwareInfo["PurchaseDate"]) }}
                    </td>
                    <td>
                      {{
                        hardwareInfo["PurchasePrice"]
                          ? `$${hardwareInfo["PurchasePrice"].replace("$", "")}`
                          : ""
                      }}
                    </td>
                    <td>
                      <v-btn
                        v-if="hardwareInfo.PurchaseReceipt?.url"
                        icon
                        :loading="PurchaseReceiptLoading"
                        @click="
                          downloadFile(
                            hardwareInfo.PurchaseReceipt.url,
                            'PurchaseReceipt'
                          )
                        "
                      >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                      <v-icon v-else>mdi-file-document-remove-outline</v-icon>
                    </td>
                    <td>{{ hardwareInfo["PurchasedBy"] }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row class="table-bg text-center pa-0 mt-6">
          <v-col cols="12" :sm="hardwareInfo.PurchasedBy === 'User' ? 6 : 12">
            <h3 class="pb-2">On Hand History</h3>
            <v-simple-table v-if="hardwareInfo.History">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">User</th>
                    <th class="text-center">On Hand Start Date</th>
                    <th class="text-center">On Hand End Date</th>
                    <th class="text-center">Hardware User Agreement</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(record, index) in JSON.parse(hardwareInfo.History)
                      .records"
                    :key="index"
                  >
                    <td>
                      {{ record.historyUser }}
                    </td>
                    <td>
                      {{ formatDate(record.historyOnHandStartDate) }}
                    </td>
                    <td>
                      {{ formatDate(record.historyOnHandEndDate) }}
                    </td>
                    <td>
                      <v-btn
                        v-if="record.historyHardwareAgr"
                        icon
                        @click="
                          downloadFile(
                            record.historyHardwareAgr,
                            `${record.historyUser}_${hardwareInfo.DeviceBrand}_${hardwareInfo.DeviceModel}_${hardwareInfo.DeviceCategory}_HardwareUserAgreement_${record.historyOnHandStartDate}`,
                            true
                          )
                        "
                      >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                      <v-icon v-else>mdi-file-document-remove-outline</v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-card
              class="d-flex justify-center align-center"
              flat
              height="96px"
              v-else
            >
              <v-card-text class="d-flex justify-center align-center"
                ><v-icon large class="mr-1"
                  >mdi-clipboard-text-clock-outline</v-icon
                >No history to show.</v-card-text
              >
            </v-card>
          </v-col>
          <v-col v-if="hardwareInfo.PurchasedBy === 'User'" cols="12" sm="6">
            <h3 class="pb-2">Reimbursement Info</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">Date</th>
                    <th class="text-center">Amount</th>
                    <th class="text-center">Record</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{ formatDate(hardwareInfo["ReimbursementDate"]) }}
                    </td>
                    <td>
                      {{
                        hardwareInfo["ReimbursementAmount"]
                          ? `$${hardwareInfo["ReimbursementAmount"]}`
                          : ""
                      }}
                    </td>
                    <td>
                      <v-btn
                        v-if="hardwareInfo.ReimbursementRecord"
                        icon
                        :href="`${fullCueDashboardPath}?ppm=${$moment(
                          hardwareInfo.ReimbursementDate.split('-')[1]
                        ).format('MMMM')}&ppy=${
                          hardwareInfo.ReimbursementDate.split('-')[0]
                        }&Search=${hardwareInfo.ReimbursementRecord}`"
                        target="_blank"
                      >
                        <v-icon>mdi-account-cash</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// library
import { saveAs } from "file-saver";
// internal
import { getFile } from "@/utils/quickbaseUtils";
import { DOMAIN, ROUTES } from "@/utils/constants";

export default {
  name: "CueHardwareTableExpansion",
  props: {
    hardwareInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      HardwareUserAgreementLoading: false,
      PurchaseReceiptLoading: false,
    };
  },
  computed: {
    fullCueDashboardPath() {
      return `https://${DOMAIN}/${ROUTES.cpmDashboard}`;
    },
  },
  methods: {
    formatDate(date) {
      return date ? this.$moment(date).format("MM/DD/YY") : "";
    },
    downloadFile(url, fileName, isBase64) {
      if (isBase64) {
        const getBlob = async () => {
          const base64Response = await fetch(url);
          const blob = await base64Response.blob();
          saveAs(blob, fileName);
        };
        getBlob();
      } else {
        this[`${fileName}Loading`] = true;
        getFile(url)
          .then(async (resp) => {
            const base64Response = await fetch(
              `data:application/pdf;base64,${resp.data}`
            );
            const blob = await base64Response.blob();
            saveAs(
              blob,
              `${
                fileName.includes("Hardware")
                  ? `${this.hardwareInfo.User}_`
                  : ""
              }${this.hardwareInfo.DeviceBrand}_${
                this.hardwareInfo.DeviceModel
              }_${this.hardwareInfo.DeviceCategory}_${fileName}`
            );
            this[`${fileName}Loading`] = false;
          })
          .catch((err) => console.error(err));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-bg {
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
</style>
