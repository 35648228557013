<template>
  <v-container class="pb-6">
    <v-row class="text-left">
      <!-- left column -->
      <v-col cols="12" sm="6" class="pa-0">
        <div class="overline">{{ assignmentInfo["AssignmentTitle"] }}</div>
        <h2
          :class="{
            'pt-2': !assignmentInfo['AssignmentTitle'],
            'pb-6': !assignmentInfo['GEO'],
          }"
        >
          {{ assignmentInfo["AssignmentDetail"] }}
        </h2>
        <v-chip v-if="assignmentInfo['GEO']" class="my-5">
          {{ assignmentInfo["GEO"] }}
        </v-chip>
        <div>
          <!-- Media type -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Media Type</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["MediaType"]
            }}</v-col>
          </v-row>

          <!-- Request Type -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Work Type Category</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["WorkTypeCategory"]
            }}</v-col>
          </v-row>

          <!-- Request Date -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Request Date</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["RequestDate"]
                ? formatDateTime(assignmentInfo["RequestDate"])
                : ""
            }}</v-col>
          </v-row>

          <!-- Delivery Date -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Delivery Date</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["DeliveryDate"]
                ? formatDateTime(assignmentInfo["DeliveryDate"])
                : ""
            }}</v-col>
          </v-row>

          <!-- Pay period -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Pay Period</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["PayPeriod"]
            }}</v-col>
          </v-row>

          <!-- Task ID -->
          <v-row v-if="isAdmin || isManager">
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Task ID</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["TaskID"]
            }}</v-col>
          </v-row>

          <!-- Client Requester -->
          <v-row v-if="isAdmin || isManager">
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Client Requester</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["RelatedClientRequester"]
            }}</v-col>
          </v-row>

          <!-- Quantity -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Quantity</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["Quantity"]
            }}</v-col>
          </v-row>

          <!-- Note -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold">Note</v-col>
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["AssignmentNote"]
            }}</v-col>
          </v-row>
        </div>
      </v-col>
      <!-- right column -->
      <v-col cols="12" sm="6">
        <div class="table-bg text-center">
          <h3 class="pb-2">Assignment Steps</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Step</th>
                  <th class="text-center">Person</th>
                  <th class="text-center">Due</th>
                  <th class="text-center">Accepted</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Primary</td>
                  <td>{{ assignmentInfo["Person-Primary"] }}</td>
                  <td>
                    {{
                      assignmentInfo["PrimaryDueDate"] &&
                      assignmentInfo["PrimaryDue(TimeOfDay)"]
                        ? formatDateTime(
                            assignmentInfo["PrimaryDueDate"],
                            assignmentInfo["PrimaryDue(TimeOfDay)"]
                          )
                        : ""
                    }}
                  </td>
                  <td>
                    <v-icon v-if="assignmentInfo['PrimaryPersonAccepted']"
                      >mdi-check</v-icon
                    >
                  </td>
                </tr>
                <tr v-if="assignmentInfo['ReviewerDueDate'] !== ''">
                  <td>Reviewer</td>
                  <td>{{ assignmentInfo["Person-Reviewer"] }}</td>
                  <td>
                    {{
                      formatDateTime(
                        assignmentInfo["ReviewerDueDate"],
                        assignmentInfo["ReviewDue(TimeOfDay)"]
                      )
                    }}
                  </td>
                  <td>
                    <v-icon v-if="assignmentInfo['ReviewerAccepted']"
                      >mdi-check</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div class="mt-6 table-bg text-center">
          <h3 class="pb-2">Assignment Work Types</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Work Type</th>
                  <th class="text-center">Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ assignmentInfo["WorkType"] }}</td>
                  <td>{{ assignmentInfo["Quantity"] }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-end px-3" v-if="isAdmin || isManager">
      <!-- bottom controls -->
      <v-btn class="accent" @click="handleOpenInQB(assignmentInfo['RecordID#'])"
        >open in quickbase</v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// internal
import { appIDs, realmID } from "@/utils/quickbaseUtils";

export default {
  name: "CPMDashboardTableExpansion",
  props: {
    assignmentInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["isAdmin", "isManager"]),
  },
  methods: {
    formatDateTime(date, time) {
      return `${this.$moment(date).format("MM/DD/YY")}${
        time ? ` ${this.$moment(time, ["HH:mm:ss"]).format("hh:mm A")}` : ""
      }`;
    },
    handleOpenInQB(rid) {
      const url = `https://${realmID}/db/${appIDs.cueProjectManager.assignments}?a=dr&rid=${rid}`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.table-bg {
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
</style>
