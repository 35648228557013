<template>
  <div class="fc-source-dropzone d-flex align-center pb-7">
    <v-btn class="mr-2" icon @click="removeFile" :disabled="!inputFile">
      <v-icon v-if="inputFile">mdi-close</v-icon>
      <v-icon v-else>mdi-paperclip</v-icon>
    </v-btn>
    <vue-dropzone
      ref="vueDropzone"
      id="sourceDropzone"
      class="flex-grow-1"
      :options="dropzoneOptions"
      :duplicateCheck="true"
      @vdropzone-file-added="handleFile"
      @vdropzone-max-files-exceeded="handleMax"
    ></vue-dropzone>
  </div>
</template>

<script>
// vuex
import { mapActions } from "vuex";
// components
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "FCSourceInput",
  components: { vueDropzone: vue2Dropzone },
  data() {
    return {
      inputFile: undefined,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        dictDefaultMessage: "Drag or Import Source File",
        maxFiles: 1,
      },
    };
  },
  watch: {
    inputFile(newValue) {
      if (!newValue) {
        this.removeSourceFile();
      } else {
        this.addSourceFile(newValue);
      }
    },
  },
  methods: {
    ...mapActions("fcBot", ["addSourceFile", "removeSourceFile"]),
    handleFile(file) {
      this.inputFile = file;
    },
    removeFile() {
      this.$refs.vueDropzone.removeAllFiles();
      this.$refs.vueDropzone.removeAllFiles(true);
      this.inputFile = undefined;
    },
    handleMax(file) {
      this.removeFile();
      this.handleFile(file);
      this.$refs.vueDropzone.addFile(file);
    },
  },
};
</script>

<style lang="scss" scoped>
#sourceDropzone {
  min-height: 56px;
  padding: 18px 12px 0;
  background: rgba(0, 0, 0, 0.06);
  border: none;
  border-style: solid;
  border-width: 0 0 thin 0;
  &:hover {
    background: rgba(0, 0, 0, 0.12);
  }
}
::v-deep {
  .dz-default.dz-message {
    text-align: left;
    margin: 0;
    line-height: 20px;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.6);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  .dz-preview {
    margin: 0 0 0 10px;
    width: auto;
    min-height: 32px;
    & .dz-details {
      position: unset;
      max-width: unset;
      background-color: transparent;
      padding: 0;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.87);
      display: inline-flex;
      align-items: center;
      & .dz-filename {
        white-space: unset;
        & span {
          padding: 0;
        }
      }
    }
  }
  .dz-started .dz-message {
    display: block;
    transform: translate(-133px, -5px) scale(0.75);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  .dz-preview .dz-image,
  .dz-preview .dz-progress,
  .dz-preview .dz-error-message,
  .dz-preview .dz-success-mark,
  .dz-preview .dz-error-mark,
  .dz-preview .dz-details .dz-size {
    display: none;
  }
}
</style>
