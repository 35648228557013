<template>
  <div>
    <v-data-table
      v-model="selectedRows"
      :loading="isStatusUpdating"
      :headers="headers"
      :items="filteredData"
      item-key="index"
      group-by="groupDate"
      sort-by="groupSort"
      :header-props="{ sortIcon: null }"
      show-expand
      show-select
      :single-select="!isAdminMode"
      group-desc
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
      }"
      :class="{ isStatusUpdating: 'status-updating', laptop, laptopSmaller }"
      @toggle-select-all="handleSelectAll"
    >
      <!-- grouping override -->
      <template v-slot:[`group.header`]="{ group, headers }">
        <td class="pa-0 group-header-row" :colspan="headers.length">
          <h2 class="mx-4 font-weight-bold d-inline">
            {{ `${dueText + formatDate(group)}` }}
          </h2>
          <v-chip
            v-if="handleDuesStats(group)"
            color="font-weight-bold white"
            small
          >
            <v-icon left> mdi-clock-alert-outline </v-icon
            >{{ handleDuesStats(group) }}
          </v-chip>
        </td>
      </template>

      <!-- selection override -->
      <template v-slot:[`item.data-table-select`]="{ item }">
        <v-checkbox
          :disabled="
            setRoleMode(item)
              ? !allowedToUpdateStatuses(item).includes(
                  item.AssignmentStatus
                ) ||
                (bulkMode !== '' && bulkMode !== setRoleMode(item))
              : false
          "
          v-model="item.checked"
          @change="handleSelectedRows($event, item)"
        ></v-checkbox>
      </template>

      <!-- Writer cell override -->
      <template v-slot:[`item.Writer`]="{ item, value }">
        <v-badge
          :value="handleDueAlert(item, 'Writing')"
          :color="
            handleDueAlert(item, 'Writing') === 'overdue'
              ? '#E57373'
              : handleDueAlert(item, 'Writing') === 'upcoming-due'
              ? '#FFB74D'
              : ''
          "
          icon="mdi-clock-alert-outline"
          overlap
        >
          <div
            :class="{
              'assigned-to-user': isActiveUserEmail(item['Writer-Email']),
            }"
          >
            <div class="text-container text-center">
              {{ value }}
            </div>
          </div>
        </v-badge>
      </template>

      <!-- Editor cell override -->
      <template v-slot:[`item.Editor`]="{ item, value }">
        <v-badge
          :value="handleDueAlert(item, 'Editing')"
          :color="
            handleDueAlert(item, 'Editing') === 'overdue'
              ? '#E57373'
              : handleDueAlert(item, 'Editing') === 'upcoming-due'
              ? '#FFB74D'
              : ''
          "
          icon="mdi-clock-alert-outline"
          overlap
        >
          <div
            :class="{
              'assigned-to-user': isActiveUserEmail(item['Editor-Email']),
            }"
          >
            <div class="text-container text-center">
              {{ value }}
            </div>
          </div>
        </v-badge>
      </template>

      <!-- Reviewer cell override -->
      <template v-slot:[`item.Reviewer`]="{ item, value }">
        <v-badge
          :value="handleDueAlert(item, 'Reviewing')"
          :color="
            handleDueAlert(item, 'Reviewing') === 'overdue'
              ? '#E57373'
              : handleDueAlert(item, 'Reviewing') === 'upcoming-due'
              ? '#FFB74D'
              : ''
          "
          icon="mdi-clock-alert-outline"
          overlap
        >
          <div
            :class="{
              'assigned-to-user': isActiveUserEmail(item['Reviewer-Email']),
            }"
          >
            <div class="text-container text-center">
              {{ value }}
            </div>
          </div>
        </v-badge>
      </template>

      <!-- Due Time cell override -->
      <template v-slot:[`item.DueTime`]="{ value }">
        <span>{{ formatDateForTableRow(value, false, true) }}</span>
      </template>

      <!-- File Location cell override -->
      <template v-slot:[`item.FileLocation`]="{ value }">
        <v-tooltip right color="primary" :disabled="value.length <= 10">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-if="!isLinkedLocation(value) && value !== ''"
              v-bind="attrs"
              v-on="on"
              >{{ ellipseText(value, 10) }}</span
            >
            <span
              v-if="isLinkedLocation(value) && value !== ''"
              v-bind="attrs"
              v-on="on"
              :style="{
                textDecoration: 'underline',
                cursor: 'pointer',
              }"
              @click="openLinkedLocation(value)"
            >
              {{ ellipseText(value.replace("https://", ""), 10) }}
            </span>
          </template>
          <span>{{ value }}</span>
        </v-tooltip>
      </template>

      <!-- Report An Issue cell override -->
      <template v-slot:[`item.Issue`]="{ item }">
        <v-btn
          small
          icon
          :color="
            item.HasUnresolvedIssue
              ? 'secondary'
              : item.AllIssuesAreResolved
              ? 'accent'
              : '#494949de'
          "
          outlined
          elevation="1"
          :loading="item.issuesLoading"
          @click="handleBugReportModal(item)"
        >
          <v-icon v-if="item.AllIssuesAreResolved">mdi-bug-check</v-icon>
          <v-icon v-else>mdi-bug</v-icon>
        </v-btn>
      </template>

      <!-- Request Date cell override -->
      <template v-slot:[`item.RequestDate`]="{ value }">
        <span>{{ formatDateForTableRow(value) }}</span>
      </template>

      <!-- Detail cell override -->
      <template v-slot:[`item.AssignmentDetail`]="{ value }">
        <v-tooltip right color="primary" :disabled="value.length <= 40">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ ellipseText(value, 40) }}</span>
          </template>
          <span>{{ value }}</span>
        </v-tooltip>
      </template>

      <!-- GEO cell override -->
      <template v-slot:[`item.GEO`]="{ value }">
        <v-chip>{{ value ? value : "None" }}</v-chip>
      </template>

      <!-- Status cell override -->
      <template v-slot:[`item.AssignmentStatus`]="{ item, value }">
        <FCDashboardStatusSelector
          :item="item"
          :value="value"
          :options="statuses"
          :optionsLoading="statusesLoading"
          :roleMode="setRoleMode(item)"
          :writerStatuses="writerStatuses"
          :editorStatuses="editorStatuses"
          :reviewerStatuses="reviewerStatuses"
          :isUpdating="statusUpdating"
          :isError="statusError"
          @refresh-table-data="updateTableDataStatus"
        />
      </template>

      <!-- accepted cell override -->
      <template v-slot:[`item.accepted`]="{ item, value }">
        <FCDashboardAcceptedSelector
          v-if="item.groupType !== 'REVIEWING'"
          :item="item"
          :value="value"
          @refresh-table-expansion="updateTableDataAccepted"
        />
      </template>

      <!-- Inquiry cell override -->
      <template v-slot:[`item.Inquiry`]="{ item }">
        <v-btn
          fab
          x-small
          outlined
          elevation="1"
          color="accent"
          @click="openSlackForm(item)"
          ><v-icon>mdi-slack</v-icon></v-btn
        >
      </template>

      <!-- Earnings cell override -->
      <template v-slot:[`item.TotalInternal`]="{ value }">
        <span>{{ value ? `$${value.toFixed(2)}` : "-" }}</span>
      </template>

      <!-- Expanded panel -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <FCDashboardTableExpansion :assignmentInfo="item" />
        </td>
      </template>
    </v-data-table>
    <v-dialog
      max-width="1200"
      v-model="bugReportModal"
      @click:outside="bugReportModal = false"
      @keydown.esc="bugReportModal = false"
    >
      <v-card>
        <v-card-title class="flex-column py-4">
          <span class="text-h5">Report Shakespeare Issue</span>
          <span class="text-subtitle-1">{{
            currentItem.ProjectName +
            " / " +
            currentItem.RequestDate +
            " / " +
            currentItem.AssignmentDetail +
            " / " +
            currentItem.GEO
          }}</span>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-center px-2">Issue Type</th>
                <th class="text-center px-2">Date Reported</th>
                <th class="text-center px-2">Last Updated</th>
                <th class="text-center px-2">Last Updated By</th>
                <th class="text-center px-2">Issue Status</th>
                <th class="text-center px-2">Remove</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in bugReports" :key="i">
                <td class="px-2">
                  <v-autocomplete
                    class="issue-field"
                    dense
                    solo-inverted
                    hide-details
                    :readonly="!isAdminMode && !!item['RecordID#']"
                    :value="item.IssueType"
                    :items="isAdminMode ? issueTypes : issueTypesByRole"
                    @change="handleBugDropdown(i, 'IssueType', $event)"
                  ></v-autocomplete>
                </td>
                <td class="px-2">
                  <v-text-field
                    class="reported-field"
                    dense
                    filled
                    solo-inverted
                    hide-details
                    readonly
                    :value="formatDateForTableRow(item.DateReported)"
                  ></v-text-field>
                </td>
                <td class="px-2">
                  <v-text-field
                    class="updated-field"
                    dense
                    filled
                    solo-inverted
                    hide-details
                    readonly
                    :value="formatDateForTableRow(item.LastUpdated, true)"
                  ></v-text-field>
                </td>
                <td class="px-2">
                  <v-text-field
                    class="updated-by-field"
                    dense
                    filled
                    solo-inverted
                    hide-details
                    readonly
                    :value="item.LastUpdatedBy"
                  ></v-text-field>
                </td>
                <td class="px-2">
                  <v-autocomplete
                    class="status-field"
                    dense
                    solo-inverted
                    hide-details
                    :readonly="!isAdminMode"
                    :value="item.IssueStatus"
                    :items="issueStatuses"
                    @change="handleBugDropdown(i, 'IssueStatus', $event)"
                  ></v-autocomplete>
                </td>
                <td class="text-center">
                  <v-icon
                    :disabled="!!item['RecordID#']"
                    @click="handleRemoveBugReport(i)"
                    >mdi-minus</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <div class="pt-3 pl-4 d-flex align-center">
            <v-btn x-small outlined color="accent" @click="handleNewBugReport">
              <v-icon small>mdi-plus</v-icon>
              add issue</v-btn
            >
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-6 py-4">
          <v-btn
            width="20%"
            color="secondary"
            @click="handleBugReportUpdate"
            :loading="bugReportUpdate"
          >
            Save
          </v-btn>
        </v-card-actions>
        <v-alert
          v-if="bugReportError"
          type="error"
          class="alert mb-0"
          dismissible
        >
          Error updating bug report. Please try again.
        </v-alert>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="1200"
      v-model="slackFormModal"
      @click:outside="slackFormModal = false"
      @keydown.esc="slackFormModal = false"
    >
      <SlackInquiryForm
        :inquiryItem="inquiryAssignment"
        :mode="'assignment'"
        :bulkInquiry="bulkInquiry"
        @close-slack-form="confirmInquiry"
      />
    </v-dialog>
    <v-snackbar
      v-model="successMessage"
      min-width="unset"
      timeout="2000"
      color="success"
      rounded="pill"
      content-class="text-center"
    >
      <v-icon color="white" class="mr-1">mdi-check-circle-outline</v-icon>
      {{ successMsgTxt }}
    </v-snackbar>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// libraries
import _ from "lodash";
import Fuse from "fuse.js";
// internal
import {
  appIDs,
  getFieldInfosFromDB,
  getBugReports,
  updateBugReports,
  getAssignments,
  getTasks,
  processTask,
} from "@/utils/quickbaseUtils";
import { customBreakpoint } from "@/utils/mixins";
import { publishMessage } from "@/utils/slackUtils";
// components
import FCDashboardTableExpansion from "./FCDashboardTableExpansion.vue";
import FCDashboardStatusSelector from "./FCDashboardStatusSelector.vue";
import FCDashboardAcceptedSelector from "./FCDashboardAcceptedSelector.vue";
import SlackInquiryForm from "../ui/SlackInquiryForm.vue";

export default {
  name: "FCDashboardTable",
  components: {
    FCDashboardTableExpansion,
    FCDashboardStatusSelector,
    FCDashboardAcceptedSelector,
    SlackInquiryForm,
  },
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    tableFields: {
      type: Array,
      required: false,
    },
    isAdminMode: {
      type: Boolean,
      required: true,
    },
    filterDeliveryDate: {
      type: String,
      required: false,
    },
    filterRequestDate: {
      type: String,
      required: false,
    },
    filterSearch: {
      type: String,
      required: false,
    },
    filterProject: {
      type: Array,
      required: true,
    },
    filterShakespeare: {
      type: Number,
      required: true,
    },
    filterPerson: {
      type: String,
      required: true,
    },
    filterRole: {
      type: String,
      required: true,
    },
    filterGeo: {
      type: String,
      required: true,
    },
    filterStatus: {
      type: String,
      required: true,
    },
    isStatusUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    statuses: {
      type: Array,
      required: true,
    },
    statusesLoading: {
      type: Boolean,
      required: true,
    },
    showEarnings: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      adminHeaders: [
        "ProjectName",
        "RequestType",
        "MediaType",
        "AssignmentDetail",
        "GEO",
        "FileLocation",
        "Writer",
        "Editor",
        "Reviewer",
        "AssignmentStatus",
        "Inquiry",
      ],
      userHeaders: [
        "RequestType",
        "MediaType",
        "AssignmentDetail",
        "WordCount",
        "FileLocation",
        "Writer",
        "Editor",
        "Reviewer",
        "DueTime",
        "accepted",
        "AssignmentStatus",
        "Inquiry",
      ],
      selectedRows: [],
      bugReportModal: false,
      issueTypes: [],
      issueStatuses: [],
      bugReports: [],
      currentItem: {},
      bugReportUpdate: false,
      bugReportError: false,
      dueControl: 2,
      writerStatuses: ["Assigned", "Rejected", "Primary Complete"],
      editorStatuses: ["Primary Complete", "Secondary Complete"],
      reviewerStatuses: [
        "Assigned",
        "Rejected",
        "Primary Complete",
        "Secondary Complete",
        "QC Complete",
      ],
      bulkMode: "",
      slackFormModal: false,
      inquiryAssignment: {},
      successMessage: false,
      successMsgTxt: "",
      statusUpdating: false,
      statusError: false,
      bulkInquiry: false,
    };
  },
  mixins: [customBreakpoint],
  computed: {
    ...mapGetters("fcBot", ["selectedDashboardRows"]),
    ...mapGetters("auth", ["user"]),
    headers() {
      if (!this.tableFields) return [];

      const tableHeaders = this.isAdminMode
        ? this.adminHeaders
        : this.userHeaders;

      let retVal = this.tableFields
        .filter((el) => tableHeaders.includes(el.value))
        .map((field) => {
          return {
            text: field.text,
            value: field.value,
          };
        });

      // order by the order of the tableHeaders
      retVal = _.orderBy(retVal, (el) => tableHeaders.indexOf(el.value));

      if (!this.isAdminMode) {
        retVal.push({
          text: "Accepted",
          value: "accepted",
        });
      }
      retVal.push({ text: "", value: "data-table-expand" });

      return retVal;
    },
    tableFieldValues() {
      return this.tableFields.map((field) => field.value);
    },
    dataWithGroupInfo() {
      if (this.isAdminMode) {
        return this.tableData.map((el, i) => {
          return {
            ...el,
            groupDate: el["DeliveryDate"],
            groupType: "DELIVERY",
            groupSort: el["DeliveryDate"],
            index: i,
          };
        });
      } else {
        return this.tableData.map((el, i) => {
          if (el["Writer-Email"] === this.user.email) {
            return {
              ...el,
              groupDate: el["WritingDueDate"],
              groupSort: el.DueTime,
              groupType: "WRITING",
              accepted: el["WriterAccepted"],
              index: i,
            };
          } else if (el["Editor-Email"] === this.user.email) {
            return {
              ...el,
              groupDate: el["EditingDueDate"],
              groupSort: el.DueTime,
              groupType: "EDITING",
              accepted: el["EditorAccepted"],
              index: i,
            };
          } else if (el["Reviewer-Email"] === this.user.email) {
            return {
              ...el,
              groupDate: el["ReviewingDueDate"],
              groupSort: el.DueTime,
              groupType: "REVIEWING",
              accepted: true,
              index: i,
            };
          }
        });
      }
    },
    filteredData() {
      let dateFilteredData = [];

      // project filtered
      const filterMap = ["iTunes FC", "iTunes Music"];

      const inProjects = this.filterProject.map((el) => filterMap[el]);

      dateFilteredData = this.dataWithGroupInfo.filter((el) => {
        return inProjects.includes(el.ProjectName);
      });

      if (this.filterShakespeare) {
        dateFilteredData = dateFilteredData.filter(
          (el) =>
            el["FileLocation"] === "https://wrkflow.app/" ||
            el["FileLocation"].includes("Shakespeare")
        );
      }

      if (this.filterDeliveryDate) {
        if (this.isAdminMode) {
          dateFilteredData = dateFilteredData.filter((el) => {
            return (
              this.$moment(el["DeliveryDate"]).format("YYYY-MM-DD") ===
              this.filterDeliveryDate
            );
          });
        } else {
          dateFilteredData = dateFilteredData.filter((el) => {
            return (
              this.$moment(el["groupDate"]).format("YYYY-MM-DD") ===
              this.filterDeliveryDate
            );
          });
        }
      }

      if (this.filterRequestDate) {
        dateFilteredData = dateFilteredData.filter((el) => {
          return (
            this.$moment(el["RequestDate"]).format("YYYY-MM-DD") ===
            this.filterRequestDate
          );
        });
      }

      if (this.filterPerson) {
        dateFilteredData = dateFilteredData.filter((el) =>
          this.showEarnings
            ? el["Writer"] === this.filterPerson ||
              el["Editor"] === this.filterPerson ||
              el["Reviewer"] === this.filterPerson
            : el["Writer"] === this.filterPerson ||
              el["Editor"] === this.filterPerson ||
              el["Reviewer"] === this.filterPerson ||
              el["CreatedBy"] === this.filterPerson
        );
      }

      if (this.filterRole) {
        dateFilteredData = dateFilteredData.filter((el) =>
          this.isAdminMode
            ? el[
                this.filterRole === "Creator" ? "CreatedBy" : this.filterRole
              ] === this.filterPerson
            : el[`${this.filterRole}-Email`] === this.user.email
        );
      }

      if (this.filterGeo) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el["GEO"] === this.filterGeo
        );
      }

      if (this.filterStatus) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el["AssignmentStatus"] === this.filterStatus
        );
      }

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: this.tableFieldValues,
      };

      if (this.filterSearch) {
        const fuse = new Fuse(dateFilteredData, searchOptions);
        dateFilteredData = fuse.search(this.filterSearch).map((el) => el.item);
      } else {
        dateFilteredData = dateFilteredData;
      }
      return dateFilteredData;
    },
    dueText() {
      if (this.isAdminMode) {
        return "Delivery Date: ";
      } else {
        return "Your Due Date: ";
      }
    },
    issueTypesByRole() {
      const specialTypes = [
        "Task disappeared",
        "Cancelled (billed)",
        "Cancelled (not billed)",
      ];
      const retVal = this.issueTypes.filter((el) => !specialTypes.includes(el));
      if (this.isActiveUserEmail(this.currentItem["Writer-Email"]))
        retVal.push("Task disappeared");
      return retVal.sort();
    },
    totalEarnings() {
      return parseFloat(
        this.filteredData.reduce((a, b) => a + (b?.TotalInternal || 0), 0)
      ).toFixed(2);
    },
  },
  watch: {
    selectedRows(newValues) {
      this.setSelectedDashboardRows(newValues);
    },
    selectedDashboardRows(newValues) {
      this.selectedRows = newValues;
    },
    filterShakespeare(newValue) {
      this.adminHeaders.splice(5, 1, newValue ? "Issue" : "FileLocation");
      this.userHeaders.splice(4, 1, newValue ? "Issue" : "FileLocation");
    },
    showEarnings(newValue) {
      this.adminHeaders.splice(1, 1, newValue ? "TotalInternal" : "MediaType");
      this.userHeaders.splice(1, 1, newValue ? "TotalInternal" : "MediaType");
    },
  },
  mounted() {
    this.handleTableHeaders();
    this.getIssuesDropdowns();
  },
  methods: {
    ...mapActions("fcBot", ["setSelectedDashboardRows"]),
    formatDate(data) {
      return this.$moment(data).format("MMM DD, YYYY");
    },
    formatDateForTableRow(data, displayTime, timeOnly) {
      return this.$moment(data).format(
        `${timeOnly ? "hh:mm A" : `MM/DD/YYYY ${displayTime ? "hh:mm A" : ""}`}`
      );
    },
    isActiveUserEmail(email) {
      return email === this.user.email;
    },
    isLinkedLocation(value) {
      const linkedLocations = [
        "Shakespeare",
        "https://wrkflow.app/",
        "Smartling",
      ];
      return linkedLocations.includes(value.split(" ")[0]);
    },
    openLinkedLocation(value) {
      if (
        value.includes("Shakespeare") ||
        value.includes("https://wrkflow.app/")
      ) {
        // open link in new tab
        window.open("https://wrkflow.app/worklist", "_blank");
      } else if (value.includes("Smartling")) {
        window.open("https://www.smartling.com", "_blank");
      } else {
        return "";
      }
    },
    ellipseText(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + "...";
      } else {
        return text;
      }
    },
    getIssuesDropdowns() {
      getFieldInfosFromDB({
        fieldID: 14,
        tableID: appIDs.iTunesProjectManager.issueTracker,
      })
        .then((data) => (this.issueTypes = data))
        .catch((err) => console.error(err));
      getFieldInfosFromDB({
        fieldID: 8,
        tableID: appIDs.iTunesProjectManager.issueTracker,
      })
        .then((data) => (this.issueStatuses = data))
        .catch((err) => console.error(err));
    },
    handleBugReportModal(item) {
      item.issuesLoading = true;
      this.currentItem = { ...item };
      getBugReports(item["RecordID#"])
        .then((data) => {
          this.bugReports = data.data;
          item.issuesLoading = false;
          this.bugReportModal = true;
        })
        .catch((err) => {
          console.error(err);
          item.issuesLoading = false;
        });
    },
    handleNewBugReport() {
      this.bugReports.push({
        "RecordID#": null,
        IssueType: "",
        DateReported: new Date(),
        LastUpdated: new Date(),
        LastUpdatedBy: this.user.Name,
        IssueStatus: `Reported by ${this.isAdminMode ? "PM" : "Staff"}`,
      });
    },
    handleBugDropdown(i, key, value) {
      this.bugReports[i][key] = value;
      this.bugReports[i].DateReported = new Date();
      this.bugReports[i].LastUpdated = new Date();
      if (this.isAdminMode) {
        this.bugReports[i].LastUpdatedBy = this.user.Name;
      }
    },
    handleRemoveBugReport(i) {
      this.bugReports.splice(i, 1);
    },
    handleBugReportUpdate() {
      this.bugReportUpdate = true;
      updateBugReports({
        issues: this.bugReports,
        relatedAssgn: this.currentItem["RecordID#"],
      })
        .then(() => {
          this.tableData[this.currentItem.index].HasUnresolvedIssue =
            !this.bugReports.every((el) => el.IssueStatus === "Resolved");
          this.tableData[this.currentItem.index].AllIssuesAreResolved =
            this.bugReports.every((el) => el.IssueStatus === "Resolved");
          const newBugs = this.bugReports.filter(
            (bug) => bug["RecordID#"] === null
          );
          if (newBugs.length)
            newBugs.forEach((bug) => {
              publishMessage({
                user: this.user.SlackFCWorkspaceID
                  ? `<@${this.user.SlackFCWorkspaceID}>`
                  : `*${this.user.Name}*`,
                mode: "shakespeare-bug",
                data: {
                  shkBugType: bug.IssueType,
                },
                item: this.currentItem,
              });
            });
          this.bugReportUpdate = this.bugReportModal = false;
          this.successMsgTxt = "Reported!";
          this.successMessage = true;
        })
        .catch((err) => {
          this.bugReportUpdate = false;
          this.bugReportError = true;
          console.error(err);
        });
    },
    updateTableDataAccepted(index, value, role) {
      this.tableData[index][
        `${role.charAt(0) + role.slice(1).toLocaleLowerCase()}Accepted`
      ] = value;
    },
    handleTableHeaders() {
      if (this.$route.query.hasOwnProperty("Shakespeare")) {
        this.adminHeaders.splice(6, 1, "Issue");
        this.userHeaders.splice(4, 1, "Issue");
      }
    },
    handleDueAlert(item, flow) {
      if (
        this.isActiveUserEmail(
          item[
            `${flow === "Editing" ? "Edito" : flow.slice(0, -3) + "e"}r-Email`
          ]
        )
      ) {
        const statuses = [],
          assgnDue = this.$moment(
            item[`${flow}DueDate`].replace(/-/g, "/") +
              " " +
              item[`${flow}Due(TimeOfDay)`]
          ).format(),
          now = this.$moment(
            new Date().toLocaleString("en-US", {
              timeZone: "America/New_York",
            })
          ).format(),
          dueMeter = this.$moment(assgnDue).diff(
            this.$moment(now),
            "hour",
            true
          );
        if (flow === "Writing") statuses.push("Assigned", "Rejected");
        if (flow === "Editing") statuses.push("Primary Complete");
        if (flow === "Reviewing") statuses.push("Secondary Complete");
        if (
          ((dueMeter <= this.dueControl && dueMeter > 0) || dueMeter == 0) &&
          statuses.includes(item["AssignmentStatus"])
        ) {
          return "upcoming-due";
        } else if (
          dueMeter < 0 &&
          statuses.includes(item["AssignmentStatus"])
        ) {
          return "overdue";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    handleDuesStats(group) {
      const groupItems = this.filteredData.filter(
          (el) => this.$moment(el.groupDate).format("YYYY-MM-DD") === group
        ),
        flows = ["Writing", "Editing", "Reviewing"],
        overdueItems = [],
        upcomingDueItems = [];
      groupItems.forEach((el) => {
        flows.forEach((flow) => {
          if (this.handleDueAlert(el, flow) === "overdue") {
            overdueItems.push(el);
          } else if (this.handleDueAlert(el, flow) === "upcoming-due") {
            upcomingDueItems.push(el);
          }
        });
      });
      return `${
        (overdueItems.length
          ? "Overdued assignments - " + overdueItems.length
          : "") +
        (overdueItems.length && upcomingDueItems.length ? " / " : "") +
        (upcomingDueItems.length
          ? `Assignments with upcoming due in ${this.dueControl} hrs - ` +
            upcomingDueItems.length
          : "")
      }`;
    },
    updateTableDataStatus(index, value) {
      this.tableData[index]["AssignmentStatus"] = value;
      // handle task status after assignment status change
      if (value.endsWith("Complete")) {
        getAssignments({ cueID: this.tableData[index]["Task-CueTaskID"] })
          .then((resp) => {
            if (resp.data.every((el) => el.AssignmentStatus === value)) {
              getTasks({ cueID: this.tableData[index]["Task-CueTaskID"] })
                .then((resp) => {
                  if (resp.data.length) {
                    processTask({
                      task: {
                        ["RecordID#"]: resp.data[0]["RecordID#"],
                        LastUpdated: new Date(),
                        LastUpdatedBy: this.user.Name,
                      },
                      status: value,
                    }).catch((err) => {
                      console.error(err);
                      this.statusUpdating = false;
                      this.statusError = true;
                    });
                  } else {
                    this.statusUpdating = false;
                  }
                })
                .catch((err) => {
                  console.error(err);
                  this.statusError = true;
                });
            }
            this.statusUpdating = false;
          })
          .catch((err) => {
            console.error(err);
            this.statusUpdating = false;
            this.statusError = true;
          });
      } else {
        this.statusUpdating = false;
      }
    },
    setRoleMode(item) {
      if (this.isActiveUserEmail(item["Writer-Email"])) {
        return "writer";
      } else if (this.isActiveUserEmail(item["Editor-Email"])) {
        return "editor";
      } else if (this.isActiveUserEmail(item["Reviewer-Email"])) {
        return "reviewer";
      } else {
        return "";
      }
    },
    async handleSelectedRows(selected, item) {
      const handleUpdate = () =>
        selected
          ? this.selectedRows.push(item)
          : this.selectedRows.splice(this.selectedRows.indexOf(item), 1);
      await handleUpdate();
      if (!this.isAdminMode) {
        if (selected) this.bulkMode = this.setRoleMode(item);
        if (!this.selectedDashboardRows.length) this.bulkMode = "";
        this.$emit(
          "update-allowed-statuses",
          this.bulkMode
            ? this.bulkMode === "reviewer"
              ? this[`${this.bulkMode}Statuses`]
              : this[`${this.bulkMode}Statuses`].slice(-1)
            : []
        );
      }
    },
    handleSelectAll(data) {
      data.items.forEach((item) => {
        const index = this.filteredData.indexOf(item);
        this.filteredData[index].checked = data.value;
        this.handleSelectedRows(data.value, item);
      });
    },
    allowedToUpdateStatuses(item) {
      const allowedStatuses = this[`${this.setRoleMode(item)}Statuses`].filter(
        (el) => el != this[`${this.setRoleMode(item)}Statuses`].slice(-1)
      );
      return this.setRoleMode(item) !== "reviewer"
        ? allowedStatuses
        : this[`${this.setRoleMode(item)}Statuses`];
    },
    openSlackForm(item) {
      this.inquiryAssignment = { ...item };
      this.bulkInquiry = false;
      this.slackFormModal = true;
    },
    confirmInquiry() {
      this.slackFormModal = false;
      this.successMsgTxt = "Inquiry sent!";
      this.successMessage = true;
    },
    callBulkInquiry() {
      this.inquiryAssignment = {};
      this.bulkInquiry = this.slackFormModal = true;
    },
  },
};
</script>

<style scoped lang="scss">
.group-header-row {
  background-color: #9af9ef70 !important;
  color: #003a35 !important;
}

.assigned-to-user {
  .text-container {
    width: fit-content;
    font-weight: 500;
    border: 2px solid #ee79753f;
    color: #540000;
    padding: 2px 5px;
    margin: 2px 0px;
    border-radius: 10px;
    line-height: 1.5;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  }
}

::v-deep {
  .v-data-table.laptop .v-data-table__wrapper table {
    & tbody > tr:not(.v-data-table__expanded__content) > td {
      padding: 0 8px;
    }
    & .v-data-table-header tr > th {
      padding: 0 8px;
    }
  }
  .v-data-table.laptopSmaller .v-data-table__wrapper table {
    & tbody > tr:not(.v-data-table__expanded__content) > td {
      padding: 0 4px;
    }
    & .v-data-table-header tr > th {
      padding: 0 4px;
    }
  }
  .v-badge--icon .v-badge__badge {
    min-width: 23px;
    height: 23px;
    display: flex;
    border-radius: 50%;
    padding: 0;
    & .v-icon {
      font-size: 17px;
      height: auto;
      margin: auto;
    }
  }
}

.v-input--selection-controls {
  padding-left: 8px;
}

.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0;
}

.status-updating {
  pointer-events: "none";
  opacity: 0.2;
}

.issue-field {
  width: 270px;
}

.reported-field {
  width: 110px;
}

.updated-field {
  width: 180px;
}

.updated-by-field,
.status-field {
  width: 220px;
}

.d-inline {
  vertical-align: middle;
}
</style>
