<template>
  <div class="home d-flex justify-center align-center">
    <v-fade-transition>
      <div
        v-if="showHomeInfo"
        class="home__inner d-flex flex-column align-center"
        :class="{ 'profile-lock': profileLock }"
      >
        <img :src="cuelogo" />
        <div v-if="isAdmin || isManager" class="text-center pa-3">
          {{
            profileLock
              ? "Please complete your Company Cue profile to continue using CueHub!"
              : "Use the menu at the top left to navigate."
          }}
        </div>
        <div v-else class="text-center pa-3">
          CueHub has moved to
          <a href="https://cocuehub.com/" target="_blank">CoCueHub.com</a>. See
          you there!
        </div>
        <v-btn
          v-if="profileLock"
          width="fit-content"
          height="38px"
          color="secondary"
          @click="handleProfileModal(true)"
        >
          <v-icon left>mdi-account</v-icon>Open Profile
        </v-btn>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import cuelogo from "@/assets/cue_logo.svg";

export default {
  name: "Home",
  data() {
    return {
      cuelogo,
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "profileLock", "isAdmin", "isManager"]),
    showHomeInfo() {
      return Object.keys(this.user ?? {}).length;
    },
  },
  methods: {
    ...mapActions("auth", ["handleProfileModal"]),
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;

  &__inner {
    height: 500px;
    width: 500px;
    &.profile-lock {
      height: 400px;
      width: 400px;
    }
  }
}
</style>
