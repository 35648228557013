<template>
  <div class="editor-table">
    <!-- <EditorProjectSelectionList
      v-if="allProjects"
      :selectedProjectIndex="selectedProjectIndex"
      @handleProjectListChange="handleProjectListChange"
    /> -->
    <EditorAssignmentsTable
      v-if="selectedProjectData"
      :projectData="selectedProjectData"
      :selectedProjectIndex="selectedProjectIndex"
    />
    <v-bottom-sheet
      class="text-editor-bottom-sheet"
      :value="editorPanelOpen"
      inset
      @click:outside="handleClickOutside"
    >
      <EditorTextEditor />
    </v-bottom-sheet>
    <v-dialog
      max-width="1200"
      :value="newAssignmentModalOpen"
      @click:outside="() => setNewAssignmentModalOpen(false)"
    >
      <EditorNewAssignmentModal />
    </v-dialog>
    <v-dialog
      max-width="1200"
      :value="editAssignmentModalOpen"
      @click:outside="() => setEditAssignmentModalOpen(false)"
    >
      <EditorEditAssignmentModal />
    </v-dialog>
    <v-dialog
      max-width="700"
      :value="projectModalOpen"
      @click:outside="() => setProjectModalOpen(false)"
    >
      <EditorProjectModal />
    </v-dialog>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import { dub } from "@/providers/dubEditor";
import { ROUTES } from "@/utils/constants";
// components
import EditorAssignmentsTable from "@/components/Editor/EditorAssignmentsTable/EditorAssignmentsTable";
import EditorTextEditor from "@/components/Editor/EditorTextEditor/EditorTextEditor";
import EditorProjectSelectionList from "@/components/Editor/EditorAssignmentsTable/EditorProjectSelectionList";
import EditorNewAssignmentModal from "@/components/Editor/EditorNewAssignmentModal/EditorNewAssignmentModal";
import EditorEditAssignmentModal from "@/components/Editor/EditorEditAssignmentModal/EditorEditAssignmentModal";
import EditorProjectModal from "@/components/Editor/EditorProjectModal/EditorProjectModal";

export default {
  name: "Editor",
  components: {
    EditorAssignmentsTable,
    EditorTextEditor,
    EditorProjectSelectionList,
    EditorNewAssignmentModal,
    EditorEditAssignmentModal,
    EditorProjectModal,
  },
  data() {
    return {
      selectedProjectIndex: 0,
      editorPanelOpen: false,
    };
  },
  computed: {
    ...mapGetters("editor", [
      "openAssignment",
      "allProjects",
      "selectedProject",
      "newAssignmentModalOpen",
      "editAssignmentModalOpen",
      "projectModalOpen",
    ]),
    ...mapGetters("auth", ["user", "isAdmin"]),
    selectedProjectData() {
      if (!this.allProjects) return null;
      return this.allProjects[this.selectedProjectIndex];
    },
  },
  watch: {
    openAssignment(newValue) {
      if (!newValue) {
        this.editorPanelOpen = false;
      } else {
        this.editorPanelOpen = true;
      }
    },
  },
  mounted() {
    dub
      .listProjects({
        userEmail: this.user.email,
        isAdmin: this.isAdmin,
      })
      .then((d) => {
        this.setAllProjects(d);
      })
      .then(() => {
        // check if there's a pid query in the vue router
        const pid = this.$route.query.pid;

        if (pid) {
          // get matching project from allProjects
          const project = this.allProjects.find(
            (project) => project.projectID === pid
          );

          if (project) {
            this.setSelectedProject(project);
            return;
          }
        }
        // set to first in list
        const newSelectedProject = this.allProjects[0];
        this.setSelectedProject(newSelectedProject);
        this.$router.push({
          path: `/${ROUTES.editor}`,
          query: { pid: newSelectedProject.projectID },
        });
      })
      .then(() => {
        // check if there's an aid query in the vue router
        const aid = this.$route.query.aid;
        if (aid) {
          this.setOpenAssignment(aid);
        }
      });
  },
  methods: {
    ...mapActions("editor", [
      "setOpenAssignment",
      "setAllProjects",
      "setNewAssignmentModalOpen",
      "setEditAssignmentModalOpen",
      "setProjectModalOpen",
      "setSelectedProject",
    ]),
    handleClickOutside() {
      this.setOpenAssignment(null);
      this.$router.push({
        path: "editor",
        query: {
          pid: this.selectedProject.projectID,
          aid: null,
        },
      });
    },
    handleProjectListChange(i) {
      this.selectedProjectIndex = 0;
    },
    handleNewAssignmentModalOutside() {},
  },
};
</script>

<style lang="scss" scoped>
.editor-table {
  max-width: 1500px;
  min-width: 1500px;
  margin: 0 auto;
  display: flex;
  flex-flow: row;
  margin-top: 20px;

  &__project-list {
    width: 300px;
    flex: 0 0 auto;
    margin-right: 10px;
  }

  #text-editor-bottom-sheet {
    height: 90%;
  }
}
</style>
