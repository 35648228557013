<template>
  <div>
    <v-form
      v-if="!calendarMode && !adminCalendarMode"
      ref="form"
      v-model="valid"
      lazy-validation
      class="contact-form v-card v-sheet theme--light"
    >
      <div
        v-if="isEditMode && !profileView"
        class="d-flex justify-center align-center py-2"
      >
        <v-img
          max-width="36"
          width="36"
          height="36"
          :src="defaultAvatar"
          class="mr-2"
        ></v-img>
        <h2>{{ Name }}</h2>
      </div>
      <v-tabs v-model="tab" background-color="transparent" grow>
        <v-tab
          v-for="(step, index) in steps"
          :key="index"
          class="rounded"
          active-class="secondary"
        >
          <v-icon left>{{ `mdi-${step.icon}` }}</v-icon> {{ step.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <!-- OVERVIEW -->
        <v-tab-item>
          <v-container class="d-flex">
            <v-col cols="12" sm="4" class="pb-0">
              <v-text-field
                filled
                shaped
                readonly
                label="Name"
                v-model="Name"
              />
              <v-text-field
                filled
                shaped
                readonly
                label="Preferred Name"
                v-model="PreferredName"
              />
              <v-text-field
                filled
                shaped
                readonly
                label="Pronouns"
                v-model="Pronouns"
              />
              <v-text-field
                filled
                shaped
                readonly
                label="Location"
                v-model="Country"
              />
              <v-text-field
                v-model="StartDate"
                label="Start Date"
                filled
                shaped
                readonly
              ></v-text-field>
            </v-col>
            <v-divider vertical></v-divider>
            <v-card class="d-flex align-center pa-2 mx-4" width="65%" shaped>
              <div
                class="contact-form__profile-chips-container d-flex flex-column align-center justify-center"
              >
                <v-chip
                  v-if="skillsBadges.length"
                  x-large
                  label
                  class="contact-form__profile-chip overline text-center py-3 my-2 elevation-3"
                  color="blue lighten-2"
                  text-color="white"
                >
                  {{ skillsBadges[0].skill }}
                </v-chip>
                <v-chip
                  v-if="skillsBadges[1]"
                  x-large
                  label
                  class="contact-form__profile-chip overline text-center py-3 my-2 elevation-3"
                  color="blue lighten-2"
                  text-color="white"
                >
                  {{ skillsBadges[1].skill }}
                </v-chip>
                <v-chip
                  v-if="Language1"
                  x-large
                  label
                  class="contact-form__profile-chip overline text-center py-3 my-2 elevation-3"
                  color="pink lighten-3"
                  text-color="white"
                >
                  {{ Language1 }}
                </v-chip>
                <v-chip
                  v-if="expertiseBadges.length"
                  x-large
                  label
                  class="contact-form__profile-chip overline text-center py-3 my-2 elevation-3"
                  color="green lighten-2"
                  text-color="white"
                >
                  {{ expertiseBadges[0].expertise }}
                </v-chip>
                <v-chip
                  v-if="expertiseBadges[1]"
                  x-large
                  label
                  class="contact-form__profile-chip overline text-center py-3 my-2 elevation-3"
                  color="green lighten-2"
                  text-color="white"
                >
                  {{ expertiseBadges[1].expertise }}
                </v-chip>
              </div>
              <div
                class="contact-form__profile-avatar-container d-flex flex-column align-center"
              >
                <v-badge
                  :color="Status === 'Available' ? 'success' : 'secondary'"
                  overlap
                  bottom
                  :value="Status"
                >
                  <v-skeleton-loader
                    v-if="itemToEdit && itemToEdit.Photo && itemToEdit.Photo.url ? filesLoading : false"
                    width="350px"
                    type="image"
                  ></v-skeleton-loader>
                  <v-img
                    v-else
                    width="350"
                    height="350"
                    :src="itemToEdit && itemToEdit.Photo && itemToEdit.Photo.url ? avatar : defaultAvatar"
                    alt="Profile photo"
                  ></v-img>
                </v-badge>
                <div
                  class="contact-form__profile-avatar-info d-flex align-center pt-4 px-4"
                >
                  <div class="d-flex align-center mr-auto">
                    <span class="text-h5">Active:</span>
                    <v-icon
                      v-if="itemToEdit.ActiveStaff"
                      x-large
                      color="success"
                      >mdi-checkbox-marked-outline</v-icon
                    >
                    <v-icon
                      v-else
                      x-large
                      color="secondary"
                      :disabled="!isEditMode"
                      >mdi-close-box-outline</v-icon
                    >
                  </div>
                  <v-icon v-if="appleLogo" x-large class="mr-2" color="primary"
                    >mdi-food-apple</v-icon
                  >
                  <v-icon
                    v-if="androidLogo"
                    x-large
                    class="mr-2"
                    color="primary"
                    >mdi-android</v-icon
                  >
                </div>
              </div>
              <v-card-actions
                class="contact-form__profile-actions-container flex-column align-start pa-0"
              >
                <v-btn
                  icon
                  x-large
                  color="accent"
                  :disabled="!isEditMode"
                  @click="handleCalendar"
                >
                  <v-icon>mdi-calendar-month</v-icon>
                </v-btn>
                <v-btn
                  v-if="!profileView"
                  x-large
                  text
                  class="slack-btn ml-0"
                  :href="`slack://user?team=${cueFC}&id=${SlackFCWorkspaceID}`"
                  :disabled="!SlackFCWorkspaceID"
                >
                  <v-icon large color="accent" class="mr-1"> mdi-slack </v-icon
                  >FC</v-btn
                >
                <v-btn
                  v-if="!profileView"
                  x-large
                  text
                  class="slack-btn ml-0"
                  :href="`slack://user?team=${cueStaff}&id=${SlackStaffWorkspaceID}`"
                  :disabled="!SlackStaffWorkspaceID"
                >
                  <v-icon large color="accent" class="mr-1"> mdi-slack </v-icon
                  >Staff</v-btn
                >
                <v-btn
                  v-if="!profileView && ProjectCoordinator"
                  x-large
                  text
                  class="slack-btn ml-0"
                  :href="`slack://user?team=${cuePM}&id=${slackIDpm}`"
                  :disabled="!slackIDpm"
                >
                  <v-icon large color="accent" class="mr-1"> mdi-slack </v-icon
                  >PM</v-btn
                >
                <v-btn
                  v-if="!profileView"
                  icon
                  x-large
                  class="ml-0"
                  color="accent"
                  :disabled="!Email"
                  @click="sendEmail(Email)"
                >
                  <v-icon>mdi-email-outline</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-container>
        </v-tab-item>
        <!-- PERSONAL DETAILS -->
        <v-tab-item eager>
          <v-container class="d-flex flex-wrap">
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :disabled="!isViewMode && isEditMode"
                label="Name"
                v-model="Name"
                :rules="[rules.required]"
              />
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                label="Preferred Name"
                v-model="PreferredName"
                :rules="isAdmin ? [] : [rules.required]"
              />
              <v-combobox
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                label="Pronouns"
                :items="pronounsList"
                v-model="Pronouns"
              ></v-combobox>
              <v-row>
                <v-col cols="12" sm="6" class="pb-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    label="LinkedIn"
                    :clearable="!isViewMode"
                    v-model="LinkedInProfile"
                /></v-col>
                <v-col cols="12" sm="6" class="pb-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    label="Website"
                    :clearable="!isViewMode"
                    v-model="PersonalWebsite"
                /></v-col>
              </v-row>
            </v-col>
            <v-divider inset vertical></v-divider>
            <v-col cols="12" sm="6" class="pb-0 text-center">
              <v-row v-if="!isViewMode" class="mt-0"
                ><v-col cols="12" sm="6" class="py-0">
                  <v-file-input
                    outlined
                    v-model="PhotoBlob"
                    accept=".png, .jpg, .jpeg"
                    truncate-length="20"
                    label="Photo"
                    :loading="itemToEdit && itemToEdit.Photo && itemToEdit.Photo.url ? filesLoading : false"
                    :disabled="itemToEdit && itemToEdit.Photo && itemToEdit.Photo.url ? filesLoading : false"
                    @change="convertFile($event, 'Photo')"
                    @click:clear="filesToDelete.push('Photo')"
                    :rules="[rules.isImage]"
                /></v-col>
                <v-col cols="12" sm="6" class="py-0"
                  ><v-file-input
                    outlined
                    v-model="PortfolioFileBlob"
                    accept=".pdf"
                    truncate-length="20"
                    label="Portfolio"
                    :loading="
                      itemToEdit && itemToEdit.PortfolioFile && itemToEdit.PortfolioFile.url ? filesLoading : false
                    "
                    :disabled="
                      itemToEdit && itemToEdit.PortfolioFile && itemToEdit.PortfolioFile.url ? filesLoading : false
                    "
                    @change="convertFile($event, 'PortfolioFile')"
                    @click:clear="filesToDelete.push('PortfolioFile')"
                    :rules="[rules.isPDF]"
                /></v-col>
              </v-row>
              <v-btn
                v-else
                x-large
                width="70%"
                height="58px"
                color="accent"
                class="mb-7"
                :disabled="!itemToEdit || !itemToEdit.PortfolioFile || !itemToEdit.PortfolioFile.url"
                :loading="itemToEdit && itemToEdit.PortfolioFile && itemToEdit.PortfolioFile.url ? filesLoading : false"
                @click="downloadFile('PortfolioFile')"
              >
                Portfolio<v-icon right>mdi-download</v-icon>
              </v-btn>
              <v-row>
                <v-col cols="12" sm="6" :class="isViewMode ? 'pb-0' : 'py-0'">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Language 1 (Native)"
                    item-text="value"
                    :items="languagesList"
                    v-model="Language1"
                    :loading="dropdownsLoading"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" :class="isViewMode ? 'pb-0' : 'py-0'">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Language 1 Level"
                    :items="Language1LevelOptions"
                    v-model="Language1Level"
                    disabled
                    :rules="Language1 ? [rules.required] : []"
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Language 2"
                    item-text="value"
                    :items="languagesList"
                    v-model="Language2"
                    :loading="dropdownsLoading"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Language 2 Level"
                    :items="Language1LevelOptions"
                    v-model="Language2Level"
                    :disabled="!Language2"
                    :rules="Language2 ? [rules.required] : []"
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Language 3"
                    item-text="value"
                    :items="languagesList"
                    v-model="Language3"
                    :loading="dropdownsLoading"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Language 3 Level"
                    :items="Language1LevelOptions"
                    v-model="Language3Level"
                    :disabled="!Language3"
                    :rules="Language3 ? [rules.required] : []"
                  ></v-autocomplete
                ></v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" class="pb-0">
              <v-textarea
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                rows="3"
                :clearable="!isViewMode"
                clear-icon="mdi-close-circle-outline"
                label="Bio"
                v-model="Bio"
                :rules="isAdmin ? [] : [rules.required]"
              />
            </v-col>
          </v-container>
        </v-tab-item>
        <!-- HR DETAILS -->
        <v-tab-item eager>
          <v-container class="d-flex">
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :disabled="!isViewMode && isEditMode"
                :append-outer-icon="
                  Email ? 'mdi-email-arrow-right-outline' : null
                "
                label="Email"
                v-model="Email"
                :rules="[rules.required]"
                @click:append-outer="sendEmail(Email)"
              />
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :disabled="!isViewMode && !isAdmin"
                :append-outer-icon="
                  CueEmail ? 'mdi-email-arrow-right-outline' : null
                "
                label="Cue Email"
                :clearable="!isViewMode"
                v-model="CueEmail"
                @click:append-outer="sendEmail(CueEmail)"
              />
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-outer-icon="
                  Gmail ? 'mdi-email-arrow-right-outline' : null
                "
                label="Gmail"
                :clearable="!isViewMode"
                v-model="Gmail"
                @click:append-outer="sendEmail(Gmail)"
              />
              <v-autocomplete
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                label="Country"
                :items="CountryOptions"
                v-model="Country"
                :loading="dropdownsLoading"
                :rules="[rules.required]"
              ></v-autocomplete>
              <v-autocomplete
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                :append-outer-icon="StreetAddress ? 'mdi-earth' : null"
                label="Address"
                no-filter
                :items="addresses"
                :loading="loadPredictions"
                v-model="StreetAddress"
                @click:append-outer="seeOnMap"
                @update:search-input="getAddress($event)"
                :rules="isAdmin ? [] : [rules.required]"
              ></v-autocomplete>
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                label="Phone Number"
                :clearable="!isViewMode"
                v-model="PhoneNumber"
              />
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                label="Legal Name"
                :clearable="!isViewMode"
                v-model="LegalName"
                :rules="isAdmin ? [] : [rules.required]"
              />
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="6" class="pb-0 text-center">
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                label="SSN/Tax ID"
                :clearable="!isViewMode"
                v-model="ssnTaxID"
              />
              <v-row class="my-0">
                <v-col
                  cols="12"
                  sm="6"
                  class="text-center"
                  :class="isViewMode ? 'pt-0' : isAdmin ? 'py-0' : 'pt-0'"
                >
                  <v-file-input
                    v-if="!isViewMode && isAdmin"
                    outlined
                    v-model="taxDocumentBlob"
                    accept=".pdf"
                    truncate-length="20"
                    label="Tax Document"
                    :loading="
                      itemToEdit && itemToEdit['W-9(U.S.Only)'] && itemToEdit['W-9(U.S.Only)'].url ? filesLoading : false
                    "
                    :disabled="
                      itemToEdit && itemToEdit['W-9(U.S.Only)'] && itemToEdit['W-9(U.S.Only)'].url ? filesLoading : false
                    "
                    @change="convertFile($event, 'W-9(U.S.Only)')"
                    @click:clear="filesToDelete.push('W-9(U.S.Only)')"
                    :rules="[rules.isPDF]"
                  />
                  <v-btn
                    v-else
                    block
                    x-large
                    height="56px"
                    color="accent"
                    class="mb-6"
                    :disabled="!itemToEdit || !itemToEdit['W-9(U.S.Only)'] || !itemToEdit['W-9(U.S.Only)'].url"
                    :loading="
                      itemToEdit && itemToEdit['W-9(U.S.Only)'] && itemToEdit['W-9(U.S.Only)'].url ? filesLoading : false
                    "
                    @click="downloadFile('W-9(U.S.Only)')"
                  >
                    Tax Document<v-icon right>mdi-download</v-icon>
                  </v-btn></v-col
                >
                <v-col
                  cols="12"
                  sm="6"
                  class="text-center"
                  :class="isViewMode ? 'pt-0' : 'py-0'"
                >
                  <v-file-input
                    v-if="!isViewMode && isAdmin"
                    outlined
                    v-model="NDABlob"
                    accept=".pdf"
                    truncate-length="20"
                    label="NDA"
                    :loading="
                      itemToEdit && itemToEdit.NDA && itemToEdit.NDA.url ? filesLoading : false"
                    :disabled="
                      itemToEdit && itemToEdit.NDA && itemToEdit.NDA.url ? filesLoading : false"
                    @change="convertFile($event, 'NDA')"
                    @click:clear="filesToDelete.push('NDA')"
                    :rules="
                      isAdmin
                        ? [rules.requiredFile, rules.isPDF]
                        : [rules.isPDF]
                    "
                  />
                  <v-btn
                    v-else
                    block
                    x-large
                    height="56px"
                    color="accent"
                    class="mb-6"
                    :disabled="!itemToEdit || !itemToEdit.NDA || !itemToEdit.NDA.url"
                    :loading="itemToEdit && itemToEdit.NDA && itemToEdit.NDA.url ? filesLoading : false"
                    @click="downloadFile('NDA')"
                  >
                    NDA<v-icon right>mdi-download</v-icon>
                  </v-btn></v-col
                >
              </v-row>
              <v-row
                class="mt-0 justify-center"
                :class="isViewMode ? 'mb-3' : 'mb-0'"
              >
                <v-col
                  cols="12"
                  sm="8"
                  class="text-center"
                  :class="isViewMode ? 'pt-0' : 'py-0'"
                >
                  <v-file-input
                    v-if="!isViewMode && isAdmin"
                    outlined
                    v-model="ContractBlob"
                    accept=".pdf"
                    truncate-length="50"
                    label="Contract"
                    :loading="
                      itemToEdit && itemToEdit.Contract && itemToEdit.Contract.url ? filesLoading : false"
                    :disabled="
                      itemToEdit && itemToEdit.Contract && itemToEdit.Contract.url ? filesLoading : false"
                    @change="convertFile($event, 'Contract')"
                    @click:clear="filesToDelete.push('Contract')"
                    :rules="
                      isAdmin
                        ? [rules.requiredFile, rules.isPDF]
                        : [rules.isPDF]
                    "
                  />
                  <v-btn
                    v-else
                    block
                    x-large
                    height="56px"
                    color="accent"
                    :class="{ 'mb-6': !isViewMode && !isAdmin }"
                    :disabled="!itemToEdit || !itemToEdit.Contract || !itemToEdit.Contract.url"
                    :loading="itemToEdit && itemToEdit.Contract && itemToEdit.Contract.url ? filesLoading : false"
                    @click="downloadFile('Contract')"
                  >
                    Contract<v-icon right>mdi-download</v-icon>
                  </v-btn></v-col
                >
              </v-row>
              <v-autocomplete
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                label="Tax Residency"
                :items="CountryOptions"
                v-model="TaxResidency"
                :loading="dropdownsLoading"
                :rules="isAdmin ? [] : [rules.required]"
              ></v-autocomplete>
              <v-row class="my-0">
                <v-col
                  cols="12"
                  :sm="PaymentType === 'PayPal' ? 3 : 6"
                  class="py-0 text-center"
                >
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Payment Type"
                    :items="PaymentTypeOptions"
                    v-model="PaymentType"
                    :loading="dropdownsLoading"
                    :rules="isAdmin ? [rules.required] : []"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  v-if="PaymentType === 'PayPal'"
                  cols="12"
                  sm="6"
                  class="py-0 text-center"
                >
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    label="PayPal Email"
                    :clearable="!isViewMode"
                    v-model="PayPalEmail"
                    :rules="
                      PaymentType === 'PayPal' && isAdmin
                        ? [rules.required]
                        : []
                    "
                  />
                </v-col>
                <v-col
                  cols="12"
                  :sm="PaymentType === 'PayPal' ? 3 : 6"
                  class="py-0 text-center"
                >
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Currency"
                    :items="CurrencyOptions"
                    v-model="Currency"
                    :loading="dropdownsLoading"
                    :rules="isAdmin ? [rules.required] : []"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-expansion-panels v-if="isEditMode && isViewMode">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Cue Hardware
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-skeleton-loader
                      v-if="hardwareLoading"
                      type="list-item-avatar-two-line"
                    ></v-skeleton-loader>
                    <v-list v-else>
                      <v-list-item
                        v-for="hardware in cueHardware"
                        :key="hardware.mainInfo"
                      >
                        <v-list-item-avatar>
                          <v-icon large color="primary">
                            {{ `mdi-${hardware.icon}` }}
                          </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            v-text="hardware.mainInfo"
                          ></v-list-item-title>

                          <v-list-item-subtitle
                            v-if="hardware.onHandDate"
                            v-text="hardware.onHandDate"
                          ></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action
                          v-if="hardware.onHandDate && isAdmin"
                        >
                          <v-btn
                            icon
                            :href="`${fullHardwareDashboardPath}?Category=${hardware.category}&User=${Name}`"
                            target="_blank"
                          >
                            <v-icon large color="accent"
                              >mdi-information</v-icon
                            >
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item></v-list
                    >
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Cue Software
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-skeleton-loader
                      v-if="softwareLoading"
                      type="list-item-avatar-two-line"
                    ></v-skeleton-loader>
                    <v-list v-else>
                      <v-list-item
                        v-for="software in cueSoftware"
                        :key="software.name"
                      >
                        <v-list-item-avatar>
                          <v-icon large color="primary">
                            {{ `mdi-${software.provider}` }}
                          </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            v-text="software.name"
                          ></v-list-item-title>

                          <v-list-item-subtitle
                            v-if="software.type"
                            v-text="software.type"
                          ></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action v-if="software.type && isAdmin">
                          <v-btn
                            icon
                            :href="`${fullSoftwareDashboardPath}?Service=${software.name}`"
                            target="_blank"
                          >
                            <v-icon large color="accent"
                              >mdi-information</v-icon
                            >
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item></v-list
                    >
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-container>
        </v-tab-item>
        <!-- ADMIN DETAILS -->
        <v-tab-item v-if="isAdmin || isManager" eager>
          <v-container class="d-flex flex-wrap">
            <v-col cols="12" sm="6" class="pb-0">
              <v-row>
                <v-col cols="12" sm="4" class="pb-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    label="Slack ID (Staff)"
                    v-model="SlackStaffWorkspaceID"
                /></v-col>
                <v-col cols="12" sm="4" class="pb-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    label="Slack ID (FC)"
                    :clearable="!isViewMode"
                    v-model="SlackFCWorkspaceID"
                /></v-col>
                <v-col cols="12" sm="4" class="pb-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    label="Slack ID (PM)"
                    :clearable="!isViewMode"
                    v-model="slackIDpm"
                /></v-col>
              </v-row>
              <v-row class="align-center">
                <v-col cols="12" sm="4" class="d-flex justify-center pb-0">
                  <v-checkbox
                    readonly
                    :disabled="!isViewMode"
                    class="mt-0 pt-0"
                    prepend-icon="mdi-account-badge-outline"
                    label="Active Staff"
                    v-model="itemToEdit.ActiveStaff"
                  ></v-checkbox
                ></v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center pb-0">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    class="mt-0 pt-0"
                    prepend-icon="mdi-account-star-outline"
                    label="Lead Editor"
                    v-model="LeadEditor"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center pb-0">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    class="mt-0 pt-0"
                    prepend-icon="mdi-account-tie-outline"
                    label="PM"
                    v-model="ProjectCoordinator"
                  ></v-checkbox
                ></v-col>
              </v-row>
              <v-row class="align-center">
                <v-col cols="12" sm="3" class="d-flex justify-center">
                  <v-checkbox
                    class="contact-form__cue-checkbox"
                    :readonly="isViewMode"
                    :disabled="
                      !isEditMode ||
                      (!isViewMode && (!isAdmin || (itemToEdit && itemToEdit.cueHub)))
                    "
                    prepend-icon="mdi-account-key-outline"
                    label="CueHub"
                    :persistent-hint="
                      cueHub && (!isEditMode || !itemToEdit || !itemToEdit.cueHub)
                    "
                    hint="Adding a new user will automatically send an email to that user to setup password."
                    v-model="cueHub"
                    :rules="[rules.required]"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-tooltip v-model="resetSucess" bottom color="success">
                    <template v-slot:activator="{ attrs }">
                      <v-btn
                        small
                        :disabled="
                          !cueHub ||
                          !isAdmin ||
                          !isEditMode ||
                          isViewMode ||
                          !itemToEdit || !itemToEdit.cueHub
                        "
                        :loading="resetLoading"
                        @click="sendPasswordReset"
                        v-bind="attrs"
                      >
                        <v-icon class="mr-2">mdi-lock-reset</v-icon>Reset Pw
                      </v-btn>
                    </template>
                    <span>Password reset sent!</span>
                  </v-tooltip></v-col
                >
                <v-col cols="12" sm="3" class="d-flex justify-center">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && (!isAdmin || !cueHub || admin)"
                    label="Dub Editor"
                    v-model="dubEditor"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3" class="d-flex justify-center">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && (!isAdmin || !cueHub)"
                    prepend-icon="mdi-account-tie-hat-outline"
                    label="Admin"
                    v-model="admin"
                  ></v-checkbox
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4" class="pb-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Status"
                    :items="StatusOptions"
                    v-model="Status"
                    :loading="dropdownsLoading"
                    :rules="[rules.required]"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" sm="4" class="pb-0">
                  <v-menu
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="StartDate"
                        :rules="isAdmin ? [rules.required] : []"
                        :class="{ disabledPicker: isViewMode }"
                        label="Start Date"
                        prepend-icon="mdi-calendar-start"
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :disabled="!isViewMode && !isAdmin"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="startDateMenu = false"
                      v-model="StartDate"
                      :rules="[rules.required]"
                    ></v-date-picker> </v-menu
                ></v-col>
                <v-col cols="12" sm="4" class="pb-0"
                  ><v-menu
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="EndDate"
                        :class="{ disabledPicker: isViewMode }"
                        label="End Date"
                        prepend-icon="mdi-calendar-end"
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :disabled="!isViewMode && !isAdmin"
                        :clearable="!isViewMode"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="endDateMenu = false"
                      v-model="EndDate"
                    ></v-date-picker> </v-menu
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" :sm="isEditMode ? 4 : 6" class="pb-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    item-text="value"
                    :items="geosList"
                    label="GEO 1"
                    :clearable="!isViewMode"
                    v-model="GEO1"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" :sm="isEditMode ? 4 : 6" class="pb-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    item-text="value"
                    :items="geosList"
                    label="GEO 2"
                    :clearable="!isViewMode"
                    v-model="GEO2"
                  ></v-autocomplete
                ></v-col>
                <v-col v-if="isEditMode" cols="12" sm="4" class="pb-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    readonly
                    :disabled="!isViewMode"
                    label="Initials"
                    v-model="itemToEdit.Initials"
                /></v-col>
              </v-row>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="6" class="pb-0">
              <v-row class="align-center">
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    class="mt-0 pt-0"
                    prepend-icon="mdi-dropbox"
                    label="Dropbox"
                    v-model="Dropbox"
                  ></v-checkbox
                ></v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    class="mt-0 pt-0"
                    prepend-icon="mdi-trello"
                    label="Trello"
                    v-model="Trello"
                  ></v-checkbox
                ></v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    class="mt-0 pt-0"
                    label="SmartCat"
                    v-model="SmartCat"
                  ></v-checkbox
                ></v-col>
              </v-row>
              <v-row class="align-center">
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    class="mt-0 pt-0"
                    label="Box"
                    v-model="Box"
                  ></v-checkbox
                ></v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    class="mt-0 pt-0"
                    label="TryRating"
                    v-model="TryRating"
                  ></v-checkbox
                ></v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    class="mt-0 pt-0"
                    prepend-icon="mdi-food-apple"
                    label="Copy Portal"
                    v-model="AppleCopyPortal"
                  ></v-checkbox
                ></v-col>
              </v-row>
              <v-row
                class="justify-space-between align-center px-3 ma-0 rounded elevation-2"
              >
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-icon>mdi-fingerprint</v-icon>
                  <h3>Smartling</h3>
                </v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    class="mt-5 pa-0"
                    label="FC"
                    v-model="smartlingApple"
                  ></v-checkbox
                ></v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-checkbox
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    class="mt-5 pa-0"
                    label="Other"
                    v-model="Smartling"
                  ></v-checkbox
                ></v-col>
              </v-row>
              <div class="rounded elevation-2 pt-4 my-4">
                <v-row class="justify-center align-center ma-0">
                  <v-img
                    :src="SHK"
                    max-width="40px"
                    width="40px"
                    height="36px"
                    alt="Shakespeare"
                  />
                  <h3>Access</h3>
                </v-row>
                <v-row class="align-center ma-0">
                  <v-col cols="12" sm="4" class="d-flex justify-center">
                    <v-checkbox
                      :readonly="isViewMode"
                      :disabled="!isViewMode && !isAdmin"
                      class="mt-0 pt-0"
                      label="Apps: Writer"
                      v-model="shakespeareAppsWriterRole"
                    ></v-checkbox
                  ></v-col>
                  <v-col cols="12" sm="4" class="d-flex justify-center">
                    <v-checkbox
                      :readonly="isViewMode"
                      :disabled="!isViewMode && !isAdmin"
                      class="mt-0 pt-0"
                      label="Apps: Editor"
                      v-model="shakespeareAppsEditorRole"
                    ></v-checkbox
                  ></v-col>
                  <v-col cols="12" sm="4" class="d-flex justify-center">
                    <v-checkbox
                      :readonly="isViewMode"
                      :disabled="!isViewMode && !isAdmin"
                      class="mt-0 pt-0"
                      label="Music: Writer"
                      v-model="shakespeareMusicWriterRole"
                    ></v-checkbox
                  ></v-col>
                </v-row>
                <v-row class="align-center ma-0">
                  <v-col cols="12" sm="4" class="d-flex justify-center pb-0">
                    <v-checkbox
                      :readonly="isViewMode"
                      :disabled="!isViewMode && !isAdmin"
                      class="mt-0 pt-0"
                      label="Music: Editor"
                      v-model="shakespeareMusicEditorRole"
                    ></v-checkbox
                  ></v-col>
                  <v-col cols="12" sm="4" class="d-flex justify-center pb-0">
                    <v-checkbox
                      :readonly="isViewMode"
                      :disabled="!isViewMode && !isAdmin"
                      class="mt-0 pt-0"
                      label="Video: Writer"
                      v-model="shakespeareVideoWriterRole"
                    ></v-checkbox
                  ></v-col>
                  <v-col cols="12" sm="4" class="d-flex justify-center pb-0">
                    <v-checkbox
                      :readonly="isViewMode"
                      :disabled="!isViewMode && !isAdmin"
                      class="mt-0 pt-0"
                      label="Video: Editor"
                      v-model="shakespeareVideoEditorRole"
                    ></v-checkbox
                  ></v-col>
                </v-row>
              </div>
            </v-col>
            <v-col
              v-if="isEditMode && !profileView"
              cols="12"
              sm="12"
              class="d-flex justify-center pb-0"
            >
              <v-btn x-large @click="adminCalendarMode = true">
                Availability<v-icon right>mdi-calendar-month</v-icon>
              </v-btn>
            </v-col>
          </v-container>
        </v-tab-item>
        <!-- EXPERTISE -->
        <v-tab-item eager>
          <v-container class="d-flex flex-wrap">
            <v-col cols="12" sm="6" class="py-0">
              <h2 class="text-center mb-2">Skills</h2>
              <v-row class="mt-0">
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Skill 1"
                    :items="activeSkillsList"
                    item-text="value"
                    v-model="Skill1"
                    :rules="isAdmin ? [] : [rules.required]"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Skill 1 Years of Experience"
                    :items="Skill2YearsOfExperienceOptions"
                    v-model="Skill1YearsOfExperience"
                    :disabled="!Skill1"
                    :rules="Skill1 ? [rules.required] : []"
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Skill 2"
                    :items="activeSkillsList"
                    item-text="value"
                    v-model="Skill2"
                    :rules="isAdmin ? [] : [rules.required]"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Skill 2 Years of Experience"
                    :items="Skill2YearsOfExperienceOptions"
                    v-model="Skill2YearsOfExperience"
                    :disabled="!Skill2"
                    :rules="Skill2 ? [rules.required] : []"
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Skill 3"
                    :items="activeSkillsList"
                    item-text="value"
                    v-model="Skill3"
                    :rules="isAdmin ? [] : [rules.required]"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Skill 3 Years of Experience"
                    :items="Skill2YearsOfExperienceOptions"
                    v-model="Skill3YearsOfExperience"
                    :disabled="!Skill3"
                    :rules="Skill3 ? [rules.required] : []"
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Skill 4"
                    :items="activeSkillsList"
                    item-text="value"
                    v-model="Skill4"
                    :rules="isAdmin ? [] : [rules.required]"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Skill 4 Years of Experience"
                    :items="Skill2YearsOfExperienceOptions"
                    v-model="Skill4YearsOfExperience"
                    :disabled="!Skill4"
                    :rules="Skill4 ? [rules.required] : []"
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Skill 5"
                    :items="activeSkillsList"
                    item-text="value"
                    v-model="Skill5"
                    :rules="isAdmin ? [] : [rules.required]"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Skill 5 Years of Experience"
                    :items="Skill2YearsOfExperienceOptions"
                    v-model="Skill5YearsOfExperience"
                    :disabled="!Skill5"
                    :rules="Skill5 ? [rules.required] : []"
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Skill 6"
                    :items="activeSkillsList"
                    item-text="value"
                    v-model="Skill6"
                    :rules="isAdmin ? [] : [rules.required]"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Skill 6 Years of Experience"
                    :items="Skill2YearsOfExperienceOptions"
                    v-model="Skill6YearsOfExperience"
                    :disabled="!Skill6"
                    :rules="Skill6 ? [rules.required] : []"
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Skill 7"
                    :items="activeSkillsList"
                    item-text="value"
                    v-model="Skill7"
                    :rules="isAdmin ? [] : [rules.required]"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Skill 7 Years of Experience"
                    :items="Skill2YearsOfExperienceOptions"
                    v-model="Skill7YearsOfExperience"
                    :disabled="!Skill7"
                    :rules="Skill7 ? [rules.required] : []"
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Skill 8"
                    :items="activeSkillsList"
                    item-text="value"
                    v-model="Skill8"
                    :rules="isAdmin ? [] : [rules.required]"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Skill 8 Years of Experience"
                    :items="Skill2YearsOfExperienceOptions"
                    v-model="Skill8YearsOfExperience"
                    :disabled="!Skill8"
                    :rules="Skill8 ? [rules.required] : []"
                  ></v-autocomplete
                ></v-col>
              </v-row>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="6" class="py-0">
              <h2 class="text-center mb-2">Genre Expertise and Specialty</h2>
              <v-expansion-panels :value="0">
                <v-expansion-panel>
                  <v-expansion-panel-header
                    :class="{
                      'expertise-error':
                        !isAdmin && !isViewMode && !MusicYearsOfExperience,
                    }"
                  >
                    Music
                  </v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Years of Experience"
                        :items="Skill2YearsOfExperienceOptions"
                        v-model="MusicYearsOfExperience"
                        :rules="isAdmin ? [] : [rules.required]"
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Genre/Topic Expertise"
                        multiple
                        :counter="20"
                        :rules="[rules.maxCount]"
                        chips
                        deletable-chips
                        :clearable="!isViewMode"
                        :items="MusicExpertiseListOptions"
                        v-model="MusicExpertiseList"
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Specialty"
                        multiple
                        :counter="20"
                        :rules="[rules.maxCount]"
                        chips
                        deletable-chips
                        :clearable="!isViewMode"
                        :items="MusicSpecialtyOptions"
                        v-model="MusicSpecialty"
                      ></v-autocomplete
                    ></v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    :class="{
                      'expertise-error':
                        !isAdmin && !isViewMode && !filmAndTvYearsOfExperience,
                    }"
                  >
                    Film & TV
                  </v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Years of Experience"
                        :items="Skill2YearsOfExperienceOptions"
                        v-model="filmAndTvYearsOfExperience"
                        :rules="isAdmin ? [] : [rules.required]"
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Genre/Topic Expertise"
                        multiple
                        :counter="20"
                        :rules="[rules.maxCount]"
                        chips
                        deletable-chips
                        :clearable="!isViewMode"
                        :items="filmAndTvExpertiseListOptions"
                        v-model="filmAndTvExpertiseList"
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Specialty"
                        multiple
                        :counter="20"
                        :rules="[rules.maxCount]"
                        chips
                        deletable-chips
                        :clearable="!isViewMode"
                        :items="MusicSpecialtyOptions"
                        v-model="filmAndTVSpecialty"
                      ></v-autocomplete
                    ></v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    :class="{
                      'expertise-error':
                        !isAdmin && !isViewMode && !BooksYearsOfExperience,
                    }"
                  >
                    Books
                  </v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Years of Experience"
                        :items="Skill2YearsOfExperienceOptions"
                        v-model="BooksYearsOfExperience"
                        :rules="isAdmin ? [] : [rules.required]"
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Genre/Topic Expertise"
                        multiple
                        :counter="20"
                        :rules="[rules.maxCount]"
                        chips
                        deletable-chips
                        :clearable="!isViewMode"
                        :items="BooksExpertiseListOptions"
                        v-model="BooksExpertiseList"
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Specialty"
                        multiple
                        :counter="20"
                        :rules="[rules.maxCount]"
                        chips
                        deletable-chips
                        :clearable="!isViewMode"
                        :items="MusicSpecialtyOptions"
                        v-model="BooksSpecialty"
                      ></v-autocomplete
                    ></v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    :class="{
                      'expertise-error':
                        !isAdmin &&
                        !isViewMode &&
                        !mobileAndVideoGamesYearsOfExperience,
                    }"
                  >
                    Mobile/Video Games
                  </v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Years of Experience"
                        :items="Skill2YearsOfExperienceOptions"
                        v-model="mobileAndVideoGamesYearsOfExperience"
                        :rules="isAdmin ? [] : [rules.required]"
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Genre/Topic Expertise"
                        multiple
                        :counter="20"
                        :rules="[rules.maxCount]"
                        chips
                        deletable-chips
                        :clearable="!isViewMode"
                        :items="mobileAndVideoGamesExpertiseListOptions"
                        v-model="mobileAndVideoGamesExpertiseList"
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Specialty"
                        multiple
                        :counter="20"
                        :rules="[rules.maxCount]"
                        chips
                        deletable-chips
                        :clearable="!isViewMode"
                        :items="MusicSpecialtyOptions"
                        v-model="mobileAndVideoGamesSpecialty"
                      ></v-autocomplete
                    ></v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    :class="{
                      'expertise-error':
                        !isAdmin && !isViewMode && !PodcastsYearsOfExperience,
                    }"
                  >
                    Podcasts
                  </v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Years of Experience"
                        :items="Skill2YearsOfExperienceOptions"
                        v-model="PodcastsYearsOfExperience"
                        :rules="isAdmin ? [] : [rules.required]"
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Genre/Topic Expertise"
                        multiple
                        :counter="20"
                        :rules="[rules.maxCount]"
                        chips
                        deletable-chips
                        :clearable="!isViewMode"
                        :items="PodcastsExpertiseListOptions"
                        v-model="PodcastsExpertiseList"
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Specialty"
                        multiple
                        :counter="20"
                        :rules="[rules.maxCount]"
                        chips
                        deletable-chips
                        :clearable="!isViewMode"
                        :items="MusicSpecialtyOptions"
                        v-model="PodcastsSpecialty"
                      ></v-autocomplete
                    ></v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    :class="{
                      'expertise-error':
                        !isAdmin && !isViewMode && !MobileAppsYearsOfExperience,
                    }"
                  >
                    Mobile Apps
                  </v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Years of Experience"
                        :items="Skill2YearsOfExperienceOptions"
                        v-model="MobileAppsYearsOfExperience"
                        :rules="isAdmin ? [] : [rules.required]"
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Genre/Topic Expertise"
                        multiple
                        :counter="20"
                        :rules="[rules.maxCount]"
                        chips
                        deletable-chips
                        :clearable="!isViewMode"
                        :items="MobileAppsExpertiseListOptions"
                        v-model="MobileAppsExpertiseList"
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Specialty"
                        multiple
                        :counter="20"
                        :rules="[rules.maxCount]"
                        chips
                        deletable-chips
                        :clearable="!isViewMode"
                        :items="MusicSpecialtyOptions"
                        v-model="MobileAppsSpecialty"
                      ></v-autocomplete
                    ></v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    :class="{
                      'expertise-error':
                        !isAdmin && !isViewMode && !LifestyleYearsOfExperience,
                    }"
                  >
                    Lifestyle
                  </v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Years of Experience"
                        :items="Skill2YearsOfExperienceOptions"
                        v-model="LifestyleYearsOfExperience"
                        :rules="isAdmin ? [] : [rules.required]"
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Genre/Topic Expertise"
                        multiple
                        :counter="20"
                        :rules="[rules.maxCount]"
                        chips
                        deletable-chips
                        :clearable="!isViewMode"
                        :items="LifestyleExpertiseListOptions"
                        v-model="LifestyleExpertiseList"
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Specialty"
                        multiple
                        :counter="20"
                        :rules="[rules.maxCount]"
                        chips
                        deletable-chips
                        :clearable="!isViewMode"
                        :items="MusicSpecialtyOptions"
                        v-model="LifestyleSpecialty"
                      ></v-autocomplete
                    ></v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    :class="{
                      'expertise-error':
                        !isAdmin && !isViewMode && !SportsYearsOfExperience,
                    }"
                  >
                    Sports
                  </v-expansion-panel-header>
                  <v-expansion-panel-content eager>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Years of Experience"
                        :items="Skill2YearsOfExperienceOptions"
                        v-model="SportsYearsOfExperience"
                        :rules="isAdmin ? [] : [rules.required]"
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Genre/Topic Expertise"
                        multiple
                        :counter="20"
                        :rules="[rules.maxCount]"
                        chips
                        deletable-chips
                        :clearable="!isViewMode"
                        :items="SportsExpertiseListOptions"
                        v-model="SportsExpertiseList"
                      ></v-autocomplete
                    ></v-col>
                    <v-col cols="12" sm="12" class="py-0">
                      <v-autocomplete
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        :readonly="isViewMode"
                        :append-icon="isViewMode ? '' : '$dropdown'"
                        label="Specialty"
                        multiple
                        :counter="20"
                        :rules="[rules.maxCount]"
                        chips
                        deletable-chips
                        :clearable="!isViewMode"
                        :items="MusicSpecialtyOptions"
                        v-model="SportsSpecialty"
                      ></v-autocomplete
                    ></v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-container>
        </v-tab-item>
        <!-- SYSTEMS AND DEVICES -->
        <v-tab-item>
          <v-container class="d-flex flex-wrap">
            <v-col cols="12" sm="6" class="py-0">
              <h2 class="text-center">Software</h2>
              <p class="text-center caption mb-2">
                SOFTWARE YOU HAVE STRONG KNOWLEDGE OF
              </p>
              <v-autocomplete
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                label="Music Streaming Services"
                multiple
                :counter="20"
                :rules="[rules.maxCount]"
                chips
                deletable-chips
                :items="MusicStreamingServicesOptions"
                v-model="MusicStreamingServices"
              ></v-autocomplete>
              <v-autocomplete
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                label="Film/TV Streaming Services"
                multiple
                :counter="20"
                :rules="[rules.maxCount]"
                chips
                deletable-chips
                :items="filmAndTvStreamingServicesOptions"
                v-model="filmAndTvStreamingServices"
              ></v-autocomplete>
              <v-autocomplete
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                label="Productivity Tools"
                multiple
                :counter="20"
                :rules="[rules.maxCount]"
                chips
                deletable-chips
                :items="ProductivityToolsOptions"
                v-model="ProductivityTools"
              ></v-autocomplete>
              <v-autocomplete
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                label="CAT Tools"
                multiple
                :counter="20"
                :rules="[rules.maxCount]"
                chips
                deletable-chips
                :items="CATToolsOptions"
                v-model="CATTools"
              ></v-autocomplete>
              <h2 class="text-center">Operating System</h2>
              <p class="text-center caption mb-2">
                OPERATING SYSTEM YOUR DEVICE RUNS ON
              </p>
              <v-row class="mt-0">
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="OS 1"
                    :items="operatingSystemsList"
                    item-text="value"
                    :clearable="!isViewMode"
                    v-model="OS1"
                    :rules="isAdmin ? [] : [rules.required]"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="OS 1 Version Year"
                    :items="yearOptions"
                    v-model="OS1VersionYear"
                    :disabled="!OS1"
                    :rules="OS1 ? [rules.required] : []"
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="OS 2"
                    :items="operatingSystemsList"
                    item-text="value"
                    :clearable="!isViewMode"
                    v-model="OS2"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="OS 2 Version Year"
                    :items="yearOptions"
                    v-model="OS2VersionYear"
                    :disabled="!OS2"
                    :rules="OS2 ? [rules.required] : []"
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="OS 3"
                    :items="operatingSystemsList"
                    item-text="value"
                    :clearable="!isViewMode"
                    v-model="OS3"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="OS 3 Version Year"
                    :items="yearOptions"
                    v-model="OS3VersionYear"
                    :disabled="!OS3"
                    :rules="OS3 ? [rules.required] : []"
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="OS 4"
                    :items="operatingSystemsList"
                    item-text="value"
                    :clearable="!isViewMode"
                    v-model="OS4"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="OS 4 Version Year"
                    :items="yearOptions"
                    v-model="OS4VersionYear"
                    :disabled="!OS4"
                    :rules="OS4 ? [rules.required] : []"
                  ></v-autocomplete
                ></v-col>
              </v-row>
            </v-col>
            <v-divider inset vertical></v-divider>
            <v-col cols="12" sm="6" class="py-0">
              <h2 class="text-center">Hardware</h2>
              <p class="text-center caption mb-2">
                HARDWARE YOU CAN USE FOR COMPANY CUE PROJECTS
              </p>
              <v-autocomplete
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                label="Desktop Computer"
                multiple
                :counter="20"
                :rules="[rules.maxCount]"
                chips
                deletable-chips
                :clearable="!isViewMode"
                :items="DesktopComputerOptions"
                v-model="DesktopComputer"
              ></v-autocomplete>
              <v-autocomplete
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                label="Laptop"
                multiple
                :counter="20"
                :rules="[rules.maxCount]"
                chips
                deletable-chips
                :clearable="!isViewMode"
                :items="LaptopOptions"
                v-model="Laptop"
              ></v-autocomplete>
              <v-autocomplete
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                label="Phone"
                multiple
                :counter="20"
                :rules="[rules.maxCount]"
                chips
                deletable-chips
                :clearable="!isViewMode"
                :items="PhoneOptions"
                v-model="Phone"
              ></v-autocomplete>
              <v-autocomplete
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                label="Tablet"
                multiple
                :counter="20"
                :rules="[rules.maxCount]"
                chips
                deletable-chips
                :clearable="!isViewMode"
                :items="TabletOptions"
                v-model="Tablet"
              ></v-autocomplete>
              <v-autocomplete
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                label="Streaming TV Device"
                multiple
                :counter="20"
                :rules="[rules.maxCount]"
                chips
                deletable-chips
                :clearable="!isViewMode"
                :items="StreamingTVDeviceOptions"
                v-model="StreamingTVDevice"
              ></v-autocomplete>
              <v-autocomplete
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                label="Watch"
                multiple
                :counter="20"
                :rules="[rules.maxCount]"
                chips
                deletable-chips
                :clearable="!isViewMode"
                :items="WatchOptions"
                v-model="Watch"
              ></v-autocomplete>
              <v-autocomplete
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-icon="isViewMode ? '' : '$dropdown'"
                label="VR/AR Device"
                multiple
                :counter="20"
                :rules="[rules.maxCount]"
                chips
                deletable-chips
                :clearable="!isViewMode"
                :items="vrArDeviceOptions"
                v-model="vrArDevice"
              ></v-autocomplete>
            </v-col>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions
        :class="isEditMode ? 'justify-space-between' : 'justify-end'"
      >
        <div v-if="isEditMode">
          <v-btn
            v-if="
              profileView
                ? itemToEdit['LastDeliveryDate-Writer'] ||
                  itemToEdit['LastDeliveryDate-Editor']
                : true
            "
            color="primary"
            :href="`${getDashboardPath('fcDashboard')}${
              profileView
                ? ''
                : `?ppm=${$moment(
                    itemToEdit[
                      `LastDeliveryDate-${
                        itemToEdit['LastDeliveryDate-Writer']
                          ? 'Writer'
                          : 'Editor'
                      }`
                    ].split('-')[1]
                  ).format('MMMM')}&ppy=${
                    itemToEdit[
                      `LastDeliveryDate-${
                        itemToEdit['LastDeliveryDate-Writer']
                          ? 'Writer'
                          : 'Editor'
                      }`
                    ].split('-')[0]
                  }&Person=${Name}`
            }`"
            target="_blank"
            :disabled="
              !itemToEdit['LastDeliveryDate-Writer'] &&
              !itemToEdit['LastDeliveryDate-Editor']
            "
          >
            {{
              `${
                (profileView &&
                  (itemToEdit["LastDeliveryDate-Primary"] ||
                    itemToEdit["LastDeliveryDate-Reviewer"])) ||
                isAdmin ||
                isManager
                  ? "FC"
                  : ""
              } Assignments`
            }}
          </v-btn>
          <v-btn
            v-if="
              profileView
                ? itemToEdit['LastDeliveryDate-Primary'] ||
                  itemToEdit['LastDeliveryDate-Reviewer']
                : true
            "
            class="ml-2"
            color="primary"
            :href="`${getDashboardPath('cpmDashboard')}${
              profileView
                ? ''
                : `?ppm=${$moment(
                    itemToEdit[
                      `LastDeliveryDate-${
                        itemToEdit['LastDeliveryDate-Primary']
                          ? 'Primary'
                          : 'Reviewer'
                      }`
                    ].split('-')[1]
                  ).format('MMMM')}&ppy=${
                    itemToEdit[
                      `LastDeliveryDate-${
                        itemToEdit['LastDeliveryDate-Primary']
                          ? 'Primary'
                          : 'Reviewer'
                      }`
                    ].split('-')[0]
                  }&Person=${Name}`
            }`"
            target="_blank"
            :disabled="
              !itemToEdit['LastDeliveryDate-Primary'] &&
              !itemToEdit['LastDeliveryDate-Reviewer']
            "
          >
            {{
              `${
                (profileView &&
                  (itemToEdit["LastDeliveryDate-Writer"] ||
                    itemToEdit["LastDeliveryDate-Editor"])) ||
                isAdmin ||
                isManager
                  ? "Cue"
                  : ""
              } Assignments`
            }}
          </v-btn>
        </div>
        <v-scale-transition>
          <div v-if="deleteConfirmation" class="d-flex align-center">
            <h3>Are you sure?</h3>
            <v-btn class="ml-2" color="success" @click="deleteContact">
              Yes
            </v-btn>
            <v-btn
              class="ml-2"
              color="error"
              @click="deleteConfirmation = false"
            >
              No
            </v-btn>
          </div>
        </v-scale-transition>
        <v-scale-transition>
          <v-container
            v-if="closingConfirmation"
            class="d-flex align-center contact-form__close-conf-block"
          >
            <h5>
              {{
                `Do you want to leave without saving your changes? To save your changes, press "No" and then press "Update"${
                  profileLock ? " on your SYSTEMS AND DEVICES tab" : ""
                }.`
              }}
            </h5>
            <v-btn class="ml-2" color="success" @click="closeForm"> Yes </v-btn>
            <v-btn
              class="ml-2"
              color="error"
              @click="closingConfirmation = false"
            >
              No
            </v-btn>
          </v-container>
        </v-scale-transition>
        <div>
          <v-scroll-x-reverse-transition>
            <v-btn
              v-if="!isViewMode && isEditMode && !profileView && isAdmin"
              min-width="unset"
              color="secondary"
              :loading="contactHandling"
              :disabled="deleteConfirmation"
              @click="deleteConfirmation = true"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-scroll-x-reverse-transition>
          <v-scroll-x-reverse-transition>
            <v-btn
              v-if="!isViewMode && (!isManager || profileView) && showUpateBtn"
              class="ml-2"
              color="secondary"
              :loading="contactHandling"
              :disabled="deleteConfirmation || closingConfirmation"
              @click="handleContact"
            >
              {{ isEditMode ? "Update" : "Save" }}
            </v-btn>
          </v-scroll-x-reverse-transition>
          <v-btn
            v-if="isEditMode && (!isManager || profileView)"
            class="ml-2"
            color="secondary"
            :disabled="deleteConfirmation || closingConfirmation"
            @click="handleValidationOnModeChange"
          >
            {{ isViewMode ? "Edit Mode" : "View Mode" }}
          </v-btn>
          <v-btn
            class="ml-2"
            color="secondary"
            :loading="contactHandling"
            :disabled="deleteConfirmation || closingConfirmation"
            @click="handleClose"
          >
            {{ isViewMode ? "Close" : "Cancel" }}
          </v-btn>
        </div></v-card-actions
      >
    </v-form>
    <Calendar
      v-if="calendarMode"
      :clientView="true"
      :employee="itemToEdit.Name"
    />
    <AdminCalendar
      v-if="adminCalendarMode"
      :profileView="true"
      :mode="1"
      :employeeToSearch="{ Name: itemToEdit.Name }"
    />
    <v-btn
      v-if="calendarMode || adminCalendarMode"
      min-width="45px"
      height="45px"
      class="contact-form__return-btn px-0"
      color="primary"
      @click="calendarMode = adminCalendarMode = false"
      ><v-icon>mdi-arrow-right</v-icon></v-btn
    >
    <!-- tabs validation note -->
    <v-snackbar
      v-model="formProgressSnackbar"
      min-width="fit-content"
      elevation="12"
      timeout="-1"
      color="secondary"
      rounded="pill"
      content-class="text-center"
      centered
    >
      Please check all form tabs and make sure that required fields are
      completed (don't forget the dropdown menus in the Expertise section).
      <v-btn icon @click="formProgressSnackbar = false">
        <v-icon> mdi-close-circle-outline </v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// library
import { saveAs } from "file-saver";
// internal
import {
  appIDs,
  getFieldInfosFromDB,
  getFile,
  getHardware,
  getSoftware,
  contactsFieldsLookup,
  processContactRow,
  deleteRowFromQB,
  deleteFile,
} from "@/utils/quickbaseUtils";
import defaultAvatar from "@/assets/cue_logo.svg";
import { workspaces, publishMessage } from "@/utils/slackUtils";
import { DOMAIN, ROUTES } from "@/utils/constants";
import SHK from "@/assets/SHK.svg";
import { timeUnits } from "@/utils/mixins";
import { auth, functions } from "@/providers/firebase";
import { createNewUserWithPasswordSendEmail } from "@/utils/authUtils";
// components
import Calendar from "@/components/AvailabilityCalendar/Calendar";
import AdminCalendar from "@/components/AvailabilityCalendar/AdminCalendar";

export default {
  name: "ContactForm",
  components: {
    Calendar,
    AdminCalendar,
  },
  props: {
    itemToEdit: {
      type: Object,
      required: true,
    },
    profileView: {
      type: Boolean,
      required: true,
    },
    tableFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tab: 1,
      formProgressSnackbar: false,
      dropdownsLoading: true,
      Name: "",
      PreferredName: "",
      pronounsList: ["She/Her", "He/Him", "They/Them"],
      Pronouns: "",
      CountryOptions: [],
      Country: "",
      startDateMenu: false,
      StartDate: "",
      filesLoading: false,
      avatar: "",
      Photo: null,
      PhotoBlob: null,
      LeadEditor: false,
      ProjectCoordinator: false,
      avatarLoading: false,
      defaultAvatar,
      CueEmail: "",
      PersonalWebsite: "",
      LinkedInProfile: "",
      PortfolioFile: null,
      PortfolioFileBlob: null,
      Language2Options: [],
      Language1: "",
      Language2: "",
      Language3: "",
      Language1LevelOptions: [],
      Language1Level: "Native",
      Language2Level: "",
      Language3Level: "",
      Bio: "",
      Email: "",
      Gmail: "",
      loadPredictions: false,
      addresses: [],
      StreetAddress: "",
      PhoneNumber: "",
      LegalName: "",
      NDA: null,
      NDABlob: null,
      Contract: null,
      ContractBlob: null,
      TaxResidency: "",
      PaymentTypeOptions: [],
      PaymentType: "",
      PayPalEmail: "",
      CurrencyOptions: [],
      Currency: "",
      ["SSN/TaxID"]: "",
      ["W-9(U.S.Only)"]: null,
      ["W-9(U.S.Only)Blob"]: null,
      hardwareLoading: false,
      cueHardware: [],
      softwareLoading: false,
      cueSoftware: [],
      SlackStaffWorkspaceID: "",
      SlackFCWorkspaceID: "",
      ["SlackCo.CueWorkspaceID(PMOnly)"]: "",
      StatusOptions: [],
      Status: "",
      startDateMenu: false,
      endDateMenu: false,
      EndDate: "",
      GEO1Options: [],
      GEO1: "",
      GEO2: "",
      Dropbox: false,
      Trello: false,
      SmartCat: false,
      Box: false,
      TryRating: false,
      AppleCopyPortal: false,
      Smartling: false,
      ["Smartling(Apple)"]: false,
      SHK,
      ["ShakespeareApps:WriterRole"]: false,
      ["ShakespeareApps:EditorRole"]: false,
      ["ShakespeareMusic:WriterRole"]: false,
      ["ShakespeareMusic:EditorRole"]: false,
      ["ShakespeareVideo:WriterRole"]: false,
      ["ShakespeareVideo:EditorRole"]: false,
      Skill2Options: [],
      Skill2YearsOfExperienceOptions: [],
      Skill1: "",
      Skill1YearsOfExperience: "",
      Skill2: "",
      Skill2YearsOfExperience: "",
      Skill3: "",
      Skill3YearsOfExperience: "",
      Skill4: "",
      Skill4YearsOfExperience: "",
      Skill5: "",
      Skill5YearsOfExperience: "",
      Skill6: "",
      Skill6YearsOfExperience: "",
      Skill7: "",
      Skill7YearsOfExperience: "",
      Skill8: "",
      Skill8YearsOfExperience: "",
      MusicSpecialtyOptions: [],
      MusicExpertiseListOptions: [],
      MusicExpertiseList: [],
      MusicYearsOfExperience: "",
      MusicSpecialty: [],
      ["Film&TVExpertiseListOptions"]: [],
      ["Film&TVExpertiseList"]: [],
      ["Film&TVYearsOfExperience"]: "",
      ["Film&TVSpecialty"]: [],
      BooksExpertiseListOptions: [],
      BooksExpertiseList: [],
      BooksYearsOfExperience: "",
      BooksSpecialty: [],
      ["Mobile/VideoGamesExpertiseListOptions"]: [],
      ["Mobile/VideoGamesExpertiseList"]: [],
      ["Mobile/VideoGamesYearsOfExperience"]: "",
      ["Mobile/VideoGamesSpecialty"]: [],
      PodcastsExpertiseListOptions: [],
      PodcastsExpertiseList: [],
      PodcastsYearsOfExperience: "",
      PodcastsSpecialty: [],
      MobileAppsExpertiseListOptions: [],
      MobileAppsExpertiseList: [],
      MobileAppsYearsOfExperience: "",
      MobileAppsSpecialty: [],
      LifestyleExpertiseListOptions: [],
      LifestyleExpertiseList: [],
      LifestyleYearsOfExperience: "",
      LifestyleSpecialty: [],
      SportsExpertiseListOptions: [],
      SportsExpertiseList: [],
      SportsYearsOfExperience: "",
      SportsSpecialty: [],
      MusicStreamingServicesOptions: [],
      MusicStreamingServices: [],
      ["Film/TVStreamingServicesOptions"]: [],
      ["Film/TVStreamingServices"]: [],
      ProductivityToolsOptions: [],
      ProductivityTools: [],
      CATToolsOptions: [],
      CATTools: [],
      OS1Options: [],
      OS1: "",
      OS2: "",
      OS3: "",
      OS4: "",
      OS1VersionYear: "",
      OS2VersionYear: "",
      OS3VersionYear: "",
      OS4VersionYear: "",
      DesktopComputerOptions: [],
      DesktopComputer: [],
      LaptopOptions: [],
      Laptop: [],
      PhoneOptions: [],
      Phone: [],
      TabletOptions: [],
      Tablet: [],
      StreamingTVDeviceOptions: [],
      StreamingTVDevice: [],
      WatchOptions: [],
      Watch: [],
      ["VR/ARDeviceOptions"]: [],
      ["VR/ARDevice"]: [],
      dubEditor: false,
      admin: false,
      cueHub: false,
      resetLoading: false,
      resetSucess: false,
      isViewMode: false,
      calendarMode: false,
      adminCalendarMode: false,
      contactHandling: false,
      deleteConfirmation: false,
      filesToDelete: [],
      closingConfirmation: false,
      showUpateBtn: true,
      valid: true,
      rules: {
        required: (value) => !!value || "Required",
        requiredFile: (value) =>
          (value ? !!(value?.size + "") : !!value) || "Required",
        isImage: (value) =>
          value?.type == "image/jpeg" ||
          value?.type == "image/jpg" ||
          value?.type == "image/png" ||
          value === null ||
          "Only JPEG (JPG) or PNG images are allowed.",
        isPDF: (value) =>
          value?.type == "application/pdf" ||
          value === null ||
          "Only PDF attachments are allowed.",
        maxCount: (value) =>
          value.length <= 20 || "Only 20 selections are allowed.",
      },
    };
  },
  mounted() {
    this.getFieldsData();
    this.dataViewsHandler();
  },
  mixins: [timeUnits],
  computed: {
    ...mapGetters("auth", ["user", "isAdmin", "profileLock"]),
    isManager() {
      return this.user.ProjectCoordinator;
    },
    steps() {
      const allSteps = [
        { icon: "card-account-details-outline", title: "OVERVIEW" },
        { icon: "account-circle-outline", title: "PERSONAL DETAILS" },
        { icon: "file-account-outline", title: "HR DETAILS" },
        { icon: "star-circle-outline", title: "EXPERTISE" },
        { icon: "devices", title: "SYSTEMS AND DEVICES" },
      ];
      if (this.isAdmin || this.isManager) {
        if (!allSteps.includes({ icon: "security", title: "ADMIN DETAILS" }))
          allSteps.splice(3, 0, { icon: "security", title: "ADMIN DETAILS" });
      } else {
        if (allSteps.includes({ icon: "security", title: "ADMIN DETAILS" }))
          allSteps.splice(3, 1);
      }
      return allSteps;
    },
    isEditMode() {
      return !!Object.keys(this.itemToEdit).length;
    },
    appleLogo() {
      return (
        this.handleLogo("Apple") ||
        this.handleLogo("MacOS") ||
        this.handleLogo("Apple Music") ||
        this.handleLogo("Apple TV") ||
        this.handleLogo("Apple Keynote") ||
        this.handleLogo("Apple Numbers") ||
        this.handleLogo("Apple Pages")
      );
    },
    androidLogo() {
      return (
        this.handleLogo("Lenovo") ||
        this.handleLogo("Samsung") ||
        this.handleLogo("Xiaomi")
      );
    },
    activeSkillsList() {
      return this.Skill2Options.map((el) => {
        return {
          ...el,
          disabled: [
            this.Skill1,
            this.Skill2,
            this.Skill3,
            this.Skill4,
            this.Skill5,
            this.Skill6,
            this.Skill7,
            this.Skill8,
          ].includes(el.value),
        };
      });
    },
    languagesList() {
      return this.Language2Options.map((el) => {
        return {
          ...el,
          disabled: [this.Language1, this.Language2, this.Language3].includes(
            el.value
          ),
        };
      });
    },
    geosList() {
      return this.GEO1Options.map((el) => {
        return {
          ...el,
          disabled: [this.GEO1, this.GEO2].includes(el.value),
        };
      });
    },
    operatingSystemsList() {
      return this.OS1Options.map((el) => {
        return {
          ...el,
          disabled: [this.OS1, this.OS2, this.OS3, this.OS4].includes(el.value),
        };
      });
    },
    ssnTaxID: {
      get() {
        return this[`${["SSN/TaxID"]}`];
      },
      set(newVal) {
        this[`${["SSN/TaxID"]}`] = newVal;
      },
    },
    slackIDpm: {
      get() {
        return this[`${["SlackCo.CueWorkspaceID(PMOnly)"]}`];
      },
      set(newVal) {
        this[`${["SlackCo.CueWorkspaceID(PMOnly)"]}`] = newVal;
      },
    },
    smartlingApple: {
      get() {
        return this[`${["Smartling(Apple)"]}`];
      },
      set(newVal) {
        this[`${["Smartling(Apple)"]}`] = newVal;
      },
    },
    shakespeareAppsWriterRole: {
      get() {
        return this[`${["ShakespeareApps:WriterRole"]}`];
      },
      set(newVal) {
        this[`${["ShakespeareApps:WriterRole"]}`] = newVal;
      },
    },
    shakespeareAppsEditorRole: {
      get() {
        return this[`${["ShakespeareApps:EditorRole"]}`];
      },
      set(newVal) {
        this[`${["ShakespeareApps:EditorRole"]}`] = newVal;
      },
    },
    shakespeareMusicWriterRole: {
      get() {
        return this[`${["ShakespeareMusic:WriterRole"]}`];
      },
      set(newVal) {
        this[`${["ShakespeareMusic:WriterRole"]}`] = newVal;
      },
    },
    shakespeareMusicEditorRole: {
      get() {
        return this[`${["ShakespeareMusic:EditorRole"]}`];
      },
      set(newVal) {
        this[`${["ShakespeareMusic:EditorRole"]}`] = newVal;
      },
    },
    shakespeareVideoWriterRole: {
      get() {
        return this[`${["ShakespeareVideo:WriterRole"]}`];
      },
      set(newVal) {
        this[`${["ShakespeareVideo:WriterRole"]}`] = newVal;
      },
    },
    shakespeareVideoEditorRole: {
      get() {
        return this[`${["ShakespeareVideo:EditorRole"]}`];
      },
      set(newVal) {
        this[`${["ShakespeareVideo:EditorRole"]}`] = newVal;
      },
    },
    filmAndTvExpertiseListOptions: {
      get() {
        return this[`${["Film&TVExpertiseListOptions"]}`];
      },
      set(newVal) {
        this[`${["Film&TVExpertiseListOptions"]}`] = newVal;
      },
    },
    filmAndTvExpertiseList: {
      get() {
        return this[`${["Film&TVExpertiseList"]}`];
      },
      set(newVal) {
        this[`${["Film&TVExpertiseList"]}`] = newVal;
      },
    },
    filmAndTvYearsOfExperience: {
      get() {
        return this[`${["Film&TVYearsOfExperience"]}`];
      },
      set(newVal) {
        this[`${["Film&TVYearsOfExperience"]}`] = newVal;
      },
    },
    filmAndTVSpecialty: {
      get() {
        return this[`${["Film&TVSpecialty"]}`];
      },
      set(newVal) {
        this[`${["Film&TVSpecialty"]}`] = newVal;
      },
    },
    mobileAndVideoGamesExpertiseListOptions: {
      get() {
        return this[`${["Mobile/VideoGamesExpertiseListOptions"]}`];
      },
      set(newVal) {
        this[`${["Mobile/VideoGamesExpertiseListOptions"]}`] = newVal;
      },
    },
    mobileAndVideoGamesExpertiseList: {
      get() {
        return this[`${["Mobile/VideoGamesExpertiseList"]}`];
      },
      set(newVal) {
        this[`${["Mobile/VideoGamesExpertiseList"]}`] = newVal;
      },
    },
    mobileAndVideoGamesYearsOfExperience: {
      get() {
        return this[`${["Mobile/VideoGamesYearsOfExperience"]}`];
      },
      set(newVal) {
        this[`${["Mobile/VideoGamesYearsOfExperience"]}`] = newVal;
      },
    },
    mobileAndVideoGamesSpecialty: {
      get() {
        return this[`${["Mobile/VideoGamesSpecialty"]}`];
      },
      set(newVal) {
        this[`${["Mobile/VideoGamesSpecialty"]}`] = newVal;
      },
    },
    filmAndTvStreamingServicesOptions: {
      get() {
        return this[`${["Film/TVStreamingServicesOptions"]}`];
      },
      set(newVal) {
        this[`${["Film/TVStreamingServicesOptions"]}`] = newVal;
      },
    },
    filmAndTvStreamingServices: {
      get() {
        return this[`${["Film/TVStreamingServices"]}`];
      },
      set(newVal) {
        this[`${["Film/TVStreamingServices"]}`] = newVal;
      },
    },
    vrArDeviceOptions: {
      get() {
        return this[`${["VR/ARDeviceOptions"]}`];
      },
      set(newVal) {
        this[`${["VR/ARDeviceOptions"]}`] = newVal;
      },
    },
    vrArDevice: {
      get() {
        return this[`${["VR/ARDevice"]}`];
      },
      set(newVal) {
        this[`${["VR/ARDevice"]}`] = newVal;
      },
    },
    taxDocumentBlob: {
      get() {
        return this[`${["W-9(U.S.Only)Blob"]}`];
      },
      set(newVal) {
        this[`${["W-9(U.S.Only)Blob"]}`] = newVal;
      },
    },
    cueFC() {
      return workspaces.find((el) => el.name === "cue-fc").id;
    },
    cueStaff() {
      return workspaces.find((el) => el.name === "Cue Staff").id;
    },
    cuePM() {
      return workspaces.find((el) => el.name === "PM").id;
    },
    skillsBadges() {
      const skillsList = [
        "Skill1",
        "Skill2",
        "Skill3",
        "Skill4",
        "Skill5",
        "Skill6",
        "Skill7",
        "Skill8",
      ]
        .map((el) => {
          return {
            skill: this[`${el}`],
            index: this.getExpIndex(el),
          };
        })
        .filter((el) => el.index !== 4 && el.index !== undefined)
        .sort((a, b) => a.index - b.index);
      return skillsList;
    },
    expertiseBadges() {
      const expertiseList = [
        { key: "Music", title: "Music" },
        { key: "Film&TV", title: "Film & TV" },
        { key: "Books", title: "Books" },
        { key: "Mobile/VideoGames", title: "Mobile/Video Games" },
        { key: "Podcasts", title: "Podcasts" },
        { key: "MobileApps", title: "Mobile Apps" },
        { key: "Lifestyle", title: "Lifestyle" },
        { key: "Sports", title: "Sports" },
      ]
        .map((el) => {
          return {
            expertise: el.title,
            index: this.getExpIndex(el.key),
          };
        })
        .filter((el) => el.index !== 4 && el.index !== undefined)
        .sort((a, b) => a.index - b.index);
      return expertiseList;
    },
    fullHardwareDashboardPath() {
      return `https://${DOMAIN}/${ROUTES.cueHardware}`;
    },
    fullSoftwareDashboardPath() {
      return `https://${DOMAIN}/${ROUTES.cueSoftware}`;
    },
    isFormCompleted() {
      const required = ["Name", "Language1", "Email", "Country"],
        adminRequired = required.concat([
          "PaymentType",
          "Currency",
          "NDA",
          "Contract",
          "Status",
          "StartDate",
        ]),
        userRequired = required.concat([
          "PreferredName",
          "Bio",
          "StreetAddress",
          "LegalName",
          "TaxResidency",
          "Skill1",
          "Skill1YearsOfExperience",
          "Skill2",
          "Skill2YearsOfExperience",
          "Skill3",
          "Skill3YearsOfExperience",
          "Skill4",
          "Skill4YearsOfExperience",
          "Skill5",
          "Skill5YearsOfExperience",
          "Skill6",
          "Skill6YearsOfExperience",
          "Skill7",
          "Skill7YearsOfExperience",
          "Skill8",
          "Skill8YearsOfExperience",
          "MusicYearsOfExperience",
          "Film&TVYearsOfExperience",
          "BooksYearsOfExperience",
          "Mobile/VideoGamesYearsOfExperience",
          "PodcastsYearsOfExperience",
          "MobileAppsYearsOfExperience",
          "LifestyleYearsOfExperience",
          "SportsYearsOfExperience",
          "OS1",
        ]);
      return (this.isAdmin ? adminRequired : userRequired).every((el) =>
        typeof this.$data[`${el}`] === "object"
          ? this.$data[`${el}Blob`] !== null
          : !!this.$data[`${el}`]
      );
    },
  },
  watch: {
    tab() {
      this.handleUpdateBtnDisplay();
    },
    itemToEdit() {
      this.dataViewsHandler();
    },
    Language2(newVal) {
      if (!newVal) this.Language2Level = "";
    },
    Language3(newVal) {
      if (!newVal) this.Language3Level = "";
    },
    OS1(newVal) {
      if (!newVal) this.OS1VersionYear = "";
    },
    OS2(newVal) {
      if (!newVal) this.OS2VersionYear = "";
    },
    OS3(newVal) {
      if (!newVal) this.OS3VersionYear = "";
    },
    OS4(newVal) {
      if (!newVal) this.OS4VersionYear = "";
    },
    admin(newVal) {
      if (newVal) {
        this.dubEditor = true;
      } else {
        this.dubEditor = this.itemToEdit && this.itemToEdit.dubEditor !== undefined
          ? this.itemToEdit.dubEditor
          : false;
      }
    },
  },
  methods: {
    formatFileName(fileField) {
      const uniqueNames = {
        "W-9(U.S.Only)": "W9",
        Contract: "ICA",
      };
      return uniqueNames[`${fileField}`] ?? fileField;
    },
    getAddress(input) {
      return new Promise((resolve, reject) => {
        const service = new google.maps.places.AutocompleteService();

        const displaySuggestions = (predictions, status) => {
          if (
            status != google.maps.places.PlacesServiceStatus.OK ||
            !predictions
          ) {
            reject(console.error(status));
            this.loadPredictions = false;
            return;
          }
          resolve((this.addresses = predictions.map((el) => el.description)));
          this.loadPredictions = false;
        };

        if (input) {
          this.loadPredictions = true;
          service.getQueryPredictions({ input }, displaySuggestions);
        }
      });
    },
    handleFormProgress() {
      if (this.isViewMode) this.$refs.form.resetValidation();
      else this.$refs.form.validate();
    },
    handleValidationOnModeChange() {
      if (this.tab === 0 && this.isViewMode) this.tab = 1;
      this.isViewMode = !this.isViewMode;
      this.handleFormProgress();
    },
    handleLogo(value) {
      return (
        this.MusicStreamingServices?.includes(value) ||
        this["Film/TVStreamingServices"]?.includes(value) ||
        this.ProductivityTools?.includes(value) ||
        this.CATTools?.includes(value) ||
        this.OS1?.includes(value) ||
        this.OS2?.includes(value) ||
        this.OS3?.includes(value) ||
        this.OS4?.includes(value) ||
        this.DesktopComputer?.includes(value) ||
        this.Laptop?.includes(value) ||
        this.Phone?.includes(value) ||
        this.Tablet?.includes(value) ||
        this.StreamingTVDevice?.includes(value) ||
        this.Watch?.includes(value) ||
        this["VR/ARDevice"]?.includes(value)
      );
    },
    dataViewsHandler() {
      if (this.isEditMode) {
        this.handleEditMode();
      } else {
        Object.keys(contactsFieldsLookup).forEach((el) => {
          this[`${el}`] = contactsFieldsLookup[el].default;
          if (this.$data.hasOwnProperty(`${el}Blob`))
            this[`${el}Blob`] = contactsFieldsLookup[el].default;
        });
      }
      this.isViewMode = this.profileView ? false : this.isEditMode;
      this.handleUpdateBtnDisplay();
      this.handleFormProgress();
    },
    getFieldsData() {
      getFieldInfosFromDB({
        tableID: appIDs.cueStaff.contacts,
      })
        .then((data) => {
          this.tableFields.forEach((field) => {
            const targetField = data.data.find((el) => el.label === field.text);
            if (targetField && targetField.properties.hasOwnProperty("choices"))
              this[`${field.value}Options`] =
                field.value === "Language2" ||
                field.value === "GEO1" ||
                field.value === "Skill2" ||
                field.value === "OS1"
                  ? targetField.properties.choices.map((el) => {
                      return { value: el, disabled: false };
                    })
                  : targetField.properties.choices;
          });
          this.dropdownsLoading = false;
        })
        .catch((err) => {
          this.dropdownsLoading = false;
          console.error(err);
        });
    },
    handleEditMode() {
      this.filesLoading = true;
      let files = [];

      // loop through fields
      Object.entries(this.itemToEdit).forEach((el) => {
        if (
          el[0] !== "groupCategory" &&
          el[0] !== "groupSort" &&
          el[0] !== "index"
        )
          if (el[1]?.hasOwnProperty("url")) {
            if (el[1].url) {
              files.push({
                url: el[1].url,
                name: el[0],
                isImage: el[0] === "Photo",
              });
            } else {
              this[`${el[0]}`] = this[`${el[0]}Blob`] = null;
            }
          } else {
            this[`${el[0]}`] = el[1];
          }
      });

      // get files
      this.handleFiles(files);

      // get hardware & software
      this.getEmployeeHardware(this.Name);
      this.getEmployeeSoftware(this.Name);

      // validate address
      this.getAddress(this.StreetAddress).then((predictions) => {
        if (!predictions.includes(this.StreetAddress)) this.StreetAddress = "";
        this.handleFormProgress();
      });
    },
    handleFile(url, fileName, isImage) {
      return new Promise((resolve, reject) => {
        getFile(url)
          .then(async (resp) => {
            const base64Response = await fetch(
              `data:${isImage ? "image/jpg" : "application/pdf"};base64,${
                resp.data
              }`
            );
            const blob = await base64Response.blob();
            let newBlob = new Blob([blob], {
              type: isImage ? "image/jpg" : "application/pdf",
            });
            newBlob.name = `${this.Name}_${this.formatFileName(fileName)}.${
              isImage ? "jpg" : "pdf"
            }`;
            if (isImage) this.avatar = base64Response?.url;
            resolve((this[`${fileName}Blob`] = newBlob));
          })
          .catch((err) => reject(err));
      });
    },
    handleFiles(files) {
      const promises = files.map((file) => {
        return this.handleFile(file?.url, file.name, file.isImage);
      });
      Promise.all(promises)
        .then(() => (this.filesLoading = false))
        .catch((err) => {
          console.error(err);
          this.filesLoading = false;
        });
    },
    getEmployeeHardware(name) {
      this.hardwareLoading = true;
      getHardware(name)
        .then((data) => {
          const getIcon = (category) => {
            switch (category) {
              case "Computer Desktop":
                return "desktop-tower";
              case "Computer Laptop":
                return "laptop";
              case "Phone":
                return "cellphone";
              case "Tablet":
                return "tablet";
              case "Smart TV":
                return "monitor-cellphone-start";
              case "Monitor":
                return "monitor";
              case "Keyboard":
                return "keyboard";
              case "Headphones":
                return "headphones";
              case "Cable":
                return "cable-data";
              case "Wearable":
                return "watch-variant";
              default:
                return "help-circle";
            }
          };
          this.cueHardware = data.length
            ? data.map((el) => {
                return {
                  category: el.DeviceCategory,
                  icon: getIcon(el.DeviceCategory),
                  onHandDate: `On hand since ${this.$moment(
                    el.OnHandStartDate
                  ).format("MM/DD/YYYY")}`,
                  mainInfo: `${el.DeviceBrand} / ${el.DeviceModel}`,
                };
              })
            : [
                {
                  category: null,
                  icon: "power-plug-off",
                  onHandDate: null,
                  mainInfo: "No devices on hand.",
                },
              ];
          this.hardwareLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.hardwareLoading = false;
        });
    },
    getEmployeeSoftware(name) {
      this.softwareLoading = true;
      getSoftware(name, this.$moment().format("MM-DD-YYYY"))
        .then((data) => {
          const getIcon = (software) => {
            switch (software) {
              case "Slack":
                return "slack";
              case "Slack (Cue-Development)":
                return "slack";
              case "iCloud":
                return "cloud";
              case "Office 365":
                return "microsoft-office";
              case "Dropbox":
                return "dropbox";
              case "Trello":
                return "trello";
              case "Apple Music":
                return "food-apple";
              case "Linkedin":
                return "linkedin";
              default:
                return "exit-to-app";
            }
          };
          this.cueSoftware = data.length
            ? data.map((el) => {
                return {
                  provider: getIcon(el.ServiceName),
                  name: el.ServiceName,
                  type: el.ServiceType,
                };
              })
            : [
                {
                  provider: "information-outline",
                  name: "No software in use.",
                  type: null,
                },
              ];
          this.softwareLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.softwareLoading = false;
        });
    },
    downloadFile(fileName) {
      saveAs(
        this[`${fileName}Blob`],
        `${this.Name}_${this.formatFileName(fileName)}`
      );
    },
    seeOnMap() {
      window.open(`http://maps.google.com/?q=${this.StreetAddress}`, "_blank");
    },
    sendEmail(email) {
      window.open(`mailto:${email}`);
    },
    getDashboardPath(dashboard) {
      return `https://${DOMAIN}/${ROUTES[dashboard]}`;
    },
    handleCalendar() {
      this.profileView
        ? window.open(`https://${DOMAIN}/${ROUTES.calendar}`, "_blank")
        : (this.calendarMode = true);
    },
    convertFile(file, field) {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this[`${field}`] = {
            fileName: `${this.Name}_${this.formatFileName(field)}.${
              file.type.split("/")[1]
            }`,
            data: reader.result?.split(",")[1],
          };
        };
        reader.readAsDataURL(file);
      } else {
        this[`${field}`] = null;
      }
    },
    handleContact() {
      this.$refs.form.validate();
      if (!this.isFormCompleted) {
        this.formProgressSnackbar = true;
      } else {
        if (this.$refs.form.validate()) {
          this.contactHandling = true;
          let data = {},
            filesDeletionPromises = [];
          Object.keys(contactsFieldsLookup).forEach((el) => {
            Object.assign(data, {
              [el]: this[`${el}`],
            });
          });
          this.filesToDelete.forEach((file) => {
            if (this.itemToEdit[`${file}`]?.url && !this[`${file}Blob`])
              filesDeletionPromises.push(
                new Promise((resolve, reject) => {
                  deleteFile(this.itemToEdit[`${file}`]?.url)
                    .then((resp) => resolve(resp))
                    .catch((err) => reject(err));
                })
              );
          });
          Promise.all(filesDeletionPromises)
            .then(() => {
              processContactRow(data).then((resp) => {
                let msg = null;
                if (resp.metadata.createdRecordIds.length) {
                  msg = "Record created!";
                } else if (
                  resp.metadata.updatedRecordIds.length ||
                  resp.metadata.unchangedRecordIds.length
                ) {
                  msg = `${this.profileView ? "Profile" : "Record"} updated!`;
                }

                // update Firebase user
                if (
                  this.cueHub &&
                  (this.itemToEdit.cueHub !== this.cueHub ||
                    this.itemToEdit.admin !== this.admin ||
                    this.itemToEdit.dubEditor !== this.dubEditor)
                ) {
                  if (this.itemToEdit.uid) {
                    const addClaims = functions.httpsCallable("addClaims");
                    addClaims({
                      email: this.Email,
                      company: "Company Cue, Inc.",
                      isAdmin: this.admin,
                      isDubEditor: this.dubEditor,
                    }).then(() => {
                      this.contactHandling = false;
                      this.$emit("refresh-data", msg);
                      if (
                        this.itemToEdit &&
                        this.itemToEdit.Name &&
                        (resp.metadata && resp.metadata.updatedRecordIds && resp.metadata.updatedRecordIds.length > 0 ||
                          resp.metadata && resp.metadata.unchangedRecordIds && resp.metadata.unchangedRecordIds.length > 0)
                      ) {
                        this.profileUpdateNotifying();
                      }
                    });
                  } else {
                    createNewUserWithPasswordSendEmail({
                      email: this.Email,
                      company: "Company Cue, Inc.",
                      isAdmin: this.admin,
                      isDubEditor: this.dubEditor,
                    })
                      .then(() => {
                        this.contactHandling = false;
                        this.$emit("refresh-data", msg);
                      })
                      .catch((err) => {
                        console.error(err);
                        this.contactHandling = false;
                      });
                  }
                } else {
                  this.contactHandling = false;
                  this.$emit("refresh-data", msg);
                  if (
                    resp.metadata.updatedRecordIds.length ||
                    resp.metadata.unchangedRecordIds.length
                  )
                    this.profileUpdateNotifying();
                }
              });
            })
            .catch((err) => {
              console.error(err);
              this.contactHandling = false;
            });
        }
      }
    },
    profileUpdateNotifying() {
      if (this.profileView) {
        const checkDocDiff = (doc) =>
          this.itemToEdit[doc].url
            ? this.$data[doc] === null && this.$data[`${doc}Blob`] !== null
            : this.$data[`${doc}Blob`] === null;
        let currentState = {};
        Object.keys(contactsFieldsLookup).forEach((key) =>
          Object.assign(currentState, { [key]: this.$data[key] })
        );
        const diff = Object.keys(currentState).reduce((diff, key) => {
          if (
            this.itemToEdit[key]?.hasOwnProperty("url")
              ? checkDocDiff(key)
              : this.itemToEdit[key] === currentState[key]
          )
            return diff;
          return {
            ...diff,
            [key]: currentState[key],
          };
        }, {});
        const diffEntries = Object.entries(diff)
          .map(
            (entry) =>
              `${(entry[0] === "W-9(U.S.Only)" ? "TaxDocument" : entry[0])
                .replace(/([A-Z][a-z]|[A-Z]+(?=[A-Z]|$))/g, " $1")
                .replace(/./, (m) => m.toUpperCase())
                .trim()}: ${
                entry[1]
                  ? typeof entry[1] === "object"
                    ? Array.isArray(entry[1])
                      ? entry[1].length
                        ? entry[1].join(", ")
                        : "*Deleted*"
                      : "*Added*"
                    : entry[1]
                  : "*Deleted*"
              }`
          )
          .join("\n");
        if (diffEntries.length)
          publishMessage({
            user: `*${this.user.Name}*`,
            data: {
              isActive: this.itemToEdit.ActiveStaff,
              entries: diffEntries,
            },
            mode: "profileUpdate",
          });
      }
    },
    deleteContact() {
      this.deleteConfirmation = false;
      this.contactHandling = true;
      deleteRowFromQB({
        id: this.itemToEdit["RecordID#"],
        tableID: appIDs.cueStaff.contacts,
      }).then((resp) => {
        const msg = resp.numberDeleted ? "Record deleted!" : null;
        if (this.itemToEdit.uid) {
          const deleteUser = functions.httpsCallable("deleteUser");
          deleteUser({
            uid: this.itemToEdit.uid,
            email: this.Email,
          }).then(() => {
            this.contactHandling = false;
            this.$emit("refresh-data", msg);
          });
        } else {
          this.contactHandling = false;
          this.$emit("refresh-data", msg);
        }
      });
    },
    getExpIndex(exp) {
      switch (this[`${exp}YearsOfExperience`]) {
        case ">10":
          return 0;
        case "5-10":
          return 1;
        case "2-5":
          return 2;
        case "<2":
          return 3;
        default:
          4;
      }
    },
    sendPasswordReset() {
      this.resetLoading = true;
      auth
        .sendPasswordResetEmail(this.Email, {
          url: `https://${DOMAIN}`,
        })
        .then(() => {
          this.resetLoading = false;
          this.resetSucess = true;
          setTimeout(() => {
            this.resetSucess = false;
          }, 3000);
        });
    },
    handleClose() {
      this.isAdmin || (this.isManager && !this.profileView)
        ? this.$emit("close-form")
        : (this.closingConfirmation = true);
    },
    closeForm() {
      this.$emit("close-form");
      this.closingConfirmation = false;
    },
    handleUpdateBtnDisplay() {
      this.showUpateBtn = this.profileLock
        ? this.tab === this.steps.indexOf(this.steps.at(-1))
        : true;
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-form {
  &__profile {
    &-chip {
      width: 100%;
      height: fit-content;
      justify-content: center;
      line-height: 1rem;
      white-space: normal;
      &s-container {
        width: 35%;
        height: 100%;
      }
      &-extra {
        position: absolute;
        bottom: 15px;
      }
    }
    &-avatar {
      &-container {
        width: 50%;
      }
      &-info {
        width: 100%;
      }
    }
    &-actions-container {
      width: 15%;
    }
  }
  &__return-btn {
    position: absolute;
    bottom: 5px;
    right: 10px;
    z-index: 1;
  }
  &__close-conf-block {
    width: 40%;
  }
}
.contact-form__profile-chip.v-chip {
  border: 1px ridge grey !important;
}
.v-badge__badge {
  border-radius: 50%;
  height: 30px;
  min-width: 30px;
}
.v-btn--icon.v-size--x-large .v-icon {
  font-size: 40px;
}
.v-input.disabledPicker {
  pointer-events: none;
}
.expertise-error {
  border: 2px solid #b71c1c;
}
::v-deep {
  .v-skeleton-loader__image {
    height: 350px;
  }
  .v-label--active {
    pointer-events: none;
  }
  .v-btn.slack-btn .v-btn__content {
    justify-content: start;
  }
  .v-icon.v-icon.v-icon--link {
    pointer-events: all;
    color: rgba(0, 0, 0, 0.54) !important;
  }
  .v-input--selection-controls .v-input__slot {
    width: fit-content;
  }
  .contact-form__cue-checkbox
    .v-input__control
    .v-messages
    .v-messages__wrapper {
    width: 500px;
    margin-left: -32px;
  }
  .v-tab--active {
    color: #fff !important;
  }
}
</style>
