var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.personAssignments.length > 0 &&
    (_vm.formValues.writingDueDate || _vm.formValues.editingDueDate)
  )?_c('div',{staticClass:"fc-assignment-viz elevation-3 grey lighten-4"},_vm._l((_vm.personAssignments),function(person,i){return _c('div',{key:`bar-${i}`,staticClass:"flex-wrap",class:`fc-assignment-viz__row ${_vm.getGroupSpacing(i)}`},[_c('div',{staticClass:"subtitle-1 text-center",style:({
        position: 'relative',
        width: '100%',
      }),on:{"mouseenter":() => _vm.handleMouseOverName(person.person),"mouseleave":() => _vm.handleMouseOverName(null)}},[_vm._v(" "+_vm._s(person.person)+" "),(!_vm.wordCountValidated && !_vm.backstageCopywriting)?_c('h3',[_vm._v(" Add Word Count ")]):_vm._e(),_c('div',{staticClass:"tooltip tooltip-name-hover",style:({
          display: _vm.getTooltimeNameDisplay(person.person),
        })},[(_vm.personWordCounts && _vm.personWordCounts.length === 0)?_c('div',[_vm._v(" No upcoming assignments ")]):_vm._e(),(_vm.personWordCounts && _vm.personWordCounts.length > 0)?_c('div',{staticClass:"d-flex font-weight-bold"},[_c('div',{staticClass:"tooltip-name-hover__block"},[_vm._v("Due Date")]),_c('div',{staticClass:"tooltip-name-hover__block"},[_vm._v("Writing Total")]),_c('div',{staticClass:"tooltip-name-hover__block"},[_vm._v("Editing Total")])]):_vm._e(),_vm._l((_vm.personWordCounts),function(wc,i){return _c('div',{key:`wc-row-${i}`,staticClass:"d-flex",style:({
            backgroundColor: i % 2 === 0 ? '#fff' : '#f5f5f5',
          })},[_c('div',{staticClass:"tooltip-name-hover__block"},[_vm._v(" "+_vm._s(wc.date)+" ")]),_c('div',{staticClass:"tooltip-name-hover__block"},[_vm._v(" "+_vm._s(wc.writingTot ? wc.writingTot : "None")+" ")]),_c('div',{staticClass:"tooltip-name-hover__block"},[_vm._v(" "+_vm._s(wc.editingTot ? wc.editingTot : "None")+" ")])])})],2)]),_c('div',{staticClass:"d-flex flex-column align-center",style:({ width: '15%' })},[(
          (_vm.formValues.writingDueDate && person.writingWordCount) ||
          (_vm.formValues.editingDueDate && person.editingWordCount)
        )?_c('div',{staticClass:"subtitle-2"},[_vm._v(" CURRENT ")]):_vm._e(),(_vm.formValues.writingDueDate && person.writingWordCount)?_c('div',{staticClass:"d-flex justify-end",style:({ width: '100%', height: '24px' })},[_vm._m(0,true),_c('div',{staticClass:"ml-2 subtitle-2 d-flex justify-center",style:({ ..._vm.barStyles, width: '50px' })},[_vm._v(" "+_vm._s(person.writingWordCount)+" ")])]):_vm._e(),(_vm.formValues.editingDueDate && person.editingWordCount)?_c('div',{staticClass:"d-flex justify-end",style:({ width: '100%', height: '24px' })},[_vm._m(1,true),_c('div',{staticClass:"ml-2 subtitle-2 d-flex justify-center",style:({ ..._vm.barStyles, width: '50px' })},[_vm._v(" "+_vm._s(person.editingWordCount)+" ")])]):_vm._e()]),_c('div',{staticClass:"d-flex flex-column align-center",style:({ width: '70%' })},[(
          (_vm.formValues.writingDueDate && person.writingWordCount) ||
          (_vm.formValues.editingDueDate && person.editingWordCount)
        )?_c('div',{staticClass:"subtitle-2"},[_vm._v(" TOTAL/MAX ")]):_vm._e(),(_vm.formValues.writingDueDate && person.writingWordCount)?_c('div',{staticClass:"d-flex",style:({ width: '100%', height: '24px' })},[_c('div',{staticClass:"bars-col",style:({
            ..._vm.barStyles,
          })},[_c('div',{staticClass:"subtitle-2 d-flex",style:({
              borderRadius: '4px',
              borderRight: '1px solid #c0c0c0',
              width: `${
                person.writingMaxCapacity > 0
                  ? ((person.writingMaxCapacity -
                      person.writingCapacity +
                      person.writingWordCount) /
                      person.writingMaxCapacity) *
                    100
                  : 100
              }%`,
              background:
                person.writingCapacity - person.writingWordCount >= 0 &&
                person.writingMaxCapacity > 0
                  ? 'repeating-linear-gradient(-45deg, rgba(158, 185, 243, 1), rgba(158, 185, 243, 1) 3px, #f5f5f5 3px, #f5f5f5 6px)'
                  : '#ee7975',
              color:
                person.writingCapacity - person.writingWordCount >= 0 &&
                person.writingMaxCapacity > 0
                  ? ''
                  : '#fff',
            })},[_c('div',{staticClass:"d-flex align-center justify-center",style:({
                width: `${
                  person.writingCapacity - person.writingWordCount >= 0 &&
                  person.writingMaxCapacity > 0
                    ? (person.writingWordCount /
                        (person.writingMaxCapacity -
                          person.writingCapacity +
                          person.writingWordCount)) *
                      100
                    : 100
                }%`,
                borderRadius: '4px',
                borderRight: '1px solid #c0c0c0',
                background:
                  person.writingCapacity - person.writingWordCount >= 0
                    ? 'rgba(158, 185, 243, 1)'
                    : '#ee7975',
              })},[_c('b',{style:([
                  (person.writingWordCount / person.writingMaxCapacity) *
                    100 <
                    20 &&
                  person.writingCapacity - person.writingWordCount >= 0
                    ? { position: 'absolute', right: '10px' }
                    : {},
                ])},[_vm._v(" "+_vm._s(_vm.wordCountValidated ? person.writingMaxCapacity - person.writingCapacity + person.writingWordCount + "/" + person.writingMaxCapacity : "Add Word Count")+" ")])])])])]):_vm._e(),(_vm.formValues.editingDueDate && person.editingWordCount)?_c('div',{staticClass:"d-flex",style:({ width: '100%', height: '24px' })},[_c('div',{staticClass:"bars-col",style:({
            ..._vm.barStyles,
          })},[_c('div',{staticClass:"subtitle-2 d-flex",style:({
              borderRadius: '4px',
              borderRight: '1px solid #c0c0c0',
              width: `${
                person.editingMaxCapacity > 0
                  ? ((person.editingMaxCapacity -
                      person.editingCapacity +
                      person.editingWordCount) /
                      person.editingMaxCapacity) *
                    100
                  : 100
              }%`,
              background:
                person.editingCapacity - person.editingWordCount >= 0 &&
                person.editingMaxCapacity > 0
                  ? 'repeating-linear-gradient(-45deg, rgba(220, 176, 242, 1), rgba(220, 176, 242, 1) 3px, #f5f5f5 3px, #f5f5f5 6px)'
                  : '#ee7975',
              color:
                person.editingCapacity - person.editingWordCount >= 0 &&
                person.editingMaxCapacity > 0
                  ? ''
                  : '#fff',
            })},[_c('div',{staticClass:"d-flex align-center justify-center",style:({
                width: `${
                  person.editingCapacity - person.editingWordCount >= 0 &&
                  person.editingMaxCapacity > 0
                    ? (person.editingWordCount /
                        (person.editingMaxCapacity -
                          person.editingCapacity +
                          person.editingWordCount)) *
                      100
                    : 100
                }%`,
                borderRadius: '4px',
                borderRight: '1px solid #c0c0c0',
                background:
                  person.editingCapacity - person.editingWordCount >= 0
                    ? 'rgba(220, 176, 242, 1)'
                    : '#ee7975',
              })},[_c('b',{style:([
                  (person.editingWordCount / person.editingMaxCapacity) *
                    100 <
                    20 &&
                  person.editingCapacity - person.editingWordCount >= 0
                    ? { position: 'absolute', right: '10px' }
                    : {},
                ])},[_vm._v(" "+_vm._s(_vm.wordCountValidated ? person.editingMaxCapacity - person.editingCapacity + person.editingWordCount + "/" + person.editingMaxCapacity : "Add Word Count")+" ")])])])])]):_vm._e()]),_c('div',{staticClass:"d-flex flex-column align-center",style:({ width: '15%' })},[(
          (_vm.formValues.writingDueDate && person.writingWordCount) ||
          (_vm.formValues.editingDueDate && person.editingWordCount)
        )?_c('div',{staticClass:"subtitle-2"},[_vm._v(" LEFT ")]):_vm._e(),(_vm.formValues.writingDueDate && person.writingWordCount)?_c('div',{staticClass:"d-flex",style:({ width: '100%', height: '24px' })},[_c('div',{staticClass:"bars-col subtitle-2 d-flex justify-center",style:({ ..._vm.barStyles })},[_vm._v(" "+_vm._s(person.writingCapacity - person.writingWordCount)+" ")])]):_vm._e(),(_vm.formValues.editingDueDate && person.editingWordCount)?_c('div',{staticClass:"d-flex",style:({ width: '100%', height: '24px' })},[_c('div',{staticClass:"bars-col subtitle-2 d-flex justify-center",style:({ ..._vm.barStyles })},[_vm._v(" "+_vm._s(person.editingCapacity - person.editingWordCount)+" ")])]):_vm._e()])])}),0):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subtitle-2"},[_c('b',[_vm._v("W:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subtitle-2"},[_c('b',[_vm._v("E:")])])
}]

export { render, staticRenderFns }