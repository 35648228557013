import Vue from "vue";
import Vuex from "vuex";

import { authModule } from "@/store/authStore.js";
import { fcBotModule } from "@/store/fcBotStore.js";
import { invoiceBotModule } from "@/store/invoiceBotStore.js";
import { myCueModule } from "@/store/myCueStore.js";
import { editorModule } from "@/store/editorStore.js";
import { fcAssignmentModule } from "@/store/fcAssignmentStore.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: {
      namespaced: true,
      ...authModule,
    },
    fcBot: {
      namespaced: true,
      ...fcBotModule,
    },
    invoiceBot: {
      namespaced: true,
      ...invoiceBotModule,
    },
    myCue: {
      namespaced: true,
      ...myCueModule,
    },
    editor: {
      namespaced: true,
      ...editorModule,
    },
    fcAssignment: {
      namespaced: true,
      ...fcAssignmentModule,
    },
  },
});
