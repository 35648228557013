<template>
  <div class="software my-10 px-2">
    <div class="d-flex justify-space-between" :style="{ position: 'relative' }">
      <!-- left -->
      <div class="d-flex">
        <!-- new record -->
        <div class="text-center">
          <span class="overline"> New </span>
          <div class="mb-4">
            <v-btn
              :min-width="laptop ? '40px' : '42px'"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              @click="handleForm({})"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- form -->
        <v-dialog
          max-width="1200"
          :value="softwareModal"
          @click:outside="softwareModal = false"
          @keydown.esc="softwareModal = false"
        >
          <CueSoftwareForm
            :types="types"
            :plans="plans"
            :subscriptions="subscriptions"
            :itemToEdit="itemToEdit"
            @software-table-refresh="handleRefresh"
          />
        </v-dialog>

        <!-- clear filters -->
        <div class="text-center ml-2">
          <span class="overline"> Clear </span>
          <div class="mb-4">
            <v-btn
              :min-width="laptop ? '40px' : '42px'"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              @click="clearFilters"
            >
              <v-icon>mdi-filter-remove</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- service filter -->
        <div class="ml-lg-3 ml-md-2 text-center">
          <span class="overline"> Service Name </span>
          <div class="d-flex mb-4">
            <v-autocomplete
              class="selector"
              :items="services"
              v-model="filterService"
              solo
              dense
              hide-details
              :loading="showTableLoader"
              @input="handleFilterQuery('Service', $event)"
            ></v-autocomplete>
          </div>
        </div>

        <!-- type filter -->
        <div class="ml-lg-3 ml-md-2 text-center">
          <span class="overline"> Service Type </span>
          <div class="d-flex align-end">
            <v-autocomplete
              class="selector"
              :items="types"
              v-model="filterType"
              solo
              dense
              hide-details
              :loading="dropdownsLoading"
              @change="handleFilterQuery('Type', $event)"
            ></v-autocomplete>
          </div>
        </div>

        <!-- plan filter -->
        <div class="ml-lg-3 ml-md-2 text-center">
          <span class="overline"> Plan Type </span>
          <div class="d-flex align-end">
            <v-autocomplete
              class="selector"
              :items="plans"
              v-model="filterPlan"
              solo
              dense
              hide-details
              :loading="dropdownsLoading"
              @change="handleFilterQuery('Plan', $event)"
            ></v-autocomplete>
          </div>
        </div>

        <!-- subscription filter -->
        <transition>
          <div
            v-show="laptop ? !expandMenu : true"
            class="ml-lg-3 ml-md-2 text-center"
          >
            <span class="overline"> Subscription Type </span>
            <div class="d-flex align-end">
              <v-autocomplete
                class="selector"
                :items="subscriptions"
                v-model="filterSubscription"
                solo
                dense
                hide-details
                :loading="dropdownsLoading"
                @change="handleFilterQuery('Subscription', $event)"
              ></v-autocomplete>
            </div>
          </div>
        </transition>
      </div>

      <!-- right -->
      <div class="d-flex flex-column justify-start align-end my-0">
        <!-- search -->
        <span class="overline" :style="{ opacity: 0, userSelect: 'none' }"
          >Search</span
        >
        <div class="mb-4">
          <v-badge
            :content="laptopSmaller ? '...' : filterSearchBadge"
            :value="filterSearch && !expandMenu"
            color="secondary"
            overlap
          >
            <v-btn
              height="38px"
              min-width="48px"
              class="elevation-2 px-0"
              outlined
              color="accent"
              @click="expandMenu = !expandMenu"
            >
              <v-icon v-if="expandMenu">mdi-magnify-minus-outline</v-icon>
              <v-icon v-else>mdi-magnify-plus-outline</v-icon>
            </v-btn>
          </v-badge>
          <v-expand-x-transition>
            <v-text-field
              :style="{ position: 'absolute', bottom: '16px', right: '60px' }"
              v-show="expandMenu"
              v-model="filterSearch"
              class="software__search-selector"
              solo
              dense
              hide-details
              clearable
            />
          </v-expand-x-transition>
        </div>
      </div>
    </div>
    <!-- table -->
    <v-card>
      <v-skeleton-loader
        v-if="showTableLoader"
        type="table"
        height="100vh"
      ></v-skeleton-loader>
      <CueSoftwareTable
        v-if="tableData && !showTableLoader"
        :tableData="tableData"
        :tableFields="tableFields"
        :filterSearch="filterSearch"
        :filterService="filterService ? filterService : ''"
        :filterType="filterType ? filterType : ''"
        :filterPlan="filterPlan ? filterPlan : ''"
        :filterSubscription="filterSubscription ? filterSubscription : ''"
        @call-edit-form="handleForm"
      />
    </v-card>
    <!-- flash message -->
    <v-snackbar
      v-model="confirmationMsg"
      min-width="unset"
      timeout="2000"
      :color="success ? 'success' : 'error'"
      rounded="pill"
      content-class="text-center"
    >
      <v-icon color="white" class="mr-1">{{
        success ? "mdi-check-circle-outline" : "mdi-close-circle-outline"
      }}</v-icon>
      {{ confirmationMsgTxt }}
    </v-snackbar>
  </div>
</template>

<script>
// libraries
import _ from "lodash";
// internal
import {
  appIDs,
  getSoftware,
  getFieldInfosFromDB,
} from "@/utils/quickbaseUtils";
import { customBreakpoint } from "@/utils/mixins";
import { ROUTES } from "@/utils/constants";
// components
import CueSoftwareTable from "@/components/CueSoftware/CueSoftwareTable";
import CueSoftwareForm from "@/components/CueSoftware/CueSoftwareForm";

export default {
  name: "CueSoftware",
  components: {
    CueSoftwareTable,
    CueSoftwareForm,
  },
  data() {
    return {
      showTableLoader: false,
      tableData: null,
      tableFields: null,
      filterSearch: "",
      expandMenu: false,
      dropdownsLoading: true,
      services: [],
      filterService: "",
      types: [],
      filterType: "",
      plans: [],
      filterPlan: "",
      subscriptions: [],
      filterSubscription: "",
      softwareModal: false,
      itemToEdit: {},
      success: true,
      confirmationMsg: false,
      confirmationMsgTxt: "",
    };
  },
  computed: {
    filterSearchBadge() {
      return this.filterSearch?.length <= 6
        ? this.filterSearch
        : `${this.filterSearch?.slice(0, 6)}...`;
    },
  },
  mixins: [customBreakpoint],
  watch: {
    filterSearch(newValue) {
      const currentQuery = this.$route.query;
      const newQuery =
        newValue !== ""
          ? {
              ...currentQuery,
              Search: newValue,
            }
          : _.omit(currentQuery, ["Search"]);

      this.$router
        .push({ path: `/${ROUTES.cueSoftware}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
  },
  mounted() {
    this.handleQuery();
    this.getTableData();
    this.handleDropdowns();
  },
  methods: {
    getTableData() {
      this.showTableLoader = true;
      getSoftware()
        .then((resp) => {
          this.tableData = resp.data;
          this.services = [
            ...new Set(
              resp.data.map((el) => {
                return el.ServiceName;
              })
            ),
          ].sort();
          this.tableFields = resp.fields;
          this.showTableLoader = false;
        })
        .catch((err) => {
          console.error(err);
          this.showTableLoader = false;
        });
    },
    handleFilterQuery(param, data) {
      const currentQuery = this.$route.query;

      const newQuery = {
        ...currentQuery,
        [param]: data,
      };
      this.$router
        .push({ path: `/${ROUTES.cueSoftware}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler

      this[`filter${param}`] = data;
    },
    clearFilters() {
      this.filterService =
        this.filterType =
        this.filterPlan =
        this.filterSubscription =
        this.filterSearch =
          "";

      this.$router.push({ path: `/${ROUTES.cueSoftware}` }).catch(() => {}); // Avoided redundant navigation error handler
    },
    getPlans() {
      return new Promise((resolve, reject) => {
        getFieldInfosFromDB({
          fieldID: 20,
          tableID: appIDs.cueProjectTools.software,
        })
          .then((plans) => resolve((this.plans = plans)))
          .catch((err) => reject(console.error(err)));
      });
    },
    getSubscriptions() {
      return new Promise((resolve, reject) => {
        getFieldInfosFromDB({
          fieldID: 19,
          tableID: appIDs.cueProjectTools.software,
        })
          .then((subscriptions) =>
            resolve((this.subscriptions = subscriptions))
          )
          .catch((err) => reject(console.error(err)));
      });
    },
    getTypes() {
      return new Promise((resolve, reject) => {
        getFieldInfosFromDB({
          fieldID: 27,
          tableID: appIDs.cueProjectTools.software,
        })
          .then((types) => resolve((this.types = types)))
          .catch((err) => reject(console.error(err)));
      });
    },
    handleDropdowns() {
      Promise.all([this.getPlans(), this.getSubscriptions(), this.getTypes()])
        .then(() => (this.dropdownsLoading = false))
        .catch((err) => {
          this.dropdownsLoading = false;
          console.error(err);
        });
    },
    handleQuery() {
      const currentQuery = this.$route.query;

      if (!_.isEmpty(currentQuery))
        Object.keys(currentQuery).forEach(
          (param) => (this[`filter${param}`] = currentQuery[param])
        );
    },
    handleForm(tableRow) {
      this.itemToEdit = { ...tableRow };
      this.softwareModal = true;
    },
    handleRefresh(msg) {
      this.softwareModal = false;
      this.getTableData();
      this.success = !!msg;
      this.confirmationMsgTxt =
        msg ?? "Something went wrong. Please try again.";
      this.confirmationMsg = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.software {
  max-width: 1500px;
  margin: 0 auto;

  &__search-selector {
    width: 340px;
  }
}

::v-deep {
  .selector {
    width: 200px;
  }
  .selector.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding-right: 0;
  }
  .selector.v-text-field.v-text-field--solo .v-input__append-inner {
    padding-left: 0;
  }
  .selector.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0;
  }
  .selector.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 2px 0;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'lg-and-down')} {
  .v-application .overline {
    font-size: 0.6rem !important;
  }
}
</style>
