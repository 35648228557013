<template>
  <v-card class="editor-new-assignment ma-4">
    <v-container class="editor-new-assignment__left editor-new-assignment__col">
      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            class="assignment-name"
            outlined
            hide-details
            label="Assignment Name"
            :value="startingAssignmentName"
            @change="updateField($event, 'assignmentName')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-textarea
            outlined
            label="Assignment Detail"
            hide-details
            :value="startingAssignmentDetails"
            @change="updateField($event, 'assignmentDetails')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            outlined
            label="Export Header"
            hide-details
            :value="startingExportHeader"
            @change="updateField($event, 'exportHeader')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-menu
            v-model="deliveryDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="startingAssignmentDeliveryDate"
                label="Delivery Date (Client)"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :value="startingAssignmentDeliveryDate"
              @input="updateField($event, 'deliveryDate')"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu
            v-model="payPeriodMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="startingPayPeriod"
                label="Pay Period"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              type="month"
              :value="startingPayPeriod"
              @input="updateField($event, 'payPeriod')"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4">
          <v-autocomplete
            outlined
            hide-details
            label="Price Card"
            :items="priceOptions"
            :loading="priceOptionsLoading"
            @change="updateField($event, 'relatedPrice')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            outlined
            hide-details
            label="Quantity"
            :value="startingQuantity"
            @change="updateField($event, 'quantity')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-autocomplete
            outlined
            hide-details
            label="PO"
            :disabled="!hasPOs"
            :items="projectPOs"
            :value="startingPO"
            @change="updateField($event, 'po')"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-autocomplete
            outlined
            hide-details
            label="GEO"
            :items="geoOptions"
            :loading="geoOptionsLoading"
            :value="startingGEO"
            @change="updateField($event, 'geo')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
            outlined
            hide-details
            label="Media Type"
            :items="mediaTypeOptions"
            :loading="mediaTypeOptionsLoading"
            :value="startingMediaType"
            @change="updateField($event, 'mediaType')"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-autocomplete
            outlined
            hide-details
            label="Client Requester"
            :items="projectRequesters"
            :value="startingClientRequester"
            @change="updateField($event, 'clientRequester')"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      class="editor-new-assignment__right editor-new-assignment__col"
    >
      <FlowStepper :datasIndex="datasIndex" :editMode="editMode" />
    </v-container>
  </v-card>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// libraries
import _ from "lodash";
const numeral = require("numeral");
// internal
import {
  getGeoChoices,
  getMediaTypeChoices,
  getPriceCodeWithWorkTypeCategory,
} from "@/utils/quickbaseUtils";
// components
import FlowStepper from "@/components/Editor/EditorNewAssignmentModal/EditorNewAssignmentItem/FlowStepper.vue";

export default {
  name: "EditorNewAssignment",
  props: {
    datasIndex: {
      type: Number,
      require: false,
    },
    editMode: {
      type: Boolean,
      require: false,
    },
    editorStartData: {
      type: Object,
      require: false,
    },
  },
  components: { FlowStepper },
  data() {
    return {
      sampleFlow: ["writer", "editor", "final"],
      deliveryDateMenu: false,
      deliveryDateData: null,
      payPeriodMenu: false,
      payPeriodData: null,
      geoOptionsLoading: false,
      geoOptions: [],
      mediaTypeOptionsLoading: false,
      mediaTypeOptions: [],
      priceOptionsLoading: false,
      priceOptions: [],
    };
  },
  computed: {
    ...mapGetters("editor", [
      "newAssignmentDatas",
      "selectedProject",
      "editAssignmentID",
      "editAssignmentData",
      "projectPOs",
      "projectRequesters",
    ]),
    startingAssignmentName() {
      return this.getStartingValues("assignmentName");
    },
    startingAssignmentDetails() {
      return this.getStartingValues("assignmentDetails");
    },
    startingAssignmentDeliveryDate() {
      return this.getStartingValues("deliveryDate");
    },
    startingRelatedPrice() {
      return this.getStartingValues("relatedPrice");
    },
    startingQuantity() {
      return this.getStartingValues("quantity");
    },
    startingClientRequester() {
      return this.getStartingValues("clientRequester");
    },
    startingPayPeriod() {
      return this.getStartingValues("payPeriod");
    },
    startingPO() {
      return this.getStartingValues("po");
    },
    startingGEO() {
      return this.getStartingValues("GEO");
    },
    startingMediaType() {
      return this.getStartingValues("mediaType");
    },
    startingExportHeader() {
      return this.getStartingValues("exportHeader");
    },
    hasPOs() {
      return this.projectPOs.length > 0;
    },
    hasClientRequester() {
      return this.projectClientRequesters.length > 0;
    },
  },
  watch: {
    selectedProject() {
      this.getPriceOptions();
    },
  },
  mounted() {
    // geo options
    this.getGeoOptions();

    // media options
    this.getMediaOptions();

    // price options
    this.getPriceOptions();
  },
  methods: {
    ...mapActions("editor", ["setNewAssignmentDatas", "setEditAssignmentData"]),
    updateField(value, field) {
      //  do pay period calculation
      if (this.editMode) {
        const newPayload = _.cloneDeep(this.editAssignmentData);
        newPayload[field] = value;
        this.setEditAssignmentData(newPayload);
      } else {
        const newPayload = _.cloneDeep(this.newAssignmentDatas);
        const indexCopy = _.cloneDeep(newPayload[this.datasIndex]);
        indexCopy[field] = value;
        newPayload[this.datasIndex] = indexCopy;
        this.setNewAssignmentDatas(newPayload);
      }
    },
    getStartingValues(field) {
      if (this.editMode) {
        return this.editAssignmentData[field];
      } else {
        return this.newAssignmentDatas[this.datasIndex][field];
      }
    },
    getGeoOptions() {
      this.geoOptionsLoading = true;
      getGeoChoices()
        .then((data) => {
          this.geoOptionsLoading = false;
          this.geoOptions = data;
        })
        .catch((err) => {
          this.geoOptionsLoading = false;
          console.error(err);
        });
    },
    getMediaOptions() {
      this.mediaTypeOptionsLoading = true;
      getMediaTypeChoices()
        .then((data) => {
          this.mediaTypeOptionsLoading = false;
          this.mediaTypeOptions = data;
        })
        .catch((err) => {
          this.mediaTypeOptions = false;
          console.error(err);
        });
    },
    getPriceOptions() {
      this.priceOptionsLoading = true;
      getPriceCodeWithWorkTypeCategory({
        workTypeCategory: this.selectedProject.workType,
      })
        .then((data) => {
          const formattedPriceOptions = data.map((el) => {
            const clientPrice = el["Price Card (Client)"]
              ? ` - ${numeral(el["Price Card (Client)"]).format("$0,0.00")}`
              : "";
            return {
              text: `${el["Work Type"]}${clientPrice}`,
              value: el["Record ID#"],
            };
          });

          this.priceOptionsLoading = false;
          this.priceOptions = formattedPriceOptions;
        })
        .catch((err) => {
          this.priceOptionsLoading = false;
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-new-assignment {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  &__col {
    width: 50%;
  }
}
</style>
