<template>
  <v-data-table
    :headers="headers"
    :items="clientEmails"
    class="elevation-1 client-emails__table"
    :search="search"
    :loading="loading"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Invoice Email Info</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <!-- <v-spacer></v-spacer> -->
        <v-dialog v-model="dialog" max-width="700px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              New Email
            </v-btn>
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.client"
                      label="Client"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem['primary_email']"
                      label="Primary Email"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem['secondary_emails']"
                      label="Secondary Emails"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
// code adapted from: https://vuetifyjs.com/en/components/data-tables/#crud-actions

import {
  getInvoiceEmails,
  addInvoiceEmail,
  updateInvoiceEmail,
  deleteInvoiceEmail,
} from "@/utils/dbUtils";
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Client",
        align: "start",
        value: "client",
      },
      {
        text: "Primary Email",
        align: "start",
        sortable: false,
        value: "primary_email",
      },
      {
        text: "Secondary Emails",
        align: "start",
        sortable: false,
        value: "secondary_emails",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    // clientEmails: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    idToDelete: null,
    search: "",
    loading: true,
  }),

  computed: {
    ...mapGetters("invoiceBot", ["clientEmails", "clientEmailsLoaded"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions("invoiceBot", ["setClientEmails"]),
    initialize() {
      if (!this.clientEmailsLoaded) {
        getInvoiceEmails().then((emailData) => {
          this.setClientEmails(emailData);
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },

    editItem(item) {
      this.editedIndex = this.clientEmails.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.clientEmails.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
      this.idToDelete = item.id;
    },

    deleteItemConfirm() {
      deleteInvoiceEmail(this.idToDelete).then((newEmailData) => {
        this.setClientEmails(newEmailData);
      });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedItem.id) {
        // update item
        console.log("update item", this.editedItem.id);
        updateInvoiceEmail(this.editedItem).then((newEmailData) => {
          this.setClientEmails(newEmailData);
        });
      } else {
        // add item
        addInvoiceEmail(this.editedItem).then((newEmailData) => {
          this.setClientEmails(newEmailData);
        });
      }
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
