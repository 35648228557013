var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isUpdating)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":24}}):_vm._e(),(!_vm.isUpdating)?_c('v-autocomplete',{staticClass:"caption selector",class:{
      'dri-selector': _vm.driMode,
      'status-selector': _vm.statusMode,
      'priority-selector': _vm.priorityMode,
    },style:({
      backgroundColor: _vm.statusColor,
    }),attrs:{"no-filter":!_vm.driMode,"items":_vm.dropdownOptions,"dense":"","hide-details":"","outlined":"","item-text":"value","loading":_vm.dropdownOptionsLoading,"value":_vm.currentSelect},on:{"change":(selected) => _vm.onChange({ item: _vm.item, selected })},nativeOn:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}}):_vm._e(),(_vm.isError)?_c('v-alert',{staticClass:"alert",attrs:{"type":"error","dismissible":""}},[_vm._v(" Error updating task "+_vm._s(_vm.modeHandler)+". Please try again. ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }