<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="v-card v-sheet theme--light pa-4"
  >
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Device Category"
            :items="categories"
            v-model="DeviceCategory"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Device Brand"
            :items="brands"
            :loading="dropdownsLoading"
            v-model="DeviceBrand"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-text-field
            outlined
            label="Device Model"
            v-model="DeviceModel"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-text-field
            outlined
            label="Device Serial Number"
            v-model="DeviceSerialNumber"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="User"
            :items="employees"
            v-model="User"
            :disabled="isEditMode && itemToEdit.OnHand"
            :rules="OnHand ? [rules.required] : []"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-file-input
            outlined
            label="Hardware User Agreement"
            accept=".pdf"
            truncate-length="50"
            v-model="HardwareUserAgreementBlob"
            :loading="HardwareUserAgreementLoading"
            :rules="computedValidationRules"
            @change="convertFile($event, 'HardwareUserAgreement')"
            @click:clear="filesToDelete.push('HardwareUserAgreement')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          :sm="isEditMode ? 3 : 4"
          class="pb-0 d-flex align-center justify-center"
        >
          <v-checkbox
            class="mt-0 pt-0"
            label="On Hand"
            prepend-icon="mdi-account-arrow-down"
            :disabled="handToNewUser"
            v-model="OnHand"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" :sm="isEditMode ? 3 : 4" class="pb-0">
          <v-menu
            v-model="onHandStartDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="OnHandStartDate"
                label="On Hand Start Date"
                prepend-icon="mdi-account-arrow-left"
                clearable
                :rules="
                  OnHand || (itemToEdit?.OnHand && !OnHand)
                    ? [rules.required]
                    : []
                "
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="onHandStartDateMenu = false"
              v-model="OnHandStartDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" :sm="isEditMode ? 3 : 4" class="pb-0">
          <v-menu
            v-model="onHandEndDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="OnHandEndDate"
                label="On Hand End Date"
                prepend-icon="mdi-account-arrow-right"
                clearable
                :rules="
                  (itemToEdit?.OnHand && !OnHand) || handToNewUser
                    ? [rules.required]
                    : []
                "
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="onHandEndDateMenu = false"
              v-model="OnHandEndDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          v-if="isEditMode"
          cols="12"
          sm="3"
          class="pb-0 d-flex align-center justify-center"
        >
          <v-checkbox
            class="mt-0 pt-0"
            label="Hand to a New User"
            prepend-icon="mdi-account-arrow-down-outline"
            :disabled="!OnHand"
            v-model="handToNewUser"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row v-if="handToNewUser">
          <v-col cols="12" sm="4" class="pb-0">
            <v-autocomplete
              outlined
              label="New User"
              :items="employees"
              v-model="newUser"
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-file-input
              outlined
              label="New Hardware User Agreement"
              accept=".pdf"
              truncate-length="50"
              v-model="newHardwareUserAgreementBlob"
              :rules="[rules.required, rules.isPDF]"
              @change="convertFile($event, 'newHardwareUserAgreement')"
            />
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-menu
              v-model="newOnHandStartDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="newOnHandStartDate"
                  label="New On Hand Start Date"
                  prepend-icon="mdi-account-arrow-left-outline"
                  clearable
                  :rules="[rules.required]"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="newOnHandStartDateMenu = false"
                v-model="newOnHandStartDate"
              ></v-date-picker> </v-menu
          ></v-col>
        </v-row>
      </v-expand-transition>
      <v-row>
        <v-col cols="12" sm="3" class="pb-0">
          <v-menu
            v-model="purchaseDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="PurchaseDate"
                :rules="[rules.required]"
                label="Purchase Date"
                prepend-icon="mdi-cash-fast"
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="purchaseDateMenu = false"
              v-model="PurchaseDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-text-field
            outlined
            type="number"
            label="Purchase Price"
            prepend-inner-icon="mdi-currency-usd"
            v-model="PurchasePrice"
          />
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-file-input
            outlined
            label="Purchase Receipt"
            accept=".pdf"
            truncate-length="20"
            v-model="PurchaseReceiptBlob"
            :loading="PurchaseReceiptLoading"
            :rules="[rules.isPDF]"
            @change="convertFile($event, 'PurchaseReceipt')"
            @click:clear="filesToDelete.push('PurchaseReceipt')"
          />
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-autocomplete
            outlined
            label="Purchased By"
            :items="purchasers"
            :loading="dropdownsLoading"
            v-model="PurchasedBy"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" class="pb-0">
          <v-menu
            v-model="reimbursementDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="ReimbursementDate"
                label="Reimbursement Date"
                hint="Must correspond to Reimbursement Record Delivery Date"
                persistent-hint
                prepend-icon="mdi-cash-refund"
                clearable
                :disabled="PurchasedBy !== 'User'"
                :rules="ReimbursementRecord ? [rules.required] : []"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="reimbursementDateMenu = false"
              v-model="ReimbursementDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-text-field
            outlined
            type="number"
            label="Reimbursement Amount"
            prepend-inner-icon="mdi-currency-usd"
            :disabled="PurchasedBy !== 'User'"
            v-model="ReimbursementAmount"
          />
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-text-field
            outlined
            label="Reimbursement Record ID"
            prepend-inner-icon="mdi-link-variant"
            :disabled="PurchasedBy !== 'User'"
            v-model="ReimbursementRecord"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Cue Project"
            :items="cueProjects"
            :loading="dropdownsLoading"
            v-model="Project1"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="iTunes Project"
            :items="iTunesProjects"
            :loading="dropdownsLoading || iTunesProjectLoading"
            v-model="Project2"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-textarea
            outlined
            clearable
            rows="2"
            clear-icon="mdi-close-circle-outline"
            label="Notes"
            v-model="Notes"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="justify-end py-0">
      <v-scale-transition>
        <div v-if="deleteConfirmation" class="d-flex align-center">
          <h3>Are you sure?</h3>
          <v-btn class="ml-2" color="success" @click="deleteHardware">
            Yes
          </v-btn>
          <v-btn class="ml-2" color="error" @click="deleteConfirmation = false">
            No
          </v-btn>
        </div>
      </v-scale-transition>
      <v-btn
        v-if="isEditMode"
        min-width="unset"
        class="ml-2"
        color="secondary"
        :loading="hardwareHandling"
        :disabled="deleteConfirmation"
        @click="deleteConfirmation = true"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        color="secondary"
        :loading="hardwareHandling"
        :disabled="deleteConfirmation"
        @click="handleHardware"
      >
        {{ isEditMode ? "Update" : "Save" }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
// internal
import {
  hardwareFieldsLookup,
  appIDs,
  getFieldInfosFromDB,
  getReportData,
  getFile,
  processProjectToolsRow,
  deleteRowFromQB,
  getProjectID,
  deleteFile,
} from "@/utils/quickbaseUtils";

export default {
  name: "CueHardwareForm",
  props: {
    itemToEdit: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dropdownsLoading: true,
      ["RecordID#"]: null,
      DeviceCategory: "",
      brands: [],
      DeviceBrand: "",
      DeviceModel: "",
      DeviceSerialNumber: "",
      User: "",
      HardwareUserAgreementLoading: false,
      HardwareUserAgreement: null,
      HardwareUserAgreementBlob: null,
      HardwareUserAgreementString: "",
      OnHand: false,
      onHandStartDateMenu: false,
      OnHandStartDate: "",
      onHandEndDateMenu: false,
      OnHandEndDate: "",
      purchaseDateMenu: false,
      PurchaseDate: "",
      PurchasePrice: "",
      PurchaseReceiptLoading: false,
      PurchaseReceipt: null,
      PurchaseReceiptBlob: null,
      purchasers: [],
      PurchasedBy: "",
      reimbursementDateMenu: false,
      ReimbursementDate: "",
      ReimbursementAmount: "",
      ReimbursementRecord: "",
      cueProjects: [],
      Project1: "",
      iTunesProjects: [],
      iTunesProjectLoading: false,
      RelatedProject: "",
      Project2: "",
      Notes: "",
      handToNewUser: false,
      newUser: "",
      newHardwareUserAgreement: null,
      newHardwareUserAgreementBlob: null,
      newOnHandStartDateMenu: false,
      newOnHandStartDate: "",
      History: "",
      filesToDelete: [],
      valid: true,
      hardwareHandling: false,
      deleteConfirmation: false,
      rules: {
        required: (value) => !!value || value === 0 || "Required",
        isPDF: (value) =>
          value?.type == "application/pdf" ||
          value === null ||
          "Only PDF attachments are allowed.",
      },
    };
  },
  computed: {
    isEditMode() {
      return !!Object.keys(this.itemToEdit).length;
    },
    computedValidationRules() {
      const { rules, itemToEdit, OnHand } = this;

      if (OnHand || (itemToEdit && itemToEdit.OnHand && !OnHand)) {
        return [rules.required, rules.isPDF];
      } else {
        return [rules.isPDF];
      }
    },
  },
  watch: {
    itemToEdit() {
      this.dataViewsHandler();
    },
    Project2(newVal) {
      this.getRelatedITunesProject(newVal);
    },
    PurchasedBy(newVal) {
      if (!newVal !== "User")
        this.ReimbursementDate =
          this.ReimbursementAmount =
          this.ReimbursementRecord =
            "";
    },
  },
  mounted() {
    this.handleDropdowns();
    this.dataViewsHandler();
  },
  methods: {
    dataViewsHandler() {
      this.handToNewUser = this.deleteConfirmation = false;
      this.newUser = this.newOnHandStartDate = "";
      this.newHardwareUserAgreement = this.newHardwareUserAgreementBlob = null;
      if (this.isEditMode) {
        this.handleEditMode();
      } else {
        this.$refs.form.resetValidation();
        Object.keys(hardwareFieldsLookup).forEach((el) => {
          this[`${el}`] = hardwareFieldsLookup[el].default;
          if (this.$data.hasOwnProperty(`${el}Blob`))
            this[`${el}Blob`] = hardwareFieldsLookup[el].default;
        });
      }
    },
    handleDropdowns() {
      Promise.all([
        this.getBrands(),
        this.getPurchasers(),
        this.getCueProjects(),
        this.getITunesProjects(),
      ])
        .then(() => (this.dropdownsLoading = false))
        .catch((err) => {
          console.error(err);
          this.dropdownsLoading = false;
        });
    },
    getBrands() {
      getFieldInfosFromDB({
        fieldID: 41,
        tableID: appIDs.cueProjectTools.hardware,
      })
        .then((brands) => (this.brands = brands))
        .catch((err) => console.error(err));
    },
    getPurchasers() {
      getFieldInfosFromDB({
        fieldID: 38,
        tableID: appIDs.cueProjectTools.hardware,
      })
        .then((purchasers) => (this.purchasers = purchasers))
        .catch((err) => console.error(err));
    },
    getCueProjects() {
      getReportData({
        reportID: 1,
        tableID: appIDs.cueProjectManager.projects,
      })
        .then(
          (projects) =>
            (this.cueProjects = projects.map((el) => {
              return el.ProjectName;
            }))
        )
        .catch((err) => console.error(err));
    },
    getITunesProjects() {
      getReportData({
        reportID: 32,
        tableID: appIDs.iTunesProjectManager.projects,
      })
        .then(
          (projects) =>
            (this.iTunesProjects = projects.map((el) => {
              return el.ProjectName;
            }))
        )
        .catch((err) => console.error(err));
    },
    handleEditMode() {
      let files = [];

      // loop through fields
      Object.entries(this.itemToEdit).forEach((el) => {
        if (el[1]?.hasOwnProperty("url")) {
          if (el[1].url) {
            this[`${el[0]}Blob`] = null;
            this[`${el[0]}Loading`] = true;
            files.push({
              url: el[1].url,
              name: el[0],
            });
          } else {
            this[`${el[0]}`] = this[`${el[0]}Blob`] = null;
            this[`${el[0]}Loading`] = false;
          }
        } else {
          this[`${el[0]}`] =
            el[0] === "PurchasePrice" ? el[1].replace("$", "") : el[1];
        }
      });

      // get files
      this.handleFiles(files);
    },
    getRelatedITunesProject(projectName) {
      this.iTunesProjectLoading = true;
      getProjectID(projectName)
        .then((project) => {
          this.RelatedProject = project?.["RecordID#"];
          this.iTunesProjectLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.iTunesProjectLoading = false;
        });
    },
    handleFile(url, fileName) {
      return new Promise((resolve, reject) => {
        getFile(url)
          .then(async (resp) => {
            const base64Response = await fetch(
              `data:application/pdf;base64,${resp.data}`
            );
            const blob = await base64Response.blob();
            let newBlob = new Blob([blob], {
              type: "application/pdf",
            });
            newBlob.name = `${
              fileName.includes("Hardware") ? `${this.User}_` : ""
            }${this.DeviceBrand}_${this.DeviceModel}_${
              this.DeviceCategory
            }_${fileName}.pdf`;
            this[`${fileName}Loading`] = false;
            if (fileName === "HardwareUserAgreement")
              this.HardwareUserAgreementString = `data:application/pdf;base64,${resp.data}`;
            resolve((this[`${fileName}Blob`] = newBlob));
          })
          .catch((err) => reject(err));
      });
    },
    handleFiles(files) {
      const promises = files.map((file) =>
        this.handleFile(file?.url, file.name)
      );
      Promise.all(promises).catch((err) => console.error(err));
    },
    convertFile(file, field) {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this[`${field}`] = {
            fileName: `${
              field.includes("Hardware")
                ? `${field.includes("new") ? this.newUser : this.User}_`
                : ""
            }${this.DeviceBrand}_${this.DeviceModel}_${
              this.DeviceCategory
            }_${field}.${file.type.split("/")[1]}`,
            data: reader.result.split(",")[1],
          };
          if (field === "HardwareUserAgreement")
            this.HardwareUserAgreementString = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        this[`${field}`] = null;
      }
    },
    handleHardware() {
      if (this.$refs.form.validate()) {
        this.hardwareHandling = true;
        let data = {},
          filesDeletionPromises = [];
        Object.keys(hardwareFieldsLookup).forEach((el) => {
          Object.assign(data, {
            [el]: this[`${el}`],
          });
        });
        // handle history record
        if (this.handToNewUser || (!this.OnHand && this.itemToEdit?.OnHand)) {
          let history = this.History
            ? JSON.parse(this.History)
            : { records: [] };
          history.records.push({
            historyUser: this.User,
            historyOnHandStartDate: this.OnHandStartDate,
            historyOnHandEndDate: this.OnHandEndDate,
            historyHardwareAgr: this.HardwareUserAgreementString,
          });
          Object.assign(data, {
            History: JSON.stringify(history),
            User: this.handToNewUser ? this.newUser : "",
            OnHandStartDate: this.handToNewUser ? this.newOnHandStartDate : "",
          });
          Object.assign(
            data,
            this.handToNewUser
              ? {
                  HardwareUserAgreement: this.newHardwareUserAgreement,
                }
              : {
                  OnHandEndDate: "",
                }
          );
          if (!this.OnHand) {
            this.HardwareUserAgreementBlob = null;
            Object.assign(data, { HardwareUserAgreement: null });
            filesDeletionPromises.push(
              new Promise((resolve, reject) => {
                deleteFile(this.itemToEdit.HardwareUserAgreement?.url)
                  .then((resp) => resolve(resp))
                  .catch((err) => reject(err));
              })
            );
          }
        }
        this.filesToDelete.forEach((file) => {
          if (this.itemToEdit[`${file}`]?.url && !this[`${file}Blob`])
            filesDeletionPromises.push(
              new Promise((resolve, reject) => {
                deleteFile(this.itemToEdit[`${file}`]?.url)
                  .then((resp) => resolve(resp))
                  .catch((err) => reject(err));
              })
            );
        });
        Promise.all(filesDeletionPromises)
          .then(() => {
            processProjectToolsRow({
              tableID: appIDs.cueProjectTools.hardware,
              fieldsLookup: hardwareFieldsLookup,
              data,
            }).then((resp) => {
              let msg = null;
              if (resp.metadata.createdRecordIds.length) {
                msg = "Record created!";
              } else if (
                resp.metadata.updatedRecordIds.length ||
                resp.metadata.unchangedRecordIds.length
              ) {
                msg = "Record updated!";
              }
              this.hardwareHandling = false;
              this.$emit("hardware-table-refresh", msg);
            });
          })
          .catch((err) => {
            console.error(err);
            this.hardwareHandling = false;
          });
      }
    },
    deleteHardware() {
      this.deleteConfirmation = false;
      this.hardwareHandling = true;
      deleteRowFromQB({
        id: this["RecordID#"],
        tableID: appIDs.cueProjectTools.hardware,
      }).then((resp) => {
        const msg = resp.numberDeleted ? "Record deleted!" : null;
        this.hardwareHandling = false;
        this.$emit("hardware-table-refresh", msg);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
