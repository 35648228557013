<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filteredData"
      item-key="Name"
      group-by="Country"
      sort-by="Name"
      :header-props="{ sortIcon: null }"
      :footer-props="{
        'items-per-page-options': [-1],
      }"
      @click:row="$emit('call-edit-form', $event)"
    >
      <!-- grouping override -->
      <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
        <td
          class="pa-0 group-header-row"
          :colspan="headers.length"
          @click="toggle"
        >
          <v-btn
            :ref="group"
            :data-open="isOpen"
            large
            icon
            class="ml-1 vertical-middle"
            @click.stop="toggle"
          >
            <v-icon v-if="isOpen">mdi-chevron-down</v-icon>
            <v-icon v-else>mdi-chevron-right</v-icon>
          </v-btn>
          <v-img
            max-width="48"
            width="48"
            :height="getFlag(group) === noCountry ? 48 : 36"
            :src="getFlag(group)"
            class="d-inline-flex mx-2 vertical-middle"
            :alt="`${group}_flag`"
          ></v-img>
          <h2 class="mr-4 font-weight-bold d-inline vertical-middle">
            {{ group }}
          </h2>
          <v-chip class="font-weight-bold white vertical-middle elevation-2">
            {{ getActiveOfAvailable(group) }}
          </v-chip>
        </td>
      </template>

      <!-- Start Date cell override -->
      <template v-slot:[`item.StartDate`]="{ value }">
        <span>{{ formatDateForTableRow(value) }}</span>
      </template>

      <!-- Main Skill cell override -->
      <template v-slot:[`item.MainSkill`]="{ item }">
        <span>{{ getMainSkill(item) }}</span>
      </template>

      <!-- General Expertise cell override -->
      <template v-slot:[`item.GeneralExpertise`]="{ item }">
        <span>{{ getGeneralExpertise(item) }}</span>
      </template>

      <!-- LinkedIn Profile cell override -->
      <template v-slot:[`item.LinkedInProfile`]="{ value }">
        <v-btn
          icon
          large
          color="blue darken-2"
          :disabled="!value"
          @click.stop="openLinkedInProfile(value)"
          ><v-icon>mdi-linkedin</v-icon></v-btn
        >
      </template>

      <!-- Slack Staff cell override -->
      <template v-slot:[`item.SlackStaff`]="{ item }">
        <v-btn
          fab
          x-small
          outlined
          elevation="1"
          color="accent"
          :href="`slack://user?team=${cueStaff}&id=${item.SlackStaffWorkspaceID}`"
          :disabled="!item.SlackStaffWorkspaceID"
          @click.stop
          ><v-icon>mdi-slack</v-icon></v-btn
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
// libraries
import _ from "lodash";
import Fuse from "fuse.js";
// internal
import { customBreakpoint } from "@/utils/mixins";
import { workspaces } from "@/utils/slackUtils";
import { ISO_COUNTRIES } from "@/utils/constants";
import noCountry from "@/assets/cue_logo.svg";

export default {
  name: "ContactsTable",
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    tableFields: {
      type: Array,
      required: false,
    },
    filterStartDate: {
      type: Array,
      required: false,
    },
    filterSearch: {
      type: String,
      required: false,
    },
    filterLanguage: {
      type: String,
      required: true,
    },
    filterActiveStaff: {
      type: Boolean,
      required: true,
    },
    filterStatus: {
      type: Array,
      required: true,
    },
    filterCountry: {
      type: String,
      required: true,
    },
    filterSkill: {
      type: Array,
      required: true,
    },
    filterExpertise: {
      type: Array,
      required: true,
    },
    filterLeadEditors: {
      type: Boolean,
      required: true,
    },
    filterPCs: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      noCountry,
      tableHeaders: [
        "Name",
        "StartDate",
        "Language1",
        "MainSkill",
        "GeneralExpertise",
        "LinkedInProfile",
        "TimeOff",
        "SlackStaff",
      ],
    };
  },
  mixins: [customBreakpoint],
  mounted() {
    this.toggleAll();
    this.getTotalActiveOfAvailable();
  },
  computed: {
    headers() {
      if (!this.tableFields) return [];

      let retVal = this.tableFields
        .filter((el) => this.tableHeaders.includes(el.value))
        .map((field) => {
          return {
            text: field.text,
            value: field.value,
          };
        });

      // order by the order of the tableHeaders
      retVal = _.orderBy(retVal, (el) => this.tableHeaders.indexOf(el.value));

      return retVal;
    },
    tableFieldValues() {
      return this.tableFields.map((field) => field.value);
    },
    filteredData() {
      let dateFilteredData = [];

      // status filtered
      const filterMap = ["Available", "Unavailable"];

      const withStatus = this.filterStatus.map((el) => filterMap[el]);

      dateFilteredData = this.tableData.filter((el) => {
        return withStatus.includes(el.Status);
      });

      if (this.filterActiveStaff) {
        dateFilteredData = dateFilteredData.filter((el) => el["ActiveStaff"]);
      }

      if (this.filterStartDate.length) {
        const datesRange = [],
          startDate = this.$moment(this.filterStartDate[0]),
          endDate = this.$moment(
            this.filterStartDate[1]
              ? this.filterStartDate[1]
              : this.filterStartDate[0]
          );
        for (
          let dateVar = new Date(startDate);
          dateVar <= endDate;
          dateVar.setDate(dateVar.getDate() + 1)
        ) {
          datesRange.push(this.$moment(new Date(dateVar)).format("YYYY-MM-DD"));
        }
        dateFilteredData = dateFilteredData.filter((el) => {
          return datesRange.includes(
            this.$moment(el["StartDate"]).format("YYYY-MM-DD")
          );
        });
      }

      if (this.filterLanguage) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el["Language1"] === this.filterLanguage
        );
      }

      if (this.filterCountry) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el["Country"] === this.filterCountry
        );
      }

      // filter skills
      const skills = [
        "Copywriting",
        "Copyediting",
        "Translation",
        "Editing Translations",
        "Curation",
        "Design",
        "Metadata",
        "Project Management",
      ];
      const skillsList = this.filterSkill.map((el) => skills[el]);
      dateFilteredData = dateFilteredData.filter((el) => {
        let skillsIDs = [];
        skillsList.forEach((skill) => {
          skillsIDs.push(
            Object.keys(el)
              .find((key) => el[key] === skill)
              ?.slice(-1)
          );
        });
        return skillsIDs.every(
          (id) =>
            el[`Skill${id}YearsOfExperience`] !== "N/A" &&
            el[`Skill${id}YearsOfExperience`]
        );
      });

      // filter expertise
      const expertise = [
        "Music",
        "Film&TV",
        "Books",
        "Mobile/VideoGames",
        "Podcasts",
        "MobileApps",
        "Lifestyle",
        "Sports",
      ];
      const expertiseList = this.filterExpertise.map((el) => expertise[el]);
      dateFilteredData = dateFilteredData.filter((el) => {
        return expertiseList.every(
          (expertise) =>
            el[`${expertise}YearsOfExperience`] !== "N/A" &&
            el[`${expertise}YearsOfExperience`]
        );
      });

      if (this.filterLeadEditors) {
        dateFilteredData = dateFilteredData.filter((el) => el["LeadEditor"]);
      }

      if (this.filterPCs) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el["ProjectCoordinator"]
        );
      }

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: this.tableFieldValues,
      };

      if (this.filterSearch) {
        const fuse = new Fuse(dateFilteredData, searchOptions);
        dateFilteredData = fuse.search(this.filterSearch).map((el) => el.item);
      }

      return dateFilteredData;
    },
    cueStaff() {
      return workspaces.find((el) => el.name === "Cue Staff").id;
    },
  },
  watch: {
    filteredData(newVal) {
      this.getTotalActiveOfAvailable(newVal);
    },
  },
  methods: {
    toggleAll() {
      Object.keys(this.$refs).forEach((k) => {
        this.$refs[k].$el?.click();
      });
    },
    getFlag(country) {
      const isoCountry = Object.keys(ISO_COUNTRIES).find((item) =>
        item.includes(country)
      );
      return isoCountry
        ? `https://flagcdn.com/192x144/${ISO_COUNTRIES[
            isoCountry
          ].toLowerCase()}.png`
        : noCountry;
    },
    getTotalActiveOfAvailable(newData) {
      const data = newData ? newData : this.filteredData;
      const available = data.filter((staff) => staff.Status === "Available");
      const active = available.filter((staff) => staff.ActiveStaff);
      const countries = [
        ...new Set(
          data.map((el) => {
            return el.Country;
          })
        ),
      ];
      const total = `${active.length} Active | ${available.length} Available Staff Members in ${countries.length} Countries`;
      this.$emit("refresh-total-info", total);
    },
    getActiveOfAvailable(contry) {
      const available = this.filteredData.filter(
        (staff) => staff.Status === "Available" && staff.Country === contry
      );
      const active = available.filter(
        (staff) => staff.ActiveStaff && staff.Country === contry
      );
      return `${active.length} Active | ${available.length} Available`;
    },
    formatDateForTableRow(data, displayTime, timeOnly) {
      return data
        ? this.$moment(data).format(
            `${
              timeOnly
                ? "hh:mm A"
                : `MM/DD/YYYY ${displayTime ? "hh:mm A" : ""}`
            }`
          )
        : "";
    },
    getMainSkill(item) {
      const skillsKeys = Object.keys(item).filter((key) =>
          key.includes("Skill")
        ),
        skillsList = Object.fromEntries(skillsKeys.map((k) => [k, item[k]])),
        mainSkill =
          Object.entries(skillsList).find((el) => el[1] === ">10") ??
          Object.entries(skillsList).find((el) => el[1] === "5-10") ??
          Object.entries(skillsList).find((el) => el[1] === "2-5") ??
          Object.entries(skillsList).find((el) => el[1] === "<2");
      return mainSkill
        ? item[mainSkill[0].replace("YearsOfExperience", "")]
        : "";
    },
    getGeneralExpertise(item) {
      const expertiseList = [
          { key: "Music", title: "Music" },
          { key: "Film&TV", title: "Film & TV" },
          { key: "Books", title: "Books" },
          { key: "Mobile/VideoGames", title: "Mobile/Video Games" },
          { key: "Podcasts", title: "Podcasts" },
          { key: "MobileApps", title: "Mobile Apps" },
          { key: "Lifestyle", title: "Lifestyle" },
          { key: "Sports", title: "Sports" },
        ].map((el) => {
          return {
            expertise: el.title,
            experience: item[`${el.key}YearsOfExperience`],
          };
        }),
        generalExpertise =
          expertiseList.find((el) => el.experience === ">10") ??
          expertiseList.find((el) => el.experience === "5-10") ??
          expertiseList.find((el) => el.experience === "2-5") ??
          expertiseList.find((el) => el.experience === "<2");
      return generalExpertise
        ? `${generalExpertise.expertise} (${generalExpertise.experience} years)`
        : "";
    },
    openLinkedInProfile(value) {
      const splitLink = value.split("/");
      const profile = splitLink.at(-1) ? splitLink.at(-1) : splitLink.at(-2);
      window.open(`https://www.linkedin.com/in/${profile}`, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.group-header-row {
  background-color: #ececec !important;
  color: #003a35 !important;
}
.vertical-middle {
  vertical-align: middle;
}

::v-deep {
  tbody > tr {
    cursor: pointer;
  }
  .v-data-table.laptop .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 8px;
    }
    & .v-data-table-header tr > th {
      padding: 0 8px;
    }
  }
  .v-data-table.laptopSmaller .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 4px;
    }
    & .v-data-table-header tr > th {
      padding: 0 4px;
    }
  }
}
</style>
