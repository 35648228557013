<template>
  <div class="report-selector">
    <v-btn
      class="report-selector__button"
      :color="selected === 'LAST' ? 'primary' : 'secondary'"
      :outlined="selected !== 'LAST'"
      depressed
      @click="() => handleChange('LAST')"
      >Last Month</v-btn
    >
    <v-btn
      class="report-selector__button"
      :color="selected === 'THIS' ? 'primary' : 'secondary'"
      :outlined="selected !== 'THIS'"
      depressed
      @click="() => handleChange('THIS')"
      >This Month</v-btn
    >
    <v-btn
      class="report-selector__button"
      :color="selected === 'NEXT' ? 'primary' : 'secondary'"
      :outlined="selected !== 'NEXT'"
      depressed
      @click="() => handleChange('NEXT')"
      >Next Month</v-btn
    >
  </div>
</template>

<script>
export default {
  props: {
    selected: String,
  },
  methods: {
    handleChange(v) {
      this.$emit("change", v);
    },
  },
};
</script>

<style lang="scss" scoped>
.report-selector {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin: 20px 0px;

  &__button {
    margin: 0px 10px;
    width: 200px;
  }
}
</style>
