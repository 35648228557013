<template>
  <v-container>
    <div>
      <span class="overline">add comment</span>
    </div>
    <div class="mb-3">
      <v-textarea solo hide-details v-model="commentContents"></v-textarea>
    </div>
    <div class="d-flex justify-end">
      <v-btn color="secondary" @click="submitComment">Submit</v-btn>
    </div>
  </v-container>
</template>
<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// library
// internal
// components
export default {
  name: "EditorNewComment",
  props: {
    assignment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      commentContents: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapActions("editor", ["setAssignmentComments"]),
    submitComment() {
      const commentData = {
        userEmail: this.user.email,
        timeStamp: new Date().toString(),
        data: this.commentContents,
      };

      this.assignment
        .addComment(commentData)
        .then(() => {
          return this.assignment.getComments();
        })
        .then((comments) => {
          this.setAssignmentComments(comments);
          this.commentContents = "";
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
