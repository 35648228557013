import Vue from "vue";
import VueRouter from "vue-router";
// components
import Home from "@/views/Home";
import FCBot from "@/views/FCBot";
import GeneralSplitter from "@/views/GeneralSplitter";
import InvoiceBot from "@/views/InvoiceBot";
import AvailabilityCalendar from "@/views/AvailabilityCalendar";
import Login from "@/views/Login";
import ClientEmails from "@/views/ClientEmails";
import Users from "@/views/Users";
import Contacts from "@/views/Contacts";
import MyCue from "@/views/MyCue";
import Request from "@/views/Request";
import TypeformTable from "@/views/TypeformTable";
import PMDashboard from "@/views/PMDashboard";
import FCDashboard from "@/views/FCDashboard";
import CPMDashboard from "@/views/CPMDashboard";
import LEDDashboard from "@/views/LEDDashboard";
import IssueTracker from "@/views/IssueTracker";
import Editor from "@/views/Editor";
import CueHardware from "@/views/CueHardware";
import CueSoftware from "@/views/CueSoftware";
// internal
import { auth } from "@/providers/firebase";
import { PASSTHROUGH_ENV, ROUTES } from "@/utils/constants";
import store from "../store/index";

Vue.use(VueRouter);

const permRejectHandler = (user, next) => {
  if (user) {
    next({ name: "Home" });
  } else {
    next({ name: "Login" });
  }
};

const adminsOrDevsOnly = (to, from, next) => {
  if (process.env.NODE_ENV === PASSTHROUGH_ENV) {
    // go on as normal
    next();
  }
  auth.currentUser.getIdTokenResult().then((idTokenResult) => {
    if (idTokenResult?.claims?.admin) {
      next();
    } else {
      permRejectHandler(auth.currentUser, next);
    }
  });
};

const adminsOrDubOnly = (to, from, next) => {
  if (process.env.NODE_ENV === PASSTHROUGH_ENV) {
    // go on as normal
    next();
  }
  auth.currentUser.getIdTokenResult().then((idTokenResult) => {
    if (
      idTokenResult?.claims?.admin ||
      (idTokenResult?.claims?.dubEditor && !store.getters["auth/profileLock"])
    ) {
      next();
    } else {
      permRejectHandler(auth.currentUser, next);
    }
  });
};

const adminsOrManagers = (to, from, next) => {
  if (process.env.NODE_ENV === PASSTHROUGH_ENV) {
    // go on as normal
    next();
  }
  auth.currentUser.getIdTokenResult().then((idTokenResult) => {
    if (
      idTokenResult?.claims?.admin ||
      (store.getters["auth/isManager"] && !store.getters["auth/profileLock"])
    ) {
      next();
    } else {
      permRejectHandler(auth.currentUser, next);
    }
  });
};

const cueOnly = (to, from, next) => {
  if (process.env.NODE_ENV === PASSTHROUGH_ENV) {
    // go on as normal
    next();
  }
  auth.currentUser.getIdTokenResult().then((idTokenResult) => {
    if (
      idTokenResult?.claims?.admin ||
      (idTokenResult?.claims?.company === "Company Cue, Inc." &&
        !store.getters["auth/profileLock"])
    ) {
      next();
    } else {
      permRejectHandler(auth.currentUser, next);
    }
  });
};

const seniorStaffOrCueOnlyFC = (to, from, next) => {
  if (process.env.NODE_ENV === PASSTHROUGH_ENV) {
    // go on as normal
    next();
  }
  auth.currentUser.getIdTokenResult().then((idTokenResult) => {
    if (
      (idTokenResult?.claims?.company === "Company Cue, Inc." &&
        !store.getters["auth/profileLock"] &&
        (store.getters["auth/user"]["LastDeliveryDate-Writer"] ||
          store.getters["auth/user"]["LastDeliveryDate-Editor"] ||
          store.getters["auth/isManager"])) ||
      idTokenResult?.claims?.admin
    ) {
      next();
    } else {
      permRejectHandler(auth.currentUser, next);
    }
  });
};

const seniorStaffOrCueOnlyCuePM = (to, from, next) => {
  if (process.env.NODE_ENV === PASSTHROUGH_ENV) {
    // go on as normal
    next();
  }
  auth.currentUser.getIdTokenResult().then((idTokenResult) => {
    if (
      (idTokenResult?.claims?.company === "Company Cue, Inc." &&
        !store.getters["auth/profileLock"] &&
        (store.getters["auth/user"]["LastDeliveryDate-Primary"] ||
          store.getters["auth/user"]["LastDeliveryDate-Reviewer"] ||
          store.getters["auth/isManager"])) ||
      idTokenResult?.claims?.admin
    ) {
      next();
    } else {
      permRejectHandler(auth.currentUser, next);
    }
  });
};

const seniorStaffOrFCReviewersOnly = (to, from, next) => {
  if (process.env.NODE_ENV === PASSTHROUGH_ENV) {
    // go on as normal
    next();
  }
  auth.currentUser.getIdTokenResult().then((idTokenResult) => {
    if (
      (idTokenResult?.claims?.company === "Company Cue, Inc." &&
        !store.getters["auth/profileLock"] &&
        store.getters["auth/user"]?.LeadEditor) ||
      idTokenResult?.claims?.admin
    ) {
      next();
    } else {
      permRejectHandler(auth.currentUser, next);
    }
  });
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: `/${ROUTES.spreadsheetUpload}`,
    name: "Spreadsheet Upload",
    component: FCBot,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.fileSplitter}`,
    name: "File Splitter",
    component: GeneralSplitter,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.invoiceBot}`,
    name: "Invoice Bot",
    component: InvoiceBot,
    beforeEnter: adminsOrDevsOnly,
  },
  {
    path: `/${ROUTES.calendar}`,
    name: "Availability Calendar",
    component: AvailabilityCalendar,
    beforeEnter: adminsOrManagers,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: `/${ROUTES.clientEmails}`,
    name: "Client Emails",
    component: ClientEmails,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.myCue}`,
    name: "MyCue",
    component: MyCue,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.users}`,
    name: "Users",
    component: Users,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.contacts}`,
    name: "Staff",
    component: Contacts,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.pmDashboard}`,
    name: "PM Dashboard",
    component: PMDashboard,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.fcDashboard}`,
    name: "FC Assignment Dashboard",
    component: FCDashboard,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.cpmDashboard}`,
    name: "Cue Assignment Dashboard",
    component: CPMDashboard,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.ledDashboard}`,
    name: "Lead Editor Dashboard",
    component: LEDDashboard,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.issueTracker}`,
    name: "Issue Tracker",
    component: IssueTracker,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.editor}`,
    name: "Dub Editor",
    component: Editor,
    beforeEnter: adminsOrDevsOnly,
  },
  {
    path: `/${ROUTES.cueHardware}`,
    name: "Cue Hardware",
    component: CueHardware,
    beforeEnter: adminsOrDevsOnly,
  },
  {
    path: `/${ROUTES.cueSoftware}`,
    name: "Cue Software",
    component: CueSoftware,
    beforeEnter: adminsOrDevsOnly,
  },
  {
    path: `/${ROUTES.request}`,
    name: "Request",
    component: Request,
    beforeEnter: adminsOrManagers,
  },
  {
    path: `/${ROUTES.typeforms}`,
    name: "Typeforms",
    component: TypeformTable,
    beforeEnter: adminsOrManagers,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (process.env.NODE_ENV === PASSTHROUGH_ENV) {
    // go on as normal
    next();
  } else if (window.location.pathname === "/login" || to.name === "Login") {
    // go on as normal
    next();
  } else if (!auth.currentUser) {
    // redirect to login page
    const entry = to.fullPath;
    next({ name: "Login", query: { to: entry } });
  } else {
    // go on as normal
    next();
  }
});

export default router;
