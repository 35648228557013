<template>
  <v-card>
    <EditorEditProjectItem />
    <v-card-actions>
      <v-btn block color="secondary" @click="handleSaveButton">save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import { dub } from "@/providers/dubEditor";
import { ROUTES } from "@/utils/constants";
// components
import EditorEditProjectItem from "./EditorEditProjectItem";

export default {
  name: "EditorProjectModal",
  components: { EditorEditProjectItem },
  computed: {
    ...mapGetters("editor", ["editProjectData", "selectedProject"]),
    ...mapGetters("auth", ["user", "isAdmin"]),
  },
  methods: {
    ...mapActions("editor", [
      "setEditProjectData",
      "setProjectModalOpen",
      "setAllProjects",
      "setSelectedProject",
    ]),
    handleSaveButton() {
      if (this.editProjectData.projectID) {
        // this is an existing project
        this.selectedProject
          .updateWithData(this.editProjectData)
          .then(() => {
            return dub.listProjects({
              userEmail: this.user.email,
              isAdmin: this.isAdmin,
            });
          })
          .then((allProjects) => {
            const updatedExistingProject = allProjects.find(
              (el) => el.projectID === this.selectedProject.projectID
            );
            this.setSelectedProject(updatedExistingProject);
            this.$router.push({
              path: `/${ROUTES.editor}`,
              query: { pid: updatedExistingProject.projectID },
            });
            this.setAllProjects(allProjects);
            this.setProjectModalOpen(false);
          })
          .catch((err) => {
            console.warn(err);
          });
      } else {
        // this is a new project
        dub
          .createNewProject(this.editProjectData)
          .then((id) => {
            return dub.listProjects({
              userEmail: this.user.email,
              isAdmin: this.isAdmin,
            });
          })
          .then((allProjects) => {
            this.setAllProjects(allProjects);
            this.setProjectModalOpen(false);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
