<template>
  <div class="fc-bot">
    <v-card
      class="fc-bot__source-section pa-5 mt-5"
      :class="{ active: sourceFile }"
    >
      <h2>Client Files</h2>
      <FCSourceInput />
      <v-scale-transition
        ><FCDropzone
          v-if="sourceFile && !xContent && !shakespeare && !shakespeareLoc"
      /></v-scale-transition>
    </v-card>
    <v-scale-transition>
      <v-card v-if="sourceFile" class="pa-5 pt-2 mt-2">
        <FCSourceTable />
      </v-card>
    </v-scale-transition>
    <v-scale-transition
      ><v-card class="pa-5 mt-5" v-if="sourceFile"> <FCInfoForm /> </v-card
    ></v-scale-transition>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// library
import _ from "lodash";
// components
import FCDropzone from "@/components/FCBot/FCDropzone";
import FCSourceInput from "@/components/FCBot/FCSourceInput";
import FCSourceTable from "@/components/FCBot/FCSourceTable";
import FCInfoForm from "@/components/FCBot/FCInfoForm";

export default {
  components: { FCDropzone, FCSourceInput, FCSourceTable, FCInfoForm },
  watch: {
    files(newValue) {
      // check for and set if files to process match source file
      const filenames = newValue.map((el) => el.name.split(".xlsx")[0]);
      const sourceNames = this.tableRows.map((el) => el.task_name);

      this.setAllSourceFilesFound(
        _.difference(filenames, sourceNames).length === 0 &&
          filenames.length > 0 &&
          sourceNames.length > 0
      );
    },
    tableRows(newValue) {
      const filenames = this.files.map((el) => el.name.split(".xlsx")[0]);
      const sourceNames = newValue?.map((el) => el.task_name);
      this.setAllSourceFilesFound(
        _.difference(filenames, sourceNames).length === 0 &&
          filenames.length > 0 &&
          sourceNames.length > 0
      );
    },
  },
  methods: {
    ...mapActions("fcBot", ["setAllSourceFilesFound"]),
  },
  computed: {
    ...mapGetters("fcBot", [
      "files",
      "tableRows",
      "allSourceFilesFound",
      "sourceFile",
      "xContent",
      "shakespeare",
      "shakespeareLoc",
    ]),
    fileNames() {
      return this.files.map((f) => f.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.fc-bot {
  max-width: 1200px;
  margin: 0 auto;

  &__source-section {
    max-height: 160px;
    transition: max-height 0.15s ease-out;
    &.active {
      max-height: 1000px;
      transition: max-height 0.25s ease-in;
    }
  }
}
</style>
