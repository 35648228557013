import { BLANK_NEW_FC_ASSIGNMENT_DATAS } from "@/utils/fcAssignmentUtils";

export const fcAssignmentModule = {
  state: () => ({
    // modals
    newFCAssignmentModalOpen: false,
    // assignments
    newFCAssignmentDatas: BLANK_NEW_FC_ASSIGNMENT_DATAS,
    geoOptions: [],
  }),
  mutations: {
    SET_NEW_FC_ASSIGNMENT_MODAL_OPEN(state, fcAssignmentModalOpen) {
      state.newFCAssignmentModalOpen = fcAssignmentModalOpen;
    },
    SET_NEW_FC_ASSIGNMENT_DATAS(state, newDatas) {
      state.newFCAssignmentDatas = newDatas;
    },
    RESET_NEW_FC_ASSIGNMENT_DATAS(state) {
      state.newFCAssignmentDatas = BLANK_NEW_FC_ASSIGNMENT_DATAS;
    },
    DELETE_NEW_FC_ASSIGNMENT_DATAS(state, datasIndex) {
      state.newFCAssignmentDatas.splice(datasIndex, 1);
    },
    SET_GEOS(state, geos) {
      state.geoOptions = geos;
    },
  },
  actions: {
    setNewFCAssignmentModalOpen(context, fcAssignmentModalOpen) {
      if (!fcAssignmentModalOpen) {
        context.commit("RESET_NEW_FC_ASSIGNMENT_DATAS");
      }
      context.commit("SET_NEW_FC_ASSIGNMENT_MODAL_OPEN", fcAssignmentModalOpen);
    },
    setNewFCAssignmentDatas(context, newDatas) {
      context.commit("SET_NEW_FC_ASSIGNMENT_DATAS", newDatas);
    },
    deleteNewFCAssignmentDatas(context, datasIndex) {
      context.commit("DELETE_NEW_FC_ASSIGNMENT_DATAS", datasIndex);
    },
    setGeos(context, geos) {
      context.commit("SET_GEOS", geos);
    },
  },
  getters: {
    newFCAssignmentModalOpen: (state) => {
      return state.newFCAssignmentModalOpen;
    },
    newFCAssignmentDatas: (state) => {
      return state.newFCAssignmentDatas;
    },
    geoOptions: (state) => {
      return state.geoOptions;
    },
  },
};
