<template>
  <v-container width="100%" class="pm-dashboard-table-expansion pa-3">
    <div class="d-flex justify-center align-center pb-3">
      <h3 class="mr-3">Cue Task ID: {{ expansionTableTitle }}</h3>
      <v-tooltip right color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            small
            elevation="2"
            color="white"
            :disabled="!expansionTableData.length"
            @click="copyInitials"
            ><v-icon>mdi-format-letter-case</v-icon></v-btn
          >
        </template>
        <span>Click to copy writers' initials</span>
      </v-tooltip>
    </div>
    <v-data-table
      :headers="expansionTableFields"
      :items="expansionTableData"
      sort-by="GEO"
      :header-props="{ sortIcon: null }"
      :items-per-page="10"
      :footer-props="{
        'items-per-page-options': [5, 10, 20],
      }"
    >
      <!-- Note cell override -->
      <template v-slot:[`item.NoteToClient`]="{ item }">
        <v-btn
          icon
          large
          color="accent"
          :disabled="!item.NoteToClient"
          @click="openNote(item.NoteToClient)"
          ><v-icon v-if="item.NoteToClient">mdi-chat-processing-outline</v-icon
          ><v-icon v-else>mdi-chat-outline</v-icon></v-btn
        >
      </template>
    </v-data-table>
    <v-dialog
      max-width="650"
      v-model="noteModal"
      @click:outside="noteModal = false"
      @keydown.esc="noteModal = false"
    >
      <v-card>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">{{ note.split(" / ")[3] }}</div>
            <v-list-item-title class="text-h5 mb-1">
              {{ note.split(" / ")[1] }}
            </v-list-item-title>
            <div class="text--primary mb-3">
              {{ note.split(" / ")[2] }}
            </div>
            <v-list-item-title class="text-h4 mb-1">
              {{ note.split(" / ")[0] }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-content>
            <div class="text--primary">
              {{ note.split(" / ")[4] }}
            </div>
            <v-list-item-subtitle>{{
              note.split(" / ")[5]
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions class="justify-end pb-4">
          <v-btn min-width="42px" outlined elevation="1" @click="copyNote"
            ><v-icon>mdi-clipboard-multiple-outline</v-icon></v-btn
          >
          <v-btn min-width="42px" color="secondary" @click="noteModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="copySuccess"
      min-width="unset"
      timeout="2000"
      color="success"
      rounded="pill"
      content-class="text-center"
    >
      <v-icon color="white" class="mr-1">mdi-check-circle-outline</v-icon>
      Copied to clipboard
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "PMDashboardTableExpansion",
  props: {
    expansionTableData: {
      type: Array,
      required: true,
    },
    expansionTableFields: {
      type: Array,
      required: true,
    },
    expansionTableTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      note: "",
      noteModal: false,
      copySuccess: false,
    };
  },
  methods: {
    openNote(note) {
      this.note = note;
      this.noteModal = true;
    },
    copyNote() {
      const noteTemplate = `Task ID: ${
        this.note.split(" / ")[0]
      }.\nAssignment Title: ${this.note.split(" / ")[1]}.\nAssignment Detail: ${
        this.note.split(" / ")[2]
      }.\nGEO: ${this.note.split(" / ")[3]}.\nNote: ${
        this.note.split(" / ")[4]
      }.\nCreated by: ${this.note.split(" / ")[5]}.`;
      navigator.clipboard.writeText(noteTemplate);
      this.copySuccess = true;
    },
    copyInitials() {
      let initials = [];
      // get only geo and writer (if present) from each assignment
      const cleanGeoWriters = this.expansionTableData
        .filter((el) => !!el.Writer)
        .map(({ GEO, Writer }) => ({ GEO, Writer }));
      // filter unique writer and generate unique geo with writers collection
      cleanGeoWriters
        .filter((v, i, a) => a.findIndex((v2) => v2.Writer === v.Writer) === i)
        .forEach((el) => {
          const existingGeo = initials.indexOf(
            initials.find((item) => item.GEO === el.GEO)
          );
          existingGeo === -1
            ? initials.push({ GEO: el.GEO, Writers: [el.Writer] })
            : initials[existingGeo].Writers.push(el.Writer);
        });
      // sort by geo and get initials
      initials
        .sort((a, b) => a.GEO.localeCompare(b.GEO))
        .forEach((el) => {
          el.Writers = el.Writers.map((writer) => {
            return (
              Array.from(writer.split(" ")[0])[0] +
              writer.split(" ")[1].substring(0, 3)
            );
          });
        });
      // generate message template
      let initialsTemplate = "";
      initials.forEach((el) => {
        initialsTemplate += `• ${el.GEO}: ${el.Writers.join(", ")}\n`;
      });
      navigator.clipboard.writeText(initialsTemplate);
      this.copySuccess = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.pm-dashboard-table-expansion {
  max-width: 100%;
  background-color: #f5f5f5;
  cursor: default;
}
::v-deep {
  .v-data-footer {
    height: 50px;
    &__select > .v-select {
      margin: 0 0 0 34px;
    }
  }
  thead.v-data-table-header {
    background-color: #fff;
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th {
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  }
  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr {
    cursor: default;
  }
}
</style>
