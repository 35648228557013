<template>
  <div class="invoice-report">
    <div class="invoice-report__loader" v-if="!invoiceRows">
      <v-progress-linear indeterminate />
    </div>

    <div class="invoice-report__main" v-if="invoiceRows">
      <InvoiceReportTable />
    </div>
  </div>
</template>

<script>
import {
  // getInvoiceRowsForPayPeriod,
  // getThisMonthInvoiceRows,
  getAllRowsFromReport,
  // getLastMonthInvoiceRows,
  appIDs,
  invoiceReportLookup,
} from "@/utils/quickbaseUtils";
import { mapGetters, mapActions } from "vuex";
import InvoiceReportTable from "@/components/InvoiceBot/InvoiceReportTable";
import _ from "lodash";
export default {
  components: { InvoiceReportTable },
  data() {
    return {
      filteredData: null,
    };
  },
  watch: {
    selectedReport(newValue) {
      this.setInvoiceRows(null);
      this.setFilteredInvoiceRows(null);
      getAllRowsFromReport({
        tableID: appIDs.cueProjectManager.invoices,
        reportID: invoiceReportLookup[newValue],
      }).then((data) => {
        this.setInvoiceRows(data.data);
        const filteredData = this.getFilteredRows(data.data);
        this.setFilteredInvoiceRows(filteredData);
        this.setInvoiceTableHeader(data.fields);
        this.setInvoiceTablePMs(
          this.getInvoiceTablePMs(data.data, data.fields)
        );
      });

      // switch (newValue) {
      //   case "THIS":
      //     getThisMonthInvoiceRows().then((data) => {
      //       this.setInvoiceRows(data.data);
      //       const filteredData = this.getFilteredRows(data.data);
      //       this.setFilteredInvoiceRows(filteredData);
      //       this.setInvoiceTableHeader(data.fields);
      //       this.setInvoiceTablePMs(
      //         this.getInvoiceTablePMs(data.data, data.fields)
      //       );
      //     });
      //     break;
      //   case "LAST":
      //     getLastMonthInvoiceRows().then((data) => {
      //       this.setInvoiceRows(data.data);
      //       const filteredData = this.getFilteredRows(data.data);
      //       this.setFilteredInvoiceRows(filteredData);
      //       this.setInvoiceTableHeader(data.fields);
      //       this.setInvoiceTablePMs(
      //         this.getInvoiceTablePMs(data.data, data.fields)
      //       );
      //     });
      //     break;

      //   case "ANOTHER":
      //     getInvoiceRowsForPayPeriod().then((data) => {
      //       this.setInvoiceRows(data.data);
      //       const filteredData = this.getFilteredRows(data.data);
      //       this.setFilteredInvoiceRows(filteredData);
      //       this.setInvoiceTableHeader(data.fields);
      //       this.setInvoiceTablePMs(
      //         this.getInvoiceTablePMs(filteredData, data.fields)
      //       );
      //     });
      //     break;
      //   default:
      //     break;
      // }
    },
  },
  methods: {
    ...mapActions("invoiceBot", [
      "setInvoiceRows",
      "setFilteredInvoiceRows",
      "setInvoiceTableHeader",
      "setInvoiceTablePMs",
    ]),
    getFilteredRows(rows) {
      console.log(rows);
      return rows;

      // if (!this.invoiceRows) return null;
      // const payPeriod = `${this.payPeriodMonth} ${this.payPeriodYear}`;
      // return rows.filter((el) => el[2].value === payPeriod);
    },
    getInvoiceTableHeader(rows) {
      return "";
    },
    getInvoiceTablePMs(rows, fields) {
      const pmFieldID = fields.find(
        (el) => el.label === "Project Manager Conv Name"
      ).id;

      console.log(pmFieldID);

      return _.uniqBy(rows, (el) => el[pmFieldID].value).map(
        (el) => el[pmFieldID].value
      );
    },
  },
  computed: {
    ...mapGetters("invoiceBot", [
      "invoiceRows",
      "filteredInvoiceRows",
      "payPeriodMonth",
      "payPeriodYear",
      "selectedReport",
    ]),
  },
  mounted() {
    getAllRowsFromReport({
      tableID: appIDs.cueProjectManager.invoices,
      reportID: 92,
    }).then((data) => {
      console.log(data);
      this.setInvoiceRows(data.data);
      const filteredData = this.getFilteredRows(data.data);
      this.setFilteredInvoiceRows(filteredData);
      this.setInvoiceTableHeader(data.fields);
      this.setInvoiceTablePMs(this.getInvoiceTablePMs(data.data, data.fields));
    });

    // getInvoiceRowsForPayPeriod().then((data) => {
    //   this.setInvoiceRows(data.data);
    //   const filteredData = this.getFilteredRows(data.data);
    //   this.setFilteredInvoiceRows(filteredData);
    //   this.setInvoiceTableHeader(data.fields);
    //   this.setInvoiceTablePMs(
    //     this.getInvoiceTablePMs(filteredData, data.fields)
    //   );
    // });
  },
};
</script>

<style lang="scss" scoped></style>
