<template>
  <v-container>
    <v-tabs v-model="tab">
      <v-tab href="#general">GENERAL</v-tab>
      <v-tab href="#staff-pool">STAFF POOL</v-tab>
      <v-tab href="#fragments">FRAGMENTS</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="general">
        <v-row class="mt-3">
          <v-col cols="12" sm="6">
            <v-autocomplete
              filled
              dense
              hide-details
              label="Project Name"
              :items="projectNameItems"
              :value="editProjectData.projectName"
              @change="handleInput($event, 'projectName')"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
            <v-autocomplete
              filled
              dense
              hide-details
              label="Work Type"
              :items="workTypeChoices"
              :value="editProjectData.workType"
              @change="handleInput($event, 'workType')"
            />
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col cols="12" sm="12">
            <v-text-field
              filled
              dense
              hide-details
              label="Link To Styleguide"
              :value="editProjectData.styleGuideLink"
              @change="handleInput($event, 'styleGuideLink')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="staff-pool">
        <v-row class="mb-2 mt-3">
          <v-col cols="12" sm="12">
            <v-combobox
              outlined
              multiple
              chips
              hide-details
              :loading="namesLoading"
              :items="staffNameItems"
              :search-input.sync="search"
              label="staff pool"
              :value="editProjectData.staffPool"
              @change="handleInput($event, 'staffPool')"
            ></v-combobox>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="fragments">
        <FragmentsEditor />
      </v-tab-item>
    </v-tabs-items>

    <!-- <v-row>
      <v-col cols="12" sm="6">
        <v-autocomplete
          filled
          dense
          hide-details
          label="Project Name"
          :items="projectNameItems"
          :value="editProjectData.projectName"
          @change="handleInput($event, 'projectName')"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" sm="6">
        <v-autocomplete
          filled
          dense
          hide-details
          label="Work Type"
          :items="workTypeChoices"
          :value="editProjectData.workType"
          @change="handleInput($event, 'workType')"
        />
      </v-col>
    </v-row>
    <v-row class="mb-10">
      <v-col cols="12" sm="12">
        <v-text-field
          filled
          dense
          hide-details
          label="Link To Styleguide"
          :value="editProjectData.styleGuideLink"
          @change="handleInput($event, 'styleGuideLink')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mb-10">
      <v-col cols="12" sm="12">
        <v-combobox
          filled
          dense
          multiple
          chips
          hide-details
          :loading="namesLoading"
          :items="staffNameItems"
          :search-input.sync="search"
          label="staff pool"
          :value="editProjectData.staffPool"
          @change="handleInput($event, 'staffPool')"
        ></v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <FragmentsEditor />
    </v-row> -->
  </v-container>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// library
import _ from "lodash";
// internal
import {
  getAllProjectNames,
  getStaffNamesFromFragment,
  getWorkTypeChoices,
} from "@/utils/quickbaseUtils";
// components
import FragmentsEditor from "./FragmentsEditor.vue";

export default {
  name: "EditorEditProjectItem",
  components: {
    FragmentsEditor,
  },
  data() {
    return {
      projectNameItems: [],
      search: null,
      namesLoading: false,
      staffNameItems: [],
      workTypeChoicesLoading: false,
      workTypeChoices: [],
      tab: "general",
    };
  },
  computed: {
    ...mapGetters("editor", ["editProjectData"]),
  },
  watch: {
    search(val) {
      if (val && val.length > 2) {
        this.doNamesSearch(val);
      }
    },
  },
  mounted() {
    getAllProjectNames()
      .then((d) => {
        this.projectNameItems = d;
      })
      .catch((err) => {
        console.warn(err);
      });

    this.workTypeChoicesLoading = true;
    getWorkTypeChoices()
      .then((d) => {
        this.workTypeChoicesLoading = false;
        this.workTypeChoices = d;
      })
      .catch((err) => {
        console.error(err);
        this.workTypeChoicesLoading = false;
      });
  },
  methods: {
    ...mapActions("editor", ["setEditProjectData"]),
    handleInput(value, field) {
      const newValue = _.cloneDeep(this.editProjectData);
      newValue[field] = value;
      if (field === "staffPool") {
        newValue["staffPoolEmails"] = value.map((el) => el.value.email);
      }
      this.setEditProjectData(newValue);
    },
    doNamesSearch(fragment) {
      this.namesLoading = true;
      getStaffNamesFromFragment(fragment)
        .then((items) => {
          this.staffNameItems = items.map((el) => {
            return {
              text: el.name,
              value: el,
            };
          });
          this.namesLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.namesLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
