<template>
  <v-container class="invoice-bot__pay-period-select">
    <v-row>
      <v-col cols="12" sm="10" md="10">
        <h2>Pay Period</h2>
      </v-col>
      <v-col cols="12" sm="2" md="2"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-autocomplete
          solo-inverted
          :items="months"
          :value="payPeriodMonth"
          @input="handleMonthChange"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-autocomplete
          solo-inverted
          :items="years"
          :value="payPeriodYear"
          @input="handleYearChange"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      months: [
        "January",
        "Feburary",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      years: [
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
      ],
    };
  },
  methods: {
    ...mapActions("invoiceBot", ["setPayPeriodMonth", "setPayPeriodYear"]),
    handleMonthChange(v) {
      this.setPayPeriodMonth(v);
    },
    handleYearChange(v) {
      this.setPayPeriodYear(v);
    },
  },
  computed: {
    ...mapGetters("invoiceBot", ["payPeriodMonth", "payPeriodYear"]),
  },
  mounted() {
    const now = new Date(Date.now());
    this.setPayPeriodYear(`${now.getFullYear()}`);
    this.setPayPeriodMonth(this.months[now.getMonth()]);
  },
};
</script>

<style lang="scss" scoped></style>
