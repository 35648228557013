import { auth, functions } from "@/providers/firebase";
import { passwordWords, DOMAIN } from "@/utils/constants";
import * as pwGen from "memorable-password-generator";

const makeWelcomeEmailText = (props) => {
  return `
    <p>Hello and welcome to CueHub. Your login details are below:</p>
    <p>
      Username: ${props.email}
      <br/>
      Password: ${props.password}
    </p>

    <p> You can access the site at <a href="https://${DOMAIN}">https://${DOMAIN}</a>.  </p>
    <p>If you'd like to change your password, you can do so by logging into the app and selecting
      "Reset Password" after clicking your email in the top right of the screen.
    </p>
    <p>Thanks,
    <br/>
    Company Cue
    </p>
  `;
};

export const doLogin = (user, pw) => {
  // create a promise to do login, send user object if it works,
  // reject if not
  return new Promise((resolve, reject) => {
    auth
      .signInWithEmailAndPassword(user, pw)
      .then((u) => {
        if (u.user) {
          resolve(u);
        }
      })
      .catch(() => reject());
  });
};

export const doLogout = () => {
  return new Promise((resolve, reject) => {
    auth
      .signOut()
      .then(() => resolve())
      .catch((err) => reject(err));
  });
};

export const createNewUserPassword = () => {};

/**
 * createNewUserWithPasswordSendEmail(userData) takes user data from form in users view
 * creates a password for them using random password generator, and sends them a welcome email
 */
export const createNewUserWithPasswordSendEmail = (props) => {
  const createUser = functions.httpsCallable("createNewUser");
  const addClaims = functions.httpsCallable("addClaims");
  const sendEmail = functions.httpsCallable("sendEmail");

  const newUserData = props;
  return new Promise((resolve, reject) => {
    pwGen(passwordWords)
      .then((pw) => {
        newUserData["password"] = pw;
        return createUser(newUserData);
      })
      .then(() => {
        return addClaims(newUserData);
      })
      .then(() => {
        const emailText = makeWelcomeEmailText(newUserData);
        sendEmail({
          from: '"Cue Hub" <cocueadmin@companycue.com>', // sender address
          to: newUserData.email, // list of receivers
          subject: `Welcome to the Company Cue Hub`, // Subject line
          // text: this.message, // plain text body
          html: emailText,
        }).then(() => resolve());
      })
      .catch((err) => reject(err));
  });
  // generate a password

  // const newPW = createMemorablePassword(passwordWords);
};
