import _ from "lodash";

export const invoiceBotModule = {
  state: () => ({
    clientEmails: [],
    clientEmailsLoaded: false,
    payPeriodMonth: null,
    payPeriodYear: null,
    invoiceRows: null,
    filteredInvoiceRows: null,
    invoiceTableHeader: null,
    invoiceTablePMs: null,
    selectedReport: "THIS",
  }),
  mutations: {
    SET_CLIENT_EMAILS(state, clientEmails) {
      state.clientEmails = clientEmails;
      state.clientEmailsLoaded = true;
    },
    SET_PAY_PERIOD_MONTH(state, month) {
      state.payPeriodMonth = month;
    },
    SET_PAY_PERIOD_YEAR(state, year) {
      state.payPeriodYear = year;
    },
    SET_INVOICE_ROWS(state, rows) {
      state.invoiceRows = rows;
    },
    SET_FILTERED_INVOICE_ROWS(state, rows) {
      state.filteredInvoiceRows = rows;
    },
    SET_INVOICE_TABLE_HEADER(state, header) {
      state.invoiceTableHeader = header;
    },
    SET_INVOICE_TABLE_PMS(state, pmList) {
      state.invoiceTablePMs = pmList;
    },
    SET_SELECTED_REPORT(state, report) {
      state.selectedReport = report;
    },
  },
  actions: {
    setClientEmails(context, clientEmails) {
      context.commit("SET_CLIENT_EMAILS", clientEmails);
    },
    setPayPeriodMonth(context, month) {
      context.commit("SET_PAY_PERIOD_MONTH", month);
    },
    setPayPeriodYear(context, year) {
      context.commit("SET_PAY_PERIOD_YEAR", year);
    },
    setInvoiceRows(context, rows) {
      context.commit("SET_INVOICE_ROWS", rows);
    },
    setFilteredInvoiceRows(context, rows) {
      context.commit("SET_FILTERED_INVOICE_ROWS", rows);
    },
    setInvoiceTableHeader(context, header) {
      context.commit("SET_INVOICE_TABLE_HEADER", header);
    },
    setInvoiceTablePMs(context, pmList) {
      context.commit("SET_INVOICE_TABLE_PMS", pmList);
    },
    setSelectedReport(context, report) {
      context.commit("SET_SELECTED_REPORT", report);
    },
  },
  getters: {
    clientEmails: (state) => {
      return state.clientEmails;
    },
    clientEmailsLoaded: (state) => {
      return state.clientEmailsLoaded;
    },
    payPeriodMonth: (state) => {
      return state.payPeriodMonth;
    },
    payPeriodYear: (state) => {
      return state.payPeriodYear;
    },
    invoiceRows: (state) => {
      return state.invoiceRows;
    },
    filteredInvoiceRows: (state) => {
      return state.filteredInvoiceRows;
    },
    invoiceTableHeader: (state) => {
      return state.invoiceTableHeader;
    },
    invoiceTablePMs: (state) => {
      return state.invoiceTablePMs;
    },
    selectedReport: (state) => {
      return state.selectedReport;
    },
  },
};
