<template>
  <div class="po-view" v-if="activePOs">
    <div class="po-view__header-and-controls">
      <div class="custom-card-header">Purchase Orders</div>
    </div>

    <div class="po-view__po-selector">
      <v-tabs v-model="selectedPOIndex">
        <v-tab v-for="(po, i) in activePONames" :key="`tab-${i}`">{{
          po
        }}</v-tab>
      </v-tabs>
    </div>

    <v-container
      v-if="selectedPOInfo && !isAllPOView"
      class="po-view__details-container elevation-2 grey lighten-4 black--text rounded-lg pa-0 overflow-hidden"
    >
      <v-row class="po-view__details-container__row">
        <v-col cols="12" sm="3 " class="grey lighten-3 pl-8 pt-4">
          <div class="po-text-info-container">
            <div class="po-text-info">
              <div class="po-text-info__top">Requestor</div>
              <div class="po-text-info__bottom">
                {{ selectedPOInfo[fieldNames.CLIENT_REQUESTOR] }}
              </div>
            </div>
            <div class="po-text-info">
              <div class="po-text-info__top">Project Name</div>

              <div class="po-text-info__bottom">
                {{ selectedPOInfo[fieldNames.PROJECT_NAME] }}
              </div>
            </div>
            <div class="po-text-info">
              <div class="po-text-info__top">PO Start Date</div>
              <div class="po-text-info__bottom">
                {{
                  formatDateForInfoCard(selectedPOInfo[fieldNames.START_DATE])
                }}
              </div>
            </div>
            <div class="po-text-info">
              <div class="po-text-info__top">PO End Date</div>
              <div class="po-text-info__bottom">
                {{ formatDateForInfoCard(selectedPOInfo[fieldNames.END_DATE]) }}
              </div>
            </div>
          </div>
        </v-col>
        <v-col class="po-visualization-column" cols="12" sm="9">
          <POViz :data="selectedPOInfo" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// internal
import { getAllPOs } from "@/utils/quickbaseUtils";
// component
import POViz from "@/components/MyCue/POViz";

export default {
  name: "POView",
  components: { POViz },
  data() {
    return {
      poData: null,
      selectedPOIndex: null,
      fieldNames: {
        PO_NUMBER_FIELD_NAME: "Purchase order Number",
        CLIENT_REQUESTOR: "Related Client Requester",
        START_DATE: "Active Date",
        END_DATE: "End Date",
        PROJECT_NAME: "Project Name",
      },
    };
  },
  watch: {
    myCueSelectedClient(newValue) {
      getAllPOs({ client: newValue }).then((result) => {
        this.poData = result;
      });
    },
    activePONames(newValue) {
      if (newValue === null) {
        this.setSelectedPO(null);
      } else {
        this.selectedPOIndex = newValue[0];
      }
    },
    selectedPOIndex(newValue) {
      this.setSelectedPO(this.activePONames[newValue]);
    },
  },
  methods: {
    ...mapActions("myCue", ["setSelectedPO"]),
    formatDateForInfoCard(unformattedDateString) {
      return this.$moment(unformattedDateString).format("LL");
    },
  },
  computed: {
    ...mapGetters("myCue", ["myCueSelectedClient"]),
    activePOs() {
      if (!this.poData || this.poData.length === 0) return null;
      const filtered = this.poData.filter((el) => el["Is Active"]);
      if (filtered.length === 0) return null;

      return [
        {
          "Purchase order Number": "All",
        },
        ...filtered,
      ];
    },

    activePONames() {
      if (!this.activePOs) return null;
      return this.activePOs.map(
        (el) => el[this.fieldNames.PO_NUMBER_FIELD_NAME]
      );
    },
    selectedPOInfo() {
      if (!this.activePOs) return null;
      return this.activePOs[this.selectedPOIndex];
    },
    isAllPOView() {
      if (!this.activePOs) return false;
      return this.selectedPOIndex === 0;
    },
  },
  mounted() {
    getAllPOs({ client: this.myCueSelectedClient }).then((result) => {
      this.poData = result;
      if (this.poData.length > 0) {
        this.selectedPOIndex = 0;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.po-view {
  margin-top: 20px;
  &__header-and-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  }
  &__details-container {
    // margin: 0px 3px;
    width: 100%;
    max-width: calc(100% - 20px);
    height: 100%;
    position: relative;

    &__row {
      height: 100%;
    }

    .po-text-info-container {
      height: 100%;

      .po-text-info {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        margin-bottom: 8px;
        // align-items: center;

        &__top {
          text-transform: uppercase;
          font-size: 10px;
          font-weight: bold;
          color: #757575;
        }

        &__bottom {
          font-weight: 700;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
