<template>
  <v-data-table
    :headers="headers"
    :items="filteredData"
    item-key="RecordID#"
    sort-by="User"
    :header-props="{ sortIcon: null }"
    :items-per-page="50"
    :footer-props="{
      'items-per-page-options': [10, 50, 100],
    }"
    show-expand
    @click:row="$emit('call-edit-form', $event)"
  >
    <!-- Expanded panel -->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <CueHardwareTableExpansion :hardwareInfo="item" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
// libraries
import _ from "lodash";
import Fuse from "fuse.js";
// internal
import { customBreakpoint } from "@/utils/mixins";
// components
import CueHardwareTableExpansion from "@/components/CueHardware/CueHardwareTableExpansion";

export default {
  name: "CueHardwareTable",
  components: {
    CueHardwareTableExpansion,
  },
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    tableFields: {
      type: Array,
      required: false,
    },
    filterActiveUsers: {
      type: Array,
      required: false,
    },
    filterSearch: {
      type: String,
      required: false,
    },
    filterCategory: {
      type: String,
      required: true,
    },
    filterUser: {
      type: String,
      required: true,
    },
    filterOnHand: {
      type: Array,
      required: true,
    },
    filterUserLocation: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableHeaders: [
        "User",
        "DeviceCategory",
        "DeviceBrand",
        "DeviceModel",
        "DeviceSerialNumber",
      ],
    };
  },
  mixins: [customBreakpoint],
  computed: {
    headers() {
      if (!this.tableFields) return [];

      let retVal = this.tableFields
        .filter((el) => this.tableHeaders.includes(el.value))
        .map((field) => {
          return {
            text: field.text,
            value: field.value,
          };
        });

      // order by the order of the tableHeaders
      retVal = _.orderBy(retVal, (el) => this.tableHeaders.indexOf(el.value));

      retVal.push({ text: "", value: "data-table-expand" });

      return retVal;
    },
    tableFieldValues() {
      return this.tableFields.map((field) => field.value);
    },
    filteredData() {
      let dateFilteredData = this.tableData;

      if (this.filterActiveUsers.length === 1) {
        dateFilteredData = dateFilteredData.filter((el) =>
          this.filterActiveUsers.includes(0) ? el.ActiveUser : !el.ActiveUser
        );
      } else if (!this.filterActiveUsers.length) {
        dateFilteredData = [];
      }

      if (this.filterOnHand.length === 1) {
        dateFilteredData = dateFilteredData.filter((el) =>
          this.filterOnHand.includes(0) ? el.OnHand : !el.OnHand
        );
      } else if (!this.filterOnHand.length) {
        dateFilteredData = [];
      }

      if (this.filterUser) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el["User"] === this.filterUser
        );
      }

      if (this.filterUserLocation) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el["UserLocation"] === this.filterUserLocation
        );
      }

      if (this.filterCategory) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el["DeviceCategory"] === this.filterCategory
        );
      }

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: this.tableFieldValues,
      };

      if (this.filterSearch) {
        const fuse = new Fuse(dateFilteredData, searchOptions);
        dateFilteredData = fuse.search(this.filterSearch).map((el) => el.item);
      }

      return dateFilteredData;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  tbody > tr:not(.v-data-table__expanded__content) {
    cursor: pointer;
  }
  .v-data-table.laptop .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 8px;
    }
    & .v-data-table-header tr > th {
      padding: 0 8px;
    }
  }
  .v-data-table.laptopSmaller .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 4px;
    }
    & .v-data-table-header tr > th {
      padding: 0 4px;
    }
  }
}
</style>
