<template>
  <div>
    <v-progress-circular v-if="isUpdating" indeterminate :size="24" />
    <v-autocomplete
      v-if="!isUpdating"
      class="caption selector"
      :class="{
        'dri-selector': driMode,
        'status-selector': statusMode,
        'priority-selector': priorityMode,
      }"
      :no-filter="!driMode"
      :style="{
        backgroundColor: statusColor,
      }"
      :items="dropdownOptions"
      dense
      hide-details
      outlined
      item-text="value"
      :loading="dropdownOptionsLoading"
      :value="currentSelect"
      @change="(selected) => onChange({ item, selected })"
      @click.native.stop.prevent
    ></v-autocomplete>
    <v-alert v-if="isError" type="error" class="alert" dismissible>
      Error updating task {{ modeHandler }}. Please try again.
    </v-alert>
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// internal
import { processTask } from "@/utils/quickbaseUtils";

export default {
  name: "PMDashboardDropdownSelector",
  props: {
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    statusMode: {
      type: Boolean,
      required: false,
    },
    priorityMode: {
      type: Boolean,
      required: false,
    },
    driMode: {
      type: Boolean,
      required: false,
    },
    dropdownOptions: {
      type: Array,
      required: true,
    },
    dropdownOptionsLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isUpdating: false,
      isError: false,
      currentSelect: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    statusColor() {
      return this.dropdownOptions.find((el) => el.value === this.currentSelect)
        ?.color;
    },
    modeHandler() {
      if (this.statusMode) return "status";
      if (this.priorityMode) return "priority";
      if (this.driMode) return "DRI";
    },
  },
  watch: {
    value(newValue) {
      this.currentSelect = newValue;
    },
  },
  mounted() {
    this.currentSelect = this.value;
  },
  methods: {
    onChange({ item, selected }) {
      this.currentSelect = selected;
      this.isUpdating = true;
      processTask({
        task: {
          ...item,
          LastUpdated: new Date(),
          LastUpdatedBy: this.user.Name,
        },
        [this.modeHandler]: selected,
      })
        .then(() => {
          item.TaskStatus === "Archived"
            ? this.$emit("get-table-data")
            : this.$emit(
                "refresh-table-data",
                item.index,
                (this.statusMode ? "Task" : "") +
                  this.modeHandler.charAt(0).toUpperCase() +
                  this.modeHandler.slice(1),
                selected
              );
          this.isUpdating = false;
        })
        .catch((err) => {
          this.isUpdating = false;
          this.isError = true;
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100;
}

.priority-selector {
  width: 90px;
}

.dri-selector {
  width: 150px;
}

.status-selector {
  width: 120px;
}
</style>
