// vuex
import store from "../store/index";
// internal
import {
  getFCWorkType,
  getFCWordCount,
  getFCRequestType,
  isAdaptation,
} from "./fcBotUtils";
import { getiTunesFCPONumber } from "@/utils/quickbaseUtils";
// libraries
const { GoogleSpreadsheet } = require("google-spreadsheet");
import _ from "lodash";
import moment from "moment";

export const SPREADSHEET_ID = "1t_4LJqCQa5tDtBYoN146qE5jbRBsUAMibtRmeQ6g1jk";

const tabName = "Tool Import";

const headerCols = [
  "Related Purchase Order",
  "Related Project",
  "Pay Period",
  "Request Date",
  "E-mail Subject Line",
  "Task ID",
  "Content ID",
  "Assignment ID",
  "Related Client Requester",
  "Assignment Title",
  "Assignment Detail",
  "Source GEO",
  "GEO",
  "Media Type",
  "Keyword",
  "Request Type",
  "Word Count",
  "Work Type 1 Reference",
  "Work Type 1 Quantity",
  "Work Type 2 Reference",
  "Work Type 2 Quantity",
  "Work Type 3 Reference",
  "Work Type 3 Quantity",
  "Work Type 4 Reference",
  "Work Type 4 Quantity",
  "Work Type 5 Reference",
  "Work Type 5 Quantity",
  "Work Type 6 Reference",
  "Work Type 6 Quantity",
  "Related Writer",
  "Writing Due Date",
  "Writing Due (Time of Day)",
  "Related Editor",
  "Editing Due Date",
  "Editing Due (Time of Day)",
  "Reviewer",
  "Reviewing Due Date",
  "Reviewing Due (Time of Day)",
  "Delivery Date",
  "Delivery Time",
  "File Location",
  "Link",
  "Assignment Status",
];

const getRowValues = (props) => {
  const { formValues, tableRow, geoVal, poNumber, staffAssignments } = props,
    shakespeare = store.getters["fcBot/shakespeare"],
    shakespeareLoc = store.getters["fcBot/shakespeareLoc"],
    backstageCopywriting = store.getters["fcBot/backstageCopywriting"],
    newAssignmentForm = tableRow.hasOwnProperty("relatedProject"),
    geo = shakespeareLoc ? geoVal.geo : geoVal,
    getGeoIndex = (geo, array) =>
      array.findIndex((el) => el.hasOwnProperty(geo)),
    geoIndividualData = newAssignmentForm
      ? null
      : formValues.individualGeosDeadlines[
          getGeoIndex(geo, formValues.individualGeosDeadlines)
        ]?.[geo];

  const rowInfo = [
    {
      col: "Related Purchase Order",
      value:
        shakespeare || backstageCopywriting
          ? formValues.po
          : newAssignmentForm
          ? tableRow.po
          : poNumber,
    },
    {
      col: "Related Project",
      value:
        shakespeare || backstageCopywriting ? formValues.relatedProject.id : 1,
    },
    {
      col: "Pay Period",
      value: (newAssignmentForm ? tableRow : formValues).payPeriod,
    },
    {
      col: "Request Date",
      value: newAssignmentForm
        ? tableRow.requestDate
        : moment(tableRow.request_date).format("YYYY-MM-DD"),
    },
    {
      col: "E-mail Subject Line",
      value: shakespeare
        ? formValues.emailSubjectLine === "Assignment Detail"
          ? tableRow.task_name
          : formValues.emailSubjectLine
        : newAssignmentForm
        ? tableRow.emailSubjectLine
        : tableRow.subject_line,
    },
    {
      col: "Task ID",
      value: tableRow[newAssignmentForm ? "taskID" : "task_id"],
    },
    {
      col: "Content ID",
      value:
        shakespeare || shakespeareLoc
          ? tableRow.content_id ?? tableRow.video_content_id
          : "",
    },
    {
      col: "Assignment ID",
      value: shakespeare
        ? ""
        : newAssignmentForm
        ? tableRow.assignmentID
        : staffAssignments[tableRow["task_name"]][geo]["assignmentId"] ?? "",
    },
    {
      col: "Related Client Requester",
      value:
        shakespeare || shakespeareLoc
          ? formValues.clientRequester
          : tableRow[
              newAssignmentForm ? "clientRequester" : "client_requester"
            ],
    },
    {
      col: "Assignment Title",
      value: newAssignmentForm
        ? tableRow.assignmentName
        : formValues.assignmentTitle,
    },
    {
      col: "Assignment Detail",
      value: newAssignmentForm
        ? tableRow.assignmentDetails
        : tableRow.task_name,
    },
    {
      col: "Source GEO",
      value: shakespeare
        ? ""
        : backstageCopywriting
        ? isAdaptation({
            locale: geo,
            allLocales: tableRow.geos,
          })
          ? isAdaptation({
              locale: geo,
              getSourceLocale: true,
            })
          : ""
        : tableRow[newAssignmentForm ? "sourceGeo" : "source_geo"],
    },
    { col: "GEO", value: geo },
    {
      col: "Media Type",
      value: shakespeare
        ? formValues.mediaType
        : tableRow[newAssignmentForm ? "mediaType" : "media_type"],
    },
    { col: "Keyword", value: tableRow.keyword },
    {
      col: "Request Type",
      value: backstageCopywriting
        ? getFCRequestType({
            locale: geo,
            allLocales: tableRow.geos,
          })
        : tableRow[newAssignmentForm ? "requestType" : "request_type"],
    },
    {
      col: "Word Count",
      value:
        shakespeare ||
        backstageCopywriting ||
        tableRow?.request_type === "Adaptation"
          ? ""
          : tableRow[newAssignmentForm ? "wordCount" : "word_count"],
    },
    {
      col: "Work Type 1 Reference",
      value: shakespeare
        ? staffAssignments[tableRow["task_id"]][geo]["workType1Reference"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType1Reference"]
          : ""
        : backstageCopywriting || tableRow?.request_type === "Adaptation"
        ? getFCWorkType({
            workType:
              staffAssignments[tableRow["task_name"]][geo][
                "workType1Reference"
              ],
            workType1: true,
            locale: geo,
            allLocales: tableRow.geos,
            adaptationRequest: tableRow.request_type === "Adaptation",
          })
        : newAssignmentForm
        ? tableRow.workType1Reference
        : getFCWorkType({
            locale: geo,
            sourceLocale: tableRow.source_geo,
            localizationMode: true,
          }),
    },
    {
      col: "Work Type 1 Quantity",
      value: shakespeare
        ? staffAssignments[tableRow["task_id"]][geo]["workType1Quantity"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType1Quantity"]
          : ""
        : backstageCopywriting
        ? getFCWordCount({
            wordCount:
              staffAssignments[tableRow["task_name"]][geo]["workType1Quantity"],
            locale: geo,
            allLocales: tableRow.geos,
          })
        : tableRow?.request_type === "Adaptation"
        ? ""
        : newAssignmentForm
        ? tableRow.workType1Quantity
        : tableRow.word_count,
    },
    {
      col: "Work Type 2 Reference",
      value: shakespeare
        ? staffAssignments[tableRow["task_id"]][geo]["workType2Reference"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType2Reference"]
          : ""
        : backstageCopywriting || tableRow?.request_type === "Adaptation"
        ? getFCWorkType({
            workType:
              staffAssignments[tableRow["task_name"]][geo][
                "workType2Reference"
              ],
            workType1: false,
            locale: geo,
            allLocales: tableRow.geos,
            adaptationRequest: tableRow.request_type === "Adaptation",
          })
        : newAssignmentForm
        ? tableRow.workType2Reference
        : "",
    },
    {
      col: "Work Type 2 Quantity",
      value: shakespeare
        ? staffAssignments[tableRow["task_id"]][geo]["workType2Quantity"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType2Quantity"]
          : ""
        : backstageCopywriting
        ? getFCWordCount({
            wordCount:
              staffAssignments[tableRow["task_name"]][geo]["workType2Quantity"],
            locale: geo,
            allLocales: tableRow.geos,
          })
        : newAssignmentForm
        ? tableRow.workType2Quantity
        : "",
    },
    {
      col: "Work Type 3 Reference",
      value: shakespeare
        ? staffAssignments[tableRow["task_id"]][geo]["workType3Reference"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType3Reference"]
          : ""
        : backstageCopywriting || tableRow?.request_type === "Adaptation"
        ? getFCWorkType({
            workType:
              staffAssignments[tableRow["task_name"]][geo][
                "workType3Reference"
              ],
            workType1: false,
            locale: geo,
            allLocales: tableRow.geos,
            adaptationRequest: tableRow.request_type === "Adaptation",
          })
        : newAssignmentForm
        ? tableRow.workType3Reference
        : "",
    },
    {
      col: "Work Type 3 Quantity",
      value: shakespeare
        ? staffAssignments[tableRow["task_id"]][geo]["workType3Quantity"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType3Quantity"]
          : ""
        : backstageCopywriting
        ? getFCWordCount({
            wordCount:
              staffAssignments[tableRow["task_name"]][geo]["workType3Quantity"],
            locale: geo,
            allLocales: tableRow.geos,
          })
        : newAssignmentForm
        ? tableRow.workType3Quantity
        : "",
    },
    {
      col: "Work Type 4 Reference",
      value: shakespeare
        ? staffAssignments[tableRow["task_id"]][geo]["workType4Reference"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType4Reference"]
          : ""
        : backstageCopywriting || tableRow?.request_type === "Adaptation"
        ? getFCWorkType({
            workType:
              staffAssignments[tableRow["task_name"]][geo][
                "workType4Reference"
              ],
            workType1: false,
            locale: geo,
            allLocales: tableRow.geos,
            adaptationRequest: tableRow.request_type === "Adaptation",
          })
        : newAssignmentForm
        ? tableRow.workType4Reference
        : "",
    },
    {
      col: "Work Type 4 Quantity",
      value: shakespeare
        ? staffAssignments[tableRow["task_id"]][geo]["workType4Quantity"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType4Quantity"]
          : ""
        : backstageCopywriting
        ? getFCWordCount({
            wordCount:
              staffAssignments[tableRow["task_name"]][geo]["workType4Quantity"],
            locale: geo,
            allLocales: tableRow.geos,
          })
        : newAssignmentForm
        ? tableRow.workType4Quantity
        : "",
    },
    {
      col: "Work Type 5 Reference",
      value: shakespeare
        ? staffAssignments[tableRow["task_id"]][geo]["workType5Reference"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType5Reference"]
          : ""
        : backstageCopywriting || tableRow?.request_type === "Adaptation"
        ? getFCWorkType({
            workType:
              staffAssignments[tableRow["task_name"]][geo][
                "workType5Reference"
              ],
            workType1: false,
            locale: geo,
            allLocales: tableRow.geos,
            adaptationRequest: tableRow.request_type === "Adaptation",
          })
        : newAssignmentForm
        ? tableRow.workType5Reference
        : "",
    },
    {
      col: "Work Type 5 Quantity",
      value: shakespeare
        ? staffAssignments[tableRow["task_id"]][geo]["workType5Quantity"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType5Quantity"]
          : ""
        : backstageCopywriting
        ? getFCWordCount({
            wordCount:
              staffAssignments[tableRow["task_name"]][geo]["workType5Quantity"],
            locale: geo,
            allLocales: tableRow.geos,
          })
        : newAssignmentForm
        ? tableRow.workType5Quantity
        : "",
    },
    {
      col: "Work Type 6 Reference",
      value: shakespeare
        ? staffAssignments[tableRow["task_id"]][geo]["workType6Reference"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType6Reference"]
          : ""
        : backstageCopywriting || tableRow?.request_type === "Adaptation"
        ? getFCWorkType({
            workType:
              staffAssignments[tableRow["task_name"]][geo][
                "workType6Reference"
              ],
            workType1: false,
            locale: geo,
            allLocales: tableRow.geos,
            adaptationRequest: tableRow.request_type === "Adaptation",
          })
        : newAssignmentForm
        ? tableRow.workType6Reference
        : "",
    },
    {
      col: "Work Type 6 Quantity",
      value: shakespeare
        ? staffAssignments[tableRow["task_id"]][geo]["workType6Quantity"]
          ? staffAssignments[tableRow["task_id"]][geo]["workType6Quantity"]
          : ""
        : backstageCopywriting
        ? getFCWordCount({
            wordCount:
              staffAssignments[tableRow["task_name"]][geo]["workType6Quantity"],
            locale: geo,
            allLocales: tableRow.geos,
          })
        : newAssignmentForm
        ? tableRow.workType6Quantity
        : "",
    },
    {
      col: "Related Writer",
      value: newAssignmentForm
        ? tableRow.writer.name
        : staffAssignments[tableRow[shakespeare ? "task_id" : "task_name"]][
            geo
          ]["writer"],
    },
    {
      col: "Writing Due Date",
      value: newAssignmentForm
        ? tableRow.writingDueDate
        : geoIndividualData?.writingDueDate ?? formValues.writingDueDate,
    },
    {
      col: "Writing Due (Time of Day)",
      value: newAssignmentForm
        ? tableRow.writingDue
        : geoIndividualData?.writingDue ?? formValues.writingDue,
    },
    {
      col: "Related Editor",
      value: newAssignmentForm
        ? tableRow.editor.name
        : staffAssignments[tableRow[shakespeare ? "task_id" : "task_name"]][
            geo
          ]["editor"],
    },
    {
      col: "Editing Due Date",
      value: newAssignmentForm
        ? tableRow.editingDueDate
        : staffAssignments[tableRow[shakespeare ? "task_id" : "task_name"]][
            geo
          ]["editor"]
        ? geoIndividualData?.editingDueDate ?? formValues.editingDueDate ?? ""
        : "",
    },
    {
      col: "Editing Due (Time of Day)",
      value: newAssignmentForm
        ? tableRow.editingDue
        : staffAssignments[tableRow[shakespeare ? "task_id" : "task_name"]][
            geo
          ]["editor"]
        ? geoIndividualData?.editingDue ?? formValues.editingDue
        : "",
    },
    {
      col: "Reviewer",
      value: newAssignmentForm
        ? tableRow.reviewer.name
        : staffAssignments[tableRow[shakespeare ? "task_id" : "task_name"]][
            geo
          ]["reviewer"],
    },
    {
      col: "Reviewing Due Date",
      value: newAssignmentForm
        ? tableRow.reviewingDueDate
        : staffAssignments[tableRow[shakespeare ? "task_id" : "task_name"]][
            geo
          ]["reviewer"]
        ? geoIndividualData?.reviewingDueDate ??
          formValues.reviewingDueDate ??
          ""
        : "",
    },
    {
      col: "Reviewing Due (Time of Day)",
      value: newAssignmentForm
        ? tableRow.reviewingDue
        : staffAssignments[tableRow[shakespeare ? "task_id" : "task_name"]][
            geo
          ]["reviewer"]
        ? geoIndividualData?.reviewingDue ?? formValues.reviewingDue
        : "",
    },
    {
      col: "Delivery Date",
      value: (newAssignmentForm ? tableRow : formValues).deliveryDate,
    },
    {
      col: "Delivery Time",
      value: (newAssignmentForm ? tableRow : formValues).deliveryDue,
    },
    {
      col: "File Location",
      value: (newAssignmentForm ? tableRow : formValues).fileLocation,
    },
    { col: "Link", value: shakespeare ? "" : tableRow.link },
    { col: "Assignment Status", value: "Assigned" },
  ];

  const retVal = [];
  rowInfo.forEach((el) => {
    retVal[el.col] = el.value;
  });

  return retVal;
};

export const addRowsToGoogleSheet = (props) => {
  const { tableRows, formValues, staffAssignments } = props,
    shakespeare = store.getters["fcBot/shakespeare"],
    backstageCopywriting = store.getters["fcBot/backstageCopywriting"],
    newAssignmentForm = tableRows[0].hasOwnProperty("relatedProject"),
    incrsTableRows = [...tableRows];

  if (backstageCopywriting || shakespeare)
    Object.entries(staffAssignments).forEach((el) => {
      const assgn = el[0],
        geos = Object.keys(el[1]),
        parent = tableRows.find(
          (tableRow) =>
            tableRow[shakespeare ? "task_id" : "task_name"] === assgn
        );
      geos.forEach((geo) => {
        if (staffAssignments[assgn][geo]?.incrementals?.length) {
          const incrsArray = staffAssignments[assgn][geo].incrementals;
          incrsArray.forEach((inc) => {
            if (shakespeare) {
              incrsTableRows.push({
                ...parent,
                index: incrsArray.indexOf(inc),
              });
            } else {
              const incrRow = { ...parent };
              Object.assign(incrRow, {
                geos: parent.geos.filter((parentGeo) => parentGeo === geo),
                index: incrsArray.indexOf(inc),
              });
              incrsTableRows.push(incrRow);
            }
          });
        }
      });
    });

  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
  let sheet, poNumber;

  return new Promise((resolve, reject) => {
    getiTunesFCPONumber()
      .then((poData) => {
        poNumber = poData.data[0][3].value;
        return doc.useServiceAccountAuth({
          client_email: process.env.VUE_APP_GDOCS_CLIENT_EMAIL,
          private_key: process.env.VUE_APP_G_KEY,
        });
      })
      .then(() => {
        // populate document info
        return doc.loadInfo();
      })
      .then(() => {
        // get worksheet
        sheet = doc.sheetsByTitle[tabName];
        // clear sheet
        return sheet.clear();
      })
      .then(() => {
        // set sheet size
        return sheet.resize({ rowCount: 500, columnCount: headerCols.length });
      })
      .then(() => {
        // add header row
        return sheet.setHeaderRow(headerCols);
      })
      .then(() => {
        let rowsToAdd = [];
        (backstageCopywriting || shakespeare
          ? incrsTableRows
          : tableRows
        ).forEach((tableRow) => {
          (shakespeare || newAssignmentForm
            ? [tableRow.geo]
            : tableRow.geos
          ).forEach((geoVal) => {
            const row = getRowValues({
              formValues,
              tableRow,
              geoVal,
              poNumber,
              staffAssignments,
            });
            rowsToAdd.push(_.values(row));
          });
        });

        const sortRank = {
          "cs-CZ": 1,
          "el-GR": 2,
          "es-ES": 3,
          "es-MX": 4,
          "hi-IN": 5,
        };

        const sorted = _.sortBy(rowsToAdd, [
          (el) => el[6], // producer
          (el) => el[4], // subject line
          (el) => el[8], // task name
          (el) => {
            return sortRank[el[9]]; // geo
          },
        ]);

        sheet.addRows(sorted, { insert: false });
      })
      .then(() => resolve("success"))
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};
