// libraries
import _ from "lodash";
import moment from "moment";
// internal
import { addAssignmentRowsFromEditor, appIDs } from "@/utils/quickbaseUtils";
import store from "../store/index";

export const BLANK_NEW_FC_ASSIGNMENT_DATAS = [
  {
    relatedProject: "",
    requestDate: moment(
      new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
      })
    ).format("YYYY-MM-DD"),
    emailSubjectLine: "",
    clientRequester: "",
    requestType: "",
    mediaType: "",
    keyword: "",
    clientDeliveryDate: "",
    deliveryDate: "",
    clientDeliveryDue: "",
    deliveryDue: "",
    timeZone: "",
    urgent: false,
    override: false,
    writerOverloaded: false,
    editorOverloaded: false,
    sourceGeo: "",
    geo: "",
    wordCount: "",
    taskID: "",
    assignmentID: "",
    assignmentDetails: "",
    po: "",
    payPeriod: "",
    assignmentName: "",
    writer: {},
    writingDueDate: "",
    writingDue: "",
    editor: {},
    editingDueDate: "",
    editingDue: "",
    reviewer: {},
    reviewingDueDate: "",
    reviewingDue: "",
    fileLocation: "",
    workType1Reference: "",
    workType2Reference: "",
    workType3Reference: "",
    workType4Reference: "",
    workType5Reference: "",
    workType6Reference: "",
    workType1Quantity: "",
    workType2Quantity: "",
    workType3Quantity: "",
    workType4Quantity: "",
    workType5Quantity: "",
    workType6Quantity: "",
    note: "",
    link: "",
  },
];

//////////////////////////////
// assignment CRUD
//////////////////////////////
const formatQuickbaseData = (data, editMode, ledMode) => {
  let retVal = {
    ...data,
    relatedProject: data.relatedProject.id,
    payPeriod: moment(data.payPeriod).format("MMMM YYYY"),
    writer: ledMode ? data.writer : data.writer.name,
  };
  Object.assign(
    retVal,
    editMode
      ? {
          lastUpdated: `${moment(
            new Date().toLocaleString("en-US", {
              timeZone: "America/New_York",
            })
          ).format("MM/DD/YY HH:mm A")} by ${store.getters["auth/user"].Name}`,
        }
      : {
          createdBy: store.getters["auth/user"].Name,
        }
  );
  if (!ledMode)
    Object.assign(retVal, {
      urgent: data.urgent ? "yes" : "no",
      override: data.override ? "yes" : "no",
      writerOverloaded: data.writerOverloaded ? "yes" : "no",
      editorOverloaded: data.editorOverloaded ? "yes" : "no",
      editor: data.editor.name,
      reviewer: data.reviewer.name,
      writerSlackID: data.writer.slackID,
      editorSlackID: data.editor.slackID,
      reviewerSlackID: data.reviewer.slackID,
    });
  return retVal;
};

export const createNewFCAssignmentsWithDatas = async ({
  datas,
  editMode,
  ledMode,
}) => {
  const qbUploadDatas = datas.map((data) => {
    return {
      ...formatQuickbaseData(data, editMode, ledMode),
      assignmentStatus: ledMode ? "Delivered" : "Assigned",
    };
  });
  try {
    const quickbaseResponse = await addAssignmentRowsFromEditor(
      qbUploadDatas,
      appIDs.iTunesProjectManager.assignments,
      ledMode
    );
    // create an object that marries the quickbase response with the original data
    const allInfos = datas.map((el, i) => {
      return { ...el, ...quickbaseResponse[i] };
    });
    // create the subset of those that are rejected
    const rejected = allInfos.filter(
      (el) =>
        el.status === "rejected" ||
        _.isEmpty(
          el.value.metadata[editMode ? "updatedRecordIds" : "createdRecordIds"]
        )
    );
    return {
      rejected,
    };
  } catch (err) {
    console.error(err);
  }
};
