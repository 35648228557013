export const authModule = {
  state: () => ({
    user: {},
    profileModal: false,
    profileFields: [],
    loginFailed: false,
    isAdmin: false,
    isManager: false,
    isDubEditor: false,
    company: "",
  }),
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    HANDLE_PROFILE_MODAL(state, profileModal) {
      state.profileModal = profileModal;
    },
    SET_PROFILE_FIELDS(state, fields) {
      state.profileFields = [...fields];
    },
    SET_LOGIN_FAILED(state, loginFailed) {
      state.loginFailed = loginFailed;
    },
    SET_IS_ADMIN(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    SET_IS_MANAGER(state, isManager) {
      state.isManager = isManager;
    },
    SET_IS_DUB_EDITOR(state, isDubEditor) {
      state.isDubEditor = isDubEditor;
    },
    SET_COMPANY(state, company) {
      state.company = company;
    },
  },
  actions: {
    setUser(context, user) {
      context.commit("SET_LOGIN_FAILED", false);
      context.commit("SET_USER", user);
    },
    handleProfileModal(context, profileModal) {
      context.commit("HANDLE_PROFILE_MODAL", profileModal);
    },
    setProfileFields(context, fields) {
      context.commit("SET_PROFILE_FIELDS", fields);
    },
    setLoginFailed(context, loginFailed) {
      context.commit("SET_LOGIN_FAILED", loginFailed);
    },
    setIsAdmin(context, isAdmin) {
      context.commit("SET_IS_ADMIN", isAdmin);
    },
    setIsManager(context, isManager) {
      context.commit("SET_IS_MANAGER", isManager);
    },
    setIsDubEditor(context, isDubEditor) {
      context.commit("SET_IS_DUB_EDITOR", isDubEditor);
    },
    setCompany(context, company) {
      context.commit("SET_COMPANY", company);
    },
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    profileModal: (state) => {
      return state.profileModal;
    },
    profileLock: (state) => {
      const requiredFields = [
        "Email",
        "Name",
        "PreferredName",
        "Language1",
        "Bio",
        "Country",
        "StreetAddress",
        "LegalName",
        "TaxResidency",
        "Skill1",
        "Skill2",
        "Skill3",
        "Skill4",
        "Skill5",
        "Skill6",
        "Skill7",
        "Skill8",
        "Skill1YearsOfExperience",
        "Skill2YearsOfExperience",
        "Skill3YearsOfExperience",
        "Skill4YearsOfExperience",
        "Skill5YearsOfExperience",
        "Skill6YearsOfExperience",
        "Skill7YearsOfExperience",
        "Skill8YearsOfExperience",
        "MusicYearsOfExperience",
        "Film&TVYearsOfExperience",
        "BooksYearsOfExperience",
        "PodcastsYearsOfExperience",
        "Mobile/VideoGamesYearsOfExperience",
        "MobileAppsYearsOfExperience",
        "LifestyleYearsOfExperience",
        "SportsYearsOfExperience",
        "OS1",
      ];
      return state.isAdmin || state.company !== "Company Cue, Inc."
        ? false
        : !requiredFields.every((field) => state.user?.[field]);
    },
    profileFields: (state) => {
      return state.profileFields;
    },
    loginFailed: (state) => {
      return state.loginFailed;
    },
    isAdmin: (state) => {
      return state.isAdmin;
    },
    isManager: (state) => {
      return state.isManager;
    },
    isDubEditor: (state) => {
      return state.isDubEditor;
    },
    company: (state) => {
      return state.company;
    },
  },
};
