<template>
  <v-card class="editor-new-assignment-card grey lighten-2">
    <!-- <div class="editor-new-assignment-card__main"> -->
    <EditorNewAssignmentItem
      v-for="(newAssignmentData, i) in newAssignmentDatas"
      :key="`new-assignment-${i}`"
      :datasIndex="i"
    />
    <!-- </div> -->
    <v-card-actions class="d-flex flex-row justify-end pr-4 pb-4">
      <v-btn
        depressed
        class="mx-3 grey lighten-2"
        @click="handleAddAnotherAssignment"
      >
        <v-icon>mdi-plus</v-icon>
        add another assignment
      </v-btn>
      <v-btn
        depressed
        class="mx-3 grey lighten-2"
        @click="handleDuplicateAssignment"
      >
        <v-icon>mdi-plus</v-icon>
        duplicate
      </v-btn>
      <v-btn width="30%" color="secondary" @click="handleCreateNewAssignments"
        >create</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// library
import _ from "lodash";
// internal
import {
  BLANK_NEW_ASSIGNMENT_DATAS,
  createNewAssignmentsWithDatas,
  getSortField,
} from "@/utils/editorUtils";
// components
import EditorNewAssignmentItem from "./EditorNewAssignmentItem/EditorNewAssignmentItem";
export default {
  name: "EditorNewAssignmentModal",
  components: { EditorNewAssignmentItem },
  data() {
    return {
      sampleFlow: ["writer", "editor", "final"],
    };
  },
  computed: {
    ...mapGetters("editor", ["newAssignmentDatas", "selectedProject"]),
    ...mapGetters("auth", ["isAdmin", "user"]),
  },
  watch: {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions("editor", [
      "setNewAssignmentDatas",
      "setTableAssignments",
      "setNewAssignmentModalOpen",
    ]),
    handleAddAnotherAssignment() {
      let newPayload = _.cloneDeep(this.newAssignmentDatas);
      newPayload = [...newPayload, ...BLANK_NEW_ASSIGNMENT_DATAS];
      this.setNewAssignmentDatas(newPayload);
    },
    handleDuplicateAssignment() {
      let newPayload = _.cloneDeep(this.newAssignmentDatas);
      let lastAssn = _.last(newPayload);
      newPayload = [...newPayload, lastAssn];
      this.setNewAssignmentDatas(newPayload);
    },
    handleCreateNewAssignments() {
      createNewAssignmentsWithDatas({
        datas: this.newAssignmentDatas,
        project: this.selectedProject,
      })
        .then((createdValues) => {
          // reload the table
          return this.selectedProject.getAssignmentDatas({
            isAdmin: this.isAdmin,
            userEmail: this.user.email,
          });
        })
        .then((d) => {
          d.forEach((el) => {
            el.sortDate = getSortField({
              row: el,
              isAdmin: this.isAdmin,
              userEmail: this.user.email,
            });
          });
          this.setTableAssignments(d);
          this.setNewAssignmentModalOpen(false);
        })
        .catch((err) => {
          console.warn(err);
          this.setNewAssignmentModalOpen(false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-new-assignment-card {
  display: flex;
  flex-flow: column;

  &__main {
    .add-another-assignment-container {
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      .add-another-assignment {
        cursor: pointer;
        &:hover {
          color: rgb(73, 73, 73);
        }
      }
    }
  }
}
</style>
