// imports
import { saveAs } from "file-saver";
import {
  sourceFileHeaders,
  shakespeareHeaders,
  shakespeareLocHeaders,
} from "@/utils/fcBotConstants";
import store from "../store/index";
import _ from "lodash";
const Excel = require("exceljs");
const JSZip = require("jszip");
const flatten = require("flat");

// constants
export const workTypeReference = {
  "en-US": {
    "ca-ES": "18",
    "cs-CZ": "74",
    "el-GR": "77",
    "hi-IN": "21",
    "it-IT": "22",
    "nl-NL": "23",
    "pt-BR": "24",
    "pt-PT": "77",
    "ru-RU": "69",
    "sk-SK": "27",
    "vi-VN": "28",
    "af-ZA": "29",
    "en-GB": "30",
    "uk-UA": "77",
    "da-DK": "34",
    "es-US": "77",
    "es-ES": "77",
    "pl-PL": "45",
    "es-MX": "48",
    "de-DE": "53",
    "de-CH": "53",
    "ar-SA": "61",
    "ja-JP": "56",
    "ko-KR": "62",
    "hu-HU": "76",
  },
  "en-GB": {
    "ca-ES": "18",
    "cs-CZ": "74",
    "el-GR": "77",
    "hi-IN": "21",
    "it-IT": "22",
    "nl-NL": "23",
    "pt-BR": "24",
    "pt-PT": "77",
    "ru-RU": "69",
    "sk-SK": "27",
    "vi-VN": "28",
    "af-ZA": "29",
    "uk-UA": "77",
    "da-DK": "34",
    "es-US": "77",
    "es-ES": "77",
    "pl-PL": "45",
    "es-MX": "48",
    "de-DE": "53",
    "de-CH": "53",
    "ar-SA": "61",
    "ja-JP": "56",
    "ko-KR": "62",
    "hu-HU": "76",
  },
  "ko-KR": {
    "en-US": "62",
    "en-GB": "62",
  },
};

////////////////////////////
// assignment utilities
////////////////////////////
export const isAdaptation = ({ locale, allLocales, getSourceLocale }) => {
  const adaptionPairs = {
    // "es-MX": "es-ES", // TURNING THIS OFF PER INSTRUCTIONS
    "de-CH": "de-DE",
    "en-AU": "en-US",
    "en-GB": "en-US",
    "en-CA": "en-US",
  };

  const sourceLang = adaptionPairs[locale];
  if (getSourceLocale) return sourceLang ?? "";
  else if (Object.keys(adaptionPairs).includes(locale))
    return allLocales.includes(sourceLang);
  else return false;
};

export const getFCWorkType = ({
  workType,
  workType1,
  locale,
  sourceLocale,
  allLocales,
  localizationMode,
  adaptationRequest,
}) => {
  const ADAPTION_WORK_TYPES = {
    "de-CH": "64",
    "en-AU": "78",
    "en-GB": "78",
    "en-CA": "78",
  };

  if (localizationMode) {
    return workTypeReference[sourceLocale || "en-US"][locale];
  } else {
    return isAdaptation({ locale, allLocales }) || adaptationRequest
      ? workType1
        ? ADAPTION_WORK_TYPES[locale]
        : ""
      : workType;
  }
};

export const getFCWordCount = ({ wordCount, locale, allLocales }) => {
  if (isAdaptation({ locale, allLocales })) {
    return "";
  } else {
    return wordCount;
  }
};

export const getFCRequestType = ({ locale, allLocales }) => {
  if (isAdaptation({ locale, allLocales })) {
    return "Adaptation";
  } else {
    return "Copywriting";
  }
};

export const getFCWriterDate = ({
  locale,
  allLocales,
  writerDate,
  adaptationDate,
}) => {
  if (isAdaptation({ locale, allLocales })) {
    return adaptationDate;
  } else {
    return writerDate;
  }
};

export const getFCWriterTime = ({
  locale,
  allLocales,
  writerTime,
  adaptationTime,
}) => {
  if (isAdaptation({ locale, allLocales })) {
    return adaptationTime;
  } else {
    return writerTime;
  }
};

////////////////////////////
// handle input file parsing
////////////////////////////
export const getRecords = (worksheet) => {
  const recordsArray = [];
  store._actions["fcBot/setShakespeareLoc"][0](
    worksheet._rows[0].values.includes("Name Ref.")
  );
  store._actions["fcBot/setShakespeare"][0](
    worksheet._rows[0].values.includes("WORKFLOW_ID")
  );
  store._actions["fcBot/setCopywriting"][0](
    worksheet._rows[1].values.includes("Copywriting") &&
      !store.getters["fcBot/shakespeare"]
  );
  try {
    let headers = worksheet.getRow(1).values,
      vendorIndex;
    const headerLookup = _.invert(
      store.getters["fcBot/shakespeare"]
        ? shakespeareHeaders
        : store.getters["fcBot/shakespeareLoc"]
        ? shakespeareLocHeaders
        : sourceFileHeaders
    );
    headers = headers.map((el) => headerLookup[el]);

    window.ws = worksheet;

    worksheet.eachRow((row, i) => {
      const rowValues = row.values;
      if (rowValues.includes("Vendor"))
        vendorIndex = rowValues.indexOf("Vendor");
      // skip first row
      if (i === 1) return;

      const newRecord = {};
      if (vendorIndex) {
        if (rowValues[vendorIndex] === "Company Cue") {
          headers.forEach((header, j) => {
            if (header) {
              newRecord[header] = rowValues[j];
            }
          });
        } else {
          return;
        }
      } else {
        headers.forEach((header, j) => {
          if (header) {
            newRecord[header] = rowValues[j];
          }
        });
      }
      recordsArray.push(newRecord);
    });
    return recordsArray;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getUniqueRowValue = (rows, key) => {
  return _.uniq(rows.map((el) => el[key]));
};

export const getSourceFileTableRows = (records) => {
  if (
    store.getters["fcBot/shakespeare"] ||
    store.getters["fcBot/shakespeareLoc"]
  )
    store._actions["fcBot/setFormValue"][0]({
      key: "fileLocation",
      value: "https://wrkflow.app/",
    });
  if (store.getters["fcBot/shakespeare"]) {
    store._actions["fcBot/setFormValue"][0]({
      key: "emailSubjectLine",
      value: "Assignment Detail",
    });
    return records.map((task) => ({
      task_name: task.task_name,
      geo: task.geo,
      keyword: "Apple Music",
      request_type: "Copywriting",
      request_date: new Date(
        new Date().toLocaleString("en-US", {
          timeZone: "America/New_York",
        })
      ),
      task_id: task.task_id,
      content_id: task.content_id,
      video_content_id: task.video_content_id,
      writer: task.writer,
    }));
  } else {
    const groupedByTaskName = _.groupBy(records, (el) => el.task_name);
    return Object.keys(groupedByTaskName).map((task_name) => {
      const groupedRows = groupedByTaskName[task_name];
      const firstRow = groupedRows[0];
      store.getters["fcBot/shakespeareLoc"]
        ? store._actions["fcBot/setFormValue"][0]({
            key: "clientRequester",
            value: "Shakespeare",
          })
        : store._actions["fcBot/setFormValue"][0]({
            key: "note",
            value: firstRow["note"] ?? "",
          });
      const retVal = {
        task_name: task_name,
        source_geo: firstRow["source_geo"] ?? "",
        geos: store.getters["fcBot/shakespeareLoc"]
          ? groupedRows
              .filter((el) => !!el.geo_divider)
              .map((el) => ({ geo: el.geo, writer: el.writer }))
          : getUniqueRowValue(groupedRows, "geo"),
        subject_line: store.getters["fcBot/shakespeareLoc"]
          ? task_name
          : firstRow["subject_line"],
        word_count: Number(firstRow["word_count"]),
        media_type: store.getters["fcBot/shakespeareLoc"]
          ? "Apps"
          : firstRow["media_type"],
        keyword: store.getters["fcBot/shakespeareLoc"]
          ? "MAS"
          : firstRow["keyword"],
        request_type: store.getters["fcBot/shakespeareLoc"]
          ? "Localization"
          : firstRow["request_type"],
        request_date: store.getters["fcBot/shakespeareLoc"]
          ? new Date(
              new Date().toLocaleString("en-US", {
                timeZone: "America/New_York",
              })
            )
          : firstRow["request_date"],
        task_id: firstRow["task_id"],
        link: store.getters["fcBot/shakespeareLoc"] ? "" : firstRow["link"],
      };
      Object.assign(
        retVal,
        store.getters["fcBot/shakespeareLoc"]
          ? {
              content_id: firstRow["content_id"],
            }
          : {
              client_requester: firstRow["client_requester"],
              due_date: firstRow["due_date"]?.toLocaleString("en-US", {
                timeZone: "Europe/Belfast",
              }),
              assignment_ids: getUniqueRowValue(groupedRows, "assignment_id"),
            }
      );
      return retVal;
    });
  }
};

export const getTableDataFromSource = async (file) => {
  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(file);
  const sheet = workbook.getWorksheet(0) || workbook.getWorksheet(1); // in example source file, index 0 sheet is empty. fallback to next - might need to fix
  const records = getRecords(sheet);
  if (!records) {
    console.error("error in getRecords from source file");
    // handle error of bad source file processing
  }
  const sourceFileTableRows = getSourceFileTableRows(records);
  return sourceFileTableRows;
};

export const getStaffMapFromRows = (rows) => {
  const retVal = {};

  rows.forEach((row) => {
    const subRetVal = {},
      specailGeosRefs = {
        workType1Reference: {
          default: "1",
          "ar-SA": "1",
          "pt-PT": "1",
          "ru-RU": "1",
          "uk-UA": "1",
          "es-ES": "1",
          "es-MX": "1",
          "it-IT": "1",
          "nl-NL": "1",
          "de-DE": "1",
        },
        workType2Reference: {
          default: "2",
          "ar-SA": "2",
          "pt-PT": "2",
          "ru-RU": "2",
          "uk-UA": "2",
          "es-ES": "2",
          "es-MX": "2",
          "it-IT": "2",
          "nl-NL": "2",
          "de-DE": "2",
        },
        workType3Reference: {
          default: "3",
          "ar-SA": "",
          "pt-PT": "",
          "ru-RU": "",
          "uk-UA": "",
          "es-ES": "",
          "es-MX": "",
          "it-IT": "",
          "nl-NL": "",
          "de-DE": "",
        },
        workType4Reference: {
          default: "37",
          "ar-SA": "",
          "pt-PT": "",
          "ru-RU": "",
          "uk-UA": "",
          "es-ES": "",
          "es-MX": "",
          "it-IT": "",
          "nl-NL": "",
          "de-DE": "",
        },
        workType5Reference: {
          default: "41",
          "ar-SA": "",
          "pt-PT": "",
          "ru-RU": "",
          "uk-UA": "",
          "es-ES": "41",
          "es-MX": "41",
          "it-IT": "41",
          "nl-NL": "41",
          "de-DE": "41",
        },
        workType6Reference: {
          default: "75",
          "ar-SA": "",
          "pt-PT": "",
          "ru-RU": "",
          "uk-UA": "",
          "es-ES": "",
          "es-MX": "",
          "it-IT": "",
          "nl-NL": "",
          "de-DE": "",
        },
      },
      handleWorkTypeRef = (ref, geo) => {
        return specailGeosRefs[ref]?.[geo] ?? specailGeosRefs[ref].default;
      };

    if (store.getters["fcBot/shakespeare"]) {
      subRetVal[row.geo] = {
        writer: row.writer,
        editor: "",
        reviewer: "",
        writerOverloaded: false,
        editorOverloaded: false,
        reviewerOverloaded: false,
        workType1Reference: handleWorkTypeRef("workType1Reference", row.geo),
        workType2Reference: handleWorkTypeRef("workType2Reference", row.geo),
        workType3Reference: handleWorkTypeRef("workType3Reference", row.geo),
        workType4Reference: handleWorkTypeRef("workType4Reference", row.geo),
        workType5Reference: handleWorkTypeRef("workType5Reference", row.geo),
        workType6Reference: handleWorkTypeRef("workType6Reference", row.geo),
        workType1Quantity: "",
        workType2Quantity: "",
        workType3Quantity: "",
        workType4Quantity: "",
        workType5Quantity: "",
        workType6Quantity: "",
      };
      subRetVal["content_id"] = row["content_id"];
      subRetVal["task_name"] = row["task_name"];

      retVal[row.task_id] = subRetVal;
    } else {
      row.geos.forEach((geo) => {
        const index = row.geos.indexOf(geo);
        subRetVal[store.getters["fcBot/shakespeareLoc"] ? geo.geo : geo] = {
          writer: store.getters["fcBot/shakespeareLoc"] ? geo.writer : "",
          editor: "",
          reviewer: "",
          writerOverloaded: false,
          editorOverloaded: false,
          reviewerOverloaded: false,
          assignmentId: store.getters["fcBot/shakespeareLoc"]
            ? ""
            : row.assignment_ids[index],
        };
        if (store.getters["fcBot/backstageCopywriting"])
          Object.assign(subRetVal[geo], {
            workType1Reference: handleWorkTypeRef(
              "workType1Reference",
              row.geo
            ),
            workType2Reference: handleWorkTypeRef(
              "workType2Reference",
              row.geo
            ),
            workType3Reference: handleWorkTypeRef(
              "workType3Reference",
              row.geo
            ),
            workType4Reference: handleWorkTypeRef(
              "workType4Reference",
              row.geo
            ),
            workType5Reference: handleWorkTypeRef(
              "workType5Reference",
              row.geo
            ),
            workType6Reference: handleWorkTypeRef(
              "workType6Reference",
              row.geo
            ),
            workType1Quantity: "",
            workType2Quantity: "",
            workType3Quantity: "",
            workType4Quantity: "",
            workType5Quantity: "",
            workType6Quantity: "",
          });
      });
      if (!store.getters["fcBot/backstageCopywriting"])
        subRetVal["wordCount"] = row["word_count"];

      retVal[row.task_name] = subRetVal;
    }
  });

  return retVal;
};

export const getHeaderRowsFromFile = async (file) => {
  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(file);
  const sheet = workbook.getWorksheet(0) || workbook.getWorksheet(1); // in example source file, index 0 sheet is empty. fallback to next - might need to fix
  const firstRow = sheet.getRow(1);
  return firstRow.values;
};

export const getGroupsOnCustomColumns = async ({ file, columns }) => {
  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(file);
  const sheet = workbook.getWorksheet(0) || workbook.getWorksheet(1); // in example source file, index 0 sheet is empty. fallback to next - might need to fix

  // get addresses of column headers
  const columnAddresses = [];
  columns.forEach((columnLabel) => {
    const address = sheet.model.rows[0].cells.find(
      (cell) => cell.value === columnLabel
    ).address;

    // extract just letters from string
    const addressLetters = address.match(/[a-zA-Z]+/g)[0];
    columnAddresses.push(addressLetters);
  });

  // get all the values in each of the column address columns
  const columnValues = columnAddresses.map((address) => {
    return sheet.getColumn(address).values;
  });

  // use lodash to zip all of the arrays in columnValues together
  const zippedColumnValues = _.zip(...columnValues);

  // get list of unique arrays in zippedColumnValues
  const uniqueColumnValues = _.uniqWith(zippedColumnValues, _.isEqual);

  // first row is undefined and second is header, remove them
  const uniqueColumnValuesWithoutHeaders = uniqueColumnValues.slice(2);

  // key values in uniqueColumnValuesWithoutHeaders by values in columnAddresses
  const uniqueColumnValuesWithKeys = uniqueColumnValuesWithoutHeaders.map(
    (row, i) => {
      const retVal = {};
      columnAddresses.forEach((columnLabel, j) => {
        retVal[columnLabel] = row[j];
      });
      return retVal;
    }
  );

  return uniqueColumnValuesWithKeys;
};

export const doSplitOnCustomGroups = async ({ file, groupings }) => {
  const filePromises = groupings.map((grouping) => {
    // remove extension from file.name string and split it to process
    let originalFileName = file.name.split(".")[0].split("_");
    // separate ID to insert GEO before it
    const id = originalFileName.splice(-1);

    const fileName = `${originalFileName.join("_")}_${createFileNameFromValues(
      Object.values(grouping)
    )}_${id}`;
    return createFilteredFile({ file, filters: grouping, fileName });
  });

  await Promise.all(filePromises).then((files) => {
    createSingleLevelZipDownload(files);
  });
};

////////////////////////////
// file splitting
////////////////////////////
const removeXLSX = (excelFileName) => {
  return excelFileName.split(".xlsx")[0];
};

export const doFileSplit = (files, sourceMap) => {
  const allPromises = [];

  sourceMap.forEach((task) => {
    task.geos.forEach((taskGeo) => {
      const file = files.find((el) => removeXLSX(el.name) === task.task_name);
      allPromises.push(
        createGeoFilteredFile(file, taskGeo).then((f) => {
          return f;
        })
      );
    });
  });

  Promise.all(allPromises).then((values) => {
    createZipDownload(values);
  });
};

export const getStaffAssignmentsByPerson = (staffAssignments, onlyWriters) => {
  const transformed = flatten(staffAssignments, { delimiter: ";" });
  let assignmentDatas = [];
  Object.keys(transformed).forEach((key) => {
    let [taskName, geo, role] = key.split(";");
    if (store.getters["fcBot/shakespeare"])
      taskName = transformed[`${key.split(";")[0]};task_name`];
    const person = transformed[key];
    if (role === "writer") {
      assignmentDatas.push({
        taskName,
        geo,
        role,
        person,
      });
    }

    if (!onlyWriters) {
      if (role === "editor" || role === "reviewer") {
        assignmentDatas.push({
          taskName,
          geo,
          role,
          person,
        });
      }
    }
  });

  return assignmentDatas;

  // group by assigned person
};

export const doFileSplitByPerson = ({
  files,
  staffAssignments,
  staffNameGEOPairs,
}) => {
  // transform staffAssignments into a objects containing assignment details, one object per assignment
  let assignmentDatas = getStaffAssignmentsByPerson(staffAssignments, true);
  assignmentDatas = _.groupBy(assignmentDatas, (el) => el.person);

  return new Promise((resolve, reject) => {
    Object.keys(assignmentDatas).forEach((person) => {
      const filePromises = [];
      if (person !== "") {
        const assignments = assignmentDatas[person];
        assignments.forEach((assignment) => {
          const file = files.find(
            (el) => removeXLSX(el.name) === assignment.taskName
          );
          filePromises.push(
            createGeoFilteredFile(file, assignment.geo).then((f) => {
              return f;
            })
          );
        });
        Promise.all(filePromises).then((values) => {
          const personObj = staffNameGEOPairs.find((el) => el.name === person);
          values.forEach((el) => {
            return new Promise((resolve, reject) => {
              const xhr = new XMLHttpRequest();

              xhr.open("POST", "https://content.dropboxapi.com/2/files/upload");

              xhr.onload = () => {
                xhr.status === 200
                  ? resolve(xhr.response)
                  : reject({
                      status: xhr.status,
                      statusText: xhr.statusText,
                    });
              };

              xhr.onerror = () => {
                reject({
                  status: xhr.status,
                  statusText: xhr.statusText,
                });
              };

              xhr.setRequestHeader(
                "Authorization",
                "Bearer " + process.env.VUE_APP_DROPBOX_TOK
              );
              xhr.setRequestHeader("Content-Type", "application/octet-stream");
              // xhr.setRequestHeader("Retry-After", 2); // to avoid CORS need to move service to backend
              let validFileName = null;
              if (el.file.name.includes("™"))
                validFileName = el.file.name.replace("™", "(TM)");
              xhr.setRequestHeader(
                "Dropbox-API-Arg",
                JSON.stringify({
                  path:
                    `/FC Content/Apps/${el.geo.toUpperCase()}_Apps/${el.geo.toUpperCase()}_Assigned/${
                      personObj.initials
                    }_Assigned/` + (validFileName ?? el.file.name),
                  mode: "add",
                  autorename: true,
                  mute: false,
                })
              );

              xhr.send(el.file);
            })
              .then(() => resolve("File(s) uploaded!"))
              .catch((error) => {
                console.error(error);
                reject("Something went wrong.");
              });
          });
        });
      } else {
        reject("Choose the writers for all GEOs.");
      }
    });
  });
};

const createGeoFilteredFile = async (file, geo) => {
  // const fileName = file.name;

  const fileNameParts = file.name.split(/(\d\d\d\d\d+)/);
  const fileNameWithGeo = `${fileNameParts[0]}${geo}_${fileNameParts[1]}${fileNameParts[2]}`;
  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(file);
  const sheet = workbook.getWorksheet(0) || workbook.getWorksheet(1); // in example source file, index 0 sheet is empty. fallback to next - might need to fix

  // find address of 'Locale' column
  const localeCell = sheet.model.rows[0].cells.find(
    (cell) => cell.value === "Locale"
  ).address;

  // extract just letters from string
  const localeCol = localeCell.replace(/[0-9].*/, "");

  // get number of rows in sheet
  const numRows = sheet.model.rows.length;

  // loop through rows starting at the end and remove any that don't match geo
  for (let i = numRows + 1; i > 1; i--) {
    if (sheet.getCell(localeCol + i).value !== geo) {
      sheet.spliceRows(i, 1);
    }
  }

  // create file and return
  const buffData = await workbook.xlsx.writeBuffer();
  var blob = new Blob([buffData], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  blob.lastModifiedDate = new Date();
  blob.name = `${fileNameWithGeo}`;
  return {
    geo,
    name: removeXLSX(fileNameWithGeo),
    file: blob,
  };
};

const createFilteredFile = async ({ file, filters, fileName }) => {
  // setup workbook
  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(file);
  const sheet = workbook.getWorksheet(0) || workbook.getWorksheet(1); // in example source file, index 0 sheet is empty. fallback to next - might need to fix

  // get number of rows in sheet
  const numRows = sheet.model.rows.length;

  for (let i = numRows + 1; i > 1; i--) {
    const truthMap = Object.keys(filters).map((column) => {
      if (sheet.getCell(column + i).value === filters[column]) {
        return true;
      } else {
        return false;
      }
    });

    // check if all values in truthMap are true
    if (!truthMap.every((el) => el === true)) {
      sheet.spliceRows(i, 1);
    }
  }

  // create file and return
  const buffData = await workbook.xlsx.writeBuffer();
  var blob = new Blob([buffData], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  blob.lastModifiedDate = new Date();
  blob.name = `${fileName}`;
  return {
    // name: removeXLSX(fileNameWithGeo),
    name: `${fileName}`,
    file: blob,
  };
};

////////////////////////////
// file downloading
////////////////////////////
const createZipDownload = (allFileInfos, fileName) => {
  const newFileName = fileName || "files";
  const zip = new JSZip();

  allFileInfos.forEach((f) => {
    zip.file(`${f.geo}/${f.name}.xlsx`, f.file);
  });
  zip.generateAsync({ type: "blob" }).then((blob) => {
    saveAs(blob, `${newFileName}.zip`);
  });
};

const createSingleLevelZipDownload = (allFileInfos, fileName) => {
  const newFileName = fileName || "files";
  const zip = new JSZip();

  allFileInfos.forEach((f) => {
    zip.file(`${f.name}.xlsx`, f.file);
  });
  zip.generateAsync({ type: "blob" }).then((blob) => {
    saveAs(blob, `${newFileName}.zip`);
  });
};

const createFileNameFromValues = (values) => {
  // values need to be escaped
  const escapedValues = values.map((el) => {
    return el.replace(/[^a-zA-Z0-9]/g, "_");
  });

  const fileName = `${escapedValues.join("_")}`;
  return fileName;
};
