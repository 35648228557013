// libraries
import _ from "lodash";
// internal
import {
  getTableDataFromSource,
  getStaffMapFromRows,
} from "../utils/fcBotUtils";
import { BLANK_FORM_VALUES } from "@/utils/fcBotConstants";

export const fcBotModule = {
  state: () => ({
    sourceFile: null,
    files: [],
    tableRows: null,
    allSourceFilesFound: false,
    formValues: BLANK_FORM_VALUES,
    staffAssignments: [],
    selectedDashboardRows: [],
    staffNameGEOPairs: [],
    capacityPool: [],
    defaultCapacityPool: [],
    adjustedCapacityPool: [],
    namesLoading: false,
    xContent: false,
    shakespeare: false,
    shakespeareLoc: false,
    backstageCopywriting: false,
    prepopulationFlow: null,
  }),
  mutations: {
    ADD_FILE(state, file) {
      state.files.push(file);
    },
    REMOVE_FILE(state, fileName) {
      state.files = state.files.filter((f) => f.name !== fileName);
    },
    SET_FILES(state, files) {
      state.files = files;
    },
    ADD_SOURCE_FILE(state, file) {
      state.sourceFile = file;
    },
    REMOVE_SOURCE_FILE(state) {
      state.sourceFile = null;
    },
    CHANGE_TABLE_ROWS(state, rows) {
      state.tableRows = rows;
      rows
        ? rows[0].keyword === "MAS" ||
          rows[0].keyword === "Today" ||
          rows[0].hasOwnProperty("content_id")
          ? (state.xContent = false)
          : (state.xContent = true)
        : (state.xContent = false);
    },
    SET_ALL_SOURCE_FILES_FOUND(state, allSourceFilesFound) {
      state.allSourceFilesFound = allSourceFilesFound;
    },
    SET_STAFF_ASSIGNMENTS(state, staffAssignments) {
      state.staffAssignments = staffAssignments;
    },
    UPDATE_STAFF_ASSIGNMENT_WORD_COUNT(state, { taskName, newWordCount }) {
      state.staffAssignments[taskName].wordCount = newWordCount;
    },
    SET_STAFF_ASSIGNMENT_OVERLOADING(state, { taskName, geo, key, value }) {
      let assignment;
      Object.entries(state.staffAssignments).forEach((el) => {
        if (el[1].hasOwnProperty(geo) && el[1].task_name === taskName)
          assignment = el[0];
      });
      Object.assign(
        state.staffAssignments[state.shakespeare ? assignment : taskName][geo],
        { [key]: value }
      );
    },
    SET_FORM_VALUE(state, { key, value }) {
      const newFormValues = _.cloneDeep(state.formValues);
      newFormValues[key] = value;
      state.formValues = newFormValues;
    },
    RESET_FORM_VALUES(state) {
      state.formValues = BLANK_FORM_VALUES;
    },
    SET_SELECTED_DASHBOARD_ROWS(state, selectedDashboardRows) {
      state.selectedDashboardRows = selectedDashboardRows;
    },
    SET_STAFF_NAME_GEO_PAIRS(state, staffNameGEOPairs) {
      state.staffNameGEOPairs = staffNameGEOPairs;
    },
    UPDATE_CAPACITY_DATA(state, { dataPiece, newData }) {
      state[dataPiece] = [...newData];
    },
    SET_NAMES_LOADING(state, namesLoading) {
      state.namesLoading = namesLoading;
    },
    SET_SHAKESPEARE(state, isShakespeare) {
      state.shakespeare = isShakespeare;
    },
    SET_SHAKESPEARE_LOC(state, isShakespeareLoc) {
      state.shakespeareLoc = isShakespeareLoc;
    },
    SET_COPYWRITING(state, isCopywriting) {
      state.backstageCopywriting = isCopywriting;
    },
    SET_PREPOPULATION(state, flow) {
      state.prepopulationFlow = flow;
    },
  },
  actions: {
    addFile(context, file) {
      context.commit("ADD_FILE", file);
    },
    removeFile(context, fileName) {
      context.commit("REMOVE_FILE", fileName);
    },
    setFiles(context, files) {
      context.commit("SET_FILES", files);
    },
    addSourceFile(context, file) {
      context.commit("RESET_FORM_VALUES");
      context.commit("ADD_SOURCE_FILE", file);
      getTableDataFromSource(file).then((data) => {
        const staffMap = getStaffMapFromRows(data);
        context.commit("SET_STAFF_ASSIGNMENTS", staffMap);
        context.commit("CHANGE_TABLE_ROWS", data);
      });
    },
    removeSourceFile(context) {
      context.commit("RESET_FORM_VALUES");
      context.commit("REMOVE_SOURCE_FILE");
      context.commit("CHANGE_TABLE_ROWS", null);
    },
    setAllSourceFilesFound(context, allSourceFilesFound) {
      context.commit("SET_ALL_SOURCE_FILES_FOUND", allSourceFilesFound);
    },
    setFormValue(context, { key, value }) {
      context.commit("SET_FORM_VALUE", { key, value });
    },
    resetFormValues(context) {
      context.commit("RESET_FORM_VALUES");
    },
    setStaffAssignments(context, staffAssignments) {
      context.commit("SET_STAFF_ASSIGNMENTS", staffAssignments);
    },
    updateStaffAssignmentWordCount(context, { taskName, newWordCount }) {
      context.commit("UPDATE_STAFF_ASSIGNMENT_WORD_COUNT", {
        taskName,
        newWordCount,
      });
    },
    setStaffAssignmentOverloading(context, { taskName, geo, key, value }) {
      context.commit("SET_STAFF_ASSIGNMENT_OVERLOADING", {
        taskName,
        geo,
        key,
        value,
      });
    },
    setSelectedDashboardRows(context, selectedDashboardRows) {
      context.commit("SET_SELECTED_DASHBOARD_ROWS", selectedDashboardRows);
    },
    setStaffNameGEOPairs(context, staffNameGEOPairs) {
      context.commit("SET_STAFF_NAME_GEO_PAIRS", staffNameGEOPairs);
    },
    updateCapacityData(context, { dataPiece, newData }) {
      context.commit("UPDATE_CAPACITY_DATA", { dataPiece, newData });
    },
    setNamesLoading(context, namesLoading) {
      context.commit("SET_NAMES_LOADING", namesLoading);
    },
    setShakespeare(context, isShakespeare) {
      context.commit("SET_SHAKESPEARE", isShakespeare);
    },
    setShakespeareLoc(context, isShakespeareLoc) {
      context.commit("SET_SHAKESPEARE_LOC", isShakespeareLoc);
    },
    setCopywriting(context, isCopywriting) {
      context.commit("SET_COPYWRITING", isCopywriting);
    },
    setPrepopulation(context, flow) {
      context.commit("SET_PREPOPULATION", flow);
    },
  },
  getters: {
    files: (state) => {
      return state.files;
    },
    sourceFile: (state) => {
      return state.sourceFile;
    },
    tableRows: (state) => {
      return state.tableRows;
    },
    allSourceFilesFound: (state) => {
      return state.allSourceFilesFound;
    },
    formValues: (state) => {
      return state.formValues;
    },
    staffAssignments: (state) => {
      return state.staffAssignments;
    },
    selectedDashboardRows: (state) => {
      return state.selectedDashboardRows;
    },
    staffNameGEOPairs: (state) => {
      return state.staffNameGEOPairs;
    },
    capacityPool: (state) => {
      return state.capacityPool;
    },
    defaultCapacityPool: (state) => {
      return state.defaultCapacityPool;
    },
    adjustedCapacityPool: (state) => {
      return state.adjustedCapacityPool;
    },
    namesLoading: (state) => {
      return state.namesLoading;
    },
    xContent: (state) => {
      return state.xContent;
    },
    shakespeare: (state) => {
      return state.shakespeare;
    },
    shakespeareLoc: (state) => {
      return state.shakespeareLoc;
    },
    backstageCopywriting: (state) => {
      return state.backstageCopywriting;
    },
    wordCountValidated: (state) => {
      const wordCountValidation = [];
      Object.values(state.staffAssignments).forEach((el) => {
        wordCountValidation.push(!!el.wordCount);
      });
      return wordCountValidation.every((value) => value === true);
    },
    prepopulationFlow: (state) => {
      return state.prepopulationFlow;
    },
  },
};
