<template>
  <div class="invoice-bot">
    <v-card>
      <div class="invoice-bot__button-container">
        <v-btn
          v-if="filteredInvoiceRows"
          color="primary"
          @click="handleCSVButton"
          >Create Importable CSV</v-btn
        >
        <v-btn color="secondary" @click="handleNavToEmails"
          >Client Emails</v-btn
        >
      </div>
      <ReportSelector :selected="selectedReport" @change="handleReportChange" />
      <PayPeriodSelector v-if="selectedReport === 'ANOTHER'" />
      <InvoiceReport />
    </v-card>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// internal
import { getInvoiceEmails } from "@/utils/dbUtils";
import { doCSVGenerate } from "@/utils/invoiceBotUtils";
import { ROUTES } from "@/utils/constants";
// components
import InvoiceReport from "@/components/InvoiceBot/InvoiceReport";
import PayPeriodSelector from "@/components/InvoiceBot/PayPeriodSelector";
import ReportSelector from "@/components/ui/ReportSelector";

export default {
  components: {
    InvoiceReport,
    PayPeriodSelector,
    ReportSelector,
  },
  methods: {
    ...mapActions("invoiceBot", ["setClientEmails", "setSelectedReport"]),
    handleMonthChange(v) {
      this.setPayPeriodMonth(v);
    },
    handleYearChange(v) {
      this.setPayPeriodYear(v);
    },
    handleNavToEmails() {
      this.$router.push(`/${ROUTES.clientEmails}`);
    },
    handleCSVButton() {
      doCSVGenerate({
        rows: this.filteredInvoiceRows,
        header: this.invoiceTableHeader,
        clientEmails: this.clientEmails,
      });
    },
    handleReportChange(v) {
      this.setSelectedReport(v);
    },
  },
  computed: {
    ...mapGetters("invoiceBot", [
      "clientEmails",
      "clientEmailsLoaded",
      "selectedReport",
      "filteredInvoiceRows",
      "invoiceTableHeader",
    ]),
  },
  mounted() {
    if (!this.clientEmailsLoaded) {
      getInvoiceEmails().then((emailData) => {
        this.setClientEmails(emailData);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.invoice-bot {
  max-width: 1400px;
  margin: 0 auto;

  &__button-container {
    box-sizing: border-box;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    padding: 10px;

    > * {
      margin-left: 10px;
    }
  }
}
</style>
