<template>
  <div>
    <v-btn v-if="stageIsComplete" class="mx-2" @click="handleClickIncomplete"
      >Set Stage Incomplete</v-btn
    >
    <v-btn
      v-if="!stageIsComplete"
      class="mx-2"
      color="secondary"
      @click="handleClick"
      >Set Stage Complete</v-btn
    >
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// library
// internal
import { handleAssignmentChangeFlowStep } from "@/utils/editorUtils";
// components
export default {
  name: "EditorCompleteStageButton",
  props: {
    assignment: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("editor", ["openAssignment", "flowStage"]),
    stageIsComplete() {
      if (this.assignment) {
        return this.assignment.flow[this.flowStage].isComplete;
      }
    },
    isLastStep() {
      return this.flowStage === this.assignment.flow.length - 1;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapActions("editor", [
      "setOpenAssignment",
      "setTableAssignmentsNeedsRefresh",
    ]),
    handleClick() {
      // this.assignment
      //   .setFlowStageCompletion(this.flowStage, true, this.isLastStep)
      handleAssignmentChangeFlowStep({
        assignment: this.assignment,
        flowStage: this.flowStage,
        isComplete: true,
      })
        .then(() => {
          this.setOpenAssignment(null);
          this.setTableAssignmentsNeedsRefresh(true);
        })
        .catch((err) => {
          console.warn(err);
          this.setOpenAssignment(null);
        });
    },
    handleClickIncomplete() {
      // this.assignment
      //   .setFlowStageCompletion(this.flowStage, false, this.isLastStep)
      handleAssignmentChangeFlowStep({
        assignment: this.assignment,
        flowStage: this.flowStage,
        isComplete: false,
      })
        .then(() => {
          console.log("setting refresh");
          this.setOpenAssignment(null);
          this.setTableAssignmentsNeedsRefresh(true);
        })
        .catch((err) => {
          console.warn(err);
          this.setOpenAssignment(null);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
