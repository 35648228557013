<template>
  <div class="flex-fill">
    <div class="top-container">
      <!-- buttons -->
      <div
        :style="{ width: 'fit-content' }"
        class="d-flex align-center"
        v-if="isAdmin"
      >
        <v-btn class="mr-2" color="secondary" @click="handleNewAssignment"
          >new assignment</v-btn
        >
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="elevation-1"
              outlined
              :disabled="!isAnySelected"
              color="accent"
              v-bind="attrs"
              v-on="on"
            >
              Export Selected
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="() => handleMultiRowExport(selected, 'TEXT')">
              <v-list-item-title>Plain Text</v-list-item-title>
            </v-list-item>
            <v-list-item @click="() => handleMultiRowExport(selected, 'EXCEL')">
              <v-list-item-title>Excel</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <!-- project selector -->
      <div>
        <EditorAssignmentsTableControls />
      </div>

      <!-- table search -->
      <div class="d-flex justify-end align-center">
        <!-- search -->
        <span class="overline" :style="{ opacity: 0, userSelect: 'none' }"
          >Search</span
        >
        <v-text-field
          solo
          dense
          hide-details
          append-icon="mdi-magnify"
          clearable
          v-model="filterSearch"
        />
      </div>
    </div>

    <v-card v-if="filteredAssignments" class="editor-assignments-table">
      <v-data-table
        :headers="tableHeaders"
        :items="filteredAssignments"
        :show-select="isAdmin"
        :items-per-page="25"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        group-by="sortDate"
        group-desc
        v-model="selected"
        @click:row="handleRowClick"
      >
        <template v-slot:[`item.assignmentDetails`]="{ item }">
          <div :style="{ 'max-width': '200px' }">
            {{ formatDetails(item.assignmentDetails) }}
          </div>
        </template>
        <template v-slot:[`item.isDelivered`]="{ item }">
          <div>
            <v-icon v-if="item.isDelivered">mdi-check</v-icon>
          </div>
        </template>
        <template v-slot:[`item.flow`]="{ item }">
          <EditorTableFlowChip
            v-for="(flowStep, i) in item.flow"
            :key="flowStep.index"
            :flowStep="flowStep"
            :assignment="item"
            :index="i"
          />
        </template>
        <template v-slot:[`item.actions`]="{ item }" v-if="isAdmin">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                class="subtitle-2"
                @click.stop="() => handleRowEdit(item.id)"
              >
                <v-icon>mdi-pencil</v-icon>
                <span class="ml-1">Edit</span>
              </v-list-item>
              <v-list-item
                class="subtitle-2"
                @click.stop="() => handleRowDelete(item)"
              >
                <v-icon>mdi-delete</v-icon>
                <span class="ml-1">Delete</span>
              </v-list-item>
              <v-list-item
                class="subtitle-2"
                @click.stop="() => handleRowExport(item)"
              >
                <v-icon>mdi-file-export</v-icon>
                <span class="ml-1">Export</span>
              </v-list-item>
              <v-list-item
                class="subtitle-2"
                @click.stop="() => handleMarkAsDelivered(item)"
              >
                <v-icon>mdi-check</v-icon>
                <span class="ml-1">Delivered</span>
              </v-list-item>
              <v-list-item
                class="subtitle-2"
                @click.stop="() => handleGoToQuickbase(item)"
              >
                <v-icon>mdi-database-export</v-icon>
                <span class="ml-1">Go to DB</span>
              </v-list-item>
              <!-- <v-list-item
                @click="() => handleMultiRowExport(selected, 'TEXT')"
              >
                <v-list-item-title>Plain Text</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="() => handleMultiRowExport(selected, 'EXCEL')"
              >
                <v-list-item-title>Excel</v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`group.header`]="{ group, headers }">
          <td class="pa-0 group-header-row" :colspan="headers.length">
            <h2 class="mx-4 font-weight-bold">
              {{ dueText }}: {{ formatTime(group) }}
            </h2>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// library
import _ from "lodash";
import Fuse from "fuse.js";
// internal
import {
  getAllPOs,
  getClientRequestersForProject,
  appIDs,
  realmID,
} from "@/utils/quickbaseUtils";
import {
  deleteAssignment,
  createSingleAssignmentText,
  createMultiAssignmentText,
  createExcel,
  saveHtmlAsPlaintext,
  handleAssignmentChangeDeliveryStatus,
  getSortField,
} from "@/utils/editorUtils";
// components
import EditorTableFlowChip from "./EditorTableFlowChip";
import EditorAssignmentsTableControls from "./EditorAssignmentsTableControls";

export default {
  name: "EditorAssignmentsTable",
  components: { EditorTableFlowChip, EditorAssignmentsTableControls },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
      assignmentsDatas: null,
      filterSearch: "",
    };
  },
  computed: {
    ...mapGetters("editor", [
      "selectedProject",
      "tableAssignments",
      "tableAssignmentsNeedsRefresh",
      "editAssignmentModalOpen",
    ]),
    ...mapGetters("auth", ["isAdmin", "user"]),
    isAnySelected() {
      return this.selected.length > 0;
    },
    tableHeaders() {
      if (this.isAdmin) {
        return [
          { text: "Assignment Name", value: "assignmentName" },
          { text: "Assignment Detail", value: "assignmentDetails" },
          { text: "Delivery Date", value: "deliveryDate" },
          { text: "Delivered", value: "isDelivered" },
          { text: "Flow", value: "flow" },
          { text: "", value: "actions" },
        ];
      } else {
        return [
          { text: "Assignment Name", value: "assignmentName" },
          { text: "Assignment Detail", value: "assignmentDetails" },
          { text: "Delivery Date", value: "deliveryDate" },
          { text: "Flow", value: "flow" },
        ];
      }
    },
    dueText() {
      return this.isAdmin ? "Delivery Date" : "Due";
    },
    filteredAssignments() {
      if (!this.tableAssignments) return [];

      if (this.filterSearch === "") return this.tableAssignments;

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: this.tableHeaders.map((el) => el.value),
      };

      const fuse = new Fuse(this.tableAssignments, searchOptions);
      const results = fuse.search(this.filterSearch).map((el) => el.item);

      return results;
    },
  },
  watch: {
    tableAssignmentsNeedsRefresh(newValue) {
      if (newValue === true) {
        this.loadAssignmentsData(this.selectedProject);
        this.setTableAssignmentsNeedsRefresh(false);
      }
    },
    selectedProject(newValue) {
      this.loadAssignmentsData(newValue);
    },
  },
  mounted() {
    this.loadAssignmentsData(this.projectData);
  },
  methods: {
    ...mapActions("editor", [
      "setOpenAssignment",
      "setNewAssignmentModalOpen",
      "setEditAssignmentModalOpen",
      "setEditAssignmentID",
      "setTableAssignments",
      "setProjectPOs",
      "setProjectRequesters",
      "setTableAssignmentsNeedsRefresh",
    ]),
    formatTime(t) {
      return this.$moment(t).format("dddd, MMM D");
    },
    formatDetails(d) {
      return _.truncate(d, {
        length: 55,
        separator: " ",
      });
    },
    doDeleteAssignment(assignment) {
      deleteAssignment({
        qbID: assignment.qbID,
        firebaseID: assignment.id,
        project: this.projectData,
      })
        .then(() => {
          return this.projectData.getAssignmentDatas({
            isAdmin: this.isAdmin,
            userEmail: this.user.email,
          });
        })
        .then((d) => {
          this.setTableAssignments(d);
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    handleRowClick(row) {
      this.setOpenAssignment(row.id);

      this.$router.push({
        path: "editor",
        query: {
          pid: this.projectData.projectID,
          aid: row.id,
        },
      });
    },
    handleNewAssignment() {
      this.setNewAssignmentModalOpen(true);
    },
    handleRowDelete(id) {
      // TODO - make a confirmation dialog
      this.doDeleteAssignment(id);
    },
    handleRowEdit(id) {
      // TODO - make an edit dialog
      this.setEditAssignmentID(id);
      this.setEditAssignmentModalOpen(true);
    },
    handleRowExport(assignment) {
      createSingleAssignmentText(assignment).then((text) => {
        saveHtmlAsPlaintext({
          string: text,
          filename: assignment.exportHeader,
        });
      });
    },
    handleMultiRowExport(assignments, format) {
      if (format === "EXCEL") {
        createExcel(assignments);
      } else if (format === "TEXT") {
        createMultiAssignmentText(assignments).then((text) => {
          saveHtmlAsPlaintext({
            string: text,
            filename: "multi",
          });
        });
      }
    },
    handleMarkAsDelivered(assignment) {
      handleAssignmentChangeDeliveryStatus({
        assignment,
        deliveryStatus: !assignment.isDelivered,
      })
        .then(() => {
          this.setTableAssignmentsNeedsRefresh(true);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleGoToQuickbase(assignment) {
      window.open(
        `https://${realmID}/db/${appIDs.cueProjectManager.assignments}?a=dr&rid=${assignment.qbID}&rl=rqd`,
        "_blank"
      );
    },
    loadAssignmentsData(project) {
      project
        .getAssignmentDatas({
          userEmail: this.user.email,
          isAdmin: this.isAdmin,
          // userEmail: "cammila@gmail.com",
          // isAdmin: false,
        })
        .then((d) => {
          d.forEach((el) => {
            el.sortDate = getSortField({
              row: el,
              isAdmin: this.isAdmin,
              userEmail: this.user.email,
            });
          });
          this.setTableAssignments(d);
          return getAllPOs({ project: project.projectName });
        })
        .then((poData) => {
          const active = poData
            .filter((el) => el["Is Active"])
            .map((el) => {
              return {
                text: el["Purchase order Number"],
                value: el["Record ID#"],
              };
            });
          this.setProjectPOs(active);
          return getClientRequestersForProject({
            project: project.projectName,
          });
        })
        .then((clientRequesterData) => {
          this.setProjectRequesters(clientRequesterData);
        })
        .catch((err) => {
          console.warn(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.top-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.editor-assignments-table {
  .group-header-row {
    background-color: #9af9ef70 !important;
    color: #003a35 !important;
  }

  th {
    display: none;
  }
}
::v-deep {
  .v-data-table-header__icon {
    display: none;
  }
}
</style>
