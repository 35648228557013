<template>
  <v-container class="pb-6">
    <v-row class="text-left">
      <!-- left column -->
      <v-col cols="12" sm="6" class="pa-0">
        <div class="overline">{{ assignmentInfo["AssignmentTitle"] }}</div>
        <h2>{{ assignmentInfo["AssignmentDetail"] }}</h2>
        <v-chip class="my-5">
          {{ assignmentInfo["GEO"] }}
        </v-chip>
        <div>
          <!-- related project -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Project</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["ProjectName"]
            }}</v-col>
          </v-row>

          <!-- related purchase order -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Purchase Order</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["PurchaseOrder-PONumber"]
            }}</v-col>
          </v-row>

          <!-- pay period -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Pay Period</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["PayPeriod"]
            }}</v-col>
          </v-row>

          <!-- delivery date -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Delivery Date</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              formatDate(assignmentInfo["DeliveryDate"])
            }}</v-col>
          </v-row>

          <!-- related client requester -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Client Requester</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["RelatedClientRequester"]
            }}</v-col>
          </v-row>

          <!-- request type -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Request Type</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["RequestType"]
            }}</v-col>
          </v-row>

          <!-- media type -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Media Type</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["MediaType"]
            }}</v-col>
          </v-row>

          <!-- keyword -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Keyword</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["Keyword"]
            }}</v-col>
          </v-row>

          <!-- word count -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Word Count</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["WordCount"]
            }}</v-col>
          </v-row>

          <!-- comments -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Comments</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["Note"]
            }}</v-col>
          </v-row>

          <!-- created -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Created</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              `${formatDate(assignmentInfo["DateCreated"], true)} by ${
                assignmentInfo["CreatedBy"]
              }`
            }}</v-col>
          </v-row>

          <!-- last updated -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Last Updated</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["LastUpdated"]
            }}</v-col>
          </v-row>
        </div>
      </v-col>
      <!-- right column -->
      <v-col cols="12" sm="6">
        <div class="table-bg text-center">
          <h3 class="pb-2">Assignment Steps</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Person</th>
                  <th class="text-center">Spelling/Typos</th>
                  <th class="text-center">Grammar/Syntax</th>
                  <th class="text-center">Factual Error</th>
                  <th class="text-center">Style/ToV</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ assignmentInfo["Writer"] }}</td>
                  <td>
                    <v-icon v-if="assignmentInfo['Spelling/Typos']"
                      >mdi-check</v-icon
                    >
                  </td>
                  <td>
                    <v-icon v-if="assignmentInfo['Grammar/Syntax']"
                      >mdi-check</v-icon
                    >
                  </td>
                  <td>
                    <v-icon v-if="assignmentInfo['FactualError']"
                      >mdi-check</v-icon
                    >
                  </td>
                  <td>
                    <v-icon v-if="assignmentInfo['Style/ToV']"
                      >mdi-check</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div class="mt-6 table-bg text-center">
          <h3 class="pb-2">Assignment Work Types</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Work Type</th>
                  <th class="text-center">Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ assignmentInfo["WorkType1"] }}</td>
                  <td>{{ assignmentInfo["WorkType1Quantity"] }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
    <!-- bottom controls -->
    <v-row v-if="isAdmin || isManager" class="d-flex justify-end px-3">
      <v-btn class="accent" @click="handleOpenInQB(assignmentInfo['RecordID#'])"
        >open in quickbase</v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// internal
import { appIDs, realmID } from "@/utils/quickbaseUtils";

export default {
  name: "LEDTableExpansion",
  props: {
    assignmentInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["isAdmin", "isManager"]),
  },
  methods: {
    formatDate(date, withTime) {
      return `${this.$moment(
        withTime
          ? new Date(date).toLocaleString("en-US", {
              timeZone: "America/New_York",
            })
          : date
      ).format(`MM/DD/YY${withTime ? " hh:mm A" : ""}`)}`;
    },
    handleOpenInQB(rid) {
      const url = `https://${realmID}/db/${appIDs.iTunesProjectManager.assignments}?a=dr&rid=${rid}`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.table-bg {
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
</style>
