<template>
  <div class="login">
    <v-card class="login__card">
      <v-form @submit="handleSubmit">
        <v-card-title class="login__card__title">Login</v-card-title>
        <div v-if="loginFailed" class="login__card__login-failed">
          login failed, try again.
        </div>
        <div class="input-label">user email</div>
        <v-text-field
          class="login__card__input"
          v-model="userValue"
          outlined
        ></v-text-field>
        <div class="input-label">password</div>
        <v-text-field
          class="login__card__input"
          type="password"
          v-model="passValue"
          outlined
        ></v-text-field>
        <v-btn type="submit" color="secondary">submit</v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { doLogin } from "@/utils/authUtils";
export default {
  data() {
    return {
      userValue: "",
      passValue: "",
    };
  },
  methods: {
    ...mapActions("auth", ["setUser", "setLoginFailed"]),
    handleSubmit(e) {
      // console.log(e);
      e.preventDefault();
      doLogin(this.userValue, this.passValue)
        .then((u) => {
          // this.setUser(u);
          const toRoute = this.$route.query.to || "/";
          this.$router.push(toRoute);
        })
        .catch(() => {
          this.setLoginFailed(true);
        });
    },
  },
  computed: {
    ...mapGetters("auth", ["loginFailed"]),
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__card {
    padding: 30px;

    .input-label {
      font-size: 12px;
    }

    &__login-failed {
      color: red;
    }

    &__title {
      padding: 0px;
    }

    &__input {
      width: 300px;
    }
  }
}
</style>
