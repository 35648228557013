<template>
  <div>
    <v-progress-circular v-if="isUpdating" indeterminate :size="24" />
    <v-checkbox
      v-if="!isUpdating"
      class="pl-4"
      :input-value="value"
      @change="handleChange($event)"
    />
    <v-alert v-if="isError" type="error" class="alert" dismissible>
      Error updating status. Please try again.
    </v-alert>
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// internal
import { updateAccepted } from "@/utils/quickbaseUtils";

export default {
  name: "CPMDashboardAcceptedSelector",
  props: {
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isUpdating: false,
      isError: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    role() {
      if (this.item["Person-Primary"] === this.user.Name) {
        return "PRIMARY";
      } else if (this.item["Person-Reviewer"] === this.user.Name) {
        return "REVIEWER";
      }
    },
  },
  methods: {
    handleChange(value) {
      this.isUpdating = true;
      updateAccepted({
        recordID: this.item["RecordID#"],
        value,
        role: this.role,
        cpmMode: true,
      })
        .then(() => {
          this.$emit(
            "refresh-table-expansion",
            this.item.index,
            value,
            this.role
          );
          this.isUpdating = false;
        })
        .catch((err) => {
          this.isUpdating = false;
          this.isError = true;
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100;
}
.v-progress-circular {
  margin: 21px 0;
}
</style>
