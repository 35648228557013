<template>
  <v-container class="project-selection-list">
    <v-select
      solo
      dense
      hide-details
      :items="allProjects"
      item-text="projectName"
      :value="selectedProject.projectName"
      @change="(v) => handleChange(v)"
      class="subtitle-2"
    >
      <template v-slot:append-item>
        <div
          class="add-new-project overline grey--text lighten-1"
          v-if="isAdmin"
          @click="handleNewProjectClick"
        >
          <v-icon color="grey lighten-1">mdi-plus</v-icon>
          add new project
        </div>
      </template>
      <template v-slot:item="{ item }">
        <div>
          <span class="subtitle-2">
            {{ item.projectName }}
          </span>
          <v-icon
            small
            v-if="isAdmin"
            class="edit-project-icon pl-2"
            color="grey lighten-1"
            @click.stop="() => handleProjectEditClick(item)"
            >mdi-pencil</v-icon
          >
        </div>
      </template>
    </v-select>
  </v-container>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import { ROUTES } from "@/utils/constants";

export default {
  name: "EditorProjectSelectionList",
  data() {
    return {
      selectedProjectIndex: 0,
    };
  },
  computed: {
    ...mapGetters("editor", [
      "allProjects",
      "selectedProject",
      "projectModalOpen",
    ]),
    ...mapGetters("auth", ["isAdmin"]),
  },
  methods: {
    ...mapActions("editor", [
      "setSelectedProject",
      "setProjectModalOpen",
      "setEditProjectData",
    ]),
    handleChange(projectName, i) {
      // const project = this.allProjects.find(
      //   (el) => el.projectName === projectName
      // );
      const projectIndex = this.allProjects.findIndex(
        (el) => el.projectName === projectName
      );
      const project = this.allProjects[projectIndex];

      this.setSelectedProject(project);
      // add project id to the url
      this.$router.push({
        path: `/${ROUTES.editor}`,
        query: { pid: project.projectID },
      });
      this.selectedProjectIndex = projectIndex;
    },
    handleNewProjectClick() {
      this.setProjectModalOpen(true);
    },
    handleProjectEditClick(project) {
      this.setEditProjectData({ ...project });
      this.setProjectModalOpen(true);
    },
    isSelectedProject(id) {
      if (this.selectedProject && this.selectedProject.projectID === id) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/colors.scss";
.project-selection-list {
  width: 260px;

  &__item {
    cursor: pointer;

    &.is-selected {
      color: $accentBlue !important;
      font-weight: 700;
    }

    &.on-hover {
      color: $accentBlue;
    }
  }

  .add-new-project {
    cursor: pointer;
  }
}
</style>
