<template>
  <v-slide-x-transition>
    <div v-if="userEmail" class="text-center">
      <v-btn
        v-if="isAdmin || isManager"
        icon
        large
        class="mr-2"
        @click="slackFormModal = true"
        ><v-icon>mdi-lifebuoy</v-icon></v-btn
      >
      <v-snackbar
        v-model="confirmationMsg"
        absolute
        centered
        min-width="unset"
        timeout="2000"
        :color="success ? 'success' : 'error'"
        rounded="pill"
        content-class="text-center"
      >
        <v-icon color="white" class="mr-1">{{
          success ? "mdi-check-circle-outline" : "mdi-close-circle-outline"
        }}</v-icon>
        {{ confirmationMsgTxt }}
      </v-snackbar>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small :loading="userRefresh" v-bind="attrs" v-on="on">
            {{ userEmail }}
            <v-icon id="down-icon">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @click="handleItemSelect(item.value)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-overlay id="reset-overlay" v-model="dialog">
        <v-card class="reset-overlay-card">
          <v-card-text @click.stop>
            A password reset email will be sent to {{ userEmail }}.
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn class="ma-1" color="white" plain @click.stop="dialog = false"
              >Cancel</v-btn
            >
            <v-btn
              class="ma-1"
              color="secondary"
              plain
              @click.stop="handleResetSend"
              >Send</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-overlay>
      <v-dialog
        max-width="1200"
        v-model="slackFormModal"
        @click:outside="slackFormModal = false"
        @keydown.esc="slackFormModal = false"
      >
        <SlackInquiryForm
          :inquiryItem="{}"
          :mode="'bug'"
          @close-slack-form="confirmFeedback"
        />
      </v-dialog>
      <v-dialog max-width="1200" v-model="profileModal" persistent>
        <ContactForm
          :tableFields="profileFields"
          :itemToEdit="user"
          :profileView="true"
          @close-form="handleProfileModal(false)"
          @refresh-data="handleUserRefresh"
        />
      </v-dialog>
    </div>
  </v-slide-x-transition>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// internal
import { doLogout } from "@/utils/authUtils";
import { auth } from "@/providers/firebase";
import { DOMAIN } from "@/utils/constants";
import { getEmployeesData } from "@/utils/quickbaseUtils";
// components
import SlackInquiryForm from "./SlackInquiryForm.vue";
import ContactForm from "@/components/Contacts/ContactForm";

export default {
  name: "NavBarUser",
  components: {
    SlackInquiryForm,
    ContactForm,
  },
  data() {
    return {
      dialog: false,
      userRefresh: false,
      slackFormModal: false,
      success: true,
      confirmationMsg: false,
      confirmationMsgTxt: "",
    };
  },
  methods: {
    ...mapActions("auth", [
      "setUser",
      "handleProfileModal",
      "setIsAdmin",
      "setIsManager",
      "setCompany",
      "setIsDubEditor",
    ]),
    handleItemSelect(value) {
      if (value === "logout") doLogout().then(() => this.$router.push("login"));
      else if (value === "changePW")
        this.dialog = true; // bring reset password modal
      else if (value === "profile") this.handleProfileModal(true); // reset form with "new" item & bring profile modal
    },
    handleResetSend() {
      auth.sendPasswordResetEmail(this.userEmail, {
        url: `https://${DOMAIN}`,
      });
      this.dialog = false;
    },
    confirmFeedback() {
      this.slackFormModal = false;
      this.confirmationMsgTxt = "Feedback sent!";
      this.confirmationMsg = this.success = true;
    },
    handleUserRefresh(msg) {
      this.handleProfileModal(false);
      this.userRefresh = true;
      this.success = !!msg;
      this.confirmationMsgTxt = msg
        ? "Profile updated!"
        : "Something went wrong. Please try again.";
      if (msg) {
        auth.currentUser.getIdTokenResult().then((idTokenResult) => {
          const newAdmin = idTokenResult?.claims?.admin || false;
          const newCompany = idTokenResult?.claims?.company || "";
          const newIsDubEditor = idTokenResult?.claims?.dubEditor || false;

          this.setIsAdmin(newAdmin);
          this.setIsDubEditor(newIsDubEditor);
          this.setCompany(newCompany);

          getEmployeesData({ email: idTokenResult?.claims?.email })
            .then((resp) => {
              this.setUser({
                ...resp.data[0],
                email: idTokenResult?.claims?.email,
                admin: newAdmin,
                dubEditor: newIsDubEditor,
                company: newCompany,
                cueHub: true,
              });
              this.setIsManager(resp.data[0].ProjectCoordinator);
              this.userRefresh = false;
              this.confirmationMsg = true;
            })
            .catch((err) => console.error(err));
        });
      } else {
        this.userRefresh = false;
        this.confirmationMsg = true;
      }
    },
  },
  computed: {
    ...mapGetters("auth", [
      "user",
      "profileFields",
      "profileModal",
      "isAdmin",
      "isManager",
    ]),
    userEmail() {
      return this.user?.email;
    },
    items() {
      let retVal = [{ title: "Logout", value: "logout" }];
      if (this.isAdmin || this.isManager)
        retVal.unshift(
          { title: "Profile", value: "profile" },
          { title: "Reset Password", value: "changePW" }
        );
      return retVal;
    },
  },
};
</script>

<style lang="scss" scoped>
#reset-overlay {
  position: fixed;
  height: 100vh;

  .reset-overlay-card {
    width: 80%;
  }
}

::v-deep {
  .v-btn__content {
    line-height: 1;
  }
}

#down-icon {
  margin-top: 1px;
}
</style>
