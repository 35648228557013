<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="v-card v-sheet theme--light pa-4"
  >
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-text-field
            outlined
            label="Service Name"
            v-model="ServiceName"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Service Type"
            :items="types"
            v-model="ServiceType"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-text-field outlined label="Plan Name" v-model="PlanName" />
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Plan Type"
            :items="plans"
            v-model="PlanType"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" class="pb-0">
          <v-text-field
            outlined
            type="number"
            label="User Amount (Max)"
            v-model="userAmountMax"
          />
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-text-field
            outlined
            type="number"
            label="User Amount (Current)"
            v-model="userAmountCurrent"
          />
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-text-field
            outlined
            type="number"
            label="Device Amount (Max)"
            v-model="deviceAmountMax"
          />
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-text-field
            outlined
            type="number"
            label="Device Amount (Current)"
            v-model="deviceAmountCurrent"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" class="pb-0">
          <v-autocomplete
            outlined
            label="Subscription Type"
            :items="subscriptions"
            v-model="SubscriptionType"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-text-field
            outlined
            type="number"
            label="Subscription Amount (Monthly)"
            prepend-inner-icon="mdi-currency-usd"
            v-model="subscriptionAmountMonthly"
          />
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-menu
            v-model="subscriptionStartDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="SubscriptionStartDate"
                label="Subscription Start Date"
                prepend-icon="mdi-calendar-check"
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="subscriptionStartDateMenu = false"
              v-model="SubscriptionStartDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-menu
            v-model="subscriptionEndDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="SubscriptionEndDate"
                label="Subscription End Date"
                prepend-icon="mdi-calendar-remove"
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="subscriptionEndDateMenu = false"
              v-model="SubscriptionEndDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-text-field
            outlined
            type="email"
            label="Account Owner"
            v-model="AccountOwner"
          />
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-file-input
            outlined
            label="User Agreement"
            accept=".png, .jpg, .jpeg"
            truncate-length="50"
            v-model="UserAgreementBlob"
            :loading="UserAgreementLoading"
            :rules="[rules.isImage]"
            @change="convertFile($event, 'UserAgreement')"
            @click:clear="filesToDelete.push('UserAgreement')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-autocomplete
            outlined
            multiple
            chips
            deletable-chips
            label="Users"
            :items="employees"
            v-model="users"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="justify-end py-0">
      <v-scale-transition>
        <div v-if="deleteConfirmation" class="d-flex align-center">
          <h3>Are you sure?</h3>
          <v-btn class="ml-2" color="success" @click="deleteSoftware">
            Yes
          </v-btn>
          <v-btn class="ml-2" color="error" @click="deleteConfirmation = false">
            No
          </v-btn>
        </div>
      </v-scale-transition>
      <v-btn
        v-if="isEditMode"
        min-width="unset"
        class="ml-2"
        color="secondary"
        :loading="softwareHandling"
        :disabled="deleteConfirmation"
        @click="deleteConfirmation = true"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        color="secondary"
        :loading="softwareHandling"
        :disabled="deleteConfirmation"
        @click="handleSoftware"
      >
        {{ isEditMode ? "Update" : "Save" }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
// internal
import {
  softwareFieldsLookup,
  appIDs,
  getEmployeesData,
  getFile,
  processProjectToolsRow,
  deleteRowFromQB,
  deleteFile,
} from "@/utils/quickbaseUtils";

export default {
  name: "CueSoftwareForm",
  props: {
    itemToEdit: {
      type: Object,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
    plans: {
      type: Array,
      required: true,
    },
    subscriptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      ["RecordID#"]: null,
      ServiceName: "",
      ServiceType: "",
      PlanName: "",
      PlanType: "",
      ["UserAmount(Max)"]: "",
      ["UserAmount(Current)"]: "",
      ["DeviceAmount(Max)"]: "",
      ["DeviceAmount(Current)"]: "",
      SubscriptionType: "",
      ["SubscriptionAmount(Monthly)"]: "",
      subscriptionStartDateMenu: false,
      SubscriptionStartDate: "",
      subscriptionEndDateMenu: false,
      SubscriptionEndDate: "",
      AccountOwner: "",
      UserAgreement: null,
      UserAgreementBlob: null,
      UserAgreementLoading: false,
      employeesLoading: true,
      employees: [],
      ["Users(non-relational)"]: [],
      filesToDelete: [],
      valid: true,
      softwareHandling: false,
      deleteConfirmation: false,
      rules: {
        required: (value) => !!value || value === 0 || "Required",
        isImage: (value) =>
          value?.type == "image/jpeg" ||
          value?.type == "image/jpg" ||
          value?.type == "image/png" ||
          value === null ||
          "Only JPEG (JPG) or PNG images are allowed.",
      },
    };
  },
  computed: {
    isEditMode() {
      return !!Object.keys(this.itemToEdit).length;
    },
    userAmountMax: {
      get() {
        return this[`${["UserAmount(Max)"]}`];
      },
      set(newVal) {
        this[`${["UserAmount(Max)"]}`] = newVal;
      },
    },
    userAmountCurrent: {
      get() {
        return this[`${["UserAmount(Current)"]}`];
      },
      set(newVal) {
        this[`${["UserAmount(Current)"]}`] = newVal;
      },
    },
    deviceAmountMax: {
      get() {
        return this[`${["DeviceAmount(Max)"]}`];
      },
      set(newVal) {
        this[`${["DeviceAmount(Max)"]}`] = newVal;
      },
    },
    deviceAmountCurrent: {
      get() {
        return this[`${["DeviceAmount(Current)"]}`];
      },
      set(newVal) {
        this[`${["DeviceAmount(Current)"]}`] = newVal;
      },
    },
    subscriptionAmountMonthly: {
      get() {
        return this[`${["SubscriptionAmount(Monthly)"]}`];
      },
      set(newVal) {
        this[`${["SubscriptionAmount(Monthly)"]}`] = newVal;
      },
    },
    users: {
      get() {
        return this[`${["Users(non-relational)"]}`];
      },
      set(newVal) {
        this[`${["Users(non-relational)"]}`] = newVal;
      },
    },
  },
  watch: {
    itemToEdit() {
      this.dataViewsHandler();
    },
  },
  mounted() {
    this.getEmployees();
    this.dataViewsHandler();
  },
  methods: {
    dataViewsHandler() {
      this.deleteConfirmation = false;
      if (this.isEditMode) {
        this.handleEditMode();
      } else {
        this.$refs.form.resetValidation();
        Object.keys(softwareFieldsLookup).forEach((el) => {
          this[`${el}`] = softwareFieldsLookup[el].default;
          if (this.$data.hasOwnProperty(`${el}Blob`))
            this[`${el}Blob`] = softwareFieldsLookup[el].default;
        });
      }
    },
    getEmployees() {
      getEmployeesData({ namesOnly: true })
        .then((names) => {
          this.employees = names;
          this.employeesLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.employeesLoading = false;
        });
    },
    handleEditMode() {
      // loop through fields
      Object.entries(this.itemToEdit).forEach((el) => {
        if (el[1]?.hasOwnProperty("url")) {
          if (el[1].url) {
            this[`${el[0]}Blob`] = null;
            this[`${el[0]}Loading`] = true;
            // get file
            this.handleFile(el[1].url, el[0]);
          } else {
            this[`${el[0]}`] = this[`${el[0]}Blob`] = null;
            this[`${el[0]}Loading`] = false;
          }
        } else {
          this[`${el[0]}`] = el[1];
        }
      });
    },
    handleFile(url, fileName) {
      return new Promise((resolve, reject) => {
        getFile(url)
          .then(async (resp) => {
            const base64Response = await fetch(
              `data:image/jpeg;base64,${resp.data}`
            );
            const blob = await base64Response.blob();
            let newBlob = new Blob([blob], {
              type: "image/jpeg",
            });
            newBlob.name = `${this.ServiceName}_${fileName}.jpeg`;
            this[`${fileName}Loading`] = false;
            resolve((this[`${fileName}Blob`] = newBlob));
          })
          .catch((err) => reject(err));
      });
    },
    convertFile(file, field) {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this[`${field}`] = {
            fileName: `${this.ServiceName}_${field}.${file.type.split("/")[1]}`,
            data: reader.result.split(",")[1],
          };
        };
        reader.readAsDataURL(file);
      } else {
        this[`${field}`] = null;
      }
    },
    handleSoftware() {
      if (this.$refs.form.validate()) {
        this.softwareHandling = true;
        let data = {},
          filesDeletionPromises = [];
        Object.keys(softwareFieldsLookup).forEach((el) => {
          Object.assign(data, {
            [el]: this[`${el}`],
          });
        });
        this.filesToDelete.forEach((file) => {
          if (this.itemToEdit[`${file}`]?.url && !this[`${file}Blob`])
            filesDeletionPromises.push(
              new Promise((resolve, reject) => {
                deleteFile(this.itemToEdit[`${file}`]?.url)
                  .then((resp) => resolve(resp))
                  .catch((err) => reject(err));
              })
            );
        });
        Promise.all(filesDeletionPromises)
          .then(() => {
            processProjectToolsRow({
              tableID: appIDs.cueProjectTools.software,
              fieldsLookup: softwareFieldsLookup,
              data,
            }).then((resp) => {
              let msg = null;
              if (resp.metadata.createdRecordIds.length) {
                msg = "Record created!";
              } else if (
                resp.metadata.updatedRecordIds.length ||
                resp.metadata.unchangedRecordIds.length
              ) {
                msg = "Record updated!";
              }
              this.softwareHandling = false;
              this.$emit("software-table-refresh", msg);
            });
          })
          .catch((err) => {
            console.error(err);
            this.softwareHandling = false;
          });
      }
    },
    deleteSoftware() {
      this.deleteConfirmation = false;
      this.softwareHandling = true;
      deleteRowFromQB({
        id: this["RecordID#"],
        tableID: appIDs.cueProjectTools.software,
      }).then((resp) => {
        const msg = resp.numberDeleted ? "Record deleted!" : null;
        this.softwareHandling = false;
        this.$emit("software-table-refresh", msg);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
