<template>
  <div class="stage-selector">
    <v-chip
      large
      v-for="(stage, i) in flow"
      :key="`stage-${i}-button`"
      class="stage-selector__button pa-3 elevation-0"
      label
      :outlined="isActiveButton(i) ? false : true"
      :color="getButtonColor(i)"
      @click="() => handleFlowButtonClick(i)"
    >
      <div class="d-flex flex-column">
        <div class="font-weight-bold">
          {{ stage.label }}
        </div>
        <div class="font-weight-light">
          {{ stage.person.name }}
        </div>
      </div>

      <!--  -->
    </v-chip>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// library
// internal
// components
export default {
  name: "EditorFlowStageSelector",
  props: {
    flow: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("editor", ["flowStage"]),
    ...mapGetters("auth", ["isAdmin", "user"]),
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapActions("editor", ["setFlowStage"]),
    isActiveButton(index) {
      return index === this.flowStage;
    },
    handleFlowButtonClick(index) {
      this.setFlowStage(index);
    },
    getButtonColor(i) {
      if (this.isAdmin) return "accent";

      if (this.user.email === this.flow[i].person.email) {
        return "secondary";
      } else {
        return "accent";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stage-selector {
  flex: 1 1 auto;
  display: flex;

  &__button {
    flex: 1 1 auto;
    margin: 0px 10px;
    width: fit-content !important;
  }
}
</style>
