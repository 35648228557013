<template>
  <div class="fc-dashboard my-10 px-2">
    <div
      v-if="user.email === 'marc@companycue.com'"
      class="d-flex"
      :style="{ width: '450px' }"
    >
      <v-text-field
        solo
        dense
        hide-details
        class="mr-4"
        placeholder="view as (dev only)"
        v-model="viewAsOverride"
        clearable
      />
      <v-btn color="primary" @click="getTableData">apply</v-btn>
    </div>
    <div class="d-flex justify-space-between" :style="{ position: 'relative' }">
      <!-- left -->

      <div class="d-flex">
        <!-- new assignment -->
        <div v-if="isAdmin || isManager" class="text-center">
          <span class="overline"> New </span>
          <div class="mb-4">
            <v-btn
              :min-width="laptop ? '40px' : '42px'"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              @click="setNewFCAssignmentModalOpen(true)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- clear filters (admin) -->
        <div v-if="isAdmin || isManager" class="text-center ml-lg-2 ml-md-1">
          <span class="overline"> Clear </span>
          <div class="mb-4">
            <v-btn
              :min-width="laptop ? '40px' : '42px'"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              @click="clearFilter(false)"
            >
              <v-icon>mdi-filter-remove</v-icon>
            </v-btn>
          </div>
        </div>

        <v-dialog
          max-width="1200"
          :value="newFCAssignmentModalOpen"
          @click:outside="setNewFCAssignmentModalOpen(false)"
          @keydown.esc="setNewFCAssignmentModalOpen(false)"
        >
          <FCDashboardNewAssignmentModal
            ref="newAssignmentModal"
            @fc-dashboard-table-refresh="getTableData()"
          />
        </v-dialog>

        <!-- pay period filter -->
        <div :class="{ 'ml-lg-2 ml-md-1': isAdmin || isManager }">
          <span class="overline"> Pay Period </span>
          <div class="d-flex mb-4">
            <v-autocomplete
              class="fc-dashboard__month-selector selector mr-1"
              :items="monthOptions"
              v-model="selectedMonth"
              solo
              dense
              hide-details
              @change="handleSelectedPeriod($event, 'ppm')"
            ></v-autocomplete>
            <v-autocomplete
              class="fc-dashboard__year-selector selector"
              :items="yearOptions"
              v-model="selectedYear"
              solo
              dense
              hide-details
              @change="handleSelectedPeriod($event, 'ppy')"
            >
            </v-autocomplete>
          </div>
        </div>

        <!-- request date filter -->
        <div class="ml-lg-2 ml-md-1 text-center">
          <span class="overline"> Request Date </span>
          <div class="d-flex align-end mb-4">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              solo-inverted
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filterRequestDate"
                  :class="{ laptop }"
                  class="fc-dashboard__date-selector selector"
                  solo
                  dense
                  readonly
                  hide-details
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearFilter('RequestDate')"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterRequestDate"
                @input="handleFilterQuery('RequestDate', $event)"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>

        <!-- delivery date filter -->
        <div class="ml-lg-2 ml-md-1 text-center">
          <span class="overline">
            {{ isAdminMode ? "Delivery Date" : "Due Date" }}
          </span>
          <div class="d-flex align-end mb-4">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              solo-inverted
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filterDeliveryDate"
                  :class="{ laptop }"
                  class="fc-dashboard__date-selector selector"
                  solo
                  dense
                  readonly
                  hide-details
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearFilter('DeliveryDate')"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterDeliveryDate"
                @input="handleFilterQuery('DeliveryDate', $event)"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>

        <!-- project filter -->
        <div class="ml-lg-2 ml-md-1 text-center">
          <span class="overline"> Project </span>
          <div>
            <v-btn-toggle
              class="elevation-2"
              color="accent"
              multiple
              dense
              v-model="filterProject"
              @change="handleFilterQuery('Project', $event)"
            >
              <v-btn min-width="38px" height="38px" class="px-0">FC</v-btn>
              <v-btn min-width="38px" height="38px" class="px-0"
                ><v-icon
                  :color="filterProject.includes(1) ? 'accent' : '#000000de'"
                  >mdi-music-note</v-icon
                ></v-btn
              >
            </v-btn-toggle>
          </div>
        </div>

        <!-- Shakespeare filter -->
        <div class="ml-lg-2 ml-md-1 d-flex align-end">
          <div class="d-flex mb-4">
            <v-btn-toggle
              class="elevation-2"
              color="accent"
              dense
              v-model="filterShakespeare"
              @change="handleFilterQuery('Shakespeare', $event)"
            >
              <v-btn min-width="42px" height="38px" class="px-0">
                <v-img
                  :src="SHK"
                  width="40px"
                  height="36px"
                  alt="Shakespeare"
                />
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>

        <!-- GEO filter -->
        <transition>
          <div
            v-if="
              ((!selectedDashboardRows.length && laptopSmaller) ||
                !laptopSmaller) &&
              isAdminMode
            "
            v-show="(!expandMenu && laptopSmaller) || !laptopSmaller"
            class="ml-lg-2 ml-md-1 text-center"
          >
            <span class="overline"> GEO </span>
            <div class="mb-4">
              <v-autocomplete
                class="fc-dashboard__geo-selector selector"
                :items="geoOptions"
                v-model="filterGeo"
                solo
                dense
                hide-details
                :loading="dropdownsLoading"
                @input="handleFilterQuery('Geo', $event)"
              ></v-autocomplete>
            </div>
          </div>
        </transition>

        <!-- person filter -->
        <transition>
          <div
            v-if="
              ((!selectedDashboardRows.length && laptop) || !laptop) &&
              (isAdmin || isManager ? earningsMode !== 0 : true)
            "
            v-show="
              (laptop && !expandMenu) ||
              (isAdminMode ? !expandMenu : true && !laptop)
            "
            class="ml-lg-2 ml-md-1 text-center"
          >
            <span class="overline">
              {{ "Role".padStart(isAdmin || isManager ? 13 : 4, "Person / ") }}
            </span>
            <div class="d-flex mb-4">
              <v-autocomplete
                v-if="isAdmin || isManager"
                :class="{ laptop }"
                class="fc-dashboard__person-selector selector mr-2"
                :items="employees"
                v-model="filterPerson"
                solo
                dense
                hide-details
                :loading="dropdownsLoading"
                @input="handleFilterQuery('Person', $event)"
              ></v-autocomplete>
              <v-autocomplete
                class="fc-dashboard__role-selector selector"
                :items="roles"
                v-model="filterRole"
                solo
                dense
                hide-details
                :disabled="isAdmin || isManager ? !filterPerson : false"
                @input="handleFilterQuery('Role', $event)"
              >
              </v-autocomplete>
            </div>
          </div>
        </transition>

        <!-- status filter -->
        <transition>
          <div
            v-if="
              !isAdminMode &&
              ((!selectedDashboardRows.length && laptop) || !laptop)
            "
            v-show="(laptop && !expandMenu) || !laptop"
            class="ml-lg-2 ml-md-1 text-center"
          >
            <span class="overline"> Status </span>
            <div class="d-flex mb-4">
              <v-autocomplete
                class="fc-dashboard__status-selector selector"
                :items="statusOptions"
                v-model="filterStatus"
                item-text="value"
                solo
                dense
                hide-details
                @input="handleFilterQuery('Status', $event)"
              >
              </v-autocomplete>
            </div>
          </div>
        </transition>

        <!-- clear filters (freelancer) -->
        <transition>
          <div
            v-if="!isAdmin && !isManager && !selectedDashboardRows.length"
            v-show="laptop ? !expandMenu : true"
            class="ml-lg-2 ml-md-1 text-center"
          >
            <span class="overline"> Clear </span>
            <div class="mb-4">
              <v-btn
                :min-width="laptop ? '40px' : '42px'"
                height="38px"
                class="elevation-2 px-0"
                color="secondary"
                @click="clearFilter(false)"
              >
                <v-icon>mdi-filter-remove</v-icon>
              </v-btn>
            </div>
          </div>
        </transition>

        <!-- user's earnings -->
        <transition>
          <div
            v-if="!selectedDashboardRows.length"
            v-show="!expandMenu"
            class="ml-lg-2 ml-md-1 text-center"
            :class="{
              'flex-column justify-end':
                (isAdmin || isManager) && earningsMode !== 0,
            }"
            :style="{
              display:
                (isAdmin || isManager) && earningsMode !== 0 ? 'flex' : 'block',
            }"
          >
            <span
              v-if="isAdmin || isManager ? earningsMode === 0 : true"
              class="overline"
            >
              {{
                `${
                  isAdmin || isManager
                    ? "My Monthly Invoice"
                    : `${filtersBadge ? "Filtered" : "Monthly"} Invoice Amount`
                }`
              }}
            </span>
            <div class="d-flex mb-4">
              <v-badge
                bordered
                color="secondary"
                overlap
                :value="filtersBadge"
                :content="filtersBadge"
              >
                <v-btn-toggle
                  class="elevation-2"
                  color="accent"
                  dense
                  v-model="earningsMode"
                >
                  <v-btn
                    min-width="fit-content"
                    :width="
                      earningsMode === 0 || isAdmin || isManager
                        ? 'fit-content'
                        : '200px'
                    "
                    height="38px"
                    :loading="showTableLoader"
                    ><v-icon color="#000000de">mdi-currency-usd</v-icon
                    ><span>{{
                      earningsMode === 0
                        ? `Total: ${$refs.dashboardTable.totalEarnings}`
                        : isAdmin || isManager
                        ? ""
                        : "Invoice"
                    }}</span></v-btn
                  >
                </v-btn-toggle>
              </v-badge>
              <v-btn
                v-if="earningsMode === 0"
                height="38px"
                :min-width="laptop ? '40px' : '42px'"
                class="elevation-2 px-0 ml-2"
                color="secondary"
                :disabled="!filteredData().length"
                @click="downloadInvoice"
              >
                <v-icon>mdi-tray-arrow-down</v-icon>
              </v-btn>
            </div>
          </div>
        </transition>

        <!-- bulk status assign -->
        <transition>
          <div
            class="ml-lg-2 ml-md-1 text-center d-flex"
            v-if="selectedDashboardRows.length > 0 && !expandMenu"
          >
            <div>
              <span class="overline"> Bulk Status Assign </span>
              <div class="d-flex align-end mb-4">
                <v-autocomplete
                  v-model="bulkStatusValue"
                  class="fc-dashboard__status-selector selector"
                  solo
                  dense
                  hide-details
                  :items="
                    updatedStatusesMode ? updatedAllowedStatuses : statusOptions
                  "
                  item-text="value"
                  @input="handleBulkStatusSelect"
                ></v-autocomplete>
              </div>
            </div>
            <div class="ml-1">
              <span class="overline"> & Inquiry </span>
              <div class="d-flex justify-center align-end mb-4">
                <v-btn
                  width="38px"
                  height="38px"
                  fab
                  elevation="4"
                  color="accent"
                  @click="callBulkInquiry"
                  ><v-icon>mdi-slack</v-icon></v-btn
                >
              </div>
            </div>
          </div>
        </transition>

        <!-- bulk operational assign -->
        <!-- <transition>
          <div
            class="ml-lg-2 ml-md-1 text-center"
            v-if="selectedDashboardRows.length"
            v-show="!expandMenu"
          >
            <span class="overline"> Role / Assignee / Delivery Dues </span>
            <div class="d-flex mb-4">
              <v-autocomplete
                class="fc-dashboard__role-selector selector"
                :items="roles"
                v-model="bulkRoleValue"
                solo
                dense
                hide-details
              >
              </v-autocomplete>
              <v-autocomplete
                class="fc-dashboard__person-selector selector ml-2"
                :items="employees"
                v-model="bulkAssigneeValue"
                solo
                dense
                hide-details
                :disabled="!bulkRoleValue"
              ></v-autocomplete>
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                solo-inverted
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="bulkDueDateValue"
                    class="fc-dashboard__date-selector selector ml-2"
                    solo
                    dense
                    readonly
                    hide-details
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!bulkRoleValue"
                    @click:clear="clearFilter"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="bulkDueDateValue"
                  @input="handleFilterDeliveryChange"
                ></v-date-picker>
              </v-menu>
              <v-select
                :value="bulkDueTimeValue"
                class="fc-dashboard__time-selector selector ml-2"
                solo
                dense
                hide-details
                :items="hoursList"
                item-text="time"
                :disabled="!bulkRoleValue"
              ></v-select>
            </div>
          </div>
        </transition> -->
      </div>

      <!-- right -->
      <div class="d-flex flex-column justify-start align-end my-0">
        <!-- search -->
        <span class="overline" :style="{ opacity: 0, userSelect: 'none' }"
          >Search</span
        >
        <div class="mb-4">
          <v-badge
            :content="laptopSmaller ? '...' : filterSearchBadge"
            :value="filterSearch && !expandMenu"
            color="secondary"
            overlap
          >
            <v-btn
              height="38px"
              min-width="48px"
              class="elevation-2 px-0"
              outlined
              color="accent"
              @click="expandMenu = !expandMenu"
            >
              <v-icon v-if="expandMenu">mdi-magnify-minus-outline</v-icon>
              <v-icon v-else>mdi-magnify-plus-outline</v-icon>
            </v-btn>
          </v-badge>
          <v-expand-x-transition>
            <v-text-field
              :style="{ position: 'absolute', bottom: '16px', right: '60px' }"
              v-show="expandMenu"
              v-model="filterSearch"
              class="fc-dashboard__search-selector selector"
              solo
              dense
              hide-details
              clearable
            />
          </v-expand-x-transition>
        </div>
      </div>
    </div>
    <!-- table -->
    <v-card class="fc-dashboard__table-card">
      <v-skeleton-loader
        v-if="showTableLoader"
        type="table"
        height="100vh"
      ></v-skeleton-loader>
      <FCDashboardTable
        ref="dashboardTable"
        v-if="tableData && !showTableLoader"
        :tableData="tableData"
        :tableFields="tableFields"
        :isAdminMode="isAdminMode"
        :filterDeliveryDate="filterDeliveryDate"
        :filterRequestDate="filterRequestDate"
        :filterSearch="filterSearch"
        :filterProject="filterProject"
        :filterShakespeare="filterShakespeare === 0 ? 1 : 0"
        :filterPerson="filterPerson ? filterPerson : ''"
        :filterRole="filterRole ? filterRole : ''"
        :filterGeo="filterGeo ? filterGeo : ''"
        :filterStatus="filterStatus ? filterStatus : ''"
        :isStatusUpdating="isStatusUpdating"
        :statuses="statusOptions"
        :statusesLoading="statusOptionsLoading"
        :showEarnings="earningsMode === 0 ? 1 : 0"
        @update-allowed-statuses="handleAllowedStatuses"
      />
    </v-card>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// library
import _ from "lodash";
// internal
import {
  getAssignments,
  updateMultipleDBRowStatus,
  appIDs,
  getEmployeesData,
  getFieldInfosFromDB,
  getTasks,
  processTask,
} from "@/utils/quickbaseUtils";
import { customBreakpoint, timeUnits } from "@/utils/mixins";
import { STATUS_COLORS, ROUTES } from "@/utils/constants";
import SHK from "@/assets/SHK.svg";
import { generateInvoice } from "@/utils/dashboardsUtils";
// components
import FCDashboardTable from "@/components/FCDashboard/FCDashboardTable";
import FCDashboardNewAssignmentModal from "@/components/FCDashboard/FCDashboardNewAssignmentModal";

export default {
  name: "FCDashboard",
  components: {
    FCDashboardTable,
    FCDashboardNewAssignmentModal,
  },
  data() {
    return {
      SHK,
      bulkStatusValue: "",
      showTableLoader: true,
      tableData: null,
      tableFields: null,
      filterEmail: false,
      selectedMonth: "",
      selectedYear: "",
      filterDeliveryDate: null,
      filterRequestDate: null,
      filterSearch: "",
      // bulkRoleValue: "",
      // bulkAssigneeValue: "",
      // bulkDueDateValue: "",
      // bulkDueTimeValue: "",
      // bulkDeliveryDateValue: "",
      // bulkDeliveryTimeValue: "",
      expandMenu: false,
      filterProject: [0, 1],
      filterShakespeare: null,
      dropdownsLoading: true,
      employees: [],
      filterPerson: "",
      roles: ["Writer", "Editor", "Reviewer"],
      filterRole: "",
      filterGeo: "",
      filterStatus: "",
      isStatusUpdating: false,
      viewAsOverride: "",
      statusOptions: [],
      updatedAllowedStatuses: [],
      statusOptionsLoading: true,
      updatedStatusesMode: false,
      earningsMode: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "isAdmin", "isManager"]),
    ...mapGetters("fcBot", ["selectedDashboardRows"]),
    ...mapGetters("fcAssignment", ["newFCAssignmentModalOpen", "geoOptions"]),
    isAdminMode() {
      return !this.filterEmail;
    },
    filterSearchBadge() {
      return this.filterSearch?.length <= 6
        ? this.filterSearch
        : `${this.filterSearch?.slice(0, 6)}...`;
    },
    filtersBadge() {
      return Object.entries(this.$route.query).filter(
        (el) =>
          el[0] !== "ppm" &&
          el[0] !== "ppy" &&
          el[1] !== undefined &&
          (typeof el[1] === "object" && el[1] !== null
            ? el[1].length === 1
            : el[1] !== null)
      ).length;
    },
  },
  mixins: [customBreakpoint, timeUnits],
  watch: {
    filterPerson(newValue) {
      if ((this.isAdmin || this.isManager) && newValue === null)
        this.filterRole = "";
    },
    viewAsOverride(newValue) {
      if (newValue !== "") {
        this.filterEmail = newValue;
      } else {
        this.filterEmail =
          this.isAdmin || this.isManager ? false : this.user.email;
      }
    },
    filterSearch(newValue) {
      const currentQuery = this.$route.query;
      const newQuery =
        newValue !== ""
          ? {
              ...currentQuery,
              Search: newValue,
            }
          : _.omit(currentQuery, ["Search"]);

      this.$router
        .push({ path: `/${ROUTES.fcDashboard}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
    earningsMode(newVal) {
      if (this.isAdmin || this.isManager)
        this.filterPerson = newVal === 0 ? this.user.Name : "";
    },
  },
  mounted() {
    this.handleQuery();
    this.handleUser();
    this.handleDropdowns();
    this.getStatuses();
  },
  methods: {
    ...mapActions("fcBot", ["setSelectedDashboardRows"]),
    ...mapActions("fcAssignment", ["setNewFCAssignmentModalOpen", "setGeos"]),
    handleSelectedPeriod(value, key, queryUpdate) {
      const query = { ...this.$route.query };

      if (value) {
        query[key] = value;
      } else {
        delete query[key];
      }

      this.$router
        .push({ path: `/${ROUTES.fcDashboard}`, query })
        .catch(() => {}); // Avoided redundant navigation error handler

      if (!queryUpdate) this.getTableData();
    },
    handleDueValue(item) {
      if (item["Writer-Email"] === this.user.email)
        return new Date(
          `${item.WritingDueDate.replace(/-/g, "/")} ${
            item["WritingDue(TimeOfDay)"]
          }`
        );
      if (item["Editor-Email"] === this.user.email)
        return new Date(
          `${item.EditingDueDate.replace(/-/g, "/")} ${
            item["EditingDue(TimeOfDay)"]
          }`
        );
      if (item["Reviewer-Email"] === this.user.email)
        return new Date(
          `${item.ReviewingDueDate.replace(/-/g, "/")} ${
            item["ReviewingDue(TimeOfDay)"]
          }`
        );
    },
    getTableData() {
      this.showTableLoader = true;
      getAssignments({
        personEmail: this.filterEmail,
        payPeriod: `${this.selectedMonth} ${this.selectedYear}`,
      })
        .then((resp) => {
          this.tableData = resp.data.map((el) => {
            return {
              ...el,
              DueTime: this.handleDueValue(el),
              checked: false,
            };
          });
          this.tableFields = resp.fields;
          this.tableFields.push(
            {
              text: "Shakespeare Issue",
              value: "Issue",
            },
            {
              text: "Inquiry",
              value: "Inquiry",
            },
            {
              text: "Due Time",
              value: "DueTime",
            },
            {
              text: "Total Internal",
              value: "TotalInternal",
            }
          );
          // get user's earnings data
          getAssignments({
            payPeriod: `${this.selectedMonth} ${this.selectedYear}`,
            personName: this.user.Name,
            earningsMode: true,
          })
            .then((earnings) => {
              earnings.data.forEach((earn) => {
                const earningID = earn.uniqueID.split(
                    appIDs.iTunesProjectManager.assignments
                  )[0],
                  earningRow = this.tableData.find(
                    (row) => row["RecordID#"] == earningID
                  ),
                  earningRowIndex = this.tableData.indexOf(earningRow);
                if (earningRowIndex >= 0)
                  Object.assign(this.tableData[earningRowIndex], {
                    TotalInternal: earn.TotalInternal,
                  });
              });
              this.showTableLoader = false;
            })
            .catch((err) => {
              console.error(err);
              this.showTableLoader = false;
            });
        })
        .catch((err) => {
          console.error(err);
          this.showTableLoader = false;
        });
    },
    handleFilterQuery(param, data) {
      const currentQuery = this.$route.query;

      const newQuery = {
        ...currentQuery,
        [param]: data,
      };
      this.$router
        .push({ path: `/${ROUTES.fcDashboard}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler

      this[`filter${param}`] = data;
    },
    clearFilter(date) {
      let query = this.$route.query;

      if (date) {
        query = _.omit(query, [date]);
        this[`filter${date}`] = null;
      } else {
        query = _.pick(query, ["ppm", "ppy"]);
        this.filterProject = [0, 1];
        this.filterShakespeare = null;
        this.filterRole =
          this.filterStatus =
          this.filterGeo =
          this.filterSearch =
            "";
        this.filterPerson =
          (this.isAdmin || this.isManager) && this.earningsMode === 0
            ? this.filterPerson
            : "";
      }

      if (!date) this.filterRequestDate = this.filterDeliveryDate = null;

      this.$router
        .push({ path: `/${ROUTES.fcDashboard}`, query })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
    handleBulkStatusSelect(status) {
      this.isStatusUpdating = true;
      updateMultipleDBRowStatus({
        rows: this.selectedDashboardRows,
        status: status,
      })
        .then(() => {
          // handle Task status on bulk Assignment status change
          if (status.endsWith("Complete")) {
            const cueIDs = [
              ...new Set(
                this.selectedDashboardRows.map((el) => {
                  return el["Task-CueTaskID"];
                })
              ),
            ];
            cueIDs.forEach((cueID) => {
              getAssignments({ cueID })
                .then((resp) => {
                  if (resp.data.every((el) => el.AssignmentStatus === status)) {
                    getTasks({ cueID })
                      .then((resp) => {
                        if (resp.data.length) {
                          processTask({
                            task: {
                              ["RecordID#"]: resp.data[0]["RecordID#"],
                              LastUpdated: new Date(),
                              LastUpdatedBy: this.user.Name,
                            },
                            status: status,
                          }).catch((err) => {
                            console.error(err);
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            });
          }
          this.isStatusUpdating = false;
          this.bulkStatusValue = "";
          this.getTableData();
          this.setSelectedDashboardRows([]);
        })
        .catch((err) => {
          console.error(err);
          this.isStatusUpdating = false;
        });
    },
    getEmployees() {
      return new Promise((resolve, reject) => {
        getEmployeesData({ namesOnly: true })
          .then((names) => {
            resolve((this.employees = names));
          })
          .catch((err) => {
            reject(console.error(err));
          });
      });
    },
    getGeos() {
      return new Promise((resolve, reject) => {
        getFieldInfosFromDB({
          fieldID: 44,
          tableID: appIDs.iTunesProjectManager.assignments,
        })
          .then((geos) => {
            resolve(this.setGeos(geos));
          })
          .catch((err) => {
            reject(console.error(err));
          });
      });
    },
    getStatuses() {
      getFieldInfosFromDB({
        fieldID: 110,
        tableID: appIDs.iTunesProjectManager.assignments,
      })
        .then((data) => {
          this.statusOptions = data.map((el) => {
            return {
              value: el,
              color: STATUS_COLORS.find((color) => color.statuses.includes(el))
                ?.value,
            };
          });
          this.statusOptionsLoading = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleRoles() {
      return new Promise((resolve, reject) => {
        resolve(this.roles.push("Creator"));
      });
    },
    handleDropdowns() {
      if (this.isAdmin || this.isManager)
        Promise.all([this.getEmployees(), this.getGeos(), this.handleRoles()])
          .then(() => {
            this.dropdownsLoading = false;
          })
          .catch((err) => {
            this.dropdownsLoading = false;
            console.error(err);
          });
    },
    handleQuery() {
      const currentQuery = this.$route.query;

      if (_.isEmpty(currentQuery)) {
        this.selectedMonth = this.$moment().format("MMMM");
        this.selectedYear = this.$moment().format("YYYY");
      } else {
        if (currentQuery.ppm && currentQuery.ppy) {
          this.selectedMonth = currentQuery.ppm;
          this.selectedYear = currentQuery.ppy;
        } else {
          this.selectedMonth = this.$moment().format("MMMM");
          this.selectedYear = this.$moment().format("YYYY");
        }
        Object.keys(currentQuery).forEach((param) => {
          if (param !== "ppm" && param !== "ppy") {
            if (param === "Project") {
              this[`filter${param}`] =
                typeof currentQuery[param] === "string"
                  ? [Number(currentQuery[param])]
                  : currentQuery[param].map((x) => Number(x));
            } else {
              this[`filter${param}`] =
                param === "Shakespeare"
                  ? Number(currentQuery[param])
                  : currentQuery[param];
            }
          }
        });
      }
      this.handleSelectedPeriod(this.selectedMonth, "ppm", true);
      this.handleSelectedPeriod(this.selectedYear, "ppy", true);
    },
    handleUser() {
      this.filterEmail =
        this.isAdmin || this.isManager ? false : this.user.email;
      this.getTableData();
    },
    handleAllowedStatuses(statuses) {
      this.updatedAllowedStatuses = [...statuses];
      this.updatedStatusesMode = statuses.length;
    },
    callBulkInquiry() {
      this.$refs.dashboardTable.callBulkInquiry();
    },
    filteredData() {
      return this.$refs.dashboardTable._computedWatchers.filteredData.value;
    },
    downloadInvoice() {
      generateInvoice({
        assignments: this.filteredData(),
        person: this.user.Name,
        total: this.$refs.dashboardTable._computedWatchers.totalEarnings.value,
        filters: this.$route.query,
        fcMode: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fc-dashboard {
  max-width: 1500px;
  margin: 0 auto;

  // &__time-selector,
  &__month-selector {
    width: 120px;
  }

  &__year-selector {
    width: 80px;
  }

  &__date-selector {
    width: 130px;
    &.laptop {
      width: 120px;
    }
  }

  &__person-selector {
    width: 170px;
    &.laptop {
      width: 160px;
    }
  }

  &__role-selector {
    width: 110px;
  }

  &__geo-selector {
    width: 100px;
  }

  &__status-selector {
    width: 160px;
  }

  &__search-selector {
    width: 340px;
  }
}

::v-deep {
  .selector.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding-right: 0;
  }
  .selector.v-text-field.v-text-field--solo .v-input__append-inner {
    padding-left: 0;
  }
  .selector.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0;
  }
  .selector.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 2px 0;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'lg-and-down')} {
  .v-application .overline {
    font-size: 0.6rem !important;
  }
}
</style>
