<template>
  <v-data-table
    :headers="headers"
    :items="filteredData"
    item-key="RecordID#"
    sort-by="ServiceName"
    :header-props="{ sortIcon: null }"
    :items-per-page="50"
    :footer-props="{
      'items-per-page-options': [10, 50, 100],
    }"
    show-expand
    @click:row="$emit('call-edit-form', $event)"
  >
    <!-- subscription amount cell override -->
    <template v-slot:[`item.SubscriptionAmount(Monthly)`]="{ value }">
      <span>{{ value ? `$${value.toFixed(2)}` : "" }}</span>
    </template>

    <!-- subscription end date cell override -->
    <template v-slot:[`item.SubscriptionEndDate`]="{ value }">
      <span>{{ formatDate(value) }}</span>
    </template>

    <!-- Expanded panel -->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <CueSoftwareTableExpansion :softwareInfo="item" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
// libraries
import _ from "lodash";
import Fuse from "fuse.js";
// internal
import { customBreakpoint } from "@/utils/mixins";
// components
import CueSoftwareTableExpansion from "@/components/CueSoftware/CueSoftwareTableExpansion";

export default {
  name: "CueSoftwareTable",
  components: {
    CueSoftwareTableExpansion,
  },
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    tableFields: {
      type: Array,
      required: false,
    },
    filterSearch: {
      type: String,
      required: false,
    },
    filterService: {
      type: String,
      required: false,
    },
    filterType: {
      type: String,
      required: true,
    },
    filterPlan: {
      type: String,
      required: true,
    },
    filterSubscription: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableHeaders: [
        "ServiceName",
        "ServiceType",
        "PlanName",
        "PlanType",
        "UserAmount(Max)",
        "UserAmount(Current)",
        "SubscriptionType",
        "SubscriptionAmount(Monthly)",
        "SubscriptionEndDate",
      ],
    };
  },
  mixins: [customBreakpoint],
  computed: {
    headers() {
      if (!this.tableFields) return [];

      let retVal = this.tableFields
        .filter((el) => this.tableHeaders.includes(el.value))
        .map((field) => {
          return {
            text: field.text,
            value: field.value,
          };
        });

      // order by the order of the tableHeaders
      retVal = _.orderBy(retVal, (el) => this.tableHeaders.indexOf(el.value));

      retVal.push({ text: "", value: "data-table-expand" });

      return retVal;
    },
    tableFieldValues() {
      return this.tableFields.map((field) => field.value);
    },
    filteredData() {
      let dateFilteredData = this.tableData;

      if (this.filterService) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el["ServiceName"] === this.filterService
        );
      }

      if (this.filterType) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el["ServiceType"] === this.filterType
        );
      }

      if (this.filterPlan) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el["PlanType"] === this.filterPlan
        );
      }

      if (this.filterSubscription) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el["SubscriptionType"] === this.filterSubscription
        );
      }

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: this.tableFieldValues,
      };

      if (this.filterSearch) {
        const fuse = new Fuse(dateFilteredData, searchOptions);
        dateFilteredData = fuse.search(this.filterSearch).map((el) => el.item);
      }

      return dateFilteredData;
    },
  },
  methods: {
    formatDate(date) {
      return date ? this.$moment(date).format("MM/DD/YY") : "";
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  tbody > tr:not(.v-data-table__expanded__content) {
    cursor: pointer;
  }
  .v-data-table.laptop .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 8px;
    }
    & .v-data-table-header tr > th {
      padding: 0 8px;
    }
  }
  .v-data-table.laptopSmaller .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 4px;
    }
    & .v-data-table-header tr > th {
      padding: 0 4px;
    }
  }
}
</style>
