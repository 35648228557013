<template>
  <div class="typeform">
    <!-- here's the typeform for {{ id }} -->

    <div
      class="typeform__embed"
      id="typeform__embed"
      ref="typeform__embed"
    ></div>
  </div>
</template>

<script>
import * as typeformEmbed from "@typeform/embed";
export default {
  name: "Typeform",
  props: {
    id: String,
  },
  watch: {
    id: function(newValue) {
      console.log(typeformEmbed);
      // delete existing typeform
      // load new typeform
      const element = this.$refs["typeform__embed"];
      typeformEmbed.makeWidget(
        element,
        `https://form.typeform.com/to/${newValue}`,
        {
          hideFooter: true,
          hideHeaders: true,
        }
      );
    },
  },
  mounted() {
    // console.log(this.$refs["typeform__embed"]);
    const element = this.$refs["typeform__embed"];
    typeformEmbed.makeWidget(
      element,
      `https://form.typeform.com/to/${this.id}`,
      {
        hideFooter: true,
        hideHeaders: true,
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.typeform {
  &__embed {
    height: 900px;
  }
}
</style>
