<template>
  <div class="">
    <EditorSingleComment
      v-for="(comment, i) in assignmentComments"
      :key="`comment-${i}`"
      :comment="comment"
    />
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// library
// internal
// components
import EditorSingleComment from "./EditorSingleComment.vue";

export default {
  name: "EditorExistingComments",
  components: {
    EditorSingleComment,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("editor", ["assignmentComments"]),
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
