var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-chip',{staticClass:"flow-chip my-2 mr-1 px-2",style:({
    backgroundColor: _vm.backgroundColor,
    border: `1px solid ${_vm.textColor}`,
  }),attrs:{"outlined":!_vm.stageIsComplete,"label":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleChipClick.apply(null, arguments)}}},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{class:`step-name font-weight-bold`,style:({
        color: _vm.textColor,
      })},[_vm._v(" "+_vm._s(_vm.flowStep.label)+" ")]),_c('div',{class:`person-name`,style:({
        color: _vm.textColor,
      })},[_vm._v(" "+_vm._s(_vm.getName(_vm.flowStep.person))+" ")])]),(_vm.hasDueDate)?_c('div',{staticClass:"date-section ml-2 rounded-sm d-flex flex-column align-center",class:{
      complete: _vm.stageIsComplete,
    },style:({ border: `1px solid ${_vm.textColor}`, color: _vm.textColor })},[_c('div',{staticClass:"month"},[_vm._v(_vm._s(_vm.dueDateMonth))]),_c('div',{staticClass:"day"},[_vm._v(_vm._s(_vm.dueDateDay))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }