<template>
  <v-container>
    <EditorExistingComments />
    <EditorNewComment :assignment="assignment" />
  </v-container>
</template>

<script>
// vuex
import { mapActions } from "vuex";
// library
// internal
// components
import EditorNewComment from "./EditorNewComment.vue";
import EditorExistingComments from "./EditorExistingComments.vue";

export default {
  name: "EditorCommentsView",
  props: {
    assignment: {
      type: Object,
      required: true,
    },
  },
  components: {
    EditorNewComment,
    EditorExistingComments,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {
    // this.assignment.getComments().then((comments) => {
    //   this.setAssignmentComments(comments);
    // });
  },
  methods: {
    ...mapActions("editor", ["setAssignmentComments"]),
  },
};
</script>

<style lang="scss" scoped></style>
