<template>
  <div class="assignment-progress">
    <v-stepper alt-labels>
      <v-stepper-header>
        <v-stepper-step class="px-0" step="" :complete="true">
          Received
          <small>
            {{ formattedReceivedString }}
          </small>
        </v-stepper-step>

        <v-divider :class="{ laptopSmaller }"></v-divider>
        <v-stepper-step class="px-0" step="" :complete="isInProgress">
          In Progress
        </v-stepper-step>

        <v-divider :class="{ laptopSmaller }"></v-divider>
        <v-stepper-step class="px-0" step="" :complete="isDesigned">
          Design In Progress
        </v-stepper-step>

        <v-divider :class="{ laptopSmaller }"></v-divider>
        <v-stepper-step class="px-0" step="" :complete="isDelivered">
          Delivered
          <small> {{ formattedDeliveryDateString }} </small>
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
  </div>
</template>

<script>
// internal
import { customBreakpoint } from "@/utils/mixins";

export default {
  name: "AssignmentProgressIndicator",
  props: {
    data: Object,
  },
  methods: {
    formatDate(d) {
      return this.$moment(d).format("MM/DD/YY");
    },
  },
  mixins: [customBreakpoint],
  computed: {
    hasStatus() {
      return this.data["Assignment Status"] === "";
    },
    formattedReceivedString() {
      return this.data["Request Date"] === ""
        ? ""
        : this.formatDate(this.data["Request Date"]);
    },
    formattedDeliveryDateString() {
      return this.data["Delivery Date"] === ""
        ? ""
        : `by ${this.formatDate(this.data["Delivery Date"])}`;
    },
    isInProgress() {
      const possibleStatus = [
        "Assigned",
        "Primary Complete",
        "Secondary Complete",
        "QC Complete",
      ];

      return possibleStatus.includes(this.data["Assignment Status"]);
    },
    isDesigned() {
      return this.data["Assignment Status"] === "Design in Progress";
    },
    isDelivered() {
      return this.data["Assignment Status"] === "Delivered";
    },
  },
};
</script>

<style lang="scss" scoped>
.assignment-progress {
  width: 100%;
  > * {
    box-shadow: none;
  }

  > div {
    background-color: transparent;
  }
}
.v-stepper--alt-labels .v-stepper__step {
  flex-basis: 100px;
}
.v-divider {
  flex: 0 1 100px;
  &.laptopSmaller {
    flex: 0 1 75px;
  }
}
::v-deep {
  .v-stepper__label {
    text-align: center;
  }
}
</style>
