import { db, auth } from "@/providers/firebase";

const EMAILS_COLLECTION_NAME = "client-invoice-contacts";
const TYPEFORM_COLLECTION_NAME = "typeforms";

////////////////////////////////
// INVOICE EMAILS
////////////////////////////////

export const getInvoiceEmails = () => {
  return new Promise((resolve, reject) => {
    db.collection(EMAILS_COLLECTION_NAME)
      .get()
      .then((querySnapshot) => {
        const retVal = [];
        querySnapshot.forEach((doc) => {
          retVal.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        resolve(retVal);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addInvoiceEmail = ({
  client,
  primary_email,
  secondary_emails,
}) => {
  return new Promise((resolve, reject) => {
    db.collection(EMAILS_COLLECTION_NAME)
      .add({
        client,
        primary_email,
        secondary_emails: secondary_emails || "",
      })
      .then((res) => {
        return getInvoiceEmails();
      })
      .then((newEmailData) => {
        resolve(newEmailData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateInvoiceEmail = ({
  id,
  client,
  primary_email,
  secondary_emails,
}) => {
  return new Promise((resolve, reject) => {
    db.collection(EMAILS_COLLECTION_NAME)
      .doc(id)
      .update({
        client,
        primary_email,
        secondary_emails: secondary_emails || "",
      })
      .then((res) => {
        return getInvoiceEmails();
      })
      .then((newEmailData) => {
        resolve(newEmailData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteInvoiceEmail = (idToDelete) => {
  return new Promise((resolve, reject) => {
    db.collection(EMAILS_COLLECTION_NAME)
      .doc(idToDelete)
      .delete()
      .then((res) => {
        return getInvoiceEmails();
      })
      .then((newEmailData) => {
        resolve(newEmailData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

////////////////////////////////
// TYPEFORMS
////////////////////////////////

export const getTypeformDataset = () => {
  return new Promise((resolve, reject) => {
    db.collection(TYPEFORM_COLLECTION_NAME)
      .get()
      .then((querySnapshot) => {
        const retVal = [];
        querySnapshot.forEach((doc) => {
          retVal.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        resolve(retVal);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTypeformIDForCompany = (company) => {
  return new Promise((resolve, reject) => {
    db.collection(TYPEFORM_COLLECTION_NAME)
      .where("company", "==", company)
      .get()
      .then((querySnapshot) => {
        let retVal = null;

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            console.log(doc.data);
            retVal = doc.data().typeform_id;
          });
        }

        resolve(retVal);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addTypeformID = ({ company, typeform_id }) => {
  return new Promise((resolve, reject) => {
    db.collection(TYPEFORM_COLLECTION_NAME)
      .add({
        company,
        typeform_id,
      })
      .then((res) => {
        return getTypeformDataset();
      })
      .then((newTypeformData) => {
        resolve(newTypeformData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateTypeformID = ({ id, company, typeform_id }) => {
  return new Promise((resolve, reject) => {
    db.collection(TYPEFORM_COLLECTION_NAME)
      .doc(id)
      .update({
        company,
        typeform_id,
      })
      .then((res) => {
        return getTypeformDataset();
      })
      .then((newTypeformData) => {
        resolve(newTypeformData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteTypeformID = (idToDelete) => {
  return new Promise((resolve, reject) => {
    db.collection(TYPEFORM_COLLECTION_NAME)
      .doc(idToDelete)
      .delete()
      .then((res) => {
        return getTypeformDataset();
      })
      .then((newTypeformData) => {
        resolve(newTypeformData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
