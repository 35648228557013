<template>
  <v-card>
    <v-form ref="form" v-model="valid" lazy-validation class="pa-6">
      <v-container>
        <h2 class="text-center" :class="mode === 'bug' ? 'mb-6' : 'mb-2'">
          {{ title }}
        </h2>
        <h3 v-if="mode !== 'bug'" class="text-center mb-6">
          {{
            mode.charAt(0).toUpperCase() +
            mode.slice(1) +
            " Detail: " +
            (mode === "assignment"
              ? bulkInquiry
                ? "Bulk Inquiry"
                : inquiryItem.AssignmentDetail
              : inquiryItem.Task.includes("Shakespeare")
              ? inquiryItem.Task + " / " + inquiryItem.DeliveryDate
              : inquiryItem.TaskID)
          }}
        </h3>
        <v-row>
          <v-col v-if="mode === 'task'" cols="12" sm="6" class="pb-0">
            <v-autocomplete
              outlined
              label="Who is this message for:"
              v-model="messageTarget"
              item-text="value"
              return-object
              :hint="messageTargetHint"
              persistent-hint
              :items="messageTargetsList"
              :rules="[rules.requiredObject]"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" :sm="mode === 'bug' ? '' : 6" class="pb-0">
            <v-autocomplete
              outlined
              label="Category:"
              v-model="category"
              :disabled="cpmMode"
              :items="categoriesList"
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="mode === 'assignment'" cols="12" sm="6" class="pb-0">
            <v-autocomplete
              outlined
              label="Urgency of the request:"
              v-model="urgency"
              item-text="value"
              return-object
              :items="urgencyList"
              :rules="[rules.requiredObject]"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-textarea
              outlined
              clearable
              rows="1"
              clear-icon="mdi-close-circle-outline"
              label="Please describe the issue."
              v-model="question"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-card-actions class="justify-end pb-6">
      <v-btn width="20%" color="secondary" @click="handleSubmit">
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// internal
import { updateDBRowField } from "@/utils/quickbaseUtils";
import { publishMessage } from "@/utils/slackUtils";
import { DOMAIN } from "@/utils/constants";

export default {
  name: "SlackInquiryForm",
  props: {
    mode: {
      type: String,
      required: true,
    },
    inquiryItem: {
      type: Object,
      required: false,
    },
    leadPMSlack: {
      type: String,
      required: false,
    },
    bulkInquiry: {
      type: Boolean,
      required: false,
    },
    cpmMode: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      question: "",
      messageTarget: {},
      messageTargetsList: [
        { value: "PM Team", ping: "<!channel>" },
        {
          value: "Lead PM",
          ping: `<@${this.leadPMSlack}>`,
          note: "Please use responsibly and escalate questions only when needed.",
        },
      ],
      category: "",
      urgency: { value: "Not Urgent", emoji: ":white_circle:" },
      urgencyList: [
        { value: "Not Urgent", emoji: ":white_circle:" },
        { value: "Timely, but Not Urgent", emoji: ":large_blue_circle:" },
        { value: "Urgent", emoji: ":red_circle:" },
      ],
      valid: true,
      rules: {
        required: (value) => !!value || "Required",
        requiredObject: (value) =>
          (value ? !!Object.keys(value).length : !!value) || "Required",
      },
    };
  },
  watch: {
    inquiryItem(newVal) {
      if (newVal) {
        this.question = "";
        this.handleCategory();
        this.messageTarget = {};
        this.urgency = this.urgencyList[0];
        this.$refs.form.resetValidation();
      }
    },
  },
  mounted() {
    this.handleCategory();
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("fcBot", ["selectedDashboardRows"]),
    title() {
      if (this.mode === "assignment") return "ResCue Me!";
      if (this.mode === "task") return "Project Manager Hotline";
      if (this.mode === "bug") return "CueHub Feedback";
    },
    categoriesList() {
      if (this.mode === "assignment") {
        let categories = [
          "Editorial Question",
          "Missing File",
          "Tool/Platform-Related Issue",
          "Deadline-Related Issue",
          "Note to Reviewer/Client",
          "Invoice-Related Issue",
          "Other",
        ];
        if (this.$route.fullPath.includes("Shakespeare=0"))
          categories.unshift("Task Reassignment");
        return categories;
      } else if (this.mode === "task") {
        return ["Note/Question to PM Team", "Note/Question to Client"];
      } else if (this.mode === "bug") {
        return ["Bug Report", "Feature Suggestion"];
      } else {
        return [];
      }
    },
    messageTargetHint() {
      return this.messageTarget?.note ?? "";
    },
  },
  methods: {
    handleCategory() {
      this.category = this.cpmMode ? "Invoice-Related Issue" : "";
    },
    handleSubmit() {
      if (this.$refs.form.validate()) {
        publishMessage({
          user: this.user.SlackFCWorkspaceID
            ? `<@${this.user.SlackFCWorkspaceID}>`
            : `*${this.user.Name}*`,
          mode:
            this.category === "Task Reassignment"
              ? "reassignment"
              : this.category === "Invoice-Related Issue"
              ? "invoice"
              : this.mode,
          data: {
            question: this.question,
            urgency: this.urgency,
            category: this.category,
            messageTarget: this.messageTarget,
            link: `https://${DOMAIN}${this.$route.fullPath}`,
          },
          item: this.inquiryItem,
          bulkInquiry: this.selectedDashboardRows,
        });
        if (this.category === "Note to Reviewer/Client")
          updateDBRowField({
            rid: this.inquiryItem["RecordID#"],
            note: `${this.inquiryItem.TaskID} / ${
              this.inquiryItem.AssignmentTitle
            } / ${this.inquiryItem.AssignmentDetail} / ${
              this.inquiryItem.GEO
            } / ${this.question} / ${this.user.Name} at ${this.$moment(
              new Date()
            ).format("MM/DD/YYYY hh:mm A")}`,
          }).catch((err) => {
            console.error(err);
          });
        this.inquirySuccess = true;
        this.$emit("close-slack-form");
      }
    },
  },
};
</script>

<style></style>
