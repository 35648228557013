var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panel',{staticClass:"fc-staff-expansion-panel grey lighten-4"},[(!_vm.xContent && !_vm.shakespeareLoc)?_c('v-expansion-panel-header',{staticClass:"fc-staff-expansion-panel__header"},[_c('span',[_vm._v(" "+_vm._s(_vm.groupItem)+" "),_c('v-chip',{staticClass:"identifier-count-chip white",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(_vm.identifierCount)+" ")])],1)]):_vm._e(),_c('v-expansion-panel-content',{ref:"fcStaffPanel",staticClass:"fc-staff-expansion-panel__content",class:{ 'pt-5': _vm.xContent || _vm.shakespeareLoc }},_vm._l((_vm.panelGroup),function(panelItem){return _c('div',{key:panelItem,staticClass:"fc-staff-expansion-panel__content__row",class:[
        _vm.shakespeare || _vm.backstageCopywriting
          ? [
              'flex-wrap',
              'grey',
              'lighten-5',
              'rounded-lg',
              'elevation-1',
              'pt-6',
              'pb-2',
              'mb-4',
              'px-4',
            ]
          : '',
      ]},[(_vm.shakespeare || _vm.backstageCopywriting)?_c('div',{staticClass:"pb-4 d-flex justify-center align-center"},[_vm._v(" "+_vm._s(panelItem)+" "),(_vm.shakespeare)?_c('v-btn',{staticClass:"white ml-4",attrs:{"x-small":""},on:{"click":() => _vm.handleApplyToAll(panelItem)}},[_vm._v("Apply to all [GEO]")]):_vm._e(),(_vm.shakespeare || _vm.backstageCopywriting)?_c('v-btn',{staticClass:"white",class:_vm.backstageCopywriting ? 'ml-4' : 'ml-2',attrs:{"x-small":""},on:{"click":() => _vm.handleApplyToAll(panelItem, true)}},[_vm._v("Apply to all [Batch]")]):_vm._e()],1):_vm._e(),_c('v-row',{staticClass:"align-center justify-center"},[(!_vm.shakespeare && !_vm.backstageCopywriting)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"16","sm":"1"}},[_vm._v(_vm._s(panelItem)+" ")]):_vm._e(),_c('v-col',{attrs:{"cols":!_vm.shakespeare && !_vm.backstageCopywriting ? 16 : 12,"sm":!_vm.shakespeare && !_vm.backstageCopywriting ? 3 : 4}},[_c('v-autocomplete',{attrs:{"label":"Related Writer","items":_vm.shakespeare
                ? _vm.staffNamesForGeo(_vm.groupItem, _vm.formValues.writingDueDate)
                : _vm.staffNamesForGeo(panelItem, _vm.formValues.writingDueDate),"value":_vm.getValue(
                panelItem,
                _vm.groupItem,
                'writer',
                _vm.formValues.writingDueDate
              ),"loading":_vm.namesLoading,"clearable":"","item-text":"name","rules":[_vm.rules.availabile('writer')]},on:{"change":(v) =>
                _vm.handleValueSelectChange(panelItem, 'writer', v, _vm.groupItem),"click:clear":function($event){return _vm.handleOverloadingReset(panelItem, 'writer', _vm.groupItem)}}})],1),_c('v-col',{attrs:{"cols":!_vm.shakespeare && !_vm.backstageCopywriting ? 16 : 12,"sm":!_vm.shakespeare && !_vm.backstageCopywriting ? 3 : 4}},[_c('v-autocomplete',{attrs:{"label":"Related Editor","items":_vm.shakespeare
                ? _vm.staffNamesForGeo(_vm.groupItem, _vm.formValues.editingDueDate)
                : _vm.staffNamesForGeo(panelItem, _vm.formValues.editingDueDate),"value":_vm.getValue(
                panelItem,
                _vm.groupItem,
                'editor',
                _vm.formValues.editingDueDate
              ),"loading":_vm.namesLoading,"clearable":"","item-text":"name","rules":[_vm.rules.availabile('editor')]},on:{"change":(v) =>
                _vm.handleValueSelectChange(panelItem, 'editor', v, _vm.groupItem),"click:clear":function($event){return _vm.handleOverloadingReset(panelItem, 'editor', _vm.groupItem)}}})],1),_c('v-col',{attrs:{"cols":!_vm.shakespeare && !_vm.backstageCopywriting ? 16 : 12,"sm":!_vm.shakespeare && !_vm.backstageCopywriting ? 3 : 4}},[_c('v-autocomplete',{attrs:{"label":"Reviewer","items":_vm.shakespeare
                ? _vm.staffNamesForGeo(_vm.groupItem, _vm.formValues.reviewingDueDate)
                : _vm.staffNamesForGeo(panelItem, _vm.formValues.reviewingDueDate),"value":_vm.getValue(
                panelItem,
                _vm.groupItem,
                'reviewer',
                _vm.formValues.reviewingDueDate
              ),"loading":_vm.namesLoading,"clearable":"","item-text":"name","rules":[_vm.rules.availabile('reviewer')]},on:{"change":(v) =>
                _vm.handleValueSelectChange(panelItem, 'reviewer', v, _vm.groupItem)}})],1),(
            !_vm.xContent &&
            !_vm.shakespeareLoc &&
            !_vm.shakespeare &&
            !_vm.backstageCopywriting
          )?_c('v-col',{staticClass:"text-center",attrs:{"cols":"16","sm":"2"}},[_c('v-btn',{staticClass:"white",attrs:{"x-small":""},on:{"click":() => _vm.handleApplyToAll(panelItem)}},[_vm._v("Apply to all [GEO]")])],1):_vm._e()],1),(_vm.shakespeare || _vm.backstageCopywriting)?_c('v-row',_vm._l(([1, 2, 3, 4, 5, 6]),function(i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"2"}},[_c('v-autocomplete',{staticClass:"py-2",attrs:{"label":`Work Type ${i} Reference`,"clearable":i !== 1,"items":_vm.getReferencesOptions(i, panelItem),"value":_vm.getValue(panelItem, _vm.groupItem, `workType${i}Reference`),"disabled":_vm.adaptationMode(panelItem)},on:{"change":(v) =>
                _vm.handleValueSelectChange(
                  panelItem,
                  `workType${i}Reference`,
                  v,
                  _vm.groupItem
                )}})],1)}),1):_vm._e(),(_vm.shakespeare || _vm.backstageCopywriting)?_c('v-row',_vm._l(([1, 2, 3, 4, 5, 6]),function(i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',{staticClass:"py-2",attrs:{"label":`Work Type ${i} Quantity`,"type":"number","clearable":i !== 1,"value":_vm.getValue(panelItem, _vm.groupItem, `workType${i}Quantity`),"disabled":_vm.adaptationMode(panelItem),"rules":[_vm.rules.negativeCheck]},on:{"change":(v) =>
                _vm.handleValueSelectChange(
                  panelItem,
                  `workType${i}Quantity`,
                  v,
                  _vm.groupItem
                )}})],1)}),1):_vm._e()],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }