export const sourceFileHeaders = {
  client_requester: "DRI",
  subject_line: "Name",
  source_geo: "Source Locale",
  geo: "Locale",
  media_type: "Line of Business",
  keyword: "Keyword",
  request_type: "Request Type",
  request_date: "Created Date",
  due_date: "Due Date",
  word_count: "Word Count",
  task_name: "Project",
  task_id: "Task ID",
  assignment_id: "ID",
  link: "Task Link",
  note: "Instructions",
};

export const shakespeareHeaders = {
  geo: "LOCALE",
  task_name: "CONTENT_NAME",
  task_id: "WORKFLOW_ID",
  content_id: "ADAM_ID",
  video_content_id: "CONTENT_ID",
  writer: "ASSIGNEE_NAME",
};

export const shakespeareLocHeaders = {
  source_geo: "Source Locale",
  geo: "Locale",
  geo_divider: "Name Ref.",
  task_id: "Workflow Id",
  content_id: "Proxy Adam Id",
  task_name: "Title",
  word_count: "Story Word Count",
  writer: "Assignee Name",
};

export const BLANK_FORM_VALUES = {
  payPeriod: "",
  assignmentTitle: "",
  writingDueDate: "",
  writingDue: "",
  editingDueDate: "",
  editingDue: "",
  reviewingDueDate: "",
  reviewingDue: "",
  deliveryDate: "",
  deliveryDue: "",
  urgent: false,
  override: false,
  relatedProject: "",
  po: "",
  emailSubjectLine: "",
  clientRequester: "",
  mediaType: "",
  overrideStaff: false,
  requestTime: "",
  fileLocation: "",
  note: "",
  individualGeosDeadlines: [],
};
