<template>
  <v-dialog :value="isActive" max-width="600" persistent>
    <v-card>
      <v-card-title class="headline"> Ask about this assignment </v-card-title>

      <v-card-text>
        Add a message below and an email will be sent to your Company Cue
        project manager along with the assignment details.
      </v-card-text>

      <v-container class="message-box-container">
        <v-textarea
          v-model="message"
          class="message-box"
          name="message-box"
          label="Message"
          outlined
        />
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color=" darken-1" text @click="handleClose"> Cancel </v-btn>

        <v-btn color="darken-1" text @click="handleSubmit"> Send </v-btn>
      </v-card-actions>
      <v-progress-linear v-if="isSending" indeterminate />
    </v-card>
  </v-dialog>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// internal
import { functions } from "@/providers/firebase";
import { FALLBACK_INQUIRY_EMAIL } from "@/utils/constants";
import { appIDs, realmID } from "@/utils/quickbaseUtils";

export default {
  name: "InqueryModal",
  data() {
    return {
      message: "",
      isSending: false,
    };
  },
  props: {
    isActive: Boolean,
    inqueryRid: Number,
    rowData: Object,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("myCue", ["setEmailAlert"]),
    getRecipient() {
      if (process.env.NODE_ENV === "development") return "marc@companycue.com";

      const rowInfo = this.rowData["Project Name - Inquiry Email"];
      if (rowInfo === "") {
        return FALLBACK_INQUIRY_EMAIL;
      } else {
        return rowInfo;
      }
    },
    handleSubmit() {
      const sendInqueryEmail = functions.httpsCallable("sendEmail");

      this.isSending = true;

      // in development, just send all emails to me - otherwise send to project manager
      const recpient = this.getRecipient();

      // link back to quickbase
      const link = `https://${realmID}/db/${appIDs.cueProjectManager.invoices}?a=dr&rid=${this.inqueryRid}`;

      // assemble email message
      const messageWithLink = `
        <h4>Message from Client</h4>
        <p>${this.user.email}</p>
        <p>${this.message}</p>
        <br/>
        <h4>Link to Quickbase Entry</h4>
        <span>${link}</span>

      `;

      sendInqueryEmail({
        from: '"Cue Hub" <cocueadmin@companycue.com>', // sender address
        to: recpient, // list of receivers
        subject: `[HUB] Client Inquiry: ${this.rowData["Project Name"]}`, // Subject line
        // text: this.message, // plain text body
        html: messageWithLink,
      })
        .then(() => {
          this.setEmailAlert("SUCCESS");
          this.$emit("closeModal");
        })
        .catch((err) => {
          console.error(err);
          this.setEmailAlert("FAIL");
          this.$emit("closeModal");
        });
    },
    handleClose() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.message-box-container {
  padding: 24px;
}
</style>
