<template>
  <div class="led-dashboard my-10 px-2">
    <div class="d-flex justify-space-between" :style="{ position: 'relative' }">
      <!-- left -->
      <div class="d-flex">
        <!-- new record -->
        <div class="text-center">
          <span class="overline"> New </span>
          <div class="mb-4">
            <v-btn
              :min-width="laptop ? '40px' : '42px'"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              @click="handleForm({})"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- form -->
        <v-dialog
          max-width="1200"
          :value="newLEDRecord"
          @click:outside="newLEDRecord = false"
          @keydown.esc="newLEDRecord = false"
        >
          <LEDForm
            :geoOptions="geos"
            :itemToEdit="itemToEdit"
            @led-dashboard-table-refresh="handleRefresh"
          />
        </v-dialog>

        <!-- report -->
        <div v-if="isAdmin || isManager" class="text-center ml-2">
          <span class="overline"> Report </span>
          <div>
            <v-btn
              height="38px"
              min-width="42px"
              class="elevation-2 px-0"
              color="secondary"
              :loading="showTableLoader"
              @click="downloadReport"
            >
              <v-icon>mdi-tray-arrow-down</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- clear filters -->
        <div class="text-center ml-2">
          <span class="overline"> Clear </span>
          <div class="mb-4">
            <v-btn
              :min-width="laptop ? '40px' : '42px'"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              @click="clearFilter(false)"
            >
              <v-icon>mdi-filter-remove</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- pay period filter -->
        <div class="ml-lg-3 ml-md-2">
          <span class="overline"> Pay Period </span>
          <div class="d-flex mb-4">
            <v-autocomplete
              class="led-dashboard__month-selector selector mr-2"
              :items="monthOptions"
              v-model="selectedMonth"
              solo
              dense
              hide-details
              @change="handleSelectedPeriod($event, 'ppm')"
            ></v-autocomplete>
            <v-autocomplete
              class="led-dashboard__year-selector selector"
              :items="yearOptions"
              v-model="selectedYear"
              solo
              dense
              hide-details
              @change="handleSelectedPeriod($event, 'ppy')"
            >
            </v-autocomplete>
          </div>
        </div>

        <!-- type filter -->
        <div class="ml-lg-2 ml-md-1 text-center">
          <span class="overline"> Type </span>
          <div>
            <v-btn-toggle
              class="elevation-2"
              color="accent"
              multiple
              dense
              v-model="filterType"
              @change="handleFilterQuery('Type', $event)"
            >
              <v-btn height="38px">Internal</v-btn>
              <v-btn height="38px">External</v-btn>
            </v-btn-toggle>
          </div>
        </div>

        <!-- delivery date filter -->
        <div class="ml-lg-3 ml-md-2 text-center">
          <span class="overline"> Delivery Date </span>
          <div class="d-flex align-end mb-4">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              solo-inverted
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filterDeliveryDate"
                  :class="{ laptop }"
                  class="led-dashboard__date-selector selector"
                  solo
                  dense
                  readonly
                  hide-details
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearFilter('DeliveryDate')"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterDeliveryDate"
                @input="handleFilterQuery('DeliveryDate', $event)"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>

        <!-- GEO filter -->
        <div v-if="isAdmin || isManager" class="ml-lg-3 ml-md-2 text-center">
          <span class="overline"> GEO </span>
          <div class="mb-4">
            <v-autocomplete
              class="led-dashboard__geo-selector selector"
              :items="geos"
              v-model="filterGeo"
              solo
              dense
              hide-details
              :loading="dropdownsLoading"
              @input="handleFilterQuery('Geo', $event)"
            ></v-autocomplete>
          </div>
        </div>

        <!-- person filter -->
        <transition>
          <div
            v-if="isAdmin || isManager"
            v-show="laptop ? !expandMenu : true"
            class="ml-lg-3 ml-md-2 text-center"
          >
            <span class="overline"> Person </span>
            <div class="d-flex mb-4">
              <v-autocomplete
                :class="{ laptop }"
                class="led-dashboard__person-selector selector mr-2"
                :items="employees"
                v-model="filterPerson"
                solo
                dense
                hide-details
                :loading="dropdownsLoading"
                @input="handleFilterQuery('Person', $event)"
              ></v-autocomplete>
            </div>
          </div>
        </transition>
      </div>

      <!-- right -->
      <div class="d-flex flex-column justify-start align-end my-0">
        <!-- search -->
        <span class="overline" :style="{ opacity: 0, userSelect: 'none' }"
          >Search</span
        >
        <div class="mb-4">
          <v-badge
            :content="laptopSmaller ? '...' : filterSearchBadge"
            :value="filterSearch && !expandMenu"
            color="secondary"
            overlap
          >
            <v-btn
              height="38px"
              min-width="48px"
              class="elevation-2 px-0"
              outlined
              color="accent"
              @click="expandMenu = !expandMenu"
            >
              <v-icon v-if="expandMenu">mdi-magnify-minus-outline</v-icon>
              <v-icon v-else>mdi-magnify-plus-outline</v-icon>
            </v-btn>
          </v-badge>
          <v-expand-x-transition>
            <v-text-field
              :style="{ position: 'absolute', bottom: '16px', right: '60px' }"
              v-show="expandMenu"
              v-model="filterSearch"
              class="led-dashboard__search-selector selector"
              solo
              dense
              hide-details
              clearable
            />
          </v-expand-x-transition>
        </div>
      </div>
    </div>
    <!-- table -->
    <v-card class="led-dashboard__table-card">
      <v-skeleton-loader
        v-if="showTableLoader"
        type="table"
        height="100vh"
      ></v-skeleton-loader>
      <LEDTable
        v-if="tableData && !showTableLoader"
        :tableData="tableData"
        :tableFields="tableFields"
        :filterDeliveryDate="filterDeliveryDate"
        :filterType="filterType"
        :filterSearch="filterSearch"
        :filterPerson="filterPerson ? filterPerson : ''"
        :filterGeo="filterGeo ? filterGeo : ''"
        @call-edit-form="handleForm"
      />
    </v-card>
    <!-- flash message -->
    <v-snackbar
      v-model="confirmationMsg"
      min-width="unset"
      timeout="2000"
      :color="success ? 'success' : 'error'"
      rounded="pill"
      content-class="text-center"
    >
      <v-icon color="white" class="mr-1">{{
        success ? "mdi-check-circle-outline" : "mdi-close-circle-outline"
      }}</v-icon>
      {{ confirmationMsgTxt }}
    </v-snackbar>
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// internal
import {
  getAssignments,
  appIDs,
  getEmployeesData,
  getFieldInfosFromDB,
} from "@/utils/quickbaseUtils";
import { customBreakpoint, timeUnits } from "@/utils/mixins";
import { ROUTES } from "@/utils/constants";
import { ledReport } from "@/utils/ledDashboardUtils";
// components
import LEDTable from "@/components/LEDDashboard/LEDTable";
import LEDForm from "@/components/LEDDashboard/LEDForm";

export default {
  name: "LEDDashboard",
  components: {
    LEDTable,
    LEDForm,
  },
  data() {
    return {
      showTableLoader: true,
      tableData: null,
      tableFields: null,
      selectedMonth: "",
      selectedYear: "",
      filterDeliveryDate: null,
      filterType: [0, 1],
      filterSearch: "",
      expandMenu: false,
      dropdownsLoading: true,
      employees: [],
      filterPerson: "",
      geos: [],
      filterGeo: "",
      newLEDRecord: false,
      itemToEdit: {},
      success: true,
      confirmationMsg: false,
      confirmationMsgTxt: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "isAdmin", "isManager"]),
    filterSearchBadge() {
      return this.filterSearch?.length <= 6
        ? this.filterSearch
        : `${this.filterSearch?.slice(0, 6)}...`;
    },
  },
  mixins: [customBreakpoint, timeUnits],
  watch: {
    filterSearch(newValue) {
      const currentQuery = this.$route.query;
      const newQuery =
        newValue !== ""
          ? {
              ...currentQuery,
              Search: newValue,
            }
          : _.omit(currentQuery, ["Search"]);

      this.$router
        .push({ path: `/${ROUTES.ledDashboard}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
  },
  mounted() {
    this.handleQuery();
    this.handleDropdowns();
  },
  methods: {
    handleSelectedPeriod(value, key, queryUpdate) {
      const query = { ...this.$route.query };

      if (value) {
        query[key] = value;
      } else {
        delete query[key];
      }

      this.$router
        .push({ path: `/${ROUTES.ledDashboard}`, query })
        .catch(() => {}); // Avoided redundant navigation error handler

      if (!queryUpdate) this.getTableData();
    },
    getTableData() {
      this.showTableLoader = true;
      getAssignments({
        payPeriod: `${this.selectedMonth} ${this.selectedYear}`,
        ledMode: true,
        personName: this.isAdmin || this.isManager ? false : this.user.Name,
      })
        .then((resp) => {
          const getWeekDates = (date) => {
            let weekDates = [];
            for (let i = 1; i <= 7; i++) {
              weekDates.push(
                this.$moment(this.$moment(date).day(i)._d).format("YYYY-MM-DD")
              );
            }
            return weekDates;
          };
          this.tableData = resp.data.map((el) => {
            return {
              ...el,
              groupWeek: getWeekDates(el.DeliveryDate).join(", "),
            };
          });
          this.tableFields = resp.fields;
          this.showTableLoader = false;
        })
        .catch((err) => {
          console.error(err);
          this.showTableLoader = false;
        });
    },
    handleFilterQuery(param, data) {
      const currentQuery = this.$route.query;

      const newQuery = {
        ...currentQuery,
        [param]: data,
      };
      this.$router
        .push({ path: `/${ROUTES.ledDashboard}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler

      this[`filter${param}`] = data;
    },
    clearFilter(date) {
      let query = this.$route.query;

      if (date) {
        query = _.omit(query, [date]);
        this[`filter${date}`] = null;
      } else {
        query = _.pick(query, ["ppm", "ppy"]);
        this.filterType = [0, 1];
        this.filterPerson = this.filterGeo = this.filterSearch = "";
      }

      if (!date) this.filterDeliveryDate = null;

      this.$router
        .push({ path: `/${ROUTES.ledDashboard}`, query })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
    getEmployees() {
      return new Promise((resolve, reject) => {
        this.isAdmin || this.isManager
          ? getEmployeesData({ namesOnly: true })
              .then((names) => resolve((this.employees = names)))
              .catch((err) => reject(console.error(err)))
          : resolve(null);
      });
    },
    getGeos() {
      return new Promise((resolve, reject) => {
        getFieldInfosFromDB({
          fieldID: 44,
          tableID: appIDs.iTunesProjectManager.assignments,
        })
          .then((geos) => {
            resolve((this.geos = geos));
          })
          .catch((err) => {
            reject(console.error(err));
          });
      });
    },
    handleDropdowns() {
      Promise.all([this.getEmployees(), this.getGeos()])
        .then(() => {
          this.dropdownsLoading = false;
        })
        .catch((err) => {
          this.dropdownsLoading = false;
          console.error(err);
        });
    },
    handleQuery() {
      const currentQuery = this.$route.query;

      if (_.isEmpty(currentQuery)) {
        this.selectedMonth = this.$moment().format("MMMM");
        this.selectedYear = this.$moment().format("YYYY");
      } else {
        if (currentQuery.ppm && currentQuery.ppy) {
          this.selectedMonth = currentQuery.ppm;
          this.selectedYear = currentQuery.ppy;
        } else {
          this.selectedMonth = this.$moment().format("MMMM");
          this.selectedYear = this.$moment().format("YYYY");
        }
        Object.keys(currentQuery).forEach((param) => {
          if (param !== "ppm" && param !== "ppy") {
            if (param === "Type") {
              this[`filter${param}`] =
                typeof currentQuery[param] === "string"
                  ? [Number(currentQuery[param])]
                  : currentQuery[param].map((x) => Number(x));
            } else {
              this[`filter${param}`] = currentQuery[param];
            }
          }
        });
      }

      this.handleSelectedPeriod(this.selectedMonth, "ppm", true);
      this.handleSelectedPeriod(this.selectedYear, "ppy", true);
      this.getTableData();
    },
    handleForm(tableRow) {
      this.itemToEdit = { ...tableRow };
      this.newLEDRecord = true;
    },
    handleRefresh(msg) {
      this.getTableData();
      this.newLEDRecord = false;
      this.success = !!msg;
      this.confirmationMsgTxt =
        msg ?? "Something went wrong. Please try again.";
      this.confirmationMsg = true;
    },
    downloadReport() {
      ledReport(
        this.tableData.filter(
          (el) => el.RelatedClientRequester === "Pilar Blanco"
        )
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.led-dashboard {
  max-width: 1500px;
  margin: 0 auto;

  &__month-selector {
    width: 120px;
  }

  &__year-selector {
    width: 80px;
  }

  &__date-selector {
    width: 130px;
    &.laptop {
      width: 120px;
    }
  }

  &__person-selector {
    width: 170px;
    &.laptop {
      width: 160px;
    }
  }

  &__geo-selector {
    width: 100px;
  }

  &__search-selector {
    width: 340px;
  }
}

::v-deep {
  .selector.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding-right: 0;
  }
  .selector.v-text-field.v-text-field--solo .v-input__append-inner {
    padding-left: 0;
  }
  .selector.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0;
  }
  .selector.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 2px 0;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'lg-and-down')} {
  .v-application .overline {
    font-size: 0.6rem !important;
  }
}
</style>
