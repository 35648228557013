<template>
  <EditorProjectSelectionList v-if="allProjects" />
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// library
// internal
// components
import EditorProjectSelectionList from "./EditorProjectSelectionList.vue";

export default {
  name: "EditorAssignmentsTableControls",
  components: { EditorProjectSelectionList },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("editor", ["allProjects"]),
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
