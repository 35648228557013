<template>
  <div class="pm-dashboard my-10 px-2">
    <div class="d-flex" :style="{ position: 'relative' }">
      <!-- left -->

      <!-- new task -->
      <div class="text-center">
        <span class="overline"> New </span>
        <div class="mb-4">
          <v-btn
            height="38px"
            :min-width="laptop ? '40px' : '42px'"
            class="elevation-2 px-0"
            color="secondary"
            @click="handleNewTask"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>

      <!-- clear filters -->
      <div class="text-center ml-1">
        <span class="overline"> Clear </span>
        <div class="mb-4">
          <v-btn
            height="38px"
            :min-width="laptop ? '40px' : '42px'"
            class="elevation-2 px-0"
            color="secondary"
            @click="clearFilter(false)"
          >
            <v-icon>mdi-filter-remove</v-icon>
          </v-btn>
        </div>
      </div>

      <div class="d-flex">
        <!-- period filter -->
        <div class="ml-lg-3 ml-md-2">
          <span class="overline"> Period </span>
          <div class="d-flex mb-4">
            <v-autocomplete
              class="pm-dashboard__month-selector selector mr-1"
              :items="monthOptions"
              v-model="selectedMonth"
              solo
              dense
              hide-details
              @change="handleSelectedPeriod($event, 'Month')"
            ></v-autocomplete>
            <v-autocomplete
              class="pm-dashboard__year-selector selector"
              :items="yearOptions"
              v-model="selectedYear"
              solo
              dense
              hide-details
              @change="handleSelectedPeriod($event, 'Year')"
            >
            </v-autocomplete>
          </div>
        </div>
        <!-- project filter -->
        <div class="ml-lg-2 ml-md-1 text-center">
          <span class="overline"> Project </span>
          <div>
            <v-btn-toggle
              class="elevation-2"
              color="accent"
              multiple
              dense
              v-model="filterProject"
              @change="handleFilterQuery('Project', $event)"
            >
              <v-btn min-width="38px" height="38px" class="px-0">FC</v-btn>
              <v-btn min-width="38px" height="38px" class="px-0"
                ><v-icon
                  :color="filterProject.includes(1) ? 'accent' : '#000000de'"
                  >mdi-music-note</v-icon
                ></v-btn
              >
            </v-btn-toggle>
          </div>
        </div>
        <!-- request date filter -->
        <div class="ml-lg-2 ml-md-1 text-center">
          <span class="overline"> Request Date </span>
          <div class="d-flex align-end mb-4">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              solo-inverted
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filterRequestDate"
                  class="pm-dashboard__date-selector selector"
                  solo
                  dense
                  readonly
                  hide-details
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearFilter('RequestDate')"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterRequestDate"
                @input="handleFilterQuery('RequestDate', $event)"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
        <!-- person filter -->
        <div class="ml-lg-2 ml-md-1 text-center">
          <span class="overline"> DRI </span>
          <div class="d-flex mb-4">
            <v-autocomplete
              :class="{ laptopSmaller }"
              class="pm-dashboard__person-selector selector"
              :items="employees"
              v-model="filterPerson"
              solo
              dense
              hide-details
              :loading="dropdownsLoading"
              @input="handleFilterQuery('Person', $event)"
            ></v-autocomplete>
          </div>
        </div>
      </div>
      <!-- task status filter -->
      <transition>
        <div v-show="!expandMenu" class="ml-lg-2 ml-md-1 text-center">
          <span class="overline"> Task Status </span>
          <div class="mb-4">
            <v-autocomplete
              :class="{ laptopSmaller }"
              class="pm-dashboard__main-selector selector"
              :items="taskStatuses"
              v-model="filterStatus"
              item-text="value"
              solo
              dense
              hide-details
              :loading="dropdownsLoading"
              @input="handleFilterQuery('Status', $event)"
            ></v-autocomplete>
          </div>
        </div>
      </transition>
      <!-- delivery date filter -->
      <transition>
        <div v-show="!expandMenu" class="ml-lg-2 ml-md-1 text-center">
          <span class="overline"> Delivery Date </span>
          <div class="d-flex align-end mb-4">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              solo-inverted
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filterDeliveryDate"
                  class="pm-dashboard__date-selector selector"
                  solo
                  dense
                  readonly
                  hide-details
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearFilter('DeliveryDate')"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterDeliveryDate"
                @input="handleFilterQuery('DeliveryDate', $event)"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
      </transition>

      <!-- right -->
      <div class="d-flex flex-column justify-start align-end my-0 ml-auto">
        <!-- search -->
        <span class="overline" :style="{ opacity: 0, userSelect: 'none' }"
          >Search</span
        >
        <div class="mb-4">
          <v-badge
            :content="filterSearchBadge"
            :value="filterSearch && !expandMenu"
            color="secondary"
            overlap
          >
            <v-btn
              height="38px"
              min-width="48px"
              class="elevation-2 px-0"
              outlined
              color="accent"
              @click="expandMenu = !expandMenu"
            >
              <v-icon v-if="expandMenu">mdi-magnify-minus-outline</v-icon>
              <v-icon v-else>mdi-magnify-plus-outline</v-icon>
            </v-btn>
          </v-badge>
          <v-expand-x-transition>
            <v-text-field
              :style="{ position: 'absolute', bottom: '16px', right: '60px' }"
              v-show="expandMenu"
              v-model="filterSearch"
              class="pm-dashboard__search-selector selector"
              solo
              dense
              hide-details
              clearable
            />
          </v-expand-x-transition>
        </div>
      </div>
    </div>
    <!-- table -->
    <v-card class="pm-dashboard__table-card">
      <v-skeleton-loader
        v-if="showTableLoader"
        type="table"
        height="100vh"
      ></v-skeleton-loader>
      <PMDashboardTable
        ref="pmDashboardTable"
        v-if="tableData && !showTableLoader"
        :tableData="tableData"
        :tableFields="tableFields"
        :filterDeliveryDate="filterDeliveryDate"
        :filterRequestDate="filterRequestDate"
        :filterSearch="filterSearch"
        :filterPerson="filterPerson ? filterPerson : ''"
        :filterStatus="filterStatus ? filterStatus : ''"
        :filterProject="filterProject"
        :taskStatuses="taskStatuses"
        :employees="employees"
        :dropdownOptionsLoading="dropdownsLoading"
        @call-edit-form="handleEditForm"
        @get-table-data="getTableData"
      />
    </v-card>
    <!-- task form -->
    <v-dialog
      v-model="taskForm"
      max-width="1200"
      @click:outside="taskForm = false"
      @keydown.esc="taskForm = false"
    >
      <PMDashboardForm
        :mediaTypes="mediaTypes"
        :taskStatuses="taskStatuses"
        :priorities="priorities"
        :employees="employees"
        :itemToEdit="itemToEdit"
        @close-task-form="handleFormClosing"
      />
    </v-dialog>
  </div>
</template>

<script>
// library
import _ from "lodash";
// internal
import {
  getTasks,
  getMediaTypeChoices,
  appIDs,
  getEmployeesData,
  getFieldInfosFromDB,
} from "@/utils/quickbaseUtils";
import { customBreakpoint, timeUnits } from "@/utils/mixins";
import { STATUS_COLORS, ROUTES } from "@/utils/constants";
import { functions } from "@/providers/firebase";
// components
import PMDashboardTable from "@/components/PMDashboard/PMDashboardTable";
import PMDashboardForm from "@/components/PMDashboard/PMDashboardForm.vue";

export default {
  name: "PMDashboard",
  components: {
    PMDashboardTable,
    PMDashboardForm,
  },
  data() {
    return {
      showTableLoader: false,
      tableData: null,
      tableFields: null,
      filterDeliveryDate: null,
      filterRequestDate: null,
      filterSearch: "",
      expandMenu: false,
      dropdownsLoading: true,
      employees: [],
      filterPerson: "",
      taskStatuses: [],
      filterStatus: "",
      priorities: [],
      mediaTypes: [],
      filterProject: [0, 1],
      taskForm: false,
      itemToEdit: {},
      selectedMonth: "",
      selectedYear: "",
    };
  },
  mixins: [customBreakpoint, timeUnits],
  computed: {
    filterSearchBadge() {
      return this.filterSearch?.length <= 6
        ? this.filterSearch
        : `${this.filterSearch?.slice(0, 6)}...`;
    },
  },
  watch: {
    filterSearch(newValue) {
      const currentQuery = this.$route.query;
      const newQuery =
        newValue !== ""
          ? {
              ...currentQuery,
              Search: newValue,
            }
          : _.omit(currentQuery, ["Search"]);

      this.$router
        .push({ path: `/${ROUTES.pmDashboard}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
  },
  mounted() {
    this.handleQuery();
    this.handleDropdowns();
  },
  methods: {
    handleSelectedPeriod(value, key, queryUpdate) {
      const query = { ...this.$route.query };

      if (value) {
        query[key] = value;
      } else {
        delete query[key];
      }

      this.$router
        .push({ path: `/${ROUTES.pmDashboard}`, query })
        .catch(() => {}); // Avoided redundant navigation error handler

      if (!queryUpdate) this.getTableData();
    },
    getTableData() {
      this.showTableLoader = true;
      getTasks({
        month: this.$moment().month(this.selectedMonth).format("MM"),
        year: this.selectedYear,
      }).then((resp) => {
        this.tableData = resp.data.map((task) => {
          return {
            ...task,
            RequestTime: new Date(`${task.RequestDate} ${task.RequestTime}`),
            DeliveryTime: new Date(`${task.DeliveryDate} ${task.DeliveryTime}`),
          };
        });
        this.tableFields = resp.fields;
        this.tableFields.push({
          text: "Inquiry",
          value: "Inquiry",
        });
        this.showTableLoader = false;
      });
    },
    handleFilterQuery(param, data) {
      const currentQuery = this.$route.query;

      const newQuery = {
        ...currentQuery,
        [param]: data,
      };
      this.$router
        .push({ path: `/${ROUTES.pmDashboard}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler

      this[`filter${param}`] = data;
    },
    clearFilter(date) {
      let query = this.$route.query;

      if (date) {
        query = _.omit(query, [date]);
        this[`filter${date}`] = null;
      } else {
        query = _.pick(query, ["Month", "Year"]);
        this.filterProject = [0, 1];
        this.filterPerson = this.filterStatus = this.filterSearch = "";
      }

      if (!date) this.filterRequestDate = this.filterDeliveryDate = null;

      this.$router
        .push({ path: `/${ROUTES.pmDashboard}`, query })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
    getDRIs() {
      return new Promise((resolve, reject) => {
        getEmployeesData()
          .then((employees) => {
            const managers = employees.data
              .filter((employee) => employee.ProjectCoordinator)
              .map((manager) => manager.Name);
            const getCueHubUsers = functions.httpsCallable("getAllUsers");
            getCueHubUsers().then((cueHubUsers) => {
              const admins = cueHubUsers.data.users
                .map((user) => {
                  if (user.customClaims.admin) {
                    const adminData = employees.data.find(
                      (admin) =>
                        admin.Email === user.email ||
                        admin.CueEmail === user.email
                    );
                    if (adminData) return adminData.Name;
                  }
                })
                .filter((el) => !!el);
              resolve(
                (this.employees = [...new Set([...managers, ...admins])].sort())
              );
            });
          })
          .catch((err) => reject(console.error(err)));
      });
    },
    getMediaTypes() {
      return new Promise((resolve, reject) => {
        getMediaTypeChoices({ instance: "iTunes" })
          .then((data) => resolve((this.mediaTypes = data)))
          .catch((err) => reject(console.error(err)));
      });
    },
    getStatuses() {
      return new Promise((resolve, reject) => {
        getFieldInfosFromDB({
          fieldID: 19,
          tableID: appIDs.iTunesProjectManager.pmDashboard,
        })
          .then((data) =>
            resolve(
              (this.taskStatuses = data.map((el) => {
                return {
                  value: el,
                  color: STATUS_COLORS.find((color) =>
                    color.statuses.includes(el)
                  )?.value,
                };
              }))
            )
          )
          .catch((err) => reject(console.error(err)));
      });
    },
    getPriorities() {
      return new Promise((resolve, reject) => {
        getFieldInfosFromDB({
          fieldID: 26,
          tableID: appIDs.iTunesProjectManager.pmDashboard,
        })
          .then((data) => resolve((this.priorities = data)))
          .catch((err) => reject(console.error(err)));
      });
    },
    handleDropdowns() {
      Promise.all([
        this.getDRIs(),
        this.getMediaTypes(),
        this.getStatuses(),
        this.getPriorities(),
      ])
        .then(() => (this.dropdownsLoading = false))
        .catch((err) => {
          this.dropdownsLoading = false;
          console.error(err);
        });
    },
    handleQuery() {
      const currentQuery = this.$route.query;
      if (_.isEmpty(currentQuery)) {
        this.selectedMonth = this.$moment().format("MMMM");
        this.selectedYear = this.$moment().format("YYYY");
      } else {
        if (currentQuery.Month && currentQuery.Year) {
          this.selectedMonth = currentQuery.Month;
          this.selectedYear = currentQuery.Year;
        } else {
          this.selectedMonth = this.$moment().format("MMMM");
          this.selectedYear = this.$moment().format("YYYY");
        }
        Object.keys(currentQuery).forEach((param) => {
          if (param !== "Month" && param !== "Year") {
            if (param === "Project") {
              this[`filter${param}`] =
                typeof currentQuery[param] === "string"
                  ? [Number(currentQuery[param])]
                  : currentQuery[param].map((x) => Number(x));
            } else {
              this[`filter${param}`] = currentQuery[param];
            }
          }
        });
      }
      this.handleSelectedPeriod(this.selectedMonth, "Month", true);
      this.handleSelectedPeriod(this.selectedYear, "Year", true);
      this.getTableData();
    },
    handleNewTask() {
      this.itemToEdit = {};
      this.taskForm = true;
    },
    handleFormClosing(i, data) {
      if (i !== undefined) {
        if (data) {
          Object.keys(data).forEach((key) => {
            this.tableData[i][key] =
              key === "DeliveryTime" || key === "RequestTime"
                ? new Date(
                    `${data[`${key.replace("Time", "")}Date`]} ${data[key]}`
                  )
                : data[key];
          });
          this.$refs.pmDashboardTable.updateTableData(
            i,
            "TaskStatus",
            data.TaskStatus
          );
        } else {
          this.tableData.splice(i, 1);
        }
      } else {
        this.getTableData();
      }
      this.taskForm = false;
    },
    handleEditForm(tableRow) {
      this.itemToEdit = { ...tableRow };
      this.taskForm = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.pm-dashboard {
  max-width: 1500px;
  margin: 0 auto;

  &__month-selector {
    width: 120px;
  }

  &__year-selector {
    width: 80px;
  }

  &__date-selector {
    width: 130px;
  }

  &__person-selector {
    width: 200px;
    &.laptopSmaller {
      width: 180px;
    }
  }

  &__main-selector {
    width: 150px;
    &.laptopSmaller {
      width: 140px;
    }
  }

  &__search-selector {
    width: 340px;
  }
}

::v-deep {
  .selector.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding-right: 0;
  }
  .selector.v-text-field.v-text-field--solo .v-input__append-inner {
    padding-left: 0;
  }
  .selector.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0;
  }
  .selector.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 2px 0;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'lg-and-down')} {
  .v-application .overline {
    font-size: 0.6rem !important;
  }
}
</style>
