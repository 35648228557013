<template>
  <div>
    <v-app-bar class="app-bar" app color="primary" dark>
      <div class="app-bar-section">
        <v-expand-x-transition>
          <v-app-bar-nav-icon
            v-if="showMenuButton && !profileLock && (isAdmin || isManager)"
            @click.stop="drawer = !drawer"
          />
        </v-expand-x-transition>
        <v-toolbar-title>
          <span :style="{ fontWeight: 700 }">Cue</span
          ><span :style="{ fontWeight: 200 }">Hub</span>
        </v-toolbar-title>
        <h2
          v-if="$route.name !== 'Home' && $route.name !== 'Login'"
          class="ml-10"
        >
          {{ $route.name }}
        </h2>
      </div>
      <NavBarUser />
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      class="nav-drawer-container"
      id="nav-drawer-container"
    >
      <v-list class="list" nav dense>
        <!-- ///////// DATA TOOLS /////////// -->
        <v-subheader v-if="isCueUI"
          >MyCue
          {{ isAdminUI || isManagerUI ? "Manager" : "Staff" }}</v-subheader
        >
        <!--  -->
        <v-list-item
          v-if="isAdminUI || isManagerUI"
          @click="handleListSelect(`${routesConst.pmDashboard}`)"
        >
          <v-list-item-title> PM Dashboard </v-list-item-title>
        </v-list-item>
        <!--  -->
        <v-list-item
          v-if="isFCView || isManagerUI"
          @click="handleListSelect(`${routesConst.fcDashboard}`)"
        >
          <v-list-item-title> FC Assignment Dashboard </v-list-item-title>
        </v-list-item>
        <!--  -->
        <v-list-item
          v-if="isCuePMView || isManagerUI"
          @click="handleListSelect(`${routesConst.cpmDashboard}`)"
        >
          <v-list-item-title> Cue Assignment Dashboard </v-list-item-title>
        </v-list-item>
        <!--  -->
        <v-list-item
          v-if="isAdminUI || isManagerUI"
          @click="handleListSelect(`${routesConst.issueTracker}`)"
        >
          <v-list-item-title> Issue Tracker </v-list-item-title>
        </v-list-item>
        <!--  -->
        <v-list-item
          v-if="isAdminUI || isManagerUI"
          @click="handleListSelect(`${routesConst.spreadsheetUpload}`)"
        >
          <v-list-item-title> Spreadsheet Upload </v-list-item-title>
        </v-list-item>
        <!--  -->
        <v-list-item
          v-if="isAdminUI || isManagerUI"
          @click="handleListSelect(`${routesConst.fileSplitter}`)"
        >
          <v-list-item-title> File Splitter </v-list-item-title>
        </v-list-item>
        <!--  -->
        <v-list-item
          v-if="isCueUI"
          @click="handleListSelect(`${routesConst.calendar}`)"
        >
          <v-list-item-title> Availability Calendar </v-list-item-title>
        </v-list-item>
        <!--  -->
        <v-list-item
          v-if="isFCReviewerView"
          @click="handleListSelect(`${routesConst.ledDashboard}`)"
        >
          <v-list-item-title> Lead Editor Dashboard </v-list-item-title>
        </v-list-item>
        <!--  -->
        <v-list-item
          v-if="isAdminUI || isManagerUI"
          @click="handleListSelect(`${routesConst.contacts}`)"
        >
          <v-list-item-title> Staff </v-list-item-title>
        </v-list-item>

        <!-- ///////// CLIENT TOOLS /////////// -->
        <v-subheader v-if="isAdminUI || isClientUI || isManagerUI"
          >MyCue Client</v-subheader
        >
        <!--  -->
        <v-list-item
          v-if="isAdminUI || isClientUI || isManagerUI"
          @click="handleListSelect(`${routesConst.myCue}`)"
        >
          <v-list-item-title> MyCue </v-list-item-title>
        </v-list-item>
        <!--  -->
        <v-list-item
          v-if="isAdminUI || isClientUI || isManagerUI"
          @click="handleListSelect(`${routesConst.request}`)"
        >
          <v-list-item-title> Request </v-list-item-title>
        </v-list-item>

        <!-- ///////// ADMIN /////////// -->
        <v-subheader v-if="isAdminUI">MyCue Admin</v-subheader>
        <!--  -->
        <v-list-item
          v-if="isAdminUI"
          @click="handleListSelect(`${routesConst.users}`)"
        >
          <v-list-item-title> Users </v-list-item-title>
        </v-list-item>
        <!--  -->
        <v-list-item
          v-if="isAdminUI"
          @click="handleListSelect(`${routesConst.invoiceBot}`)"
        >
          <v-list-item-title> Invoice Bot </v-list-item-title>
        </v-list-item>
        <!--  -->
        <v-list-item
          v-if="isAdminUI"
          @click="handleListSelect(`${routesConst.cueHardware}`)"
        >
          <v-list-item-title> Cue Hardware </v-list-item-title>
        </v-list-item>
        <!--  -->
        <v-list-item
          v-if="isAdminUI"
          @click="handleListSelect(`${routesConst.cueSoftware}`)"
        >
          <v-list-item-title> Cue Software </v-list-item-title>
        </v-list-item>

        <!-- //////////// CUE ONLY /////////////// -->
        <v-subheader v-if="isDubEditorView">EDITOR</v-subheader>
        <!--  -->
        <v-list-item
          v-if="isDubEditorView"
          @click="handleListSelect(`${routesConst.editor}`)"
        >
          <v-list-item-title> Dub Editor </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// library
import * as d3 from "d3";
// internal
import { doLogout } from "@/utils/authUtils";
import { PASSTHROUGH_ENV, ROUTES } from "@/utils/constants";
// components
import NavListSectionHeader from "@/components/typography/NavListSectionHeader";
import NavBarUser from "@/components/ui/NavBarUser";

export default {
  components: { NavListSectionHeader, NavBarUser },
  data: function () {
    return {
      drawer: false,
    };
  },
  methods: {
    handleListSelect(value) {
      this.drawer = false;
      const router = this.$router;
      if (value === "logout") {
        doLogout().then(() => router.push("login"));
      } else {
        router.push(value);
      }
    },
  },
  computed: {
    ...mapGetters("auth", [
      "user",
      "isAdmin",
      "company",
      "isDubEditor",
      "profileLock",
      "isManager",
    ]),
    userEmail() {
      return this.user.email;
    },
    showMenuButton() {
      if (process.env.NODE_ENV === PASSTHROUGH_ENV) {
        return false;
      } else {
        return Object.keys(this.user ?? {}).length;
      }
    },
    isAdminUI() {
      if (process.env.NODE_ENV === PASSTHROUGH_ENV) {
        return true;
      } else {
        return this.isAdmin;
      }
    },
    isManagerUI() {
      return this.isManager;
    },
    isClientUI() {
      return this.company !== "Company Cue, Inc.";
    },
    isCueUI() {
      return this.isAdmin || this.company === "Company Cue, Inc.";
    },
    isFCView() {
      return (
        this.isAdmin ||
        (this.isCueUI &&
          (this.user?.["LastDeliveryDate-Writer"] ||
            this.user?.["LastDeliveryDate-Editor"]))
      );
    },
    isCuePMView() {
      return (
        this.isAdmin ||
        (this.isCueUI &&
          (this.user?.["LastDeliveryDate-Primary"] ||
            this.user?.["LastDeliveryDate-Reviewer"]))
      );
    },
    isFCReviewerView() {
      return this.isAdmin || (this.isCueUI && this.user?.LeadEditor);
    },
    isDubEditorView() {
      if (process.env.NODE_ENV === PASSTHROUGH_ENV) {
        return true;
      } else {
        return this.isAdmin || this.isDubEditor;
      }
    },
    routesConst() {
      return ROUTES;
    },
  },
  mounted() {
    // bit of a hack here as CSS selectors we're playing nice.
    // this forces the list to be flex with main list at top and secondary list at bottom
    const a = d3
      .select("#nav-drawer-container")
      .select(".v-navigation-drawer__content");

    a.style("display", "flex");
    a.style("flex-flow", "column");
    a.style("justify-content", "space-between");
  },
};
</script>

<style lang="scss" scoped>
.app-bar-section {
  display: flex;
  flex-flow: row;
  align-items: center;
}

#nav-drawer-container {
  height: 100vh !important;
}

.v-list {
  .v-list-item {
    padding: 0px 20px !important;
  }
}
</style>
