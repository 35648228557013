import _ from "lodash";
const numeral = require("numeral");
const PO_FIELD = "Related Purchase Order - Purchase order Number";

export const getAssignmentTitles = (recordsData) => {
  return _.uniq(recordsData.map((el) => el["Assignment Title"]));
};

export const groupAssignmentsByTitle = (recordsData, fields) => {
  const groupedObj = _.groupBy(recordsData, (el) => el["Assignment Title"]);
  const retVal = _.keys(groupedObj).map((assignmentTitle) => {
    const getSum = (field) => {
      return _.sum(groupedObj[assignmentTitle].map((el) => el[field]));
    };
    return {
      "Assignment Title": assignmentTitle,
      Quantity: getSum("Quantity"),
      Total: numeral(getSum("Client Total")).format("$0.00"),
      Assignments: groupedObj[assignmentTitle].length,
      GEOS: _.uniq(groupedObj[assignmentTitle].map((el) => el.GEO)).sort(),
      POs: _.uniq(groupedObj[assignmentTitle].map((el) => el[PO_FIELD])).join(
        ", "
      ),
      actualTotal: getSum("Client Total"),
      Requester: _.uniq(
        groupedObj[assignmentTitle].map((el) => el["Related Client Requester"])
      ).join(", "),
      Descriptions: _.uniq(
        groupedObj[assignmentTitle].map((el) => el["Assignment Detail"])
      ).join(" "),
    };
  });

  return retVal;
};
