<template>
  <v-container class="pb-6 px-0">
    <v-row class="text-left ma-0">
      <!-- left column -->
      <v-col cols="12" sm="6" class="pa-0">
        <div class="overline">{{ softwareInfo["ServiceType"] }}</div>
        <h2>{{ softwareInfo["ServiceName"] }}</h2>
        <v-chip :disabled="!softwareInfo.PlanType" class="my-5">
          {{
            softwareInfo["PlanType"] ? softwareInfo["PlanType"] : "Plan Type"
          }}
        </v-chip>
        <div>
          <!-- Plan Name -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Plan Name</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              softwareInfo["PlanName"]
            }}</v-col>
          </v-row>

          <!-- Account Owner -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Account Owner</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">{{
              softwareInfo["AccountOwner"]
            }}</v-col>
          </v-row>

          <!-- User Agreement -->
          <v-row class="mx-0">
            <v-col
              cols="12"
              sm="6"
              class="py-1 px-0 font-weight-bold d-flex align-center"
              >User Agreement</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">
              <v-btn
              v-if="softwareInfo && softwareInfo.UserAgreement && softwareInfo.UserAgreement.url"
                color="accent"
                :loading="userAgreementLoading"
                @click="downloadUserAgreement"
              >
                Download<v-icon right>mdi-download</v-icon>
              </v-btn>
              <v-icon v-else>mdi-file-document-remove-outline</v-icon>
            </v-col>
          </v-row>

          <!-- Users -->
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-1 px-0 font-weight-bold"
              >Users</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">
              <ul>
                <li
                  v-for="user in softwareInfo['Users(non-relational)']"
                  :key="user"
                >
                  {{ user }}
                </li>
              </ul>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <!-- right column -->
      <v-col cols="12" sm="6">
        <div class="table-bg text-center mb-3">
          <h3 class="pb-2">Usage Info</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">User Amount (Max)</th>
                  <th class="text-center">User Amount (Current)</th>
                  <th class="text-center">Device Amount (Max)</th>
                  <th class="text-center">Device Amount (Current)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ softwareInfo["UserAmount(Max)"] }}
                  </td>
                  <td>
                    {{ softwareInfo["UserAmount(Current)"] }}
                  </td>
                  <td>
                    {{ softwareInfo["DeviceAmount(Max)"] }}
                  </td>
                  <td>
                    {{ softwareInfo["DeviceAmount(Current)"] }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div class="table-bg text-center">
          <h3 class="pb-2">Subscription Info</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Type</th>
                  <th class="text-center">Amount (Monthly)</th>
                  <th class="text-center">Start Date</th>
                  <th class="text-center">End Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ softwareInfo["SubscriptionType"] }}
                  </td>
                  <td>
                    {{
                      softwareInfo["SubscriptionAmount(Monthly)"]
                        ? `$${softwareInfo[
                            "SubscriptionAmount(Monthly)"
                          ].toFixed(2)}`
                        : ""
                    }}
                  </td>
                  <td>
                    {{ formatDate(softwareInfo["SubscriptionStartDate"]) }}
                  </td>
                  <td>{{ formatDate(softwareInfo["SubscriptionEndDate"]) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// library
import { saveAs } from "file-saver";
// internal
import { getFile } from "@/utils/quickbaseUtils";

export default {
  name: "CueSoftwareTableExpansion",
  props: {
    softwareInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userAgreementLoading: false,
    };
  },
  methods: {
    formatDate(date) {
      return date ? this.$moment(date).format("MM/DD/YY") : "";
    },
    downloadUserAgreement() {
      this.userAgreementLoading = true;
      getFile(this.softwareInfo.UserAgreement.url)
        .then(async (resp) => {
          const base64Response = await fetch(
            `data:image/jpg;base64,${resp.data}`
          );
          const blob = await base64Response.blob();
          saveAs(blob, `${this.softwareInfo.ServiceName}_UserAgreement.jpg`);
          this.userAgreementLoading = false;
        })
        .catch((err) => console.error(err));
    },
  },
};
</script>

<style lang="scss" scoped>
.table-bg {
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
</style>
