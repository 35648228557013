<template>
  <div class="assignments-table">
    <div class="assignments-table__header-and-controls">
      <div class="custom-card-header">Assignments</div>

      <div class="assignments-table__header-and-controls__right">
        <v-text-field
          class="search-field"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>
        <ReportSelector :selected="selectedReport" @change="handleChange" />
      </div>
    </div>

    <div v-if="groupedRecordsData">
      <template>
        <v-data-table
          :headers="outerTableHeaders"
          :items="groupedRecordsData"
          :search="search"
          :custom-filter="customSearch"
          :single-expand="true"
          item-key="Assignment Title"
          :expanded.sync="expanded"
          show-expand
        >
          <template v-slot:[`item.GEOS`]="{ item }">
            <v-chip v-for="(geo, index) in item.GEOS" :key="index">
              {{ geo }}
            </v-chip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <SubAssignmentsView
                :assignmentTitle="item['Assignment Title']"
                :cleanedTableData="cleanedTableData"
                :search="search"
              />
            </td>
          </template>
          <template slot="body.append" v-if="isSearchEmpty">
            <tr>
              <th>Pay Period Total</th>
              <th>{{ sumAssignments }}</th>
              <th>{{ sumQuantity }}</th>
              <th></th>
              <th>{{ sumTotal }}</th>
              <th></th>
            </tr>
          </template>
        </v-data-table>
      </template>
    </div>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// libraries
import _ from "lodash";
const numeral = require("numeral");
// internal
import { getAssignmentsFromClient } from "@/utils/quickbaseUtils";
import { cleanRecordsData } from "@/utils/quickbaseUtils";
import {
  groupAssignmentsByTitle,
  getAssignmentTitles,
} from "@/utils/myCueUtils";
// components
import ReportSelector from "@/components/ui/ReportSelector";
import SubAssignmentsView from "@/components/MyCue/SubAssignmentsView";

export default {
  name: "MyCueAssignmentsTable",
  components: { ReportSelector, SubAssignmentsView },
  data() {
    return {
      tableData: null,
      tableFields: null,
      expanded: [],
      search: "",
    };
  },
  watch: {
    myCueSelectedClient(newValue) {
      getAssignmentsFromClient({
        client: newValue,
        monthSelector: this.selectedReport,
      }).then((data) => {
        this.tableData = data.data;
        this.tableFields = data.fields;
      });
    },
    selectedReport(newValue) {
      getAssignmentsFromClient({
        client: this.myCueSelectedClient,
        monthSelector: newValue,
      }).then((data) => {
        this.tableData = data.data;
        this.tableFields = data.fields;
      });
    },
  },
  methods: {
    ...mapActions("myCue", ["setSelectedReport"]),
    handleChange(v) {
      this.setSelectedReport(v);
    },
    customSearch(value, search, item) {
      return Object.values(item).some(
        (v) => v && v.toString().toLowerCase().includes(search.toLowerCase())
      );
    },
  },
  computed: {
    ...mapGetters("myCue", [
      "myCueSelectedClient",
      "selectedReport",
      "selectedPO",
    ]),
    outerTableFields() {
      if (this.selectedPO) {
        return [
          "Assignment Title",
          "Assignments",
          "Quantity",
          "Requester",
          "Total",
          "POs",
          "GEOS",
        ];
      } else {
        return ["Assignment Title", "Assignments", "Quantity", "Total", "GEOS"];
      }
    },
    cleanedTableData() {
      if (!this.tableData) return null;

      return cleanRecordsData(this.tableData, this.tableFields);
    },
    groupedRecordsData() {
      if (!this.cleanedTableData) return null;
      const allPOGroup = groupAssignmentsByTitle(
        this.cleanedTableData,
        this.outerTableFields
      );

      if (this.selectedPO !== null && this.selectedPO !== "All") {
        return allPOGroup.filter((el) => el["POs"].includes(this.selectedPO));
      } else {
        return allPOGroup;
      }
    },
    sumAssignments() {
      if (!this.groupedRecordsData) return 0;
      return _.sumBy(this.groupedRecordsData, "Assignments");
    },
    sumQuantity() {
      if (!this.groupedRecordsData) return 0;
      return _.sumBy(this.groupedRecordsData, "Quantity");
    },
    sumTotal() {
      if (!this.groupedRecordsData) return 0;
      return numeral(_.sumBy(this.groupedRecordsData, "actualTotal")).format(
        "$0.00"
      );
    },
    outerTableHeaders() {
      const retVal = this.outerTableFields.map((el) => {
        return {
          text: el,
          value: el,
        };
      });
      retVal.push({ text: "", value: "data-table-expand" });
      return retVal;
    },
    assignmentTitlesInRecords() {
      if (!this.tableData) return null;

      return getAssignmentTitles(this.cleanedTableData);
    },
    isSearchEmpty() {
      return this.search === "";
    },
  },
  mounted() {
    getAssignmentsFromClient({
      client: this.myCueSelectedClient,
      monthSelector: this.selectedReport,
    }).then((data) => {
      this.tableData = data.data;
      this.tableFields = data.fields;
    });
  },
};
</script>

<style lang="scss" scoped>
.assignments-table {
  margin-top: 20px;
  min-width: 1165px;
  &__header-and-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;

    .custom-card-header {
      margin-right: 10px;
    }

    &__right {
      display: flex;
    }

    .search-field {
      width: 400px;
      padding-top: 18px;
      padding-right: 30px;
    }
  }
}
</style>
