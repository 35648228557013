<template>
  <div class="invoice-report-table-view">
    <div class="search-container">
      <v-text-field
        class="search-field"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </div>
    <v-data-table
      :headers="formatedHeaders"
      :items="formattedData"
      :search="search"
      :items-per-page="30"
      @current-items="handleFilter"
    >
      <template slot="body.append">
        <tr>
          <th>Pay Period Total</th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th>{{ sumInvoices }}</th>
          <th>{{ sumQuantity }}</th>
          <th>{{ sumTotal }}</th>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
const numeral = require("numeral");
export default {
  props: {
    selectedPM: Number,
  },
  data() {
    return {
      search: "",
      currentItems: null,
    };
  },
  methods: {
    handleFilter(e) {
      this.currentItems = e;
    },
  },
  computed: {
    ...mapGetters("invoiceBot", [
      "invoiceTablePMs",
      "filteredInvoiceRows",
      "invoiceTableHeader",
    ]),
    selectedPMName() {
      return this.invoiceTablePMs[this.selectedPM];
    },
    rowsForPM() {
      const pmColumnId = this.invoiceTableHeader.find(
        (el) => el.label === "Project Manager Conv Name"
      )["id"];

      return this.filteredInvoiceRows.filter(
        (el) => el[pmColumnId].value === this.selectedPMName
      );
    },
    formatedHeaders() {
      return this.invoiceTableHeader.map((el) => {
        return {
          text: el.label,
          value: `${el.id}`,
        };
      });
    },
    formattedData() {
      return this.rowsForPM.map((el) => {
        const retVal = {};
        _.keys(el).forEach((id) => {
          switch (id) {
            case "10":
              retVal[id] = numeral(el[id].value).format("$0.00");
              break;

            default:
              retVal[id] = el[id].value;
              break;
          }
        });
        return retVal;
      });
    },
    sumInvoices() {
      const INVOICE_INDEX = 8;
      if (!this.currentItems) return 0;
      return _.sumBy(this.currentItems, INVOICE_INDEX);
    },
    sumQuantity() {
      const SUM_INDEX = 9;
      if (!this.currentItems) return 0;
      return _.sumBy(this.currentItems, SUM_INDEX);
    },
    sumTotal() {
      const TOTAL_INDEX = 10;
      if (!this.currentItems) return 0;
      return numeral(
        _.sum(
          this.currentItems.map((el) =>
            parseFloat(el[TOTAL_INDEX].replace("$", ""))
          )
        )
      ).format("$0,0.00");
    },
  },
};
</script>

<style lang="scss" scoped>
.invoice-report-table-view {
  .search-container {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    .search-field {
      max-width: 300px;
      margin-bottom: 8px;
    }
  }
}
</style>
