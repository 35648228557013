<template>
  <v-app>
    <NavBar />
    <v-main class="grey lighten-4">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// vuex
import { mapActions } from "vuex";
// internal
import { auth } from "@/providers/firebase";
import "@/styles/styles.scss";
import { getEmployeesData } from "@/utils/quickbaseUtils";
// components
import NavBar from "@/components/ui/NavBar";

export default {
  name: "App",
  components: { NavBar },
  methods: {
    ...mapActions("auth", [
      "setIsAdmin",
      "setIsManager",
      "setCompany",
      "setUser",
      "setProfileFields",
      "setIsDubEditor",
    ]),
  },
  mounted() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.setUser(null);
        this.setIsAdmin(false);
        this.setIsManager(false);
        this.setIsDubEditor(false);
        this.setCompany(null);
      } else {
        auth.currentUser.getIdTokenResult().then((idTokenResult) => {
          const newAdmin = idTokenResult?.claims?.admin || false;
          const newCompany = idTokenResult?.claims?.company || "";
          const newIsDubEditor = idTokenResult?.claims?.dubEditor || false;

          this.setIsAdmin(newAdmin);
          this.setIsDubEditor(newIsDubEditor);
          this.setCompany(newCompany);

          getEmployeesData({ email: user.email })
            .then((resp) => {
              this.setUser({
                ...user,
                ...resp.data[0],
                admin: newAdmin,
                dubEditor: newIsDubEditor,
                company: newCompany,
                cueHub: true,
              });
              this.setIsManager(resp.data[0].ProjectCoordinator);
              this.setProfileFields(resp.fields);
              if (this.$route.path === "/login") {
                if (this.$route?.query?.to) {
                  this.$router.push(this.$route.query.to);
                } else {
                  this.$router.push("/");
                }
              }
            })
            .catch((err) => console.error(err));
        });
      }
    });
  },
};
</script>
