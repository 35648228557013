<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filteredData"
      item-key="index"
      sort-by="sortParams"
      :header-props="{ sortIcon: null }"
      show-expand
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
      }"
      :class="{ laptop, laptopSmaller }"
      @item-expanded="handleTableExpansion"
      @click:row="$emit('call-edit-form', $event)"
    >
      <!-- Request Date cell override -->
      <template v-slot:[`item.RequestDate`]="{ value }">
        <span>{{ formatDateTime(value) }}</span>
      </template>

      <!-- Request Time cell override -->
      <template v-slot:[`item.RequestTime`]="{ value }">
        <span>{{ formatDateTime(value, false, true) }}</span>
      </template>

      <!-- Delivery Date cell override -->
      <template v-slot:[`item.DeliveryDate`]="{ value }">
        <span>{{ formatDateTime(value) }}</span>
      </template>

      <!-- Delivery Time cell override -->
      <template v-slot:[`item.DeliveryTime`]="{ value }">
        <span>{{ formatDateTime(value, false, true) }}</span>
      </template>

      <!-- Item cell override -->
      <template v-slot:[`item.Task`]="{ value }">
        <v-tooltip right color="primary" :disabled="value.length <= 40">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ ellipseText(value, 40) }}</span>
          </template>
          <span>{{ value }}</span>
        </v-tooltip>
      </template>

      <!-- Status cell override -->
      <template v-slot:[`item.TaskStatus`]="{ item, value }">
        <PMDashboardDropdownSelector
          :item="item"
          :value="value"
          :statusMode="true"
          :dropdownOptions="taskStatuses"
          :dropdownOptionsLoading="dropdownOptionsLoading"
          @refresh-table-data="updateTableData"
          v-on="$listeners"
        />
      </template>

      <!-- DRI cell override -->
      <template v-slot:[`item.DRI`]="{ item, value }">
        <PMDashboardDropdownSelector
          :item="item"
          :value="value"
          :driMode="true"
          :dropdownOptions="employees"
          :dropdownOptionsLoading="dropdownOptionsLoading"
          @refresh-table-data="updateTableData"
          v-on="$listeners"
        />
      </template>

      <!-- Note cell override -->
      <template v-slot:[`item.NotesToClient`]="{ item }">
        <v-badge
          overlap
          color="secondary"
          :content="item.NotesToClient.length"
          :value="item.NotesToClient.length"
        >
          <v-icon :disabled="!item.NotesToClient.length" color="accent" large
            >mdi-chat-outline</v-icon
          >
        </v-badge>
      </template>

      <!-- Inquiry cell override -->
      <template v-slot:[`item.Inquiry`]="{ item }">
        <v-btn
          fab
          x-small
          outlined
          elevation="1"
          color="accent"
          @click.native.stop.prevent="openSlackForm(item)"
          ><v-icon>mdi-slack</v-icon></v-btn
        >
      </template>

      <!-- QC cell override -->
      <template v-slot:[`item.QC`]="{ item, value }">
        <v-tooltip :disabled="!item.Validation" bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <PMDashboardValidationSelector
                :item="item"
                :value="value"
                :leadPMEmail="leadPM.Email"
                @refresh-table-data="updateTableData"
                v-on="$listeners"
              />
            </span>
          </template>
          {{
            formatDateTime(item.Validation.split(" by ")[0], true) +
            " by " +
            item.Validation.split(" by ")[1]
          }}
        </v-tooltip>
      </template>

      <!-- Expanded panel -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="px-0">
          <v-skeleton-loader
            v-if="showExpansionTableLoader"
            type="table"
            height="500px"
          ></v-skeleton-loader>
          <PMDashboardTableExpansion
            v-else
            :expansionTableData="item.Assignments.data"
            :expansionTableFields="item.Assignments.fields"
            :expansionTableTitle="item.CueTaskID"
            @open-slack-form="openSlackForm(item)"
          />
        </td>
      </template>
    </v-data-table>
    <v-dialog
      max-width="1200"
      v-model="slackFormModal"
      @click:outside="slackFormModal = false"
      @keydown.esc="slackFormModal = false"
    >
      <SlackInquiryForm
        :inquiryItem="inquiryTask"
        :mode="'task'"
        :leadPMSlack="leadPM.SlackFCWorkspaceID"
        @close-slack-form="confirmInquiry"
      />
    </v-dialog>
    <v-snackbar
      v-model="inquirySuccess"
      min-width="unset"
      timeout="2000"
      color="success"
      rounded="pill"
      content-class="text-center"
    >
      <v-icon color="white" class="mr-1">mdi-check-circle-outline</v-icon>
      Inquiry sent!
    </v-snackbar>
  </div>
</template>

<script>
// libraries
import _ from "lodash";
import Fuse from "fuse.js";
// internal
import {
  getAssignments,
  updateMultipleDBRowStatus,
  getEmployeesData,
  leadPM,
} from "@/utils/quickbaseUtils";
import { customBreakpoint } from "@/utils/mixins";
// component
import PMDashboardDropdownSelector from "./PMDashboardDropdownSelector.vue";
import PMDashboardValidationSelector from "./PMDashboardValidationSelector.vue";
import PMDashboardTableExpansion from "./PMDashboardTableExpansion.vue";
import SlackInquiryForm from "../ui/SlackInquiryForm.vue";

export default {
  name: "PMDashboardTable",
  components: {
    PMDashboardDropdownSelector,
    PMDashboardValidationSelector,
    PMDashboardTableExpansion,
    SlackInquiryForm,
  },
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    tableFields: {
      type: Array,
      required: false,
    },
    filterDeliveryDate: {
      type: String,
      required: false,
    },
    filterRequestDate: {
      type: String,
      required: false,
    },
    filterSearch: {
      type: String,
      required: false,
    },
    filterPerson: {
      type: String,
      required: true,
    },
    filterStatus: {
      type: String,
      required: true,
    },
    filterProject: {
      type: Array,
      required: true,
    },
    taskStatuses: {
      type: Array,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
    dropdownOptionsLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      defaultHeaders: [
        "Task",
        "CueTaskID",
        "RequestDate",
        "RequestTime",
        "ClientRequester",
        "DRI",
        "TaskStatus",
        "DeliveryDate",
        "DeliveryTime",
        "NotesToClient",
        "Inquiry",
        "QC",
      ],
      expansionTableData: null,
      expansionTableFields: null,
      showExpansionTableLoader: false,
      slackFormModal: false,
      inquiryTask: {},
      inquirySuccess: false,
      leadPM: {},
    };
  },
  computed: {
    headers() {
      if (!this.tableFields) return [];

      let retVal = this.tableFields
        .filter((el) => this.defaultHeaders.includes(el.value))
        .map((field) => {
          return {
            text: field.text,
            value: field.value,
          };
        });

      // order by the order of the defaultHeaders
      retVal = _.orderBy(retVal, (el) => this.defaultHeaders.indexOf(el.value));

      // add expandable item
      retVal.push({ text: "", value: "data-table-expand" });

      return retVal;
    },
    tableFieldValues() {
      return this.tableFields.map((field) => field.value);
    },
    dataWithGroupInfo() {
      return this.tableData.map((el, i) => {
        return {
          ...el,
          sortParams: [el["RequestDate"], el["RequestTime"]],
          index: i,
        };
      });
    },
    filteredData() {
      let filteredData = [];

      // project filtered
      const filterMap = ["iTunes FC", "iTunes Music"];

      const inProjects = this.filterProject.map((el) => filterMap[el]);

      filteredData = this.dataWithGroupInfo.filter((el) => {
        return inProjects.includes(el.RelatedProject);
      });

      // hide Archived
      const archived = filteredData.filter(
        (el) => el.TaskStatus === "Archived"
      );
      filteredData = filteredData.filter((el) => el.TaskStatus !== "Archived");

      if (this.filterDeliveryDate) {
        filteredData = filteredData.filter((el) => {
          return (
            this.$moment(el["DeliveryDate"]).format("YYYY-MM-DD") ===
            this.filterDeliveryDate
          );
        });
      }

      if (this.filterRequestDate) {
        filteredData = filteredData.filter((el) => {
          return (
            this.$moment(el["RequestDate"]).format("YYYY-MM-DD") ===
            this.filterRequestDate
          );
        });
      }

      if (this.filterPerson) {
        filteredData = filteredData.filter(
          (el) => el["DRI"] === this.filterPerson
        );
      }

      if (this.filterStatus) {
        filteredData =
          this.filterStatus === "Archived"
            ? [...archived]
            : filteredData.filter(
                (el) => el["TaskStatus"] === this.filterStatus
              );
      }

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: this.tableFieldValues,
      };

      if (this.filterSearch) {
        const fuse = new Fuse(filteredData, searchOptions);
        filteredData = fuse.search(this.filterSearch).map((el) => el.item);
      } else {
        filteredData = filteredData;
      }

      return filteredData;
    },
  },
  mixins: [customBreakpoint],
  methods: {
    formatDateTime(data, withtTime, timeOnly) {
      return this.$moment(data).format(
        `${timeOnly ? "hh:mm A" : `MM/DD/YYYY${withtTime ? " hh:mm A" : ""}`}`
      );
    },
    ellipseText(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + "...";
      } else {
        return text;
      }
    },
    updateTableData(index, key, value, qcValidation, archiving) {
      this.tableData[index][key] = value;
      if (
        key === "TaskStatus" &&
        (value === "Assigned" ||
          value === "Delivered" ||
          value === "Canceled (Billed)" ||
          value === "Canceled (Not Billed)")
      ) {
        const task = { item: { ...this.tableData[index], index } };
        this.handleTableExpansion(task)
          .then((rows) => {
            this.showExpansionTableLoader = true;
            updateMultipleDBRowStatus({
              rows,
              status: value.includes("Canceled")
                ? value.includes("Not")
                  ? "Canceled"
                  : "Delivered"
                : value,
            })
              .then(() => {
                this.tableData[index].Assignments.data.forEach(
                  (el) => (el.AssignmentStatus = value)
                );
                this.showExpansionTableLoader = false;
              })
              .catch((err) => {
                this.showExpansionTableLoader = false;
                console.error(err);
              });
          })
          .catch((err) => {
            console.error(err);
          });
      } else if (key === "QC") {
        this.tableData[index].Validation = qcValidation;
        if (archiving) this.tableData[index].TaskStatus = "Archived";
      }
    },
    handleTableExpansion(data) {
      return new Promise((resolve, reject) => {
        if (data.item.Assignments === "") {
          this.showExpansionTableLoader = true;
          getAssignments({
            cueID: data.item.CueTaskID,
          })
            .then((resp) => {
              this.tableData[data.item.index].Assignments = {};
              this.tableData[data.item.index].Assignments.data = resp.data;
              this.tableData[data.item.index].Assignments.fields = resp.fields
                .map((field) => {
                  return {
                    text: field.text.replace("(Time of Day)", "Time"),
                    value: field.value,
                  };
                })
                .filter((field) => field.value !== "RecordID#");
              this.showExpansionTableLoader = false;
              resolve(resp.data);
            })
            .catch((err) => {
              console.error(err);
              this.showExpansionTableLoader = false;
              reject(err);
            });
        } else {
          resolve(this.tableData[data.item.index].Assignments.data);
        }
      });
    },
    openSlackForm(item) {
      this.inquiryTask = { ...item };
      this.slackFormModal = true;
    },
    confirmInquiry() {
      this.slackFormModal = false;
      this.inquirySuccess = true;
    },
    getLeadPMData() {
      getEmployeesData({ email: leadPM })
        .then((resp) => (this.leadPM = { ...resp.data[0] }))
        .catch((err) => console.error(err));
    },
  },
  mounted() {
    this.getLeadPMData();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table-header {
    background-color: #9af9ef70;
    & > tr > th {
      color: #003a35 !important;
      padding: 0 12px !important;
      &:hover {
        color: rgba(0, 0, 0, 0.6) !important;
      }
    }
  }
  tbody > tr {
    cursor: pointer;
    & > td {
      padding: 0 12px !important;
      &:nth-child(2) {
        pointer-events: none;
      }
    }
  }
  .v-data-table.laptop .v-data-table__wrapper table {
    & tbody > tr:not(.v-data-table__expanded__content) > td {
      padding: 0 6px !important;
    }
    & .v-data-table-header tr > th {
      padding: 0 6px !important;
    }
  }
  .v-data-table.laptopSmaller .v-data-table__wrapper table {
    & tbody > tr:not(.v-data-table__expanded__content) > td {
      padding: 0 4px !important;
    }
    & .v-data-table-header tr > th {
      padding: 0 4px !important;
    }
  }
}

.v-application .text-start {
  text-align: center !important;
}
</style>
