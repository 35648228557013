<template>
  <div class="users">
    <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
    <v-data-table
      v-if="usersData && !loading"
      :headers="headers"
      :items="usersData"
      class="elevation-1 users__table"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Users Info</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <!-- <v-spacer></v-spacer> -->
          <v-dialog v-model="dialog" max-width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New User
              </v-btn>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row v-if="isNewUser">
                    Adding a new user will automatically send an email to that
                    user to setup password.
                  </v-row>
                  <v-row v-if="isNewUser">
                    <v-col cols="12" sm="5" md="5">
                      <v-checkbox
                        label="Add from Quickbase User DB"
                        v-model="addUserFromQB"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row v-if="addUserFromQB">
                    <v-col cols="12" sm="9" md="9">
                      <v-combobox
                        outlined
                        hide-details
                        clearable
                        :loading="namesLoading"
                        :items="staffNameItems"
                        :search-input.sync="staffSearch"
                        :value="selectedQBStaff"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="9" md="9">
                      <v-text-field
                        v-model="editedItem.email"
                        label="User Email"
                        :disabled="
                          !isNewUser ||
                          (staffNameItems.length > 0 &&
                            staffSearch &&
                            addUserFromQB)
                        "
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="9" md="9">
                      <v-autocomplete
                        v-model="editedItem['company']"
                        label="Company"
                        :items="companies"
                        :disabled="
                          staffNameItems.length > 0 &&
                          staffSearch &&
                          addUserFromQB
                        "
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="3" md="3">
                      <v-checkbox
                        label="Dub Editor"
                        v-model="editedItem['isDubEditor']"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-checkbox
                        v-model="editedItem['isAdmin']"
                        label="Admin"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this user?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.isAdmin`]="{ item }">
        <div>
          <v-icon v-if="item.isAdmin">mdi-check</v-icon>
        </div>
      </template>
      <template v-slot:[`item.isDubEditor`]="{ item }">
        <div>
          <v-icon v-if="item.isDubEditor">mdi-check</v-icon>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small class="mr-2" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
        <!-- <v-icon small class="mr-2" @click="sendPasswordReset(item)">
          mdi-lock-reset
        </v-icon> -->
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// internal
import { functions, auth } from "@/providers/firebase";
import {
  getAllClientNames,
  getStaffNamesFromFragment,
} from "@/utils/quickbaseUtils";
import { createNewUserWithPasswordSendEmail } from "@/utils/authUtils";
import { DOMAIN } from "@/utils/constants";

export default {
  name: "Users",
  data() {
    return {
      usersData: null,
      headers: [
        {
          text: "User Email",
          align: "start",
          value: "email",
        },
        {
          text: "Company",
          align: "start",
          sortable: true,
          value: "company",
        },
        {
          text: "Admin",
          align: "start",
          sortable: true,
          value: "isAdmin",
        },
        {
          text: "DubEditor",
          align: "start",
          sortable: true,
          value: "isDubEditor",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      idToDelete: null,
      search: "",
      loading: true,
      companies: [],
      addUserFromQB: false,
      namesLoading: false,
      staffNameItems: [],
      staffSearch: null,
      selectedQBStaff: null,
    };
  },
  computed: {
    isNewUser() {
      return this.editedIndex === -1;
    },
    formTitle() {
      return this.isNewUser ? "New User" : "Edit User";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    staffSearch(val) {
      if (val && val.length > 2) {
        this.doNamesSearch(val);
      }
    },
    staffNameItems: {
      deep: true,
      handler: function (values) {
        if (values[0]) {
          const newEditedItem = { ...this.editedItem };
          newEditedItem.email = values[0].value.email;
          newEditedItem.company = "Company Cue, Inc.";
          this.editedItem = newEditedItem;
        }
      },
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.usersData.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.usersData.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
      this.idToDelete = item.id;
    },
    sendPasswordReset(item) {
      auth.sendPasswordResetEmail(item.email, {
        url: `https://${DOMAIN}`,
      });
    },
    deleteItemConfirm() {
      const deleteUser = functions.httpsCallable("deleteUser");
      deleteUser(this.editedItem).then(() => {
        this.closeDelete();
        this.refreshUsersData();
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedItem.uid) {
        // updating a user
        this.addClaims(this.editedItem).then((resp) => {
          this.refreshUsersData();
          this.close();
        });
      } else {
        createNewUserWithPasswordSendEmail({
          email: this.editedItem.email ?? false,
          company: this.editedItem.company ?? "",
          isAdmin: this.editedItem.isAdmin ?? false,
          isDubEditor: this.editedItem.dubEditor ?? false,
        })
          .then(() => {
            this.refreshUsersData();
            this.close();
          })
          .catch((err) => {
            console.warn(err);
          });
      }
    },
    refreshUsersData() {
      const listAllUsers = functions.httpsCallable("getAllUsers");
      listAllUsers().then((result) => {
        const expandedClaims = result.data.users.map((d) => {
          return {
            ...d,
            isAdmin: d.customClaims?.admin ?? false,
            isDubEditor: d.customClaims?.dubEditor ?? false,
            company: d.customClaims?.company || "",
          };
        });

        this.usersData = expandedClaims;
        this.loading = false;
      });
    },
    doNamesSearch(val) {
      this.namesLoading = true;
      getStaffNamesFromFragment(val)
        .then((items) => {
          this.staffNameItems = items.map((el) => {
            return {
              text: el.name,
              value: el,
            };
          });
          this.namesLoading = false;
        })
        .catch((err) => {
          console.warn(err);
          this.namesLoading = false;
        });
    },
  },
  mounted() {
    this.addClaims = functions.httpsCallable("addClaims");
    this.refreshUsersData();

    getAllClientNames().then((data) => {
      this.companies = data;
    });
  },
};
</script>

<style lang="scss" scoped>
.users {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
}
</style>
