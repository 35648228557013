<template>
  <div class="flow-stepper">
    <v-simple-table id="stepper-table">
      <thead>
        <tr>
          <th>
            Flow Step
          </th>
          <th>
            Person
          </th>
          <th>
            Due Date
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody ref="table-body">
        <tr
          v-for="(item, i) in thisAssignmentSteps"
          :key="i"
          class="flow-table-row"
        >
          <!-- <td>{{ item.step }}</td> -->
          <!-- <td>{{ item.person }}</td> -->
          <td>
            <v-text-field
              class="step-field"
              dense
              filled
              solo-inverted
              hide-details
              :value="item.label"
              @change="handleStepChange($event, i)"
            ></v-text-field>
          </td>
          <td>
            <v-autocomplete
              class="name-field"
              dense
              solo-inverted
              hide-details
              :value="item.person"
              :items="selectedProject.staffPool"
              @input="handlePersonChange($event, i)"
            ></v-autocomplete>
          </td>
          <td>
            <v-menu
              :close-on-content-click="false"
              :nudge-left="45"
              transition="scale-transition"
              offset-y
              solo-inverted
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="item.stepDueDate"
                  solo-inverted
                  dense
                  readonly
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :value="item.stepDueDate"
                @input="handleStepDueDateChange($event, i)"
              ></v-date-picker>
            </v-menu>
          </td>
          <td>
            <v-icon @click="handleRemoveStep(i)">mdi-minus</v-icon>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <div class="add-step">
      <v-btn
        x-small
        outlined
        color="accent"
        @click="handleNewStep(thisAssignmentSteps.length)"
      >
        <v-icon class="btn-icon" small>
          mdi-plus
        </v-icon>
        add step</v-btn
      >
    </div>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// library
import _ from "lodash";
// internal
// components
export default {
  name: "FlowStepper",
  props: {
    datasIndex: {
      type: Number,
      require: false,
    },
    editMode: {
      type: Boolean,
      require: false,
    },
  },
  data() {
    return {
      items: null,
    };
  },
  computed: {
    ...mapGetters("editor", [
      "newAssignmentDatas",
      "editAssignmentData",
      "selectedProject",
    ]),
    thisAssignmentSteps() {
      if (this.editMode) {
        return this.editAssignmentData.flow;
      } else {
        return this.newAssignmentDatas[this.datasIndex].flow;
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapActions("editor", ["setNewAssignmentDatas", "setEditAssignmentData"]),

    handleStepChange(value, index) {
      if (this.editMode) {
        const newPayload = _.cloneDeep(this.editAssignmentData);
        newPayload.flow[index].label = value;
        this.setEditAssignmentData(newPayload);
      } else {
        const newPayload = _.cloneDeep(this.newAssignmentDatas);
        const indexCopy = _.cloneDeep(newPayload[this.datasIndex]);
        indexCopy.flow[index].label = value;
        newPayload[this.datasIndex] = indexCopy;
        this.setNewAssignmentDatas(newPayload);
      }
    },
    handlePersonChange(value, index) {
      if (this.editMode) {
        const newPayload = _.cloneDeep(this.editAssignmentData);
        const newAssignees = _.cloneDeep(newPayload.assignees);
        newPayload.flow[index].person = value;
        newAssignees[index] = value.email;
        newPayload.assignees = newAssignees;
        this.setEditAssignmentData(newPayload);
      } else {
        const newPayload = _.cloneDeep(this.newAssignmentDatas);
        const indexCopy = _.cloneDeep(newPayload[this.datasIndex]);
        const newAssignees = _.cloneDeep(indexCopy.assignees);
        indexCopy.flow[index].person = value;
        newAssignees[index] = value.email;
        indexCopy.assignees = newAssignees;
        newPayload[this.datasIndex] = indexCopy;
        this.setNewAssignmentDatas(newPayload);
      }
    },
    handleStepDueDateChange(value, index) {
      if (this.editMode) {
        const newPayload = _.cloneDeep(this.editAssignmentData);
        newPayload.flow[index].stepDueDate = value;
        this.setEditAssignmentData(newPayload);
      } else {
        const newPayload = _.cloneDeep(this.newAssignmentDatas);
        const indexCopy = _.cloneDeep(newPayload[this.datasIndex]);
        indexCopy.flow[index].stepDueDate = value;
        newPayload[this.datasIndex] = indexCopy;
        this.setNewAssignmentDatas(newPayload);
      }
    },
    handleNewStep(i) {
      if (this.editMode) {
        const newPayload = _.cloneDeep(this.editAssignmentData);
        newPayload.flow.push({
          label: "",
          person: "",
          stepDueDate: "",
          index: i,
          isComplete: false,
        });
        this.setEditAssignmentData(newPayload);
      } else {
        const newPayload = _.cloneDeep(this.newAssignmentDatas);
        const indexCopy = _.cloneDeep(newPayload[this.datasIndex]);
        indexCopy.flow.push({
          label: "",
          person: "",
          stepDueDate: "",
          index: i,
          isComplete: false,
        });
        newPayload[this.datasIndex] = indexCopy;
        this.setNewAssignmentDatas(newPayload);
      }
    },
    handleRemoveStep(i) {
      if (this.editMode) {
        const newPayload = _.cloneDeep(this.editAssignmentData);
        newPayload.flow.splice(i, 1);
        this.setEditAssignmentData(newPayload);
      } else {
        const newPayload = _.cloneDeep(this.newAssignmentDatas);
        const indexCopy = _.cloneDeep(newPayload[this.datasIndex]);
        indexCopy.flow.splice(i, 1);
        newPayload[this.datasIndex] = indexCopy;
        this.setNewAssignmentDatas(newPayload);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.flow-stepper {
  #stepper-table {
    background-color: transparent;
    .flow-table-row {
      .step-field {
        width: 100px;
      }
      .name-field {
      }
    }
  }

  .add-step {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    font-size: 14px;
    padding-top: 10px;

    .btn-icon {
      transform: translateY(0.09em);
    }
  }
}
</style>
