<template>
  <v-form
    ref="form"
    :disabled="!allowedToEdit"
    v-model="valid"
    lazy-validation
    class="v-card v-sheet theme--light"
  >
    <v-tabs v-model="tab" background-color="transparent" grow>
      <v-tab v-for="step in steps" :key="step">
        {{ step }}
      </v-tab>
      <v-btn
        v-if="isEditMode && (isAdmin || isManager)"
        class="ma-1"
        height="40px"
        small
        color="secondary"
        :loading="deletionProcessing"
        @click="handleRecordDeletion"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="12" sm="4" class="pb-0">
              <v-autocomplete
                outlined
                label="Related Project"
                :items="projectNameItems"
                :loading="dropdownsLoading"
                v-model="relatedProject"
                item-text="name"
                return-object
                :disabled="!isAdmin && !isManager"
                :rules="[rules.required]"
                @change="updatePO($event)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-text-field
                outlined
                label="Related Purchase Order"
                v-model="po"
                disabled
                :loading="poLoading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-menu
                v-model="requestDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="requestDate"
                    :rules="[rules.required]"
                    label="Request Date"
                    prepend-icon="mdi-calendar-plus"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="requestDateMenu = false"
                  v-model="requestDate"
                  :min="allowedCalendarDates"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Related Client Requester"
                :items="clientRequesters"
                :loading="dropdownsLoading"
                v-model="clientRequester"
                :disabled="!isAdmin && !isManager"
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Request Type"
                :items="requestTypes"
                :loading="dropdownsLoading"
                v-model="requestType"
                :disabled="!isAdmin && !isManager"
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-combobox
                outlined
                label="Assignment Title"
                :items="assignmentTitles"
                v-model="assignmentName"
                :rules="[rules.required]"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                outlined
                label="Assignment Detail"
                v-model="assignmentDetails"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Media Type"
                :items="mediaTypes"
                :loading="dropdownsLoading"
                v-model="mediaType"
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Keyword"
                :items="keywords"
                :loading="dropdownsLoading"
                v-model="keyword"
                :disabled="!isAdmin && !isManager"
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item eager>
        <v-container>
          <v-row>
            <v-col cols="12" sm="4" class="pb-0">
              <v-autocomplete
                outlined
                label="GEO"
                :disabled="!isAdmin && !isManager"
                :items="geoOptions"
                v-model="geo"
                :rules="[rules.required]"
                @change="handleWorkTypeRef($event)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-text-field
                outlined
                label="Word Count"
                type="number"
                v-model="wordCount"
              />
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-menu
                v-model="deliveryDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="deliveryDate"
                    :rules="[rules.required]"
                    label="Delivery Date"
                    prepend-icon="mdi-calendar-check"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="deliveryDateMenu = false"
                  v-model="deliveryDate"
                  :min="allowedCalendarDates"
                  @change="handlePayPeriod($event)"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Writer"
                :disabled="!isAdmin && !isManager"
                :items="relatedWriters"
                :loading="staffLoading"
                v-model="writer"
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-menu
                v-model="payPeriodMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="payPeriod"
                    :rules="[rules.required]"
                    label="Pay Period"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="payPeriodMenu = false"
                  v-model="payPeriod"
                  type="month"
                  :min="allowedCalendarDates"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Work Type 1 Reference"
                :items="workType1References"
                v-model="workType1Reference"
                :disabled="!isAdmin && !isManager"
                :loading="dropdownsLoading || workType1ReferenceLoading"
                :rules="[rules.required]"
                @change="handleRequester($event)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Work Type 1 Quantity"
                :items="workType1Quantities"
                v-model="workType1Quantity"
                :rules="[rules.required]"
              ></v-autocomplete></v-col
          ></v-row>
          <v-row>
            <v-col cols="12" sm="3" class="d-flex justify-center">
              <v-checkbox
                class="mt-0"
                v-model="spellingTypos"
                label="Spelling/Typos"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="3" class="d-flex justify-center">
              <v-checkbox
                class="mt-0"
                v-model="grammarSyntax"
                label="Grammar/Syntax"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="3" class="d-flex justify-center">
              <v-checkbox
                class="mt-0"
                v-model="factualError"
                label="Factual Error"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="3" class="d-flex justify-center">
              <v-checkbox
                class="mt-0"
                v-model="styleToV"
                label="Style/ToV"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-textarea
                outlined
                clearable
                clear-icon="mdi-close-circle-outline"
                label="Comments"
                v-model="note"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <v-card-actions class="d-flex justify-end pr-4 pb-4">
      <v-btn
        v-if="allowedToEdit"
        width="20%"
        color="secondary"
        :loading="recordProcessing"
        @click="handleRecord"
        >{{ `${isEditMode ? "Update" : "Add"} Record` }}</v-btn
      >
    </v-card-actions>
  </v-form>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// internal
import {
  getAllProjectNames,
  getClientRequestersForProject,
  appIDs,
  getFieldInfosFromDB,
  getMediaTypeChoices,
  getiTunesFCPONumber,
  getStaffNameFieldID,
  getFCStaffDatasFromID,
  deleteRowFromQB,
  getPriceCodeWithWorkTypeCategory,
  getEmployeesData,
  leadPM,
} from "@/utils/quickbaseUtils";
import { createNewFCAssignmentsWithDatas } from "@/utils/fcAssignmentUtils";

export default {
  name: "LEDForm",
  props: {
    geoOptions: {
      type: Array,
      required: true,
    },
    itemToEdit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      relatedProject: {
        name: "",
        id: null,
      },
      requestDate: "",
      clientRequester: "",
      requestType: "",
      mediaType: "",
      keyword: "",
      deliveryDate: "",
      geo: "",
      po: null,
      wordCount: "",
      assignmentDetails: "",
      assignmentName: "",
      writer: "",
      payPeriod: "",
      workType1References: [],
      workType1ReferenceLoading: false,
      workType1Reference: "",
      workType1Quantity: "",
      spellingTypos: false,
      grammarSyntax: false,
      factualError: false,
      styleToV: false,
      note: "",
      tab: null,
      steps: ["General Info", "Feedback"],
      projectNameItems: [],
      requestDateMenu: false,
      dropdownsLoading: true,
      clientRequesters: [],
      requestTypes: [],
      clientDeliveryDateMenu: false,
      deliveryDateMenu: false,
      mediaTypes: [],
      keywords: [],
      poLoading: false,
      titleGeoReferences: [
        {
          geos: ["es-ES", "es-MX"],
          titleGEO: "ESP",
        },
        {
          geos: ["nl-NL"],
          titleGEO: "NDL",
        },
        {
          geos: ["uk-UA"],
          titleGEO: "UKR",
        },
        {
          geos: ["ar-SA"],
          titleGEO: "ARA",
        },
        {
          geos: ["cs-CZ"],
          titleGEO: "CZE",
        },
        {
          geos: ["ru-RU"],
          titleGEO: "RUS",
        },
      ],
      workType1Quantities: [
        0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0, 2.25, 2.5, 2.75, 3.0, 3.25,
      ],
      staffLoading: false,
      relatedWriters: [],
      payPeriodMenu: false,
      editMode: false,
      recordProcessing: false,
      deletionProcessing: false,
      qbID: null,
      valid: true,
      rules: {
        required: (value) => !!value || value === 0 || "Required",
      },
    };
  },
  mounted() {
    this.handleDropdowns();
    this.handleEditMode();
  },
  computed: {
    ...mapGetters("auth", ["user", "isAdmin", "isManager"]),
    isEditMode() {
      return !!Object.keys(this.itemToEdit).length;
    },
    assignmentTitles() {
      return [
        "Q&A with Cue team",
        "Editorial Call with Cue team",
        "Editorial Call with Client",
        `Review of Localized ${
          this.titleGeoReferences.find((el) => el.geos.includes(this.geo))
            ?.titleGEO ?? "GEO"
        } Copy`,
        `Review of Original ${
          this.titleGeoReferences.find((el) => el.geos.includes(this.geo))
            ?.titleGEO ?? "GEO"
        } Copy`,
        "Glossary Development",
        "Guidelines Development",
      ];
    },
    allowedCalendarDates() {
      return this.isAdmin || this.isManager
        ? null
        : this.$moment().startOf("month").format("YYYY-MM-DD");
    },
    allowedToEdit() {
      return (
        this.isAdmin ||
        this.isManager ||
        !this.isEditMode ||
        (this.$moment(this.itemToEdit.DeliveryDate).isSameOrAfter(
          this.allowedCalendarDates
        ) &&
          this.itemToEdit.CreatedBy === this.user.Name)
      );
    },
  },
  watch: {
    itemToEdit() {
      this.handleEditMode();
    },
    clientRequester() {
      this.updatePO(this.relatedProject);
    },
  },
  methods: {
    handlePayPeriod(dateInput) {
      if (!this.payPeriod) {
        this.payPeriod = dateInput.slice(0, -3);
      }
    },
    getActiveClientRequesters() {
      getEmployeesData({ email: leadPM })
        .then((resp) => {
          const leadPM = resp.data[0].Name;
          getClientRequestersForProject({ instance: "iTunes" })
            .then((clientRequesters) => {
              this.clientRequesters = clientRequesters.concat(leadPM).sort();
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    },
    updatePO(selectedProject) {
      if (selectedProject && this.clientRequester !== "Company Cue, Inc.") {
        this.poLoading = true;
        getiTunesFCPONumber(selectedProject.name)
          .then((data) => {
            this.po = selectedProject.name
              ? Object.values(data.data[0])[0].value
              : "";
            this.poLoading = false;
          })
          .catch((err) => {
            console.error(err);
            this.poLoading = false;
          });
      } else {
        this.po = "";
      }
    },
    handleProjectID() {
      this.relatedProject.id = this.projectNameItems.find(
        (el) => el.name === this.relatedProject.name
      ).id;
      this.updatePO(this.relatedProject);
    },
    getRelatedProjects() {
      getAllProjectNames("iTunes")
        .then((data) => {
          this.projectNameItems = data;
          this.handleProjectID();
        })
        .catch((err) => console.error(err));
    },
    getRequestTypes() {
      getFieldInfosFromDB({
        fieldID: 164,
        tableID: appIDs.iTunesProjectManager.assignments,
      })
        .then((data) => (this.requestTypes = data))
        .catch((err) => console.error(err));
    },
    getMediaTypes() {
      getMediaTypeChoices({ instance: "iTunes" })
        .then((data) => (this.mediaTypes = data))
        .catch((err) => console.error(err));
    },
    getKeywords() {
      getFieldInfosFromDB({
        fieldID: 163,
        tableID: appIDs.iTunesProjectManager.assignments,
      })
        .then((data) => (this.keywords = data))
        .catch((err) => console.error(err));
    },
    getWorkType1References() {
      getPriceCodeWithWorkTypeCategory({
        iTunes: true,
        getAll: true,
      })
        .then(
          (data) =>
            (this.workType1References = data.map((el) => {
              return el["Record ID#"];
            }))
        )
        .catch((err) => console.error(err));
    },
    handleDropdowns() {
      Promise.all([
        this.getRelatedProjects(),
        this.getActiveClientRequesters(),
        this.getRequestTypes(),
        this.getMediaTypes(),
        this.getKeywords(),
        this.getWorkType1References(),
      ])
        .then(() => (this.dropdownsLoading = false))
        .catch((err) => {
          console.error(err);
          this.dropdownsLoading = false;
        });
    },
    handleWorkTypeRef(selectedGeo, noRefreshCall) {
      if (!noRefreshCall) this.writer = "";
      this.getStaffByGeo(selectedGeo, noRefreshCall);
      this.workType1Reference =
        selectedGeo === "nl-NL" || selectedGeo === "ar-SA"
          ? 44
          : selectedGeo === "cs-CZ"
          ? 5
          : 51;
      this.handleRequester(this.workType1Reference);
    },
    handleRequester(selectedRef) {
      this.clientRequester =
        selectedRef === 5 || selectedRef === 52
          ? "Company Cue, Inc."
          : "Pilar Blanco";
    },
    getStaffByGeo(selectedGeo, noRefreshCall) {
      this.staffLoading = true;
      getStaffNameFieldID()
        .then((fieldID) => {
          return getFCStaffDatasFromID(fieldID, selectedGeo);
        })
        .then((data) => {
          this.relatedWriters = data.data.data
            .map((el) => Object.values(el)[0].value)
            .sort();
          this.staffLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.staffLoading = false;
        });
      if (!noRefreshCall && this.assignmentTitle?.includes("Copy"))
        this.assignmentName = "";
    },
    handleRecord() {
      if (this.$refs.form.validate()) {
        this.recordProcessing = true;
        let record = {
          relatedProject: { ...this.relatedProject },
          po: this.po,
          clientRequester: this.clientRequester,
          requestType: this.requestType,
          workType1Reference: this.workType1Reference,
          keyword: this.keyword,
          writer: this.writer,
          deliveryDate: this.deliveryDate,
          mediaType: this.mediaType,
          requestDate: this.requestDate,
          geo: this.geo,
          note: this.note,
          wordCount: this.wordCount,
          assignmentDetails: this.assignmentDetails,
          assignmentName: this.assignmentName,
          payPeriod: this.payPeriod,
          workType1Quantity: this.workType1Quantity,
          spellingTypos: this.spellingTypos,
          grammarSyntax: this.grammarSyntax,
          factualError: this.factualError,
          styleToV: this.styleToV,
        };
        if (this.isEditMode) Object.assign(record, { qbID: this.qbID });
        createNewFCAssignmentsWithDatas({
          datas: [record],
          editMode: this.isEditMode,
          ledMode: true,
        })
          .then((resp) => {
            const msg = !resp.rejected.length
              ? `Record ${this.isEditMode ? "updated" : "created"}!`
              : null;
            this.recordProcessing = false;
            this.$emit("led-dashboard-table-refresh", msg);
          })
          .catch((err) => {
            console.error(err);
            this.recordProcessing = false;
          });
      }
    },
    handleRecordDeletion() {
      this.deletionProcessing = true;
      deleteRowFromQB({
        id: this.qbID,
        tableID: appIDs.iTunesProjectManager.assignments,
      })
        .then((resp) => {
          const msg = resp.numberDeleted ? "Record deleted!" : null;
          this.deletionProcessing = false;
          this.$emit("led-dashboard-table-refresh", msg);
        })
        .catch((err) => {
          console.error(err);
          this.deletionProcessing = false;
        });
    },
    handleWorkType1ByID(workType1) {
      this.workType1ReferenceLoading = true;
      getPriceCodeWithWorkTypeCategory({
        workTypeCategory: workType1,
        iTunes: true,
      })
        .then((data) => {
          this.workType1Reference = data.at(-1)["Record ID#"];
          this.workType1ReferenceLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.workType1ReferenceLoading = false;
        });
    },
    handleEditMode() {
      if (!this.isEditMode) this.$refs.form.resetValidation();
      this.relatedProject.name = this.isEditMode
        ? this.itemToEdit.ProjectName
        : "iTunes FC";
      if (this.projectNameItems.length) this.handleProjectID();
      this.clientRequester = this.isEditMode
        ? this.itemToEdit.RelatedClientRequester
        : "Pilar Blanco";
      this.requestType = this.isEditMode
        ? this.itemToEdit.RequestType
        : "Editorial Supervision";
      this.keyword = this.isEditMode ? this.itemToEdit.Keyword : "QaS";
      this.writer = this.isEditMode ? this.itemToEdit.Writer : this.user.Name;
      this.deliveryDate = this.isEditMode ? this.itemToEdit.DeliveryDate : "";
      this.mediaType = this.isEditMode ? this.itemToEdit.MediaType : "";
      this.qbID = this.isEditMode ? this.itemToEdit["RecordID#"] : null;
      this.requestDate = this.isEditMode
        ? this.itemToEdit.RequestDate
        : this.$moment(
            new Date().toLocaleString("en-US", {
              timeZone: "America/New_York",
            })
          ).format("YYYY-MM-DD");
      this.geo = this.isEditMode ? this.itemToEdit.GEO : this.user.GEO1;
      this.note = this.isEditMode ? this.itemToEdit.Note : "";
      this.wordCount = this.isEditMode ? this.itemToEdit.WordCount : "";
      this.assignmentDetails = this.isEditMode
        ? this.itemToEdit.AssignmentDetail
        : "";
      this.assignmentName = this.isEditMode
        ? this.itemToEdit.AssignmentTitle
        : "";
      this.payPeriod = this.isEditMode
        ? this.$moment(this.itemToEdit.PayPeriod).format("YYYY-MM")
        : "";
      this.workType1Quantity = this.isEditMode
        ? this.itemToEdit.WorkType1Quantity
        : "";
      this.spellingTypos = this.isEditMode
        ? this.itemToEdit["Spelling/Typos"]
        : false;
      this.grammarSyntax = this.isEditMode
        ? this.itemToEdit["Grammar/Syntax"]
        : false;
      this.factualError = this.isEditMode
        ? this.itemToEdit.FactualError
        : false;
      this.styleToV = this.isEditMode ? this.itemToEdit["Style/ToV"] : false;
      this.isEditMode
        ? (this.handleWorkType1ByID(this.itemToEdit.WorkType1),
          this.getStaffByGeo(this.geo))
        : this.handleWorkTypeRef(this.geo, true);
    },
  },
};
</script>

<style lang="scss" scoped></style>
