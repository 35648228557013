<template>
  <v-card class="d-flex flex-column grey lighten-2">
    <FCDashboardNewAssignmentItem
      ref="newAssignmentItem"
      v-for="(newFCAssignmentData, i) in newFCAssignmentDatas"
      :key="`new-fc-assignment-${i + 1}`"
      :datasIndex="i"
    />
    <v-card-actions class="d-flex justify-end pr-4 pb-4">
      <v-btn
        depressed
        class="mx-3 grey lighten-2"
        @click="handleAddingAnotherFCAssignment"
      >
        <v-icon>mdi-plus</v-icon>
        Add another assignment
      </v-btn>
      <v-btn
        depressed
        class="mx-3 grey lighten-2"
        @click="handleFCAssignmentDuplication"
      >
        <v-icon>mdi-plus</v-icon>
        Duplicate
      </v-btn>
      <v-btn
        width="250px"
        color="secondary"
        :loading="newFCAssignmentsPosting"
        @click="handleExportToGoogle"
        >Export to Google Sheet</v-btn
      >
      <v-btn
        width="100px"
        color="secondary"
        class="mx-3"
        :loading="newFCAssignmentsPosting"
        @click="handleNewFCAssignmentsCreation"
        >Create</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// library
import _ from "lodash";
// internal
import {
  BLANK_NEW_FC_ASSIGNMENT_DATAS,
  createNewFCAssignmentsWithDatas,
} from "@/utils/fcAssignmentUtils";
import { getAssignments, getTasks, processTask } from "@/utils/quickbaseUtils";
import {
  addRowsToGoogleSheet,
  SPREADSHEET_ID,
} from "@/utils/googleSheetsUtils";
// components
import FCDashboardNewAssignmentItem from "@/components/FCDashboard/FCDashboardNewAssignmentItem";

export default {
  name: "FCDashboardNewAssignmentModal",
  components: { FCDashboardNewAssignmentItem },
  data() {
    return {
      newFCAssignmentsPosting: false,
    };
  },
  computed: {
    ...mapGetters("fcAssignment", ["newFCAssignmentDatas"]),
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("fcAssignment", [
      "setNewFCAssignmentDatas",
      "setNewFCAssignmentModalOpen",
    ]),
    handleAddingAnotherFCAssignment() {
      let newPayload = _.cloneDeep(this.newFCAssignmentDatas);
      newPayload = [...newPayload, ...BLANK_NEW_FC_ASSIGNMENT_DATAS];
      this.setNewFCAssignmentDatas(newPayload);
    },
    handleFCAssignmentDuplication() {
      let newPayload = _.cloneDeep(this.newFCAssignmentDatas);
      let lastAssn = _.last(newPayload);
      newPayload = [...newPayload, lastAssn];
      this.setNewFCAssignmentDatas(newPayload);
    },
    confirmFormValid() {
      let validator = [];
      this.$refs.newAssignmentItem.forEach((el) => {
        validator.push(el.validateForm());
      });
      return validator.every((value) => value === true);
    },
    handleNewFCAssignmentsCreation() {
      let validator = [];
      this.$refs.newAssignmentItem.forEach((el) => {
        validator.push(el.validateForm());
      });
      if (this.confirmFormValid()) {
        this.newFCAssignmentsPosting = true;
        createNewFCAssignmentsWithDatas({
          datas: this.newFCAssignmentDatas,
        })
          .then(() => {
            this.$emit("fc-dashboard-table-refresh");
            const cueID =
              this.$moment(this.newFCAssignmentDatas[0].requestDate).format(
                "MM-DD-YYYY"
              ) +
              "_" +
              this.newFCAssignmentDatas[0].taskID;
            // handle task status after upload
            getAssignments({ cueID })
              .then((resp) => {
                if (
                  resp.data.length &&
                  resp.data.every((el) => el.AssignmentStatus === "Assigned")
                ) {
                  getTasks({ cueID })
                    .then((resp) => {
                      if (resp.data.length) {
                        const task = {
                          ["RecordID#"]: resp.data[0]["RecordID#"],
                          LastUpdated: new Date(),
                          LastUpdatedBy: this.user.Name,
                        };
                        processTask({
                          task: task,
                          status: "Assigned",
                        })
                          .then(() => {
                            this.setNewFCAssignmentModalOpen(false);
                            this.newFCAssignmentsPosting = false;
                          })
                          .catch((err) => console.error(err));
                      } else {
                        this.setNewFCAssignmentModalOpen(false);
                        this.newFCAssignmentsPosting = false;
                      }
                    })
                    .catch((err) => console.error(err));
                } else {
                  this.setNewFCAssignmentModalOpen(false);
                  this.newFCAssignmentsPosting = false;
                }
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => console.error(err));
      }
    },
    handleExportToGoogle() {
      if (this.confirmFormValid()) {
        this.newFCAssignmentsPosting = true;
        addRowsToGoogleSheet({ tableRows: this.newFCAssignmentDatas })
          .then(() => {
            this.newFCAssignmentsPosting = false;
            window.open(
              `https://docs.google.com/spreadsheets/d/${SPREADSHEET_ID}/edit#gid=638440309`
            );
          })
          .catch((err) => {
            console.error(err);
            this.newFCAssignmentsPosting = false;
          });
      }
    },
  },
};
</script>
