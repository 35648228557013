<template>
  <div>
    <v-progress-circular v-if="isUpdating" indeterminate :size="24" />
    <v-select
      v-if="!isUpdating"
      class="caption status-selector"
      :style="{
        backgroundColor: statusColor,
      }"
      :items="roleMode ? roleBasedOptions : options"
      item-text="value"
      dense
      hide-details
      outlined
      :disabled="
        roleMode ? !allowedToUpdateStatuses().includes(currentStatus) : false
      "
      :value="currentStatus"
      :loading="optionsLoading"
      @change="(selected) => onChange({ item, selected })"
    />
    <v-alert v-if="isError" type="error" class="alert" dismissible>
      Error updating status. Please try again.
    </v-alert>
  </div>
</template>

<script>
// internal
import { updateDBRowField } from "@/utils/quickbaseUtils";

export default {
  name: "FCDashboardStatusSelector",
  props: {
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    optionsLoading: {
      type: Boolean,
      required: true,
    },
    roleMode: {
      type: String,
      required: true,
    },
    writerStatuses: {
      type: Array,
      required: true,
    },
    editorStatuses: {
      type: Array,
      required: true,
    },
    reviewerStatuses: {
      type: Array,
      required: true,
    },
    isUpdating: { type: Boolean, required: true, default: false },
    isError: { type: Boolean, required: true, default: false },
  },
  data() {
    return {
      currentStatus: "",
    };
  },
  computed: {
    statusColor() {
      return this.options.find((el) => el.value === this.currentStatus)?.color;
    },
    roleBasedOptions() {
      return this.options
        .map((el) => ({
          ...el,
          disabled: !this.allowedToSelectStatuses().includes(el.value),
        }))
        .sort((a, b) => Number(a.disabled) - Number(b.disabled));
    },
  },
  watch: {
    value(newValue) {
      this.currentStatus = newValue;
    },
  },
  mounted() {
    this.currentStatus = this.value;
  },
  methods: {
    onChange({ item, selected }) {
      this.currentStatus = selected;
      // do call to QB to change in db
      this.isUpdating = true;
      updateDBRowField({
        rid: item["RecordID#"],
        status: selected,
      })
        .then(() => {
          this.$emit("refresh-table-data", item.index, selected);
        })
        .catch((err) => {
          this.isUpdating = false;
          this.isError = true;
          console.error(err);
        });
    },
    allowedToSelectStatuses() {
      const allowedStatuses = [...this[`${this.roleMode}Statuses`]];
      if (this.roleMode === "writer") {
        if (this.currentStatus === "Assigned") allowedStatuses.splice(1, 1);
        if (this.currentStatus === "Rejected") allowedStatuses.splice(0, 1);
      }
      return allowedStatuses;
    },
    allowedToUpdateStatuses() {
      const allowedStatuses = this[`${this.roleMode}Statuses`].filter(
        (el) => el != this[`${this.roleMode}Statuses`].slice(-1)
      );
      return this.roleMode !== "reviewer"
        ? allowedStatuses
        : this[`${this.roleMode}Statuses`];
    },
  },
};
</script>

<style lang="scss" scoped>
.status-selector {
  width: 120px;
}

.alert {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100;
}
</style>
