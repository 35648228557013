<template>
  <div class="sub-assignments-view">
    <v-container class="sub-assignments-view__container mx-0 px-0">
      <v-row class="sub-assignments-view__header-row mx-0">
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          GEO
        </v-col>
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          Quantity
        </v-col>
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          Total
        </v-col>
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          Task ID
        </v-col>
        <v-col align-self="center" class="text-center" cols="12" sm="2">
          Detail
        </v-col>
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          Billed
        </v-col>
        <v-col cols="12" sm="5"> </v-col>
      </v-row>

      <!--  -->

      <v-row
        v-for="(row, i) in filteredAssigments"
        :key="i + '-row'"
        class="sub-assignments-view__row"
        :class="{ 'alt-background': i % 2 === 0 }"
      >
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          {{ row["GEO"] }}
        </v-col>
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          {{ row["Quantity"] }}
        </v-col>
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          {{ getPriceString(row["Client Total"]) }}
        </v-col>
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          {{ row["Task ID"] }}
        </v-col>
        <v-col align-self="center" cols="12" sm="2">
          {{ row["Assignment Detail"] }}
        </v-col>
        <v-col align-self="center" class="text-center" cols="12" sm="1">
          <v-icon v-if="row['Billed']">mdi-check</v-icon>
        </v-col>
        <v-col class="d-flex align-center pl-0 pr-2" cols="12" sm="5">
          <AssignmentProgressIndicator class="mr-1" :data="row" />
          <v-icon
            v-if="showQBLink"
            class="sub-assignments-view__qb-link"
            @click="handleLinkClick(row['Record ID#'])"
            >mdi-link</v-icon
          >
          <v-icon
            v-if="showInqueryLink"
            class="sub-assignments-view__qb-link"
            @click="handleQuestionClick(row)"
            >mdi-email-outline</v-icon
          >
        </v-col>
      </v-row>
    </v-container>
    <InqueryModal
      :isActive="inqueryModalActive"
      :inqueryRid="inqueryRid"
      :rowData="inqueryRowData"
      @closeModal="handleCloseModal"
    />
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// libraries
import _ from "lodash";
const numeral = require("numeral");
// internal
import { PASSTHROUGH_ENV } from "@/utils/constants.js";
import { appIDs, realmID } from "@/utils/quickbaseUtils";
// components
import AssignmentProgressIndicator from "@/components/MyCue/AssignmentProgressIndicator";
import InqueryModal from "@/components/MyCue/InqueryModal";

export default {
  name: "SubAssignmentsView",
  components: { AssignmentProgressIndicator, InqueryModal },
  data() {
    return {
      inqueryModalActive: false,
      inqueryRid: null,
      inqueryRowData: null,
    };
  },
  props: {
    cleanedTableData: Array,
    assignmentTitle: String,
    search: String,
  },
  methods: {
    getPriceString(d) {
      return numeral(d).format("$0.00");
    },
    handleLinkClick(id) {
      const url = `https://${realmID}/db/${appIDs.cueProjectManager.invoices}?a=dr&rid=${id}`;
      window.open(url, "_blank");
    },
    handleQuestionClick(d) {
      this.inqueryRid = d["Record ID#"];
      this.inqueryRowData = d;
      this.inqueryModalActive = true;
    },
    handleCloseModal() {
      this.inqueryModalActive = false;
    },
  },
  computed: {
    ...mapGetters("auth", ["isAdmin", "isManager", "company"]),
    showQBLink() {
      if (
        process.env.NODE_ENV === PASSTHROUGH_ENV ||
        this.isAdmin ||
        this.isManager
      ) {
        return true;
      } else {
        return false;
      }
    },
    showInqueryLink() {
      return (
        process.env.NODE_ENV === PASSTHROUGH_ENV ||
        this.company !== "Company Cue"
      );
    },
    searchIsActive() {
      return this.search !== "" && this.search !== null;
    },
    filteredAssigments() {
      let retval;

      if (!this.searchIsActive) {
        retval = this.cleanedTableData.filter(
          (el) => el["Assignment Title"] === this.assignmentTitle
        );
      } else {
        retval = this.cleanedTableData.filter((el) => {
          return (
            el["Assignment Title"] === this.assignmentTitle &&
            el["Assignment Detail"]
              .toLowerCase()
              .includes(this.search.toLowerCase())
          );
        });
      }

      retval = _.reverse(
        _.sortBy(retval, (d) => {
          return new Date(d["Delivery Date"]);
        })
      );

      return retval;
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-assignments-view {
  position: relative;
  overflow-x: hidden;
  &__container {
    max-width: unset !important;
    margin: 0px 20px;
    width: 100%;
  }

  &__header-row {
    font-weight: 500;
  }

  &__row {
    margin: 5px 0px;

    &.alt-background {
      background-color: #f5f5f5;
    }
  }

  &__qb-link {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}
</style>
