// libraries
import axios from "axios";
import moment from "moment";
// internal
import { DOMAIN, ROUTES } from "@/utils/constants";

const url = "https://cocuedev.com/api/slack";

export const workspaces = [
  {
    name: "cue-fc",
    id: "T0299B3FPRC",
  },
  {
    name: "Cue Staff",
    id: "TD7JWK0A1",
  },
  {
    name: "PM",
    id: "T0RH3FLDD",
  },
];

const channels = [
  // cue-fc workspace
  { name: "fc-and-music-questions", id: "C02AN9A3G0H", mode: ["assignment"] },
  {
    // Apps
    name: "fc-apps",
    id: "C02E82VNVNU",
    conditions: {
      mediaType: ["Apps"],
      keyword: ["Today", "Arcade", "MAS"],
    },
    blockBuilder: {
      mediaTypeEmoji: ":space_invader:",
      useFile: true,
    },
  },
  {
    name: "fc-apps-discovery",
    id: "C02D3NBTQH5",
    conditions: {
      mediaType: ["Apps"],
      keyword: ["Discovery"],
    },
    blockBuilder: {
      mediaTypeEmoji: ":space_invader:",
      useFile: true,
      wordCount: true,
      note: "\n  • In Discovery files, the established character limits for *taglines is 30 characters including spaces*.\n  • Please check this thread for GEO-specific deadlines, role distribution, glossaries and other useful information when available.",
    },
  },
  {
    // Books
    name: "fc-books",
    id: "C02EATHCFUZ",
    conditions: {
      mediaType: ["Books", "Mktg Apple Books"],
    },
    blockBuilder: {
      mediaTypeEmoji: ":book:",
    },
  },
  {
    // Fitness+
    name: "fc-fitness",
    id: "C02DYDG8JQJ",
    conditions: {
      mediaType: ["Fitness+", "Mktg Apple Fitness+"],
    },
    blockBuilder: {
      mediaTypeEmoji: ":person_in_lotus_position:",
    },
  },
  {
    // Marketing (Media Type only)
    name: "fc-marketing",
    id: "C02EDHBDLE5",
    conditions: {
      mediaType: [
        "Mktg App Store",
        "Mktg Apple Arcade",
        "Mktg Apple Gift Card",
        "Mktg Apple Maps",
        "Mktg Apple Music",
        "Mktg Apple News",
        "Mktg Apple One",
        "Mktg Apple Pay",
        "Mktg Apple TV app",
        "Mktg Apple TV+",
        "Mktg iCloud",
        "Mktg Payments Marketing",
        "Mktg Shazam",
        "Mktg X-Content/Other",
      ],
    },
    blockBuilder: {
      mediaTypeEmoji: "*@*",
    },
  },
  {
    // Marketing (Keyword only)
    name: "fc-marketing",
    id: "C02EDHBDLE5",
    conditions: {
      keyword: [
        "Mktg Email",
        "Mktg Event",
        "Mktg iFrame",
        "Mktg In-app event",
        "Mktg Landing page",
        "Mktg Laurel",
        "Mktg Motion/trailer",
        "Mktg Multiple deliverables",
        "Mktg Other",
        "Mktg Partner training",
        "Mktg Pedigree",
        "Mktg Product page",
        "Mktg Push notifications",
        "Mktg Release date",
        "Mktg Search ads",
        "Mktg Search keywords",
        "Mktg Social post copy",
        "Mktg Static",
        "Mktg Subtitles",
        "Mktg Tagline",
        "Mktg Talent name",
        "Mktg Title",
        "Mktg Voice-over script",
        "Mktg YouTube",
      ],
    },
    blockBuilder: {
      mediaTypeEmoji: "*@*",
    },
  },
  {
    // Artist Bio
    name: "fc-music-bios-deu",
    id: "C02D3LXD3GX",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Bios"],
      geo: "de-DE",
    },
    blockBuilder: {
      mediaTypeEmoji: ":musical_note:",
      title: "Hi team, here’s a new batch of the *Artist Bios Project*!",
      instructions: true,
    },
  },
  {
    name: "fc-music-bios-ita",
    id: "C02DJCP4LNP",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Bios"],
      geo: "it-IT",
    },
    blockBuilder: {
      mediaTypeEmoji: ":musical_note:",
      title: "Hi team, here’s a new batch of the *Artist Bios Project*!",
      instructions: true,
    },
  },
  {
    name: "fc-music-bios-mex",
    id: "C02DBLBKE6A",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Bios"],
      geo: "es-MX",
    },
    blockBuilder: {
      mediaTypeEmoji: ":musical_note:",
      title: "Hi team, here’s a new batch of the *Artist Bios Project*!",
      instructions: true,
    },
  },
  {
    name: "fc-music-bios-rus",
    id: "C02D3M3RLJK",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Bios"],
      geo: "ru-RU",
    },
    blockBuilder: {
      mediaTypeEmoji: ":musical_note:",
      title: "Hi team, here’s a new batch of the *Artist Bios Project*!",
      instructions: true,
    },
  },
  {
    // Music Interview Localization
    name: "fc-music-interview-loc",
    id: "C02DY02HMFF",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Interviews"],
      requestType: "Localization",
    },
    blockBuilder: {
      title:
        "Hi team, you’ve got a new a new music artist interview to localize!",
      mediaTypeEmoji: ":musical_note:",
      wordCount: true,
      note: "\n  • Remember to stay close to the English while ensuring natural flow in the target language and to include the HTML code.\n  • Please check this thread for glossary, copy points and other useful information when available.",
    },
  },
  {
    // Music Promotions
    name: "fc-music-promotions",
    id: "C02EDJ3D0M7",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Promotions"],
    },
    blockBuilder: {
      title: "Hi team, you’ve got a new a new music promotion to work on!",
      mediaTypeEmoji: ":musical_note:",
    },
  },
  {
    // Weekly Music
    name: "fc-music-weekly",
    id: "C02DYCK8YQK",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Weekly"],
    },
    blockBuilder: {
      title:
        "Hi team, you’ve got a new a new batch of weekly music tasks to work on!",
      mediaTypeEmoji: ":musical_note:",
    },
  },
  {
    // Generic
    name: "fc-other",
    id: "C02EQNWBZUY",
    generic: true,
  },
  { name: "fc-pilot-kr", id: "C035V9B2Z25" },
  {
    // Podcasts
    name: "fc-podcasts",
    id: "C02DY98NXPV",
    conditions: {
      mediaType: ["Podcasts", "Mktg Apple Podcasts"],
    },
    blockBuilder: {
      mediaTypeEmoji: ":studio_microphone:",
    },
  },
  {
    // Video Promotions (combined conditions)
    name: "fc-video-promotions",
    id: "C02DL84BH71",
    conditions: {
      mediaType: ["Video"],
      keyword: ["Promotions"],
    },
    blockBuilder: {
      title: "Hi team, you’ve got a new a new video promotion to work on!",
      mediaTypeEmoji: ":movie_camera:",
      separateKeyword: true,
      note: "\n  • The exact copy length can be found in in the *Video: Reference Guide* in <https://copy.apple.com/|*Copy*>.\n  • For local TV+ titles, please reference each market’s storefront.\n  • Please check this thread for glossary, copy points and other useful information when available.",
    },
  },
  {
    // Video Promotions (Media Type only)
    name: "fc-video-promotions",
    id: "C02DL84BH71",
    conditions: {
      mediaType: ["TV+"],
    },
    blockBuilder: {
      title: "Hi team, you’ve got a new a new video promotion to work on!",
      mediaTypeEmoji: ":movie_camera:",
      separateKeyword: true,
      note: "\n  • The exact copy length can be found in in the *Video: Reference Guide* in <https://copy.apple.com/|*Copy*>.\n  • For local TV+ titles, please reference each market’s storefront.\n  • Please check this thread for glossary, copy points and other useful information when available.",
    },
  },
  {
    // Shakespeare Video
    name: "fc-video-shakespeare",
    id: "C02HRR16AES",
    conditions: {
      mediaType: ["Video"],
      keyword: ["Apple Music"],
    },
    blockBuilder: {
      mediaTypeEmoji: ":movie_camera:",
      useNote: true,
      bug: true,
    },
  },
  {
    // Weekly Video
    name: "fc-video-weekly",
    id: "C02DVCAMKGV",
    conditions: {
      mediaType: ["Video"],
      keyword: ["Weekly"],
    },
    blockBuilder: {
      title:
        "Hi team, you’ve got a new a new batch of weekly video tasks to work on!",
      mediaTypeEmoji: ":movie_camera:",
    },
  },
  {
    // Global Brands Playlists
    name: "global-brands-playlists",
    id: "C02DHEQAQ5D",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Playlists"],
    },
    blockBuilder: {
      title: "Hi team, you’ve got new Daily Playlists to create notes for!",
      mediaTypeEmoji: ":musical_note:",
      useNote: true,
    },
  },
  {
    // Shakespeare Music Playlists
    name: "music-classical-playlists",
    id: "C03511GEF1V",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Apple Music"],
      assignmentName: ["Classical Playlist Copy"],
    },
    blockBuilder: {
      mediaTypeEmoji: ":musical_note:",
      useNote: true,
      bug: true,
    },
  },
  {
    name: "music-shakespeare",
    id: "C02DV5U8N9K",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Apple Music"],
      assignmentName: [
        "P1 Playlist Copy",
        "P3 Playlist Copy",
        "Album Review",
        "Hero Blurb",
        "Custom Title",
      ],
    },
    blockBuilder: {
      mediaTypeEmoji: ":musical_note:",
      useNote: true,
      bug: true,
    },
  },
  {
    name: "music-shakespeare-titles",
    id: "C03EF8CTMRA",
    conditions: {
      mediaType: ["Music"],
      keyword: ["Apple Music"],
      assignmentName: ["Playlist Title"],
    },
    blockBuilder: {
      mediaTypeEmoji: ":musical_note:",
      useNote: true,
      bug: true,
    },
  },
  {
    // Smartling
    name: "fc-smartling",
    id: "C02E4E3BA1J",
    conditions: {
      fileLocation: "Smartling",
    },
    blockBuilder: {
      title:
        "Hi team, you’ve got a new :sparkles:*Smartling*:sparkles: assignment!",
      mediaTypeEmoji: ":newspaper:",
    },
  },
  {
    name: "shakespeare-reassignments",
    id: "C03A42857D5",
    mode: ["reassignment"],
  },
  { name: "fc-pm-flow", id: "C03RH0UVBU5", mode: ["task"] },
  {
    name: "fc-project-availability",
    id: "C03RV897X1Q",
    mode: ["defaultCapacity"],
  },
  { name: "cuehub-feedback", id: "C03S6JK329L", mode: ["bug"] },
  {
    name: "shakespeare-bug-report",
    id: "C0423N34RFT",
    mode: ["shakespeare-bug"],
  },
  {
    name: "staff-invoice-questions",
    id: "C047X9GQVMJ",
    mode: ["invoice"],
  },
  // Co. Cue workspace
  {
    name: "cuedata-profileupdates",
    id: "C03E6FN20KC",
    mode: ["profileUpdate"],
  },
  {
    name: "global-work-and-time-off-calendar",
    id: "C016V1XRMC5",
    mode: ["availability"],
  },
];

const teams = [
  { name: "FC Team", id: "S02F8KQQVJS", mode: "assignment" },
  { name: "Daily Shaky Team", id: "S02FFC5T5MZ", mode: "reassignment" },
  { name: "CueHub Development Team", id: "S03QZDE4PC6", mode: "bug" },
];

export const publishMessage = async ({
  user,
  mode,
  data,
  item,
  bulkInquiry,
}) => {
  const handleQueryChars = (string) => {
      if (string) {
        let retVal = string;
        // handle divider for Slack rich text link
        if (retVal.includes("|")) retVal = retVal.split(" | ")[0];
        // handle + for query
        retVal = retVal.replace(/\+/g, "%2B");
        // cut space at the end of string
        retVal = retVal.replace(/\s+$/g, "");
        return retVal;
      }
    },
    getBlockData = (mode, getPreview) => {
      const blocks = [
          {
            mode: "assignment",
            preview: "New Question!",
            text: `*New Question* from ${user}\n\n\`To\`: <!subteam^${
              teams.find((team) => team.mode === "assignment").id
            }>\n\n\`Urgency\`: ${
              data?.urgency?.emoji + " " + data?.urgency?.value
            }\n\n\`Category\`: ${data?.category}\n\n\`Assignment Title${
              bulkInquiry?.length
                ? `s\`:\n${bulkInquiry
                    .map((assgn) => {
                      return `     ○ <https://${DOMAIN}/${
                        ROUTES.fcDashboard
                      }?ppm=${assgn?.PayPeriod?.split(" ")[0]}&ppy=${
                        assgn?.PayPeriod?.split(" ")[1]
                      }&Search=${handleQueryChars(
                        assgn?.AssignmentDetail
                      )}&Geo=${assgn?.GEO}|${assgn?.AssignmentDetail}>`;
                    })
                    .join("\n")}`
                : `\`: <https://${DOMAIN}/${ROUTES.fcDashboard}?ppm=${
                    item?.PayPeriod?.split(" ")[0]
                  }&ppy=${
                    item?.PayPeriod?.split(" ")[1]
                  }&Search=${handleQueryChars(item?.AssignmentDetail)}&Geo=${
                    item?.GEO
                  }|${item?.AssignmentDetail}>\n\n\`Question\`: ${
                    data?.question
                  }`
            }\n\n:arrow_right:  A member of the ${
              teams.find((team) => team.mode === "assignment").name
            } will reply in thread. Stay tuned!`, // TODO - Slack btn "Claim for Review"
          },
          {
            mode: "invoice",
            preview: "New Invoice Question!",
            text: `*New Invoice Question* from ${user}\n\n\`To\`: <!channel>\n\n\`Urgency\`: ${
              data?.urgency?.emoji + " " + data?.urgency?.value
            }\n\n\`Category\`: ${data?.category}\n\n\`Assignment Title\`: <${
              data?.link?.split("?")[0]
            }?ppm=${item?.PayPeriod?.split(" ")[0]}&ppy=${
              item?.PayPeriod?.split(" ")[1]
            }&Search=${handleQueryChars(item?.AssignmentDetail)}&Geo=${
              item?.GEO
            }|${handleQueryChars(item?.AssignmentDetail)}>\n\n\`Question\`: ${
              data?.question
            }`, // TODO - Slack btn "Claim for Review"
          },
          {
            mode: "shakespeare-bug",
            preview: "New Shakespeare bug reported!",
            text: `*New Bug* flagged by ${user}\n\n\`Assignment Title\`: ${
              item?.AssignmentDetail
            }\n\`GEO\`: ${item?.GEO}\n\`Media Type\`: ${
              item?.MediaType
            }\n\`Bug Type\`: <https://${DOMAIN}/${ROUTES.issueTracker}?Type=${
              data?.shkBugType
            }&Media=${item?.MediaType}&Search=${handleQueryChars(
              item?.AssignmentDetail
            )}&Geo=${item?.GEO}|${data?.shkBugType}>`,
          },
          {
            mode: "task",
            preview: "New Question!",
            text: `*New Question* from ${user}\n\n\`To\`: ${
              data?.messageTarget?.ping
            }\n\n\`Category\`: ${
              data?.category
            }\n\n\`Task ID\`: <https://${DOMAIN}/${
              ROUTES.pmDashboard
            }?Search=${handleQueryChars(item?.CueTaskID)}|${
              item?.TaskID
            }>\n\n\`Question\`: ${
              data?.question
            }\n\n:arrow_right:  A member of the PM Team will reply in thread. Stay tuned!`, // TODO - Slack btn "Claim for Review"
          },
          {
            mode: "bug",
            preview: "New CueHub Feedback!",
            text: `:robot_face: *New CueHub Feedback* :robot_face:\n\n\`To\`: <!subteam^${
              teams.find((team) => team.mode === "bug").id
            }>\n\n\`From\`: ${user}\n\n\`Category\`: ${
              data?.category
            }\n\n\`Link\`: ${data?.link}\n\n\`Issue Description\`: ${
              data?.question
            }`, // TODO - Slack btn "On it!"
          },
          {
            mode: "availability",
            preview: "New Availability Record!",
            text: `${user} will be offline ${
              data?.to
                ? `from *${moment(data?.from).format(
                    "MM/DD/YYYY"
                  )}* to *${moment(data?.to).format("MM/DD/YYYY")}*`
                : `on *${moment(data?.from).format("MM/DD/YYYY")}*`
            } :arrow_right: ${data?.note}.`,
          },
          {
            mode: "defaultCapacity",
            preview: "New Capacity Record!",
            text: `${user}'s default capacity changed:\n     • *LOC Translating Daily Workload*: ${data?.locDefault}\n     • *LOC Editing Daily Workload*: ${data?.editDefault}.`,
          },
          {
            mode: "profileUpdate",
            preview: "Staff Profile Updated",
            text: `:rolled_up_newspaper: Good news! ${user} has just updated their personal profile (${
              data?.entries
            }).\n:arrow_right: Feel free to check <https://${DOMAIN}/${
              ROUTES.contacts
            }?ActiveStaff=${data?.isActive}&Search=${user.replace(
              /\*/g,
              ""
            )}|Staff Table> for more details.`,
          },
          {
            mode: "reassignment",
            preview: "New Reassignment Request!",
            text: `*New Reassignment Request* from ${user}\n\n\`To\`: <!subteam^${
              teams.find((team) => team.mode === "reassignment").id
            }>\n\n\`Urgency\`: ${
              data?.urgency?.emoji + " " + data?.urgency?.value
            }\n\n\`Category\`: ${
              data?.category
            }\n\n\`Assignment Title\`: <https://${DOMAIN}/${
              ROUTES.fcDashboard
            }?ppm=${item?.PayPeriod?.split(" ")[0]}&ppy=${
              item?.PayPeriod?.split(" ")[1]
            }&Search=${handleQueryChars(item?.AssignmentDetail)}&Geo=${
              item?.GEO
            }&Shakespeare=0|${item?.AssignmentDetail}>\n\n\`Workflow\`: ${
              item?.MediaType
            }\n\n\`Details\`: ${
              data?.question
            }\n\n:arrow_right: A member of the ${
              teams.find((team) => team.mode === "reassignment").name
            } Team will reply in thread. Stay tuned!`, // TODO - Slack btn "Claim for Review"
          },
        ],
        blockToUse = blocks.find((block) => block.mode === mode);
      return getPreview ? blockToUse.preview : blockToUse.text;
    };

  let messageData = {
      token:
        process.env[
          mode === "profileUpdate" || mode === "availability"
            ? "VUE_APP_CO_CUE_SLACK_TOKEN"
            : "VUE_APP_CUE_FC_SLACK_TOKEN"
        ],
    },
    geosThreads = [];

  if (mode === "staffNotification") {
    let targetChannel;
    const generateStaffNotificationBlock = (channel, item) => {
      return `:speaking_head_in_silhouette: *New Assignment Alert:*\n\n${
        channel.blockBuilder?.title ?? "Hi team, you’ve got a new assignment!"
      }\n\n${
        channel?.generic
          ? ""
          : `${channel.blockBuilder?.mediaTypeEmoji} *Media Type:* ${item.mediaType} | `
      }${
        channel.blockBuilder?.separateKeyword
          ? `:key: *Keyword:* ${item.keyword} | `
          : ""
      }${
        channel.blockBuilder?.useNote
          ? ":memo: *Note"
          : `:file_cabinet: *${
              channel.blockBuilder?.useFile ? "File" : "Request"
            }`
      } Type:* ${
        item[
          channel.blockBuilder?.useNote
            ? "assignmentName"
            : channel.blockBuilder?.useFile
            ? "keyword"
            : "requestType"
        ]
      } ${
        channel.blockBuilder?.wordCount
          ? `| :abcd: *Word Count:* ${item?.wordCount} `
          : ""
      }| :round_pushpin: *File Location:* ${item.fileLocation}\n\n${
        item.geos.length === 1
          ? `:open_file_folder: *File Names:*\n${item.geos[0][
              Object.keys(item.geos[0])[0]
            ].assignments
              .map((assgn) => {
                return `     ○ <https://${DOMAIN}/${ROUTES.fcDashboard}?ppm=${
                  item.payPeriod?.split(" ")[0]
                }&ppy=${
                  item.payPeriod?.split(" ")[1]
                }&Search=${handleQueryChars(assgn)}&DeliveryDate=${
                  item.deliveryDate
                }|${assgn}>`;
              })
              .join("\n")}`
          : ":link: *Files:* Please find a message with your name in the thread below to see your assignments. Clicking on the file names will bring you to your _Assignments Dashboard_ in CueHub."
      }\n\n:warning: *Important Notes*: ${
        channel.blockBuilder?.note ??
        `Please check this thread for ${
          item.geos.length === 1
            ? "glossary, copy points"
            : "GEO-specific deadlines, role distribution, glossaries"
        } and other useful information when available.`
      }${
        channel.blockBuilder?.instructions
          ? "\n\n:heavy_plus_sign: *Instructions:*\n     • Please confirm the bio type based on the tier (column *J* of the file):\n\n          ○ *Tier 1:* Full bio; under 1500cc\n          ○ *Tier 2:* Baseball card style bio (bullet points); under 1500cc\n          ○ *Tier 3:* Short notes; *one full sentence*, capturing the artist’s career highlights. No cc limit.\n\n     • :musical_note: *In column J (Adam ID)*, you’ll find the corresponding ID of the artist in Apple Music.\n     • :mag: Use the English copy (*column M*) as reference, when available.\n     • :writing_hand::skin-tone-2: The bios should be added in *column N* of the Excel spreadsheet.\n     • :book: Please add your main sources in *column O*.\n     • :no_entry_sign: Do not modify any other column."
          : ""
      }${
        item.geos.length === 1
          ? `\n\n:alarm_clock: *Deadlines:*\n     ○ *Writing:* ${moment(
              Object.values(item.geos[0])[0].writingDueDate
                ? Object.values(item.geos[0])[0].writingDueDate
                : item.writingDueDate
            ).format("dddd, M/D")}, ${
              Object.values(item.geos[0])[0].writingDue
                ? Object.values(item.geos[0])[0].writingDue
                : item.writingDue
            } ET — ${Object.values(item.geos[0])[0].writers.join(", ")}.${
              Object.values(item.geos[0])[0].hasOwnProperty("editors")
                ? `\n     ○ *Editing:* ${moment(
                    Object.values(item.geos[0])[0].editingDueDate
                      ? Object.values(item.geos[0])[0].editingDueDate
                      : item.editingDueDate
                  ).format("dddd, M/D")}, ${
                    Object.values(item.geos[0])[0].editingDue
                      ? Object.values(item.geos[0])[0].editingDue
                      : item.editingDue
                  } ET — ${Object.values(item.geos[0])[0].editors.join(", ")}.`
                : ""
            }${
              Object.values(item.geos[0])[0].hasOwnProperty("reviewers")
                ? `\n     ○ *Review:* ${moment(
                    Object.values(item.geos[0])[0].reviewingDueDate
                      ? Object.values(item.geos[0])[0].reviewingDueDate
                      : item.reviewingDueDate
                  ).format("dddd, M/D")}, ${
                    Object.values(item.geos[0])[0].reviewingDue
                      ? Object.values(item.geos[0])[0].reviewingDue
                      : item.reviewingDue
                  } ET — ${Object.values(item.geos[0])[0].reviewers.join(
                    ", "
                  )}.`
                : ""
            }`
          : ""
      }\n\n--------------------------------------------------\n\n:heavy_check_mark: Make sure to let us know you have completed your part of the task by changing the status in the “Assignment Status” field on ${
        item.geos.length === 1
          ? `<https://${DOMAIN}/${ROUTES.fcDashboard}?ppm=${
              item.payPeriod?.split(" ")[0]
            }&ppy=${item.payPeriod?.split(" ")[1]}&Search=${handleQueryChars(
              item.assignmentName
            )}&DeliveryDate=${item.deliveryDate}|*MyCue Assignment Dashboard*>`
          : "*MyCue Assignment Dashboard*"
      }.\n\n:alphabet-white-question: Use the dedicated :slack: button on ${
        item.geos.length === 1
          ? `<https://${DOMAIN}/${ROUTES.fcDashboard}?ppm=${
              item.payPeriod?.split(" ")[0]
            }&ppy=${item.payPeriod?.split(" ")[1]}&Search=${handleQueryChars(
              item.assignmentName
            )}&DeliveryDate=${item.deliveryDate}|*MyCue Assignment Dashboard*>`
          : "*MyCue Assignment Dashboard*"
      } to submit a note to reviewers or ask specific questions about this task (character count limit, deadlines, etc.). The Slack thread below can be used for a group discussion with the whole team.${
        channel.blockBuilder?.bug
          ? "\n\n:beetle: Please use the “Bug” button on *MyCue Assignment Dashboard* to report any technical issues in Shakespeare."
          : ""
      }\n\n:sparkles: Thank you! Have fun writing! :sparkles:`;
    };

    // find channel for post
    channels.forEach((channel) => {
      let keysValidator = [];
      if (channel.conditions)
        Object.keys(channel.conditions).forEach((key) => {
          if (
            key === "keyword" ||
            key === "assignmentName" ||
            key === "mediaType"
          ) {
            keysValidator.push(channel.conditions[key].includes(item[key]));
          } else {
            keysValidator.push(channel.conditions[key] === item[key]);
          }
        });
      if (
        keysValidator.length &&
        keysValidator.every((value) => value === true)
      ) {
        targetChannel = channel;
      } else {
        keysValidator = [];
      }
    });
    if (!targetChannel)
      targetChannel = channels.find((channel) =>
        channel.hasOwnProperty("generic")
      );

    // set message data
    Object.assign(messageData, {
      channel: targetChannel.id,
      preview: "New Assignment Alert!",
      blocks: [
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: generateStaffNotificationBlock(targetChannel, item),
          },
        },
      ],
    });

    // handle block limit - 3000 characters
    if (messageData.blocks[0].text.text.length > 3000) {
      // handle single GEO message with 20+ assignments
      let splitBlocks = [];
      if (messageData.blocks[0].text.text.includes(":open_file_folder:")) {
        const introAndMain =
            messageData.blocks[0].text.text.split(":open_file_folder:"),
          filesAndNotes = introAndMain[1].split(":warning:");
        if (filesAndNotes[0].length > 3000) {
          const files = filesAndNotes[0].split("     ○");
          splitBlocks.push(
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: introAndMain[0],
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: ":open_file_folder:".concat(files[0]),
              },
            }
          );
          files.forEach((str) => {
            if (files.indexOf(str) !== 0)
              splitBlocks.push({
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: "     ○".concat(str),
                },
              });
          });
          splitBlocks.push({
            type: "section",
            text: {
              type: "mrkdwn",
              text: ":warning:".concat(filesAndNotes[1]),
            },
          });
        } else {
          splitBlocks.push(
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: introAndMain[0],
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: ":open_file_folder:".concat(filesAndNotes[0]),
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: ":warning:".concat(filesAndNotes[1]),
              },
            }
          );
        }
        messageData.blocks = [...splitBlocks];
      } else {
        // usual slice of message
        const sliceIndex =
          messageData.blocks[0].text.text.indexOf(":heavy_check_mark:");
        blocks.push({
          type: "section",
          text: {
            type: "mrkdwn",
            text: messageData.blocks[0].text.text.slice(sliceIndex),
          },
        });
        messageData.blocks[0].text.text = messageData.blocks[0].text.text.slice(
          0,
          sliceIndex
        );
      }
    }

    // multiple GEOs threads processing
    if (item.geos.length > 1) {
      item.geos.forEach((geo) => {
        const geoLabel = Object.keys(geo)[0];
        geosThreads.push(
          `:globe_with_meridians: GEO: ${geoLabel}\n\n:open_file_folder: *File Names:*\n${geo[
            geoLabel
          ].assignments
            .map((assgn) => {
              return `     ○ <https://${DOMAIN}/${ROUTES.fcDashboard}?ppm=${
                item.payPeriod?.split(" ")[0]
              }&ppy=${item.payPeriod?.split(" ")[1]}&Search=${handleQueryChars(
                assgn
              )}&DeliveryDate=${item.deliveryDate}|${assgn}>`;
            })
            .join(
              "\n"
            )}\n\n:alarm_clock: *Deadlines for ${geoLabel}:*\n     ○ *Writing:* ${moment(
            geo[geoLabel].writingDueDate
              ? geo[geoLabel].writingDueDate
              : item.writingDueDate
          ).format("dddd, M/D")}, ${
            geo[geoLabel].writingDue
              ? geo[geoLabel].writingDue
              : item.writingDue
          } ET — ${geo[geoLabel].writers.join(", ")}.${
            Object.values(geo)[0].hasOwnProperty("editors")
              ? `\n     ○ *Editing:* ${moment(
                  geo[geoLabel].editingDueDate
                    ? geo[geoLabel].editingDueDate
                    : item.editingDueDate
                ).format("dddd, M/D")}, ${
                  geo[geoLabel].editingDue
                    ? geo[geoLabel].editingDue
                    : item.editingDue
                } ET — ${geo[geoLabel].editors.join(", ")}.`
              : ""
          }${
            Object.values(geo)[0].hasOwnProperty("reviewers")
              ? `\n     ○ *Review:* ${moment(
                  geo[geoLabel].reviewingDueDate
                    ? geo[geoLabel].reviewingDueDate
                    : item.reviewingDueDate
                ).format("dddd, M/D")}, ${
                  geo[geoLabel].reviewingDue
                    ? geo[geoLabel].reviewingDue
                    : item.reviewingDue
                } ET — ${geo[geoLabel].reviewers.join(", ")}.`
              : ""
          }`
        );
      });
    }
  } else {
    Object.assign(messageData, {
      channel: channels.find((channel) => channel?.mode?.includes(mode)).id,
      preview: getBlockData(mode, true),
      blocks: [
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: getBlockData(mode),
          },
        },
      ],
    });
  }

  try {
    const message = await axios.post(`${url}/send-to-channel`, messageData);
    if (geosThreads.length)
      geosThreads.forEach((el) => {
        let threadBlocks = [
          {
            type: "section",
            text: {
              type: "mrkdwn",
              text: el,
            },
          },
        ];
        // handle block limit - 3000 characters
        if (el.length > 3000) {
          let splitThreadBlocks = [];
          const introAndMain = el.split(":open_file_folder:"),
            filesAndDeadlines = introAndMain[1].split(":alarm_clock:"),
            files = filesAndDeadlines[0].split("     ○");
          splitThreadBlocks.push(
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: introAndMain[0],
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: ":open_file_folder:".concat(files[0]),
              },
            }
          );
          files.forEach((str) => {
            if (files.indexOf(str) !== 0)
              splitThreadBlocks.push({
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: "     ○".concat(str),
                },
              });
          });
          splitThreadBlocks.push({
            type: "section",
            text: {
              type: "mrkdwn",
              text: ":alarm_clock:".concat(filesAndDeadlines[1]),
            },
          });
          threadBlocks = [...splitThreadBlocks];
        }
        axios.post(`${url}/send-to-thread`, {
          token: process.env.VUE_APP_CUE_FC_SLACK_TOKEN,
          channel: message.data.channel,
          thread_ts: message.data.ts,
          blocks: threadBlocks,
        });
      });
  } catch (err) {
    return err.response;
  }
};
