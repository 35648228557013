<template>
  <div class="contacts my-10 px-2">
    <div class="d-flex align-end mb-4" :style="{ position: 'relative' }">
      <!-- left -->

      <!-- new contact -->
      <v-btn
        v-if="!laptopSmaller && isAdmin"
        height="38px"
        color="secondary"
        :loading="showTableLoader"
        @click="handleContactForm({})"
        >New</v-btn
      >
      <div v-if="laptopSmaller && isAdmin" class="text-center">
        <span class="overline"> New </span>
        <div>
          <v-btn
            min-width="42px"
            height="38px"
            class="px-0"
            color="secondary"
            :loading="showTableLoader"
            @click="handleContactForm({})"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>

      <!-- filters -->
      <div class="d-flex" :class="laptopSmaller ? 'align-end' : 'align-center'">
        <v-badge
          v-if="!laptopSmaller"
          bordered
          color="accent"
          overlap
          :value="filtersBadge"
          :content="filtersBadge"
          ><v-btn
            height="38px"
            color="#494949"
            class="ml-3"
            dark
            @click="filtersDialog = true"
            >Add Filters</v-btn
          ></v-badge
        >
        <div v-else class="text-center ml-3">
          <span class="overline"> Add </span>
          <div>
            <v-badge
              bordered
              color="accent"
              overlap
              :value="filtersBadge"
              :content="filtersBadge"
            >
              <v-btn
                min-width="42px"
                height="38px"
                color="#494949"
                class="px-0"
                @click="filtersDialog = true"
              >
                <v-icon color="white">mdi-filter-plus</v-icon>
              </v-btn></v-badge
            >
          </div>
        </div>
        <v-btn
          v-if="!laptopSmaller"
          height="38px"
          color="secondary"
          class="ml-3"
          :disabled="!filtersBadge"
          @click="clearFilters(false)"
          >Clear Filters</v-btn
        >
        <div v-else class="ml-3 text-center">
          <span class="overline"> Clear </span>
          <div>
            <v-btn
              min-width="42px"
              height="38px"
              class="px-0"
              color="secondary"
              :disabled="!filtersBadge"
              @click="clearFilters(false)"
            >
              <v-icon>mdi-filter-remove</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- total data -->
        <transition>
          <v-chip
            v-if="!laptopSmaller"
            v-show="tableData"
            class="font-weight-bold accent elevation-2 ml-3"
          >
            {{ `TOTAL: ${totalInfo}` }}
          </v-chip>
          <div v-else v-show="tableData" class="text-center ml-3 mb-1">
            <span class="overline"> Total </span>
            <div>
              <v-chip class="font-weight-bold accent elevation-2">
                {{ totalInfo }}
              </v-chip>
            </div>
          </div>
        </transition>

        <v-dialog
          content-class="filters"
          transition="dialog-top-transition"
          max-width="1500px"
          v-model="filtersDialog"
          hide-overlay
          persistent
          no-click-animation
        >
          <v-card
            class="d-flex justify-space-around flex-wrap px-6 pt-md-2 pt-lg-4 pb-md-4 pb-lg-6"
          >
            <!--  -->
            <div class="text-center">
              <span class="overline"> Active Staff </span>
              <div class="d-flex align-end justify-center">
                <v-checkbox
                  class="mt-2 pt-0"
                  v-model="filterActiveStaff"
                  @change="handleFilterQuery('ActiveStaff', $event)"
                ></v-checkbox>
              </div>
            </div>

            <!--  -->
            <div class="ml-lg-0 ml-md-2 text-center">
              <span class="overline"> Status </span>
              <div>
                <v-btn-toggle
                  class="elevation-2"
                  color="accent"
                  multiple
                  dense
                  v-model="filterStatus"
                  @change="handleFilterQuery('Status', $event)"
                >
                  <v-btn
                    v-for="(status, i) in statusOptions"
                    :key="i"
                    height="38px"
                    >{{ status }}</v-btn
                  >
                </v-btn-toggle>
              </div>
            </div>

            <!--  -->
            <div class="text-center">
              <span class="overline"> Start Date(s) </span>
              <div class="d-flex align-end">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  solo-inverted
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filterStartDateRange"
                      class="contacts__date-selector selector"
                      solo
                      dense
                      readonly
                      hide-details
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="clearFilters(true)"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterStartDate"
                    range
                    @input="handleFilterQuery('StartDate', $event)"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>

            <!--  -->
            <div class="ml-lg-0 ml-md-2 text-center">
              <span class="overline"> Skills </span>
              <div>
                <v-btn-toggle
                  class="elevation-2"
                  color="accent"
                  multiple
                  dense
                  v-model="filterSkill"
                  @change="handleFilterQuery('Skill', $event)"
                >
                  <v-tooltip
                    v-for="(skill, i) in skills"
                    :key="i"
                    color="primary"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        min-width="38px"
                        height="38px"
                        class="px-0"
                        ><v-icon
                          :color="
                            filterSkill.includes(i) ? 'accent' : '#000000de'
                          "
                          >{{ `mdi-${skill.icon}` }}</v-icon
                        ></v-btn
                      >
                    </template>
                    <span>{{ skill.name }}</span>
                  </v-tooltip>
                </v-btn-toggle>
              </div>
            </div>

            <!--  -->
            <div class="ml-lg-0 ml-md-2 text-center">
              <span class="overline"> General Expertise </span>
              <div>
                <v-btn-toggle
                  class="elevation-2"
                  color="accent"
                  multiple
                  dense
                  v-model="filterExpertise"
                  @change="handleFilterQuery('Expertise', $event)"
                >
                  <v-tooltip
                    v-for="(item, i) in expertise"
                    :key="i"
                    color="primary"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        min-width="38px"
                        height="38px"
                        class="px-0"
                        ><v-icon
                          :color="
                            filterExpertise.includes(i) ? 'accent' : '#000000de'
                          "
                          >{{ `mdi-${item.icon}` }}</v-icon
                        ></v-btn
                      >
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>
                </v-btn-toggle>
              </div>
            </div>

            <!--  -->
            <div class="ml-lg-0 ml-md-2 text-center">
              <span class="overline"> Native Language </span>
              <div>
                <v-autocomplete
                  class="contacts__language-selector selector"
                  :items="languages"
                  v-model="filterLanguage"
                  item-text="value"
                  solo
                  dense
                  hide-details
                  @input="handleFilterQuery('Language', $event)"
                ></v-autocomplete>
              </div>
            </div>

            <!--  -->
            <div class="text-center">
              <span class="overline"> Location (Country) </span>
              <div class="d-flex align-end">
                <v-autocomplete
                  class="contacts__country-selector selector"
                  :items="countries"
                  v-model="filterCountry"
                  solo
                  dense
                  hide-details
                  :loading="dropdownsLoading"
                  @change="handleFilterQuery('Country', $event)"
                ></v-autocomplete>
              </div>
            </div>

            <!--  -->
            <div class="ml-2 text-center">
              <span class="overline"> Lead Editor </span>
              <div class="d-flex align-end justify-center">
                <v-checkbox
                  class="mt-2 pt-0"
                  v-model="filterLeadEditors"
                  @change="handleFilterQuery('LeadEditors', $event)"
                ></v-checkbox>
              </div>
            </div>

            <!--  -->
            <div class="ml-4 text-center">
              <span class="overline"> Project Coordinator </span>
              <div class="d-flex align-end justify-center">
                <v-checkbox
                  class="mt-2 pt-0"
                  v-model="filterPCs"
                  @change="handleFilterQuery('PCs', $event)"
                ></v-checkbox>
              </div>
            </div>

            <!--  -->
            <div class="ml-4 text-center">
              <span class="overline"> Search </span>
              <div>
                <v-text-field
                  v-model="search"
                  class="contacts__search-selector selector"
                  prepend-inner-icon="mdi-magnify"
                  solo
                  dense
                  hide-details
                  clearable
                  @click:prepend-inner="applySearch(search)"
                  @click:clear="applySearch('')"
                  @keydown.enter="applySearch(search)"
                />
              </div>
            </div>

            <v-card-actions class="ml-auto pa-0 align-end">
              <v-btn
                color="secondary"
                @click="clearFilters(false)"
                :disabled="!filtersBadge"
                >Clear Filters</v-btn
              >
              <v-btn color="#494949" dark @click="filtersDialog = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          class="contacts__modal"
          max-width="1200"
          v-model="contactModal"
          persistent
        >
          <ContactForm
            :itemToEdit="itemToEdit"
            :profileView="false"
            :tableFields="tableFields"
            @close-form="contactModal = false"
            @refresh-data="handleRefresh"
          />
        </v-dialog>
      </div>

      <!-- right -->
      <div class="ml-auto">
        <v-text-field
          v-model="search"
          class="contacts__search-selector selector"
          append-outer-icon="mdi-magnify"
          solo
          dense
          hide-details
          clearable
          @click:append-outer="applySearch(search)"
          @click:clear="applySearch('')"
          @keydown.enter="applySearch(search)"
        />
      </div>
    </div>
    <!-- table -->
    <v-card>
      <v-skeleton-loader
        v-if="showTableLoader"
        type="table"
        height="100vh"
      ></v-skeleton-loader>
      <ContactsTable
        v-if="tableData && !showTableLoader"
        :tableData="tableData"
        :tableFields="tableFields"
        :filterStartDate="filterStartDate"
        :filterSearch="filterSearch"
        :filterLanguage="filterLanguage ? filterLanguage : ''"
        :filterStatus="filterStatus"
        :filterActiveStaff="filterActiveStaff"
        :filterCountry="filterCountry ? filterCountry : ''"
        :filterSkill="filterSkill"
        :filterExpertise="filterExpertise"
        :filterLeadEditors="filterLeadEditors"
        :filterPCs="filterPCs"
        @call-edit-form="handleContactForm"
        @refresh-total-info="setTotalInfo"
      />
    </v-card>
    <!-- flash message -->
    <v-snackbar
      v-model="confirmationMsg"
      min-width="unset"
      timeout="2000"
      :color="success ? 'success' : 'error'"
      rounded="pill"
      content-class="text-center"
    >
      <v-icon color="white" class="mr-1">{{
        success ? "mdi-check-circle-outline" : "mdi-close-circle-outline"
      }}</v-icon>
      {{ confirmationMsgTxt }}
    </v-snackbar>
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// libraries
import _ from "lodash";
// internal
import {
  appIDs,
  getEmployeesData,
  getFieldInfosFromDB,
  getAvailabilityRecords,
} from "@/utils/quickbaseUtils";
import { customBreakpoint } from "@/utils/mixins";
import { ROUTES } from "@/utils/constants";
import { functions } from "@/providers/firebase";
// components
import ContactsTable from "@/components/Contacts/ContactsTable";
import ContactForm from "@/components/Contacts/ContactForm";

export default {
  name: "Contacts",
  components: {
    ContactsTable,
    ContactForm,
  },
  data() {
    return {
      showTableLoader: false,
      tableData: null,
      tableFields: null,
      filterStartDate: [],
      filterSearch: "",
      search: "",
      expandMenu: false,
      dropdownsLoading: true,
      filterActiveStaff: false,
      statusOptions: [],
      filterStatus: [0],
      languages: [],
      filterLanguage: "",
      countries: [],
      filterCountry: "",
      skills: [
        { name: "Copywriting", icon: "fountain-pen-tip" },
        { name: "Copyediting", icon: "text-box-edit" },
        { name: "Translation", icon: "translate-variant" },
        { name: "Editing Translations", icon: "text-box-edit-outline" },
        { name: "Curation", icon: "table-filter" },
        { name: "Design", icon: "pencil-ruler" },
        { name: "Metadata", icon: "database" },
        { name: "Project Management", icon: "account-cog" },
      ],
      filterSkill: [],
      expertise: [
        { name: "Music", icon: "music" },
        { name: "TV & Films", icon: "video-vintage" },
        { name: "Books", icon: "book-open-page-variant" },
        { name: "Mobile/Video Games", icon: "controller" },
        { name: "Podcasts", icon: "microphone" },
        { name: "Mobile Apps", icon: "tablet-cellphone" },
        { name: "Lifestyle", icon: "creation" },
        { name: "Sports", icon: "soccer" },
      ],
      filterExpertise: [],
      filterLeadEditors: false,
      filterPCs: false,
      filtersDialog: false,
      contactModal: false,
      itemToEdit: {},
      success: true,
      confirmationMsg: false,
      confirmationMsgTxt: "",
      totalInfo: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "isAdmin"]),
    filtersBadge() {
      return Object.entries(this.$route.query).filter((el) => el[1] !== null)
        .length;
    },
    filterStartDateRange: {
      get: function () {
        return this.filterStartDate.join(" - ");
      },
      set: function (newVal) {
        return (newVal ? [...newVal] : []).join(" - ");
      },
    },
    filterSearchBadge() {
      return this.filterSearch?.length <= 6
        ? this.filterSearch
        : `${this.filterSearch?.slice(0, 6)}...`;
    },
  },
  mixins: [customBreakpoint],
  watch: {
    filterSearch(newValue) {
      const currentQuery = this.$route.query;
      const newQuery =
        newValue !== ""
          ? {
              ...currentQuery,
              Search: newValue,
            }
          : _.omit(currentQuery, ["Search"]);

      this.$router
        .push({ path: `/${ROUTES.contacts}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
  },
  mounted() {
    this.handleQuery();
    this.getTableData();
    this.handleDropdowns();
  },
  methods: {
    setTotalInfo(newTotal) {
      this.totalInfo = newTotal;
    },
    applySearch(search) {
      this.filterSearch = search;
    },
    getTableData() {
      this.showTableLoader = true;
      getAvailabilityRecords({
        startingFrom: this.$moment(new Date()).format("MM-DD-YYYY"),
      })
        .then((data) => {
          const timeOffs = data.filter(
            (el) =>
              el["Adjusted Daily Workload - Localization"] === 0 &&
              el["Adjusted Daily Workload - Editing"] === 0
          );
          const getCueHubUsers = functions.httpsCallable("getAllUsers");
          getCueHubUsers().then((authUsers) => {
            getEmployeesData()
              .then((resp) => {
                this.tableData = resp.data.map((el) => {
                  const timeOff = timeOffs.find(
                    (item) => item.Name === el.Name
                  );
                  const firebaseUser = authUsers.data.users.find(
                    (user) =>
                      user.email === el.Email || user.email === el.CueEmail
                  );
                  return {
                    ...el,
                    TimeOff: timeOff
                      ? `From ${this.$moment(timeOff["Start Date"]).format(
                          "MM/DD/YYYY"
                        )} to ${this.$moment(timeOff["End Date"]).format(
                          "MM/DD/YYYY"
                        )}`
                      : "",
                    ...firebaseUser,
                    admin: firebaseUser
                      ? firebaseUser.customClaims.admin
                      : null,
                    dubEditor: firebaseUser
                      ? firebaseUser.customClaims.dubEditor
                      : null,
                    company: firebaseUser
                      ? firebaseUser.customClaims.company
                      : null,
                    cueHub: !!firebaseUser,
                  };
                });
                this.tableFields = resp.fields.map((field) => {
                  return {
                    text: field.text.replace("Language 1", "Native Language"),
                    value: field.value,
                  };
                });
                this.tableFields.push(
                  {
                    text: "Main Skill",
                    value: "MainSkill",
                  },
                  {
                    text: "Primary Expertise",
                    value: "GeneralExpertise",
                  },
                  {
                    text: "Time Off",
                    value: "TimeOff",
                  },
                  {
                    text: "Slack Staff",
                    value: "SlackStaff",
                  }
                );
                this.showTableLoader = false;
              })
              .catch((err) => {
                console.error(err);
                this.showTableLoader = false;
              });
          });
        })
        .catch((err) => {
          console.error(err);
          this.showTableLoader = false;
        });
    },
    handleFilterQuery(param, data) {
      const currentQuery = this.$route.query;

      const newQuery = {
        ...currentQuery,
        [param]: data,
      };
      this.$router
        .push({ path: `/${ROUTES.contacts}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler

      this[`filter${param}`] = data;
    },
    clearFilters(dateOnly) {
      let query = this.$route.query;

      if (dateOnly) {
        query = _.omit(query, ["StartDate"]);
      } else {
        this.filterStatus = [0];
        this.filterSkill = this.filterExpertise = [];
        this.filterLeadEditors =
          this.filterPCs =
          this.filterActiveStaff =
            false;
        this.filterLanguage =
          this.filterCountry =
          this.filterSearch =
          this.search =
            "";
      }

      this.filterStartDate = [];

      this.$router.push({ path: `/${ROUTES.contacts}` }).catch(() => {}); // Avoided redundant navigation error handler
    },
    getLanguages() {
      return new Promise((resolve, reject) => {
        getFieldInfosFromDB({
          fieldID: 146,
          tableID: appIDs.cueStaff.contacts,
        })
          .then((languages) => resolve((this.languages = languages)))
          .catch((err) => reject(console.error(err)));
      });
    },
    getStatuses() {
      return new Promise((resolve, reject) => {
        getFieldInfosFromDB({
          fieldID: 9,
          tableID: appIDs.cueStaff.contacts,
        })
          .then((statuses) => resolve((this.statusOptions = statuses)))
          .catch((err) => reject(console.error(err)));
      });
    },
    getContries() {
      return new Promise((resolve, reject) => {
        getFieldInfosFromDB({
          fieldID: 17,
          tableID: appIDs.cueStaff.contacts,
        })
          .then((countries) => resolve((this.countries = countries)))
          .catch((err) => reject(console.error(err)));
      });
    },
    handleDropdowns() {
      Promise.all([this.getLanguages(), this.getStatuses(), this.getContries()])
        .then(() => (this.dropdownsLoading = false))
        .catch((err) => {
          this.dropdownsLoading = false;
          console.error(err);
        });
    },
    handleQuery() {
      const currentQuery = this.$route.query;

      if (!_.isEmpty(currentQuery))
        Object.keys(currentQuery).forEach((param) => {
          if (param === "StartDate") {
            this[`filter${param}`] =
              typeof currentQuery[param] === "string"
                ? [currentQuery[param]]
                : currentQuery[param];
          } else if (
            param === "ActiveStaff" ||
            param === "LeadEditors" ||
            param === "PCs"
          ) {
            this[`filter${param}`] = currentQuery[param] === "true";
          } else if (
            param === "Skill" ||
            param === "Expertise" ||
            param === "Status"
          ) {
            this[`filter${param}`] =
              typeof currentQuery[param] === "string"
                ? [Number(currentQuery[param])]
                : currentQuery[param].map((x) => Number(x));
          } else {
            this[`filter${param}`] = currentQuery[param];
            if (param === "Search") this.search = currentQuery[param];
          }
        });
    },
    handleContactForm(tableRow) {
      this.itemToEdit = { ...tableRow };
      this.contactModal = true;
    },
    handleRefresh(msg) {
      this.contactModal = false;
      this.getTableData();
      this.success = !!msg;
      this.confirmationMsgTxt =
        msg ?? "Something went wrong. Please try again.";
      this.confirmationMsg = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.contacts {
  max-width: 1500px;
  margin: 0 auto;

  &__date-selector {
    width: 220px;
  }

  &__language-selector {
    width: 200px;
  }

  &__country-selector {
    width: 280px;
  }

  &__search-selector {
    width: 450px;
  }
}

::v-deep {
  .selector.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding-right: 0;
  }
  .selector.v-text-field.v-text-field--solo .v-input__append-inner {
    padding-left: 0;
  }
  .selector.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0;
  }
  .selector.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 2px 0;
  }
  .v-dialog--active {
    position: relative;
  }
  .filters {
    position: absolute;
    top: 20px;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'lg-and-down')} {
  .v-application .overline {
    font-size: 0.6rem !important;
  }
}
</style>
