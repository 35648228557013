<template>
  <v-container class="pb-6">
    <v-row class="text-left">
      <!-- left column -->
      <v-col cols="12" sm="6" class="pa-0">
        <div class="overline">{{ assignmentInfo["AssignmentTitle"] }}</div>
        <h2>{{ assignmentInfo["AssignmentDetail"] }}</h2>
        <v-chip class="my-5">
          {{
            assignmentInfo["GEO"] +
            (assignmentInfo["SourceGEO"]
              ? ` (${assignmentInfo["SourceGEO"]})`
              : "")
          }}
        </v-chip>
        <div>
          <!-- media type -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Media Type</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["MediaType"]
            }}</v-col>
          </v-row>

          <!-- keyword -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Keyword</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["Keyword"]
            }}</v-col>
          </v-row>

          <!-- Request Type -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Request Type</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["RequestType"]
            }}</v-col>
          </v-row>

          <!-- File Location -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >File Location</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["FileLocation"]
            }}</v-col>
          </v-row>

          <!-- Request Date -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Request Date</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              formatDateTime(assignmentInfo["RequestDate"])
            }}</v-col>
          </v-row>

          <!-- pay period -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Pay Period</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["PayPeriod"]
            }}</v-col>
          </v-row>

          <!-- Task ID -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Task ID</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["TaskID"]
            }}</v-col>
          </v-row>

          <!-- Cue Task ID -->
          <v-row v-if="isAdmin || isManager">
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Cue Task ID</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["Task-CueTaskID"]
            }}</v-col>
          </v-row>

          <!-- Client Requester -->
          <v-row v-if="isAdmin || isManager">
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Client Requester</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["RelatedClientRequester"]
            }}</v-col>
          </v-row>

          <!-- word count -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Word Count</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["WordCount"]
            }}</v-col>
          </v-row>

          <!-- note -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold">Note</v-col>
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo["Note"]
            }}</v-col>
          </v-row>
        </div>
      </v-col>
      <!-- right column -->
      <v-col cols="12" sm="6">
        <div class="table-bg text-center">
          <h3 class="pb-2">Assignment Steps</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Step</th>
                  <th class="text-center">Person</th>
                  <th class="text-center">Due</th>
                  <th class="text-center">Accepted</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Writer</td>
                  <td>{{ assignmentInfo["Writer"] }}</td>
                  <td>
                    {{
                      formatDateTime(
                        assignmentInfo["WritingDueDate"],
                        assignmentInfo["WritingDue(TimeOfDay)"]
                      )
                    }}
                  </td>
                  <td>
                    <v-icon v-if="assignmentInfo['WriterAccepted']"
                      >mdi-check</v-icon
                    >
                  </td>
                </tr>
                <tr v-if="assignmentInfo['EditingDueDate'] !== ''">
                  <td>Editor</td>
                  <td>{{ assignmentInfo["Editor"] }}</td>
                  <td>
                    {{
                      formatDateTime(
                        assignmentInfo["EditingDueDate"],
                        assignmentInfo["EditingDue(TimeOfDay)"]
                      )
                    }}
                  </td>
                  <td>
                    <v-icon v-if="assignmentInfo['EditorAccepted']"
                      >mdi-check</v-icon
                    >
                  </td>
                </tr>
                <tr v-if="assignmentInfo['ReviewingDueDate'] !== ''">
                  <td>Reviewer</td>
                  <td>{{ assignmentInfo["Reviewer"] }}</td>
                  <td>
                    {{
                      formatDateTime(
                        assignmentInfo["ReviewingDueDate"],
                        assignmentInfo["ReviewingDue(TimeOfDay)"]
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div class="mt-6 table-bg text-center">
          <h3 class="pb-2">Assignment Work Types</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Work Type</th>
                  <th class="text-center">Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ assignmentInfo["WorkType1"] }}</td>
                  <td>{{ assignmentInfo["WorkType1Quantity"] }}</td>
                </tr>
                <tr v-if="assignmentInfo['WorkType2'] !== ''">
                  <td>{{ assignmentInfo["WorkType2"] }}</td>
                  <td>{{ assignmentInfo["WorkType2Quantity"] }}</td>
                </tr>
                <tr v-if="assignmentInfo['WorkType3'] !== ''">
                  <td>{{ assignmentInfo["WorkType3"] }}</td>
                  <td>{{ assignmentInfo["WorkType3Quantity"] }}</td>
                </tr>
                <tr v-if="assignmentInfo['WorkType4'] !== ''">
                  <td>{{ assignmentInfo["WorkType4"] }}</td>
                  <td>{{ assignmentInfo["WorkType4Quantity"] }}</td>
                </tr>
                <tr v-if="assignmentInfo['WorkType5'] !== ''">
                  <td>{{ assignmentInfo["WorkType5"] }}</td>
                  <td>{{ assignmentInfo["WorkType5Quantity"] }}</td>
                </tr>
                <tr v-if="assignmentInfo['WorkType6'] !== ''">
                  <td>{{ assignmentInfo["WorkType6"] }}</td>
                  <td>{{ assignmentInfo["WorkType6Quantity"] }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-end px-3" v-if="isAdmin || isManager">
      <!-- bottom controls -->
      <v-btn class="accent" @click="handleOpenInQB(assignmentInfo['RecordID#'])"
        >open in quickbase</v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// internal
import { appIDs, realmID } from "@/utils/quickbaseUtils";

export default {
  name: "FCDashboardTableExpansion",
  props: {
    assignmentInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["isAdmin", "isManager"]),
  },
  methods: {
    formatDateTime(date, time) {
      return `${this.$moment(date).format("MM/DD/YY")}${
        time ? " " + time : ""
      }`;
    },
    handleOpenInQB(rid) {
      const url = `https://${realmID}/db/${appIDs.iTunesProjectManager.assignments}?a=dr&rid=${rid}`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.table-bg {
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
</style>
