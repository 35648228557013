<template>
  <div class="client-emails">
    <ClientEmailsTable />
  </div>
</template>

<script>
import ClientEmailsTable from "@/components/ClientEmails/ClientEmailsTable";
export default {
  components: { ClientEmailsTable },
};
</script>

<style lang="scss" scoped>
.client-emails {
  max-width: 1400px;
  margin: 0 auto;
}
</style>
