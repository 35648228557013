<template>
  <div class="calendar elevation-3" :class="{ 'my-10': !profileView }">
    <v-sheet tile height="54" class="d-flex justify-space-between">
      <div class="d-flex align-center">
        <v-btn
          icon
          class="ma-2"
          :loading="showCalendarLoader"
          @click="$refs.calendar.prev()"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="text-h4">{{ calendarTitle }}</span>
      </div>
      <div class="d-flex align-center">
        <v-autocomplete
          v-if="!profileView"
          class="filter mr-3"
          label="Role"
          :items="roles"
          :disabled="showCalendarLoader"
          v-model="filterRole"
        ></v-autocomplete>
        <v-autocomplete
          v-if="!profileView"
          class="filter mr-3"
          label="GEO"
          :items="geos"
          :disabled="showCalendarLoader || geosLoading"
          :loading="geosLoading"
          v-model="filterGeo"
        ></v-autocomplete>
        <v-btn-toggle
          class="elevation-2 mr-3"
          color="secondary"
          mandatory
          dense
          v-model="view"
        >
          <v-btn>Mon</v-btn>
          <v-btn>Week</v-btn>
          <v-btn>Day</v-btn>
        </v-btn-toggle>
        <div class="d-flex align-center">
          <v-btn
            min-width="32px"
            width="32px"
            height="32px"
            color="secondary"
            class="mr-3"
            :loading="showCalendarLoader"
            @click="focus = ''"
          >
            <v-icon>mdi-calendar-today</v-icon></v-btn
          >
          <v-menu
            v-model="focusMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                min-width="32px"
                width="32px"
                height="32px"
                color="secondary"
                class="mr-3"
                :loading="showCalendarLoader"
              >
                <v-icon>mdi-calendar-search</v-icon></v-btn
              >
            </template>
            <v-date-picker
              v-model="focus"
              @input="focusMenu = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <v-btn-toggle
          v-if="!profileView"
          class="elevation-2 mr-3"
          color="secondary"
          mandatory
          dense
          v-model="mutableMode"
          @change="handleEmployeeCheck"
        >
          <v-btn>{{ laptopSmaller ? "Avail" : "Availability" }}</v-btn>
          <v-btn :disabled="!searchEmployee || !searchEmployee.Name">
            {{ laptopSmaller ? "Cap" : "Capacity" }}
          </v-btn>
        </v-btn-toggle>
        <div class="d-flex align-center">
          <v-btn
            min-width="32px"
            width="32px"
            height="32px"
            color="secondary"
            class="mr-3"
            :disabled="
              !Object.keys(searchEmployee).length ||
              searchEmployee === null ||
              !mutableMode
            "
            @click="handleDefaultCapacity"
          >
            <v-icon>mdi-account-edit</v-icon></v-btn
          >
          <v-autocomplete
            class="person-picker"
            :label="profileView ? '' : 'Choose the person to check'"
            :items="employees"
            item-text="Name"
            return-object
            :append-icon="profileView ? '' : '$dropdown'"
            :readonly="profileView"
            :disabled="showCalendarLoader"
            v-model="searchEmployee"
            @input="handleEmployeeCheck"
          ></v-autocomplete>
        </div>
        <v-btn
          icon
          class="ma-2"
          :loading="showCalendarLoader"
          @click="$refs.calendar.next()"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-sheet>
    <v-sheet max-height="700px" class="overflow-y-auto overflow-x-hidden pb-14">
      <v-skeleton-loader
        v-if="showCalendarLoader"
        type="table-tbody@2"
      ></v-skeleton-loader>
      <v-calendar
        v-if="!showCalendarLoader"
        ref="calendar"
        class="admin-calendar"
        :class="{ disabled: mutableMode }"
        v-model="focus"
        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        :type="viewType"
        :event-more="false"
        :event-height="16"
        :events="filteredEvents"
        @click:date="profileView ? null : markUnavailable($event)"
        @click:event="profileView ? null : editUnavailabilityRecord($event)"
      ></v-calendar>
    </v-sheet>
    <v-row justify="center">
      <v-dialog
        v-model="unavailabilityRecord"
        width="950px"
        @click:outside="handleUnavailabilityRecordClosing"
        @keydown.esc="handleUnavailabilityRecordClosing"
      >
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="v-card v-sheet theme--light"
        >
          <v-row
            v-if="editMode"
            class="pa-6 ma-0 align-center justify-space-between"
          >
            <span v-if="tab == 0" class="text-h5">{{ formMode[0] }}</span>
            <span v-if="tab == 1" class="text-h5">{{ formMode[1] }}</span>
            <v-btn
              class="mx-2"
              fab
              small
              color="secondary"
              @click="deleteUnavailabilityRecord"
              :loading="deletionProcessing"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-row>
          <v-tabs
            v-else
            v-model="tab"
            background-color="transparent"
            grow
            class="pa-4 pb-8"
          >
            <v-tab v-for="mode in formMode" :key="mode">
              {{ mode }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="px-3">
            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      outlined
                      label="Name"
                      :items="employees"
                      item-text="Name"
                      return-object
                      v-model="employee"
                      :disabled="editMode"
                      :rules="[rules.requiredObject]"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-menu
                      v-model="unavailabilityDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="unavailabilityDatesRange"
                          label="Date(s) unavailable"
                          prepend-icon="mdi-calendar-remove"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="
                            unavailabilityDatesValidation
                              ? null
                              : dateValidationError
                          "
                          :rules="tab == 0 ? [rules.required] : []"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="unavailabilityDates"
                        range
                        :rules="tab == 0 ? [rules.required] : []"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-combobox
                      ref="comboBox"
                      outlined
                      label="Note"
                      :items="suggestedNotes"
                      v-model="note"
                      :rules="tab == 0 ? [rules.required] : []"
                    ></v-combobox>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      outlined
                      label="Name"
                      :items="employees"
                      item-text="Name"
                      return-object
                      v-model="employee"
                      :disabled="editMode"
                      :rules="[rules.requiredObject]"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-menu
                      v-model="limitedCapacityDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="limitedCapacityDatesRange"
                          label="Date(s) capacity limited"
                          prepend-icon="mdi-calendar-remove"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="
                            limitedCapacityDateValidation
                              ? null
                              : dateValidationError
                          "
                          :rules="tab == 1 ? [rules.required] : []"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="limitedCapacityDates"
                        range
                        :rules="tab == 1 ? [rules.required] : []"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-combobox
                      ref="limitedCapacityBox"
                      outlined
                      label="Note"
                      :items="suggestedNotes"
                      v-model="limitedCapacityNote"
                      :rules="tab == 1 ? [rules.required] : []"
                    ></v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      outlined
                      label="LOC Translating"
                      type="number"
                      v-model="localization"
                      :rules="tab == 1 && !editing ? [rules.required] : []"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      outlined
                      label="LOC Editing"
                      type="number"
                      v-model="editing"
                      :rules="tab == 1 && !localization ? [rules.required] : []"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
          <v-card-actions class="pa-6 pt-4 justify-end">
            <v-btn
              width="30%"
              color="secondary"
              :disabled="
                (tab == 0 && !unavailabilityDatesValidation) ||
                (tab == 1 && !limitedCapacityDateValidation)
              "
              @click="handleUnavailabilityRecord"
              :loading="creationProcessing || updatingProcessing"
            >
              {{ btnTxt }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="defaultCapacity" width="fit-content">
        <v-form
          ref="defaultCapacityForm"
          v-model="defaultCapacityValid"
          lazy-validation
          class="v-card v-sheet theme--light"
        >
          <v-card-title class="justify-space-between py-4">
            <span class="text-h5">Default Workload</span>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-container>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    outlined
                    label="Name"
                    v-model="searchEmployee.Name"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="pb-0">
                  <v-text-field
                    outlined
                    label="LOC Translating Daily Workload"
                    type="number"
                    :readonly="profileView"
                    :disabled="showDefaultCapacityLoader"
                    :loading="showDefaultCapacityLoader"
                    v-model="localizationDefaultCapacity"
                  />
                </v-col>
                <v-col cols="12" sm="6" class="pb-0">
                  <v-text-field
                    outlined
                    label="LOC Editing Daily Workload"
                    type="number"
                    :readonly="profileView"
                    :disabled="showDefaultCapacityLoader"
                    :loading="showDefaultCapacityLoader"
                    v-model="editingDefaultCapacity"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="d-flex justify-end px-6 py-4">
            <v-btn
              v-if="!profileView"
              width="30%"
              color="secondary"
              @click="handleDefaultCapacityUpdate"
              :loading="confirmationProcessing"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// internal
import {
  getEmployeesData,
  addAvailabilityRecord,
  getAvailabilityRecords,
  deleteRowFromQB,
  appIDs,
  getDefaultCapacityByEmployee,
  getWorkloadByEmployee,
  getFieldInfosFromDB,
} from "@/utils/quickbaseUtils";
import { publishMessage } from "@/utils/slackUtils";
import { customBreakpoint } from "@/utils/mixins";
import { functions } from "@/providers/firebase";

export default {
  name: "AdminCalendar",
  props: {
    mode: {
      type: Number,
      required: false,
      default: 0,
    },
    employeeToSearch: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    profileView: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      showCalendarLoader: false,
      focus: "",
      focusMenu: false,
      currentDate: {},
      events: [],
      employees: [],
      managers: [],
      leadEditors: [],
      admins: [],
      employee: {},
      roles: ["Admin", "Manager", "Lead Editor"],
      filterRole: "",
      geos: [],
      filterGeo: "",
      geosLoading: true,
      valid: true,
      unavailabilityRecord: false,
      unavailabilityDateMenu: false,
      unavailabilityDates: [],
      suggestedNotes: [
        "Time off",
        "At capacity (can't accept new tasks)",
        "Sick/personal day",
        "National holiday",
      ],
      note: "",
      limitedCapacityDateMenu: false,
      limitedCapacityDates: [],
      limitedCapacityNote: "",
      localization: "",
      editing: "",
      tab: 0,
      formMode: ["Unavailability Record", "Limited Capacity"],
      creationProcessing: false,
      editMode: false,
      recordID: null,
      updatingProcessing: false,
      deletionProcessing: false,
      view: 0,
      mutableMode: this.mode,
      searchEmployee: this.employeeToSearch,
      dataForWriting: [],
      dataForEditing: [],
      showDefaultCapacityLoader: false,
      defaultCapacity: false,
      defaultCapacityValid: true,
      localizationDefaultCapacity: "",
      editingDefaultCapacity: "",
      confirmationProcessing: false,
      dateValidationError: "End date can't be before start date",
      rules: {
        required: (value) => !!value || "Required",
        requiredObject: (value) =>
          (value ? !!Object.keys(value).length : !!value) || "Required",
      },
    };
  },
  methods: {
    refreshChecker(date) {
      if (date.month !== this.currentDate.month) {
        this.updateCurrentDate(date.month, date.year);
        if (!this.mutableMode) this.getCalendarData();
      }
    },
    updateCurrentDate(month, year) {
      const newDate = {
        month,
        year,
      };
      this.currentDate = { ...newDate };
    },
    getCalendarData() {
      this.showCalendarLoader = true;
      getAvailabilityRecords({
        mm: this.currentDate.month,
        yyyy: this.currentDate.year,
        employeeName: this.searchEmployee?.Name,
      })
        .then((data) => {
          this.events = data.map((el) => ({
            name: this.searchEmployee?.Name ? el["Note"] : el["Name"],
            start: el["Start Date"],
            end: el["End Date"],
            note: el["Note"],
            localization: el["Adjusted Daily Workload - Localization"],
            editing: el["Adjusted Daily Workload - Editing"],
            id: el["Record ID#"],
            color:
              el["Note"] === "Time off"
                ? "#ADE3ED"
                : el["Note"] === "At capacity (can't accept new tasks)"
                ? "#EBA79B"
                : el["Note"] === "National holiday"
                ? "#CAF2BF"
                : el["Note"] === "Sick/personal day"
                ? "#F2E9AE"
                : "#DBD5D0",
          }));
          this.showCalendarLoader = false;
        })
        .catch((err) => {
          console.error(err);
          this.showCalendarLoader = false;
        });
    },
    markUnavailable(value) {
      this.refreshChecker({
        month: this.$moment(value.date).format("MM"),
        year: this.$moment(value.date).format("YYYY"),
      });
      this.employee = {};
      this.note =
        this.localization =
        this.editing =
        this.limitedCapacityNote =
          "";
      this.unavailabilityDates = [];
      this.unavailabilityDates.push(value.date);
      this.limitedCapacityDates = [];
      this.unavailabilityRecord = true;
      this.$refs?.form?.resetValidation();
    },
    handleUnavailabilityRecord() {
      if (this.note || this.tab == 0) this.$refs.comboBox.blur();
      if (this.limitedCapacityNote || this.tab == 1)
        this.$refs.limitedCapacityBox.blur();
      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.creationProcessing = true;
          const postData = [
            {
              6: { value: this.employee.Name },
              7: {
                value:
                  this.tab == 0
                    ? this.unavailabilityDates[0]
                    : this.limitedCapacityDates[0],
              },
              8: {
                value:
                  this.tab == 0
                    ? this.unavailabilityDates[1]
                      ? this.unavailabilityDates[1]
                      : this.unavailabilityDates[0]
                    : this.limitedCapacityDates[1]
                    ? this.limitedCapacityDates[1]
                    : this.limitedCapacityDates[0],
              },
              9: {
                value: this.tab == 0 ? this.note : this.limitedCapacityNote,
              },
              16: { value: this.tab == 0 ? 0 : this.localization },
              17: { value: this.tab == 0 ? 0 : this.editing },
            },
          ];

          if (this.editMode)
            Object.assign(postData[0], { 3: { value: this.recordID } });
          addAvailabilityRecord(postData)
            .then(() => {
              this.creationProcessing = false;
              this.getCalendarData();
              if (this.tab == 0)
                publishMessage({
                  user: `*${this.employee.Name}*`,
                  mode: "availability",
                  data: {
                    from: this.unavailabilityDates[0],
                    to: this.unavailabilityDates[1],
                    note: this.note,
                  },
                });
              this.handleUnavailabilityRecordClosing();
            })
            .catch((err) => {
              console.error(err);
              this.creationProcessing = false;
            });
        }
      }, 100);
    },
    handleUnavailabilityRecordClosing() {
      this.unavailabilityRecord = this.editMode = false;
      this.unavailabilityDates = [];
      this.limitedCapacityDates = [];
    },
    editUnavailabilityRecord(record) {
      this.editMode = true;
      this.employee = this.employees.find(
        (employee) =>
          employee.Name === (this.searchEmployee?.Name ?? record.event.name)
      );
      if (record.event.localization || record.event.editing) {
        this.tab = 1;
        record.event.start === record.event.end
          ? this.limitedCapacityDates.push(record.event.start)
          : this.limitedCapacityDates.push(
              record.event.start,
              record.event.end
            );
        this.limitedCapacityNote = record.event.note;
        this.localization = record.event.localization;
        this.editing = record.event.editing;
      } else {
        this.tab = 0;
        record.event.start === record.event.end
          ? this.unavailabilityDates.push(record.event.start)
          : this.unavailabilityDates.push(record.event.start, record.event.end);
        this.note = record.event.note;
      }
      this.recordID = record.event.id;
      this.unavailabilityRecord = true;
    },
    deleteUnavailabilityRecord() {
      this.deletionProcessing = true;
      deleteRowFromQB({ id: this.recordID, tableID: appIDs.cueStaff.calendar })
        .then(() => {
          this.deletionProcessing = false;
          this.getCalendarData();
          this.handleUnavailabilityRecordClosing();
        })
        .catch((err) => {
          console.error(err);
          this.deletionProcessing = this.editMode = false;
        });
    },
    getDatesRange(startDate, endDate) {
      let datesRange = [];
      for (
        let dateVar = new Date(startDate);
        dateVar <= endDate;
        dateVar.setDate(dateVar.getDate() + 1)
      ) {
        datesRange.push(this.$moment(new Date(dateVar)).format("YYYY-MM-DD"));
      }
      return datesRange;
    },
    mergeRecordsByDate(initialArray, operation) {
      initialArray.forEach((el) => {
        const existingItems = this[`dataFor${operation}`].filter((item) => {
          return item[`${operation} Due Date`] === el[`${operation} Due Date`];
        });
        if (existingItems.length) {
          const existingItemIndex = this[`dataFor${operation}`].indexOf(
            existingItems[0]
          );
          this[`dataFor${operation}`][existingItemIndex]["Word Count"] =
            Number(
              this[`dataFor${operation}`][existingItemIndex]["Word Count"]
            ) + el["Word Count"];
        } else {
          this[`dataFor${operation}`].push(el);
        }
      });
    },
    handleEmployeeCheck() {
      if (this.searchEmployee !== null)
        if (Object.keys(this.searchEmployee).length) {
          this.showCalendarLoader = true;
          this.events = [];
          this.dataForWriting = [];
          this.dataForEditing = [];
          if (this.mutableMode) {
            let defaultCapacityData = [],
              adjustedCapacityRecords = [],
              adjustedWritingDates = [],
              adjustedEditingDates = [];
            getDefaultCapacityByEmployee(this.searchEmployee.Name)
              .then((data) => {
                defaultCapacityData = Object.assign({}, ...data);
                return getWorkloadByEmployee({
                  employeeName: this.searchEmployee.Name,
                  writing: true,
                }).then((data) => {
                  this.mergeRecordsByDate(data, "Writing");
                  return getWorkloadByEmployee({
                    employeeName: this.searchEmployee.Name,
                    editing: true,
                  }).then((data) => {
                    this.mergeRecordsByDate(data, "Editing");
                    return getAvailabilityRecords({
                      employeeName: this.searchEmployee.Name,
                    }).then((data) => {
                      data.forEach((el) => {
                        adjustedCapacityRecords.push({
                          dates: this.getDatesRange(
                            this.$moment(el["Start Date"]),
                            this.$moment(el["End Date"])
                          ),
                          editing: el["Adjusted Daily Workload - Editing"],
                          localization:
                            el["Adjusted Daily Workload - Localization"],
                        });
                      });
                      this.dataForWriting.forEach((el) => {
                        const adjustedWritingRecord =
                          adjustedCapacityRecords.find(
                            (acr) =>
                              acr.dates.includes(el["Writing Due Date"]) &&
                              acr.localization !== null
                          );
                        this.events.push({
                          name: adjustedWritingRecord
                            ? `LOC Writing: ${
                                Number(adjustedWritingRecord.localization) -
                                el["Word Count"]
                              } *Adjusted`
                            : `LOC Writing: ${
                                Number(
                                  defaultCapacityData[
                                    "FC LOC Availability (Translating)"
                                  ]
                                ) - el["Word Count"]
                              }`,
                          start: el["Writing Due Date"],
                          end: el["Writing Due Date"],
                          color: adjustedWritingRecord ? "#F6CF71" : "#9EB9F3",
                        });
                      });
                      this.dataForEditing.forEach((el) => {
                        const adjustedEditingRecord =
                          adjustedCapacityRecords.find(
                            (acr) =>
                              acr.dates.includes(el["Editing Due Date"]) &&
                              acr.editing !== null
                          );
                        this.events.push({
                          name: adjustedEditingRecord
                            ? `LOC Editing: ${
                                Number(adjustedEditingRecord.editing) -
                                el["Word Count"]
                              } *Adjusted`
                            : `LOC Editing: ${
                                Number(
                                  defaultCapacityData[
                                    "FC LOC Availability (Editing)"
                                  ]
                                ) - el["Word Count"]
                              }`,
                          start: el["Editing Due Date"],
                          end: el["Editing Due Date"],
                          color: adjustedEditingRecord ? "#F89C74" : "#DCB0F2",
                        });
                      });
                      adjustedCapacityRecords.forEach((el) => {
                        for (let i = 0; i < el.dates.length; i++) {
                          if (el.localization !== null)
                            adjustedWritingDates.push(
                              Object.assign(
                                {},
                                {
                                  date: el.dates[i],
                                  localization: el.localization,
                                }
                              )
                            );
                          if (el.editing !== null)
                            adjustedEditingDates.push(
                              Object.assign(
                                {},
                                { date: el.dates[i], editing: el.editing }
                              )
                            );
                        }
                      });
                      const cleanAdjustedWriting = adjustedWritingDates.filter(
                        (awd) => {
                          return !this.dataForWriting.find((dfr) => {
                            return awd.date === dfr["Writing Due Date"];
                          });
                        }
                      );
                      cleanAdjustedWriting.forEach((el) => {
                        this.events.push({
                          name: `LOC Writing: ${Number(
                            el.localization
                          )} *Adjusted`,
                          start: el.date,
                          end: el.date,
                          color: "#F6CF71",
                        });
                      });
                      const cleanAdjustedEditing = adjustedEditingDates.filter(
                        (aed) => {
                          return !this.dataForEditing.find((dfe) => {
                            return aed.date === dfe["Editing Due Date"];
                          });
                        }
                      );
                      cleanAdjustedEditing.forEach((el) => {
                        this.events.push({
                          name: `LOC Editing: ${Number(el.editing)} *Adjusted`,
                          start: el.date,
                          end: el.date,
                          color: "#F89C74",
                        });
                      });
                      this.showCalendarLoader = false;
                    });
                  });
                });
              })
              .catch((err) => {
                console.error(err);
                this.showCalendarLoader = false;
              });
          } else this.getCalendarData();
        }
    },
    getDefaultCapacity() {
      this.showDefaultCapacityLoader = true;
      getDefaultCapacityByEmployee(this.searchEmployee.Name)
        .then((data) => {
          this.localizationDefaultCapacity =
            data[0]["FC LOC Availability (Translating)"];
          this.editingDefaultCapacity =
            data[0]["FC LOC Availability (Editing)"];
          this.showDefaultCapacityLoader = false;
        })
        .catch((err) => {
          console.error(err);
          this.showDefaultCapacityLoader = false;
        });
    },
    handleDefaultCapacity() {
      this.getDefaultCapacity();
      this.defaultCapacity = true;
    },
    handleDefaultCapacityUpdate() {
      if (this.$refs.defaultCapacityForm.validate()) {
        this.confirmationProcessing = true;
        addAvailabilityRecord(
          [
            {
              7: { value: this.searchEmployee.Name },
              277: { value: this.localizationDefaultCapacity },
              278: { value: this.editingDefaultCapacity },
            },
          ],
          true
        )
          .then(() => {
            this.confirmationProcessing = false;
            this.defaultCapacity = false;
            publishMessage({
              user: this.searchEmployee.SlackFCWorkspaceID
                ? `<@${this.searchEmployee.SlackFCWorkspaceID}>`
                : `*${this.searchEmployee.Name}*`,
              mode: "defaultCapacity",
              data: {
                locDefault: this.localizationDefaultCapacity,
                editDefault: this.editingDefaultCapacity,
              },
            });
          })
          .catch((err) => {
            console.error(err);
            this.confirmationProcessing = false;
          });
      }
    },
    handleEmployeesData() {
      getEmployeesData()
        .then((resp) => {
          this.employees = resp.data.sort((a, b) =>
            a.Name.localeCompare(b.Name)
          );
          this.managers = this.employees
            .filter((el) => el.ProjectCoordinator)
            .map((el) => el.Name);
          this.leadEditors = this.employees
            .filter((el) => el.LeadEditor)
            .map((el) => el.Name);
          const firebaseUsers = functions.httpsCallable("getAllUsers");
          firebaseUsers().then((resp) => {
            this.admins = resp.data.users
              .filter((el) => el.customClaims?.admin)
              .map(
                (el) =>
                  this.employees.find(
                    (item) =>
                      item.Email === el.email || item.CueEmail === el.email
                  )?.Name
              );
          });
        })
        .catch((err) => console.error(err));
    },
    getGeos() {
      getFieldInfosFromDB({
        fieldID: 44,
        tableID: appIDs.iTunesProjectManager.assignments,
      })
        .then((data) => {
          this.geos = data;
          this.geosLoading = false;
        })
        .catch((err) => {
          this.geosLoading = false;
          console.error(err);
        });
    },
    handleInitialData() {
      if (this.profileView) {
        this.handleEmployeeCheck();
      } else {
        this.getCalendarData();
        this.handleEmployeesData();
        this.getGeos();
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    calendarTitle() {
      return `${this.$moment(this.currentDate.month, "M").format(
        `${this.laptop ? "MMM" : "MMMM"}`
      )}${
        this.laptop
          ? `'${this.$moment(this.currentDate.year).format("YY")}`
          : ` ${this.$moment(this.currentDate.year).format("YYYY")}`
      }`;
    },
    filteredEvents() {
      let filteredData = [...this.events];

      if (this.filterRole) {
        const rolePool = () => {
          if (this.filterRole === "Admin") return this.admins;
          if (this.filterRole === "Manager") return this.managers;
          if (this.filterRole === "Lead Editor") return this.leadEditors;
        };
        filteredData = filteredData.filter((el) =>
          rolePool().includes(el.name)
        );
      }

      if (this.filterGeo) {
        const geoGroup = this.employees
          .filter((el) => el.GEO1 === this.filterGeo)
          .map((el) => el.Name);
        filteredData = filteredData.filter((el) => geoGroup.includes(el.name));
      }

      return filteredData;
    },
    unavailabilityDatesRange() {
      return this.unavailabilityDates.join(" - ");
    },
    unavailabilityDatesValidation() {
      return this.unavailabilityDates[1]
        ? this.$moment(
            this.unavailabilityDatesRange.split(" - ")[0]
          ).isSameOrBefore(
            this.$moment(this.unavailabilityDatesRange.split(" - ")[1])
          )
        : true;
    },
    limitedCapacityDatesRange() {
      return this.limitedCapacityDates.join(" - ");
    },
    limitedCapacityDateValidation() {
      return this.limitedCapacityDates[1]
        ? this.$moment(
            this.limitedCapacityDatesRange.split(" - ")[0]
          ).isSameOrBefore(
            this.$moment(this.limitedCapacityDatesRange.split(" - ")[1])
          )
        : true;
    },
    btnTxt() {
      return this.editMode ? "Update" : "Save";
    },
    viewType() {
      if (this.view === 0) return "month";
      if (this.view === 1) return "week";
      if (this.view === 2) return "day";
    },
  },
  watch: {
    searchEmployee(newVal) {
      if (newVal === null) {
        this.searchEmployee = {};
        this.mutableMode = 0;
        if (this.mutableMode) this.events = [];
        else this.getCalendarData();
      }
      if (newVal?.Name) this.filterRole = this.filterGeo = "";
    },
    filterRole(newVal) {
      if (newVal) this.searchEmployee = null;
    },
    filterGeo(newVal) {
      if (newVal) this.searchEmployee = null;
    },
    focus(newVal) {
      const date = {
        month: newVal
          ? newVal.split("-")[1]
          : this.$moment(new Date()).format("MM"),
        year: newVal
          ? newVal.split("-")[0]
          : this.$moment(new Date()).format("YYYY"),
      };
      this.refreshChecker(date);
    },
  },
  mixins: [customBreakpoint],
  mounted() {
    this.updateCurrentDate(
      this.$moment(new Date()).format("MM"),
      this.$moment(new Date()).format("YYYY")
    );
    this.handleInitialData();
  },
};
</script>

<style scoped>
.calendar {
  max-width: 1400px;
  margin: 0 auto;
}

.filter {
  width: 120px;
}

.person-picker {
  max-width: 260px;
}

.admin-calendar.disabled {
  pointer-events: none;
}

.admin-calendar >>> .v-calendar-weekly__week {
  min-height: 100px !important;
}

.admin-calendar >>> .v-calendar-weekly__day-label {
  margin-bottom: 5px;
}

.admin-calendar >>> .v-event {
  color: #000 !important;
  line-height: 1.2;
}

.admin-calendar
  >>> .v-btn.v-btn--fab.v-btn--has-bg.v-btn--round.theme--light.v-size--small.transparent {
  background-color: #fff !important;
  color: #3fa198;
}

.admin-calendar
  >>> .v-btn.v-btn--fab.v-btn--has-bg.v-btn--round.theme--light.v-size--small {
  background-color: #ee7975;
}
</style>
