import _ from "lodash";
import { schema } from "prosemirror-schema-basic";
import { EditorState } from "prosemirror-state";
import { DOMSerializer, DOMParser, Node } from "prosemirror-model";
import { exampleSetup, buildMenuItems } from "prosemirror-example-setup";
import {
  addAssignmentRowsFromEditor,
  updateAssignmentRowFromEditor,
  deleteRowFromQB,
  appIDs,
} from "@/utils/quickbaseUtils";
import moment from "moment";
const Excel = require("exceljs");
var { htmlToText } = require("html-to-text");

// constants
export const DOCUMENTS_COLLECTION_NAME = "editor-documents";
export const ASSIGNMENTS_COLLECTION_NAME = "editor-assignments";
export const PROJECTS_COLLECTION_NAME = "editor-projects";
export const EDITOR_AUTOSAVE_INTERVAL = 500;
export const BLANK_DOCUMENT_CONTENTS = {
  type: "doc",
  content: [
    {
      type: "paragraph",
    },
  ],
};
export const BLANK_DOCUMENT_STRING =
  "{“type”:“doc”,“content”:[{“type”:“paragraph”}]";

export const BLANK_NEW_ASSIGNMENT_DATAS = [
  {
    assignmentName: "",
    assignmentDetails: "",
    projectID: "",
    deliveryDate: "",
    relatedPrice: "",
    payPeriod: "",
    quantity: null,
    clientRequester: "",
    qbID: "",
    GEO: "EN-US",
    mediaType: "",
    po: "",
    isDelivered: false,
    id: "",
    exportHeader: "",
    assignees: ["", ""],
    flow: [
      {
        label: "Writer",
        person: "",
        personEmail: "",
        stepDueDate: "",
        isComplete: false,
      },
      {
        label: "Editor",
        person: "",
        personEmail: "",
        stepDueDate: "",
        isComplete: false,
      },
    ],
  },
];

export const BLANK_NEW_PROJECT_DATA = {
  projectName: "",
  workType: "",
  staffPool: [],
  staffPoolEmails: [],
  styleGuideLink: "",
  fragmentSchema: [],
};

export const BLANK_NEW_FRAGMENT_DATA = {
  fragmentName: "",
  maxCount: null,
  minCount: null,
  isCountWords: true,
};

const assignmentStatusList = [
  "Assigned",
  "Primary Complete",
  "Secondary Complete",
  "QC Complete",
  "Client Reviewed",
];

///////////////////////////////
// local editor
///////////////////////////////
export const initState = (content) => {
  const menuItems = buildMenuItems(schema);
  const plugins = exampleSetup({
    schema,
    menuContent: [[menuItems.toggleStrong, menuItems.toggleEm]],
    history: true,
  });

  if (_.isEmpty(content)) {
    return EditorState.create({
      // doc: DOMParser.fromSchema(schema).parse(""),
      doc: DOMParser.fromSchema(schema).parse(BLANK_DOCUMENT_STRING),
      plugins: plugins,
    });
  } else {
    const n = Node.fromJSON(schema, content);
    return EditorState.create({
      doc: n,
      plugins: plugins,
    });
  }
};

export const getSortField = ({ row, isAdmin, userEmail }) => {
  if (isAdmin) return row.deliveryDate;

  const match = row.flow.find((flowStep) => {
    return flowStep.person.email === userEmail;
  });

  return match ? match.stepDueDate : row.deliveryDate;
};

//////////////////////////////
// text export
//////////////////////////////
export const saveHtmlAsPlaintext = ({ string, filename }) => {
  let text = htmlToText(string, {
    wordwrap: false,
    ignoreHref: true,
    formatters: {
      // Create a formatter.
      boldFormatter: function (elem, walk, builder, formatOptions) {
        builder.openBlock({
          leadingLineBreaks: 0,
        });
        builder.addInline("\n<b>");
        walk(elem.children, builder);
        builder.addInline("</b>\n");
        builder.closeBlock({
          trailingLineBreaks: 0,
        });
      },
      italicFormatter: function (elem, walk, builder, formatOptions) {
        builder.openBlock({
          leadingLineBreaks: 0,
        });
        builder.addInline(" ");
        builder.addInline("\n<i>");
        walk(elem.children, builder);
        builder.addInline("</i>\n");
        builder.addInline(" ");
        builder.closeBlock({
          trailingLineBreaks: 0,
        });
      },
    },
    tags: {
      // Assign it to `foo` tags.
      b: {
        format: "boldFormatter",
      },
      i: {
        format: "italicFormatter",
      },
    },
  });

  text = text.replaceAll("</b>", "</b> ");
  text = text.replaceAll("<b>", " <b>");
  text = text.replaceAll("</i>", "</i> ");
  text = text.replaceAll("<i>", " <i>");
  text = text.replaceAll("\n ", "\n");

  const blob = new Blob([text]);
  saveAs(blob, `${filename}.txt`);
};

const editTags = (contents) => {
  let retVal = _.cloneDeep(contents);

  retVal = retVal.replace("</em>", "</i>");
  retVal = retVal.replace("</strong>", "</b>");
  retVal = retVal.replace("<em>", "<i>");
  retVal = retVal.replace("<strong>", "<b>");

  return retVal;
};

const assembleFragmentsForExport = ({ assignment, contents }) => {
  let assembled = `<p>${assignment.exportHeader}</p>`;
  contents.forEach((fragment) => {
    const html = `
      <p>${fragment.fragmentName}</p>
      ${fragment.textContent}`;
    assembled = assembled + html;
  });

  return assembled;
};

const getHTMLRepresentationOfObject = (obj) => {
  const tempState = EditorState.create({
    schema,
    doc: Node.fromJSON(schema, obj),
  });
  // const textContent = tempState.doc.content;
  const div = document.createElement("div");
  const fragment = DOMSerializer.fromSchema(schema).serializeFragment(
    tempState.doc.content
  );

  div.appendChild(fragment);

  const textContent = editTags(div.innerHTML);

  return textContent;
};

export const createSingleAssignmentText = async (assignment) => {
  let contents = await assignment.getContentsOfFinalStage();

  contents = contents.map((el) => {
    // turn the json object into content string
    const textContent = getHTMLRepresentationOfObject(el.lastStageData);

    // marry with existing data
    return {
      ...el,
      textContent,
    };
  });
  const assembled = assembleFragmentsForExport({ assignment, contents });
  return assembled;
};

export const createMultiAssignmentText = async (assignments) => {
  const proms = assignments.map((assignment) => {
    return createSingleAssignmentText(assignment);
  });
  const results = await Promise.all(proms);
  const assembled = results.join("<hr>");
  return assembled;
};

//////////////////////////////
// excel export
//////////////////////////////
export const createExcel = async (assignments) => {
  const contentProms = assignments.map((assignment) => {
    return assignment.getContentsOfFinalStage();
  });

  let contents = await Promise.all(contentProms);

  const pairs = contents.map((fragments) => {
    return getNameValuePairsFromFragments(fragments);
  });

  // get list of fragment names, these are the columns of the excel sheet
  const fragmentNames = assignments.reduce((acc, assignment) => {
    const fragments = assignment.fragments;
    fragments.forEach((fragment) => {
      if (!acc.includes(fragment.fragmentName)) {
        acc.push(fragment.fragmentName);
      }
    });
    return acc;
  }, []);

  const workbook = new Excel.Workbook();
  const sheet = workbook.addWorksheet("Copy Export");
  let cols = [
    {
      header: "",
      key: "exportHeader",
      width: 45,
      style: {
        alignment: {
          wrapText: true,
        },
      },
    },
  ];
  fragmentNames.forEach((el) => {
    cols.push({
      header: el,
      key: el,
      width: 75,
      style: {
        alignment: {
          wrapText: true,
        },
      },
    });
  });

  sheet.columns = cols;

  pairs.forEach((pair) => {
    sheet.addRow(pair);
  });

  const headerRow = sheet.getRow(1);
  headerRow.font = { bold: true };
  headerRow.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFC9F2EE" },
  };

  const fileName = `${assignments[0].projectName}_${moment().format(
    "YYYY-MM-DD"
  )}.xlsx`;

  const buffData = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffData], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  blob.lastModifiedDate = new Date();
  blob.name = `${fileName}`;
  saveAs(blob, `${fileName}.xlsx`);
};

const getNameValuePairsFromFragments = (fragments, assignment) => {
  const retVal = {
    exportHeader: fragments[0].exportHeader,
  };
  fragments.forEach((fragment) => {
    retVal[fragment.fragmentName] = getHTMLRepresentationOfObject(
      fragment.lastStageData
    )
      .replace("<p>", "")
      .replace("</p>", "");
  });
  return retVal;
};

//////////////////////////////
// assignment CRUD
//////////////////////////////
const formatQuickbaseData = (data) => {
  return {
    ...data,
    payPeriod: moment(data.payPeriod).format("MMMM YYYY"),
    personPrimary: data.flow[0]?.person.name ?? "",
    personPrimaryDueDate: data.flow[0]?.stepDueDate ?? "",
    personSecondary: data.flow[1]?.person.name ?? "",
    personSecondaryDueDate: data.flow[1]?.stepDueDate ?? "",
  };
};

export const createNewAssignmentsWithDatas = async ({ datas, project }) => {
  // create datas object
  // const firebasePromises = datas.map((el) => {
  //   return project.createNewAssignment({
  //     ...el,
  //   });
  // });
  // const firebaseResp = await Promise.all(firebasePromises);
  // return {
  //   firebaseResp,
  //   // rejected,
  // };
  /////////////////////////////////////
  //  restore below when done testing
  /////////////////////////////////////
  const qbUploadDatas = datas.map((data) => {
    return {
      ...formatQuickbaseData(data),
      projectName: project.projectName,
      workType: project.workType,
      assignmentStatus: "Assigned",
      requestDate: moment(new Date(Date.now())).format("YYYY-MM-DD"),
    };
  });
  try {
    const quickbaseResponse = await addAssignmentRowsFromEditor(
      qbUploadDatas,
      appIDs.cueProjectManager.assignments,
      true
    );
    // create an object that marries the quickbase response with the original data
    const allInfos = datas.map((el, i) => {
      return { ...el, ...quickbaseResponse[i] };
    });
    // create the subset of those that are fulfilled
    const fulfilled = allInfos
      .filter(
        (el) =>
          el.status === "fulfilled" &&
          !_.isEmpty(el.value.metadata.createdRecordIds)
      )
      .map((el) => {
        return {
          ..._.omit(el, ["value", "status"]),
          qbID: `${el.value.metadata.createdRecordIds[0]}`,
        };
      });
    // create the subset of those that are rejected
    const rejected = allInfos.filter(
      (el) =>
        el.status === "rejected" ||
        _.isEmpty(el.value.metadata.createdRecordIds)
    );
    // create firebase entries for the assignments that now have qb entries
    const firebasePromises = fulfilled.map((el) => {
      return project.createNewAssignment({
        ...el,
      });
    });
    // wait for all the firebase promises to resolve
    const firebaseResp = await Promise.all(firebasePromises);
    return {
      firebaseResp,
      rejected,
    };
  } catch (err) {
    console.error(err);
  }
};

export const updateAssignmentWithData = async ({
  id,
  project,
  assignment,
  originalData,
}) => {
  try {
    // update quickbase
    // RETURN AFTER EDIT
    await updateAssignmentRowFromEditor({
      id,
      data: formatQuickbaseData(assignment, originalData),
    });
    // update firebase

    await project.updateAssignmentWithData({ id, assignment, originalData });

    return;
  } catch (err) {
    console.error(err);
  }
};

export const deleteAssignment = ({ qbID, firebaseID, project }) => {
  return new Promise((resolve, reject) => {
    deleteRowFromQB({
      id: qbID,
      tableID: appIDs.cueProjectManager.assignments,
    })
      .then((resp) => {
        return project.deleteAssignment(firebaseID);
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleAssignmentChangeDeliveryStatus = ({
  assignment,
  deliveryStatus,
}) => {
  return new Promise((resolve, reject) => {
    let updatedStatus;

    if (deliveryStatus) {
      updatedStatus = "Delivered";
    } else {
      const lastCompletedStep = _.sum(
        assignment.flow.map((el) => el.isComplete)
      );
      updatedStatus = assignmentStatusList[lastCompletedStep];
    }

    updateAssignmentRowFromEditor({
      data: {
        qbID: assignment.qbID,
        assignmentStatus: updatedStatus,
      },
    })
      .then((resp) => {
        return assignment.setIsDelivered(deliveryStatus);
      })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleAssignmentChangeFlowStep = ({
  assignment,
  flowStage,
  isComplete,
}) => {
  return new Promise((resolve, reject) => {
    const stepCount = assignment.flow.length;
    if (isComplete) {
      // simply set what's needed
      const newStatus = assignmentStatusList[flowStage + 1];
      updateAssignmentRowFromEditor({
        data: {
          qbID: assignment.qbID,
          assignmentStatus: newStatus,
        },
      })
        .then((resp) => {
          return assignment.setFlowStageCompletion({
            flowStage,
            isComplete,
            isLastStep: flowStage === stepCount - 1,
            newStatus: newStatus,
          });
        })

        .then(() => {
          // here's where we should set the status in quickbase
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      // here we need to account for possibly setting multiple steps to false
      const newStatus = assignmentStatusList[flowStage];
      const rangeToSet = _.range(flowStage, stepCount);

      updateAssignmentRowFromEditor({
        data: {
          qbID: assignment.qbID,
          assignmentStatus: newStatus,
        },
      })
        .then((resp) => {
          return assignment.setFlowStageCompletion({
            flowStage: rangeToSet,
            isComplete: false,
            newStatus: newStatus,
          });
        })

        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};
