<template>
  <v-card>
    <v-form ref="form" v-model="valid" lazy-validation class="pa-6">
      <v-container>
        <v-row>
          <v-col cols="12" sm="4" class="pb-0">
            <v-menu
              v-model="pcRequestDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="pcRequestDate"
                  label="Request Date"
                  prepend-icon="mdi-calendar-edit"
                  readonly
                  :error="requestConversionErrors"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="pcRequestDate"
                @input="pcRequestDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-menu
              ref="pcRequestTimeMenu"
              v-model="pcRequestTimeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="pcRequestTime"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="pcRequestTime12hrs"
                  label="Request Time"
                  prepend-icon="mdi-clock-edit-outline"
                  readonly
                  :error="requestConversionErrors"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="pcRequestTime"
                full-width
                format="ampm"
                @click:minute="$refs.pcRequestTimeMenu.save(pcRequestTime)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-autocomplete
              class="pc-time-zone"
              outlined
              append-icon="mdi-account-clock"
              label="Your Time Zone"
              :error="requestConversionErrors"
              v-model="pcTimeZone"
              :items="utcTimeZones"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" class="d-flex justify-center pb-0">
            <v-btn
              width="auto"
              height="56px"
              color="accent"
              outlined
              @click="convertDues(true)"
              >Convert to ET</v-btn
            >
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-menu
              v-model="requestDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="requestDate"
                  :rules="[rules.required]"
                  label="Request Date (ET)"
                  prepend-icon="mdi-calendar-plus"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="requestDate"
                @input="requestDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-menu
              ref="requestTimeMenu"
              v-model="requestTimeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="requestTime"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="requestTime12hrs"
                  :rules="[rules.required]"
                  label="Request Time (ET)"
                  prepend-icon="mdi-clock-plus-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="requestTime"
                full-width
                format="ampm"
                @click:minute="$refs.requestTimeMenu.save(requestTime)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="pb-0">
            <v-text-field
              outlined
              label="Task ID"
              v-model="taskID"
              :disabled="
                isEditMode && itemToEdit.CueTaskID.split('_').length > 2
              "
            />
          </v-col>
          <v-col cols="12" sm="6" class="pb-0">
            <v-text-field
              outlined
              label="Cue Task ID"
              readonly
              v-model="cueTaskID"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-textarea
              outlined
              clearable
              rows="1"
              clear-icon="mdi-close-circle-outline"
              label="Task / Email Subject Line"
              v-model="emailSubjectLine"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" class="pb-0">
            <v-autocomplete
              outlined
              label="Related Project"
              :items="projectNameItems"
              :loading="projectNameItemsLoading"
              v-model="relatedProject"
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-autocomplete
              outlined
              label="Related Client Requester"
              :items="clientRequesters"
              :loading="clientRequestersLoading"
              v-model="clientRequester"
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-autocomplete
              outlined
              label="Media Type"
              :items="mediaTypes"
              v-model="mediaType"
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" class="pb-0">
            <v-autocomplete
              outlined
              label="DRI"
              :items="employees"
              v-model="dri"
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-autocomplete
              outlined
              label="Task Status"
              :items="taskStatuses"
              v-model="status"
              item-text="value"
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-autocomplete
              outlined
              label="Priority"
              :items="priorities"
              v-model="priority"
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" class="pb-0">
            <v-menu
              v-model="clientDeliveryDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="clientDeliveryDate"
                  label="Delivery Date (Client)"
                  prepend-icon="mdi-calendar"
                  readonly
                  :error="conversionErrors"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="clientDeliveryDate"
                @input="clientDeliveryDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-select
              :items="hoursList"
              v-model="clientDeliveryDue"
              item-text="time"
              label="Delivery Time (Client)"
              prepend-icon="mdi-clock-time-five-outline"
              :error="conversionErrors"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-autocomplete
              outlined
              append-icon="mdi-airplane-clock"
              label="Time Zone (Client)"
              :error="conversionErrors"
              v-model="timeZone"
              :items="utcTimeZones"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" class="d-flex justify-center pb-0">
            <v-btn
              width="auto"
              height="56px"
              color="accent"
              outlined
              @click="convertDues()"
              >Convert to ET</v-btn
            >
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-menu
              v-model="deliveryDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="deliveryDate"
                  :rules="[rules.required]"
                  label="Delivery Date (ET)"
                  prepend-icon="mdi-calendar-check"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="deliveryDate"
                @input="deliveryDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-select
              :items="hoursList"
              v-model="deliveryDue"
              item-text="time"
              :rules="[rules.required]"
              label="Delivery Time (ET)"
              prepend-icon="mdi-clock-check-outline"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-card-actions class="justify-end pb-6">
      <v-btn
        v-if="Object.keys(itemToEdit).length"
        class="ma-1"
        height="36px"
        small
        color="secondary"
        :loading="deleteProcessing"
        @click="handletaskDeletion()"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        width="20%"
        color="secondary"
        @click="handleTask()"
        :loading="updateProcessing"
      >
        {{ Object.keys(itemToEdit).length ? "Update" : "Save" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// internal
import {
  getAllProjectNames,
  getClientRequestersForProject,
  processTask,
  appIDs,
  deleteRowFromQB,
} from "@/utils/quickbaseUtils";
import { timeUnits } from "@/utils/mixins";

export default {
  name: "PMDashboardForm",
  props: {
    mediaTypes: {
      type: Array,
      required: true,
    },
    taskStatuses: {
      type: Array,
      required: true,
    },
    priorities: {
      type: Array,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
    itemToEdit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      requestDateMenu: false,
      requestDate: this.$moment(
        new Date().toLocaleString("en-US", {
          timeZone: "America/New_York",
        })
      ).format("YYYY-MM-DD"),
      requestTime: this.$moment(
        new Date().toLocaleString("en-US", {
          timeZone: "America/New_York",
        })
      ).format("HH:mm"),
      requestTimeMenu: false,
      projectNameItems: [],
      projectNameItemsLoading: false,
      relatedProject: "",
      clientRequestersLoading: false,
      clientRequesters: [],
      clientRequester: "",
      clientDeliveryDateMenu: false,
      clientDeliveryDate: "",
      clientDeliveryDue: "",
      emailSubjectLine: "",
      mediaType: "",
      taskID: "",
      status: "Confirmed",
      priority: "",
      dri: "",
      timeZone: "",
      deliveryDateMenu: false,
      deliveryDate: "",
      deliveryDue: "",
      conversionErrors: false,
      requestConversionMode: false,
      pcRequestDateMenu: false,
      pcRequestDate: "",
      pcRequestTimeMenu: false,
      pcRequestTime: "",
      pcTimeZone: "",
      requestConversionErrors: false,
      updateProcessing: false,
      deleteProcessing: false,
      recordID: "",
      valid: true,
      rules: {
        required: (value) => !!value || value === 0 || "Required",
      },
    };
  },
  mounted() {
    this.getProjectNames();
    this.getActiveClientRequesters();
    this.handleEditMode();
  },
  watch: {
    itemToEdit() {
      this.handleEditMode();
    },
  },
  mixins: [timeUnits],
  computed: {
    ...mapGetters("auth", ["user"]),
    cueTaskID() {
      return this.isEditMode
        ? this.itemToEdit.CueTaskID.split("_").length === 2
          ? `${this.$moment(this.requestDate).format("MM-DD-YYYY")}_${
              this.taskID
            }`
          : `${this.$moment(this.requestDate).format("MM-DD-YYYY")}_${
              this.requestTime
            }_${this.itemToEdit.CueTaskID.split("_")[2]}`
        : `${this.$moment(this.requestDate).format("MM-DD-YYYY")}_${
            this.taskID
              ? this.taskID
              : Math.floor(100000 + Math.random() * 900000)
          }`;
    },
    isEditMode() {
      return !!Object.keys(this.itemToEdit).length;
    },
    pcRequestTime12hrs() {
      return this.pcRequestTime
        ? this.$moment(this.pcRequestTime, ["HH:mm"]).format("hh:mm A")
        : "";
    },
    requestTime12hrs() {
      return this.requestTime
        ? this.$moment(this.requestTime, ["HH:mm"]).format("hh:mm A")
        : "";
    },
  },
  methods: {
    getProjectNames() {
      this.projectNameItemsLoading = true;
      getAllProjectNames("iTunes")
        .then((data) => {
          this.projectNameItems = data.map((el) => {
            return el.name;
          });
          this.projectNameItemsLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.projectNameItemsLoading = false;
        });
    },
    getActiveClientRequesters() {
      this.clientRequestersLoading = true;
      getClientRequestersForProject({ instance: "iTunes" })
        .then((data) => {
          this.clientRequesters = data;
          this.clientRequestersLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.clientRequestersLoading = false;
        });
    },
    convertDues(requestMode) {
      if (
        !this[`${requestMode ? "pcRequest" : "clientDelivery"}Date`] ||
        !this[`${requestMode ? "pcRequestTime" : "clientDeliveryDue"}`] ||
        !this[`${requestMode ? "pcT" : "t"}imeZone`]
      ) {
        this[`${requestMode ? "requestC" : "c"}onversionErrors`] = true;
      } else {
        this[`${requestMode ? "requestC" : "c"}onversionErrors`] = false;
        const data = new Date(
          `${this[`${requestMode ? "pcRequest" : "clientDelivery"}Date`]}T${
            requestMode
              ? this.pcRequestTime
              : this.$moment(this.clientDeliveryDue, "hh:mm A").format("HH:mm")
          }:00.000${this[`${requestMode ? "pcT" : "t"}imeZone`]
            .split(" ")
            .at(-1)}`
        );
        const convertedData = data.toLocaleString("en-US", {
          timeZone: "America/New_York",
        });
        this[`${requestMode ? "request" : "delivery"}Date`] =
          this.$moment(convertedData).format("YYYY-MM-DD");
        requestMode
          ? (this.requestTime = this.$moment(convertedData).format("HH:mm"))
          : (this.deliveryDue = data.toLocaleString("en-US", {
              timeZone: "America/New_York",
              hour: "2-digit",
              minute: "2-digit",
            }));
      }
    },
    handleTask() {
      if (this.$refs.form.validate()) {
        this.updateProcessing = true;
        const task = {
          RelatedProject: this.relatedProject,
          ClientRequester: this.clientRequester,
          DRI: this.dri,
          CueTaskID: this.cueTaskID,
          DeliveryDate: this.deliveryDate,
          DeliveryTime: this.deliveryDue,
          Task: this.emailSubjectLine,
          MediaType: this.mediaType,
          Priority: this.priority,
          ["RecordID#"]: this.recordID,
          RequestDate: this.requestDate,
          RequestTime: this.requestTime12hrs,
          TaskID: this.taskID,
          TaskStatus: this.status,
          LastUpdated: new Date(),
          LastUpdatedBy: this.user.Name,
        };
        processTask({ task })
          .then(() => {
            this.updateProcessing = false;
            this.$emit("close-task-form", this.itemToEdit.index, task);
          })
          .catch((err) => {
            this.updateProcessing = false;
            console.error(err);
          });
      }
    },
    handletaskDeletion() {
      this.deleteProcessing = true;
      deleteRowFromQB({
        id: this.itemToEdit["RecordID#"],
        tableID: appIDs.iTunesProjectManager.pmDashboard,
      })
        .then(() => {
          this.deleteProcessing = false;
          this.$emit("close-task-form", this.itemToEdit.index);
        })
        .catch((err) => {
          this.deleteProcessing = false;
          console.error(err);
        });
    },
    handleEditMode() {
      if (!this.isEditMode) this.$refs.form.resetValidation();
      this.relatedProject = this.isEditMode
        ? this.itemToEdit.RelatedProject
        : "";
      this.clientRequester = this.isEditMode
        ? this.itemToEdit.ClientRequester
        : "";
      this.dri = this.isEditMode ? this.itemToEdit.DRI : this.user.Name;
      this.deliveryDate = this.isEditMode ? this.itemToEdit.DeliveryDate : "";
      this.deliveryDue = this.isEditMode
        ? this.$moment(this.itemToEdit.DeliveryTime).format("hh:mm A")
        : "";
      this.emailSubjectLine = this.isEditMode ? this.itemToEdit.Task : "";
      this.mediaType = this.isEditMode ? this.itemToEdit.MediaType : "";
      this.priority = this.isEditMode ? this.itemToEdit.Priority : "";
      this.recordID = this.isEditMode ? this.itemToEdit["RecordID#"] : null;
      this.requestDate = this.isEditMode
        ? this.itemToEdit.RequestDate
        : this.$moment(
            new Date().toLocaleString("en-US", {
              timeZone: "America/New_York",
            })
          ).format("YYYY-MM-DD");
      this.requestTime = this.isEditMode
        ? this.$moment(this.itemToEdit.RequestTime).format("HH:mm")
        : this.$moment(
            new Date().toLocaleString("en-US", {
              timeZone: "America/New_York",
            })
          ).format("HH:mm");
      this.taskID = this.isEditMode ? this.itemToEdit.TaskID : "";
      this.status = this.isEditMode ? this.itemToEdit.TaskStatus : "Confirmed";
    },
  },
};
</script>

<style>
.pc-time-zone.v-autocomplete.v-select--is-menu-active
  .v-input__icon--append
  .v-icon {
  transform: none;
}
</style>
