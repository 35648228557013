<template>
  <v-data-table
    :headers="headers"
    :items="filteredData"
    item-key="index"
    group-by="groupWeek"
    sort-by="groupSort"
    :header-props="{ sortIcon: null }"
    show-expand
    group-desc
    sort-desc
    :items-per-page="50"
    :footer-props="{
      'items-per-page-options': [10, 50, 100],
    }"
    :class="{ laptop, laptopSmaller }"
    @click:row="$emit('call-edit-form', $event)"
  >
    <!-- grouping override -->
    <template v-slot:[`group.header`]="{ group, headers }">
      <td class="pa-0 group-header-row" :colspan="headers.length">
        <h2 class="mx-4 font-weight-bold d-inline">
          {{ setGroupHeader(group) }}
        </h2>
        <v-chip
          v-if="filterGeo || (!isAdmin && !isManager)"
          class="font-weight-bold white"
          :color="handleTotalWeekHrs(group) > 25 ? 'secondary' : ''"
        >
          {{ `Total HRS: ${handleTotalWeekHrs(group)}` }}
        </v-chip>
      </td>
    </template>

    <!-- Writer cell override -->
    <template v-slot:[`item.Writer`]="{ value }">
      <span>
        {{ value }}
      </span>
    </template>

    <!-- Request Date cell override -->
    <template v-slot:[`item.RequestDate`]="{ value }">
      <span>{{ formatDateForTableRow(value) }}</span>
    </template>

    <!-- Detail cell override -->
    <template v-slot:[`item.AssignmentDetail`]="{ value }">
      <v-tooltip right color="primary" :disabled="value.length <= 40">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">{{ ellipseText(value, 40) }}</span>
        </template>
        <span>{{ value }}</span>
      </v-tooltip>
    </template>

    <!-- GEO cell override -->
    <template v-slot:[`item.GEO`]="{ value }">
      <v-chip>{{ value ? value : "None" }}</v-chip>
    </template>

    <!-- Expanded panel -->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <LEDTableExpansion :assignmentInfo="item" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// libraries
import _ from "lodash";
import Fuse from "fuse.js";
// internal
import { customBreakpoint } from "@/utils/mixins";
// component
import LEDTableExpansion from "./LEDTableExpansion.vue";

export default {
  name: "LEDTable",
  components: {
    LEDTableExpansion,
  },
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    tableFields: {
      type: Array,
      required: false,
    },
    filterDeliveryDate: {
      type: String,
      required: false,
    },
    filterType: {
      type: Array,
      required: true,
    },
    filterSearch: {
      type: String,
      required: false,
    },
    filterPerson: {
      type: String,
      required: true,
    },
    filterGeo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableHeaders: [
        "RequestDate",
        "AssignmentTitle",
        "AssignmentDetail",
        "MediaType",
        "WordCount",
        "WorkType1Quantity",
        "Writer",
        "GEO",
      ],
    };
  },
  mixins: [customBreakpoint],
  computed: {
    ...mapGetters("auth", ["user", "isAdmin", "isManager"]),
    headers() {
      if (!this.tableFields) return [];

      let retVal = this.tableFields
        .filter((el) => this.tableHeaders.includes(el.value))
        .map((field) => {
          return {
            text: field.text,
            value: field.value,
          };
        });

      // order by the order of the tableHeaders
      retVal = _.orderBy(retVal, (el) => this.tableHeaders.indexOf(el.value));

      retVal.push({ text: "", value: "data-table-expand" });

      return retVal;
    },
    tableFieldValues() {
      return this.tableFields.map((field) => field.value);
    },
    dataWithGroupInfo() {
      return this.tableData.map((el, i) => {
        return {
          ...el,
          groupWeek: el.groupWeek,
          groupSort: el.DeliveryDate,
          index: i,
        };
      });
    },
    filteredData() {
      let dateFilteredData = [...this.dataWithGroupInfo];

      if (this.filterType.length === 1) {
        dateFilteredData = dateFilteredData.filter((el) =>
          this.filterType.includes(0)
            ? el.RelatedClientRequester === "Ines Santos" ||
              el.RelatedClientRequester === "Company Cue, Inc."
            : el.RelatedClientRequester === "Pilar Blanco"
        );
      } else if (!this.filterType.length) {
        dateFilteredData = [];
      }

      if (this.filterDeliveryDate) {
        dateFilteredData = dateFilteredData.filter(
          (el) =>
            this.$moment(el["DeliveryDate"]).format("YYYY-MM-DD") ===
            this.filterDeliveryDate
        );
      }

      if (this.filterPerson) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el["Writer"] === this.filterPerson
        );
      }

      if (this.filterGeo) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el["GEO"] === this.filterGeo
        );
      }

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: this.tableFieldValues,
      };

      if (this.filterSearch) {
        const fuse = new Fuse(dateFilteredData, searchOptions);
        dateFilteredData = fuse.search(this.filterSearch).map((el) => el.item);
      } else {
        dateFilteredData = dateFilteredData;
      }
      return dateFilteredData;
    },
  },
  methods: {
    formatDateForTableRow(date) {
      return this.$moment(date).format("MM/DD/YYYY");
    },
    ellipseText(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + "...";
      } else {
        return text;
      }
    },
    handleTotalWeekHrs(week) {
      let totalHrs = 0;
      this.filteredData.forEach((el) => {
        if (el.groupWeek === week) totalHrs += el.WorkType1Quantity;
      });
      return totalHrs;
    },
    setGroupHeader(week) {
      return this.filterDeliveryDate
        ? `Day: ${this.$moment(this.filterDeliveryDate).format("MMM DD, YYYY")}`
        : `Week: ${this.$moment(week.split(", ")[0]).format(
            "MMM DD, YYYY"
          )} - ${this.$moment(week.split(", ")[4]).format("MMM DD, YYYY")}`;
    },
  },
};
</script>

<style scoped lang="scss">
.group-header-row {
  background-color: #9af9ef70 !important;
  color: #003a35 !important;
  cursor: default;
}

::v-deep {
  tbody > tr {
    cursor: pointer;
  }
  .v-data-table.laptop .v-data-table__wrapper table {
    & tbody > tr:not(.v-data-table__expanded__content) > td {
      padding: 0 8px;
    }
    & .v-data-table-header tr > th {
      padding: 0 8px;
    }
  }
  .v-data-table.laptopSmaller .v-data-table__wrapper table {
    & tbody > tr:not(.v-data-table__expanded__content) > td {
      padding: 0 4px;
    }
    & .v-data-table-header tr > th {
      padding: 0 4px;
    }
  }
}

.d-inline {
  vertical-align: middle;
}
</style>
