<template>
  <div>
    <v-data-table
      v-model="selectedRows"
      :loading="isStatusUpdating"
      :headers="headers"
      :items="filteredData"
      item-key="index"
      group-by="groupDate"
      sort-by="groupSort"
      :header-props="{ sortIcon: null }"
      show-expand
      show-select
      group-desc
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [10, 50, 100],
      }"
      :class="{ isStatusUpdating: 'status-updating', laptop, laptopSmaller }"
      @toggle-select-all="handleSelectAll"
    >
      <!-- grouping override -->
      <template v-slot:[`group.header`]="{ group, headers }">
        <td class="pa-0 group-header-row" :colspan="headers.length">
          <h2 class="mx-4 font-weight-bold d-inline">
            {{ `${dueText + formatDate(group)}` }}
          </h2>
          <v-chip
            v-if="handleDuesStats(group)"
            color="font-weight-bold white"
            small
          >
            <v-icon left> mdi-clock-alert-outline </v-icon
            >{{ handleDuesStats(group) }}
          </v-chip>
        </td>
      </template>

      <!-- selection override -->
      <template v-slot:[`item.data-table-select`]="{ item }">
        <v-checkbox
          v-model="item.checked"
          @change="handleSelectedRows($event, item)"
        ></v-checkbox>
      </template>

      <!-- Primary cell override -->
      <template v-slot:[`item.Person-Primary`]="{ item, value }">
        <v-badge
          :value="handleDueAlert(item, 'Primary')"
          :color="
            handleDueAlert(item, 'Primary') === 'overdue'
              ? '#E57373'
              : handleDueAlert(item, 'Primary') === 'upcoming-due'
              ? '#FFB74D'
              : ''
          "
          icon="mdi-clock-alert-outline"
          overlap
        >
          <div
            :class="{
              'assigned-to-user': isActivePerson(item['Person-Primary']),
            }"
          >
            <div class="text-container text-center">
              {{ value }}
            </div>
          </div>
        </v-badge>
      </template>

      <!-- Reviewer cell override -->
      <template v-slot:[`item.Person-Reviewer`]="{ item, value }">
        <v-badge
          :value="handleDueAlert(item, 'Reviewing')"
          :color="
            handleDueAlert(item, 'Reviewing') === 'overdue'
              ? '#E57373'
              : handleDueAlert(item, 'Reviewing') === 'upcoming-due'
              ? '#FFB74D'
              : ''
          "
          icon="mdi-clock-alert-outline"
          overlap
        >
          <div
            :class="{
              'assigned-to-user': isActivePerson(item['Person-Reviewer']),
            }"
          >
            <div class="text-container text-center">
              {{ value }}
            </div>
          </div>
        </v-badge>
      </template>

      <!-- Due Time cell override -->
      <template v-slot:[`item.DueTime`]="{ item }">
        <span>{{ formatDateForTableRow(item.DueTime, false, true) }}</span>
      </template>

      <!-- Request Date cell override -->
      <template v-slot:[`item.RequestDate`]="{ value }">
        <span>{{ formatDateForTableRow(value) }}</span>
      </template>

      <!-- Title cell override -->
      <template v-slot:[`item.AssignmentTitle`]="{ value }">
        <v-tooltip
          right
          color="primary"
          :disabled="value.length <= (laptop ? 25 : 30)"
        >
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{
              ellipseText(value, laptop ? 25 : 30)
            }}</span>
          </template>
          <span>{{ value }}</span>
        </v-tooltip>
      </template>

      <!-- Detail cell override -->
      <template v-slot:[`item.AssignmentDetail`]="{ value }">
        <v-tooltip
          right
          color="primary"
          :disabled="value.length <= (laptop ? 25 : 30)"
        >
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{
              ellipseText(value, laptop ? 25 : 30)
            }}</span>
          </template>
          <span>{{ value }}</span>
        </v-tooltip>
      </template>

      <!-- GEO cell override -->
      <template v-slot:[`item.GEO`]="{ value }">
        <v-chip>{{ value ? value : "None" }}</v-chip>
      </template>

      <!-- Status cell override -->
      <template v-slot:[`item.AssignmentStatus`]="{ item, value }">
        <CPMDashboardStatusSelector
          :item="item"
          :value="value"
          :options="statuses"
          :optionsLoading="statusesLoading"
        />
      </template>

      <!-- accepted cell override -->
      <template v-slot:[`item.accepted`]="{ item, value }">
        <CPMDashboardAcceptedSelector
          :item="item"
          :value="value"
          @refresh-table-expansion="updateTableDataAccepted"
        />
      </template>

      <!-- Inquiry cell override -->
      <template v-slot:[`item.Inquiry`]="{ item }">
        <v-btn
          fab
          x-small
          outlined
          elevation="1"
          color="accent"
          @click="openSlackForm(item)"
          ><v-icon>mdi-slack</v-icon></v-btn
        >
      </template>

      <!-- Earnings cell override -->
      <template v-slot:[`item.TotalInternal`]="{ value }">
        <span>{{ value ? `$${value.toFixed(2)}` : "-" }}</span>
      </template>

      <!-- Expanded panel -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <CPMDashboardTableExpansion :assignmentInfo="item" />
        </td>
      </template>
    </v-data-table>
    <v-dialog
      max-width="1200"
      v-model="slackFormModal"
      @click:outside="slackFormModal = false"
      @keydown.esc="slackFormModal = false"
    >
      <SlackInquiryForm
        :inquiryItem="inquiryAssignment"
        :mode="'assignment'"
        :cpmMode="true"
        @close-slack-form="confirmInquiry"
      />
    </v-dialog>
    <v-snackbar
      v-model="successMessage"
      min-width="unset"
      timeout="2000"
      color="success"
      rounded="pill"
      content-class="text-center"
    >
      <v-icon color="white" class="mr-1">mdi-check-circle-outline</v-icon>
      {{ successMsgTxt }}
    </v-snackbar>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// libraries
import _ from "lodash";
import Fuse from "fuse.js";
// internal
import { customBreakpoint } from "@/utils/mixins";
// components
import CPMDashboardTableExpansion from "./CPMDashboardTableExpansion.vue";
import CPMDashboardStatusSelector from "./CPMDashboardStatusSelector.vue";
import CPMDashboardAcceptedSelector from "./CPMDashboardAcceptedSelector.vue";
import SlackInquiryForm from "../ui/SlackInquiryForm.vue";

export default {
  name: "CPMDashboardTable",
  components: {
    CPMDashboardTableExpansion,
    CPMDashboardStatusSelector,
    CPMDashboardAcceptedSelector,
    SlackInquiryForm,
  },
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    tableFields: {
      type: Array,
      required: false,
    },
    filterDeliveryDate: {
      type: String,
      required: false,
    },
    filterRequestDate: {
      type: String,
      required: false,
    },
    filterSearch: {
      type: String,
      required: false,
    },
    filterProject: {
      type: String,
      required: true,
    },
    filterPerson: {
      type: String,
      required: true,
    },
    filterRole: {
      type: String,
      required: true,
    },
    filterGeo: {
      type: String,
      required: true,
    },
    filterStatus: {
      type: String,
      required: true,
    },
    isStatusUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    statuses: {
      type: Array,
      required: true,
    },
    statusesLoading: {
      type: Boolean,
      required: true,
    },
    showEarnings: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      adminHeaders: [
        "ProjectName",
        "WorkTypeCategory",
        "AssignmentTitle",
        "AssignmentDetail",
        "GEO",
        "Quantity",
        "Person-Primary",
        "Person-Reviewer",
        "AssignmentStatus",
        "Inquiry",
      ],
      userHeaders: [
        "ProjectName",
        "WorkTypeCategory",
        "MediaType",
        "AssignmentTitle",
        "AssignmentDetail",
        "Quantity",
        "Person-Primary",
        "Person-Reviewer",
        "DueTime",
        "Inquiry",
        "accepted",
        "AssignmentStatus",
      ],
      selectedRows: [],
      dueControl: 2,
      primaryStatuses: ["Assigned", "Primary Complete", "Written/Translated"],
      reviewerStatuses: [
        "Primary Complete",
        "Written/Translated",
        "Secondary Complete",
        "Edited",
      ],
      bulkMode: "",
      statusUpdating: false,
      statusError: false,
      slackFormModal: false,
      inquiryAssignment: {},
      successMessage: false,
      successMsgTxt: "",
    };
  },
  mixins: [customBreakpoint],
  computed: {
    ...mapGetters("fcBot", ["selectedDashboardRows"]),
    ...mapGetters("auth", ["user", "isAdmin", "isManager"]),
    headers() {
      if (!this.tableFields) return [];

      const tableHeaders =
        this.isAdmin || this.isManager ? this.adminHeaders : this.userHeaders;

      let retVal = this.tableFields
        .filter((el) => tableHeaders.includes(el.value))
        .map((field) => {
          return {
            text: field.text,
            value: field.value,
          };
        });

      // order by the order of the tableHeaders
      retVal = _.orderBy(retVal, (el) => tableHeaders.indexOf(el.value));

      if (!this.isAdmin && !this.isManager) {
        retVal.push({
          text: "Accepted",
          value: "accepted",
        });
      }
      retVal.push({ text: "", value: "data-table-expand" });

      return retVal;
    },
    tableFieldValues() {
      return this.tableFields.map((field) => field.value);
    },
    dataWithGroupInfo() {
      if (this.isAdmin || this.isManager) {
        return this.tableData.map((el, i) => {
          return {
            ...el,
            groupDate: el["DeliveryDate"],
            groupType: "DELIVERY",
            groupSort: el["DeliveryDate"],
            index: i,
          };
        });
      } else {
        return this.tableData.map((el, i) => {
          if (el["Person-Primary"] === this.user.Name) {
            return {
              ...el,
              groupDate: el["PrimaryDueDate"],
              groupSort: el.DueTime,
              groupType: "PRIMARY",
              accepted: el["PrimaryPersonAccepted"],
              index: i,
            };
          } else if (el["Person-Reviewer"] === this.user.Name) {
            return {
              ...el,
              groupDate: el["ReviewerDueDate"],
              groupSort: el.DueTime,
              groupType: "REVIEWING",
              accepted: el["ReviewerAccepted"],
              index: i,
            };
          }
        });
      }
    },
    filteredData() {
      let dateFilteredData = [...this.dataWithGroupInfo];

      if (this.filterProject) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el["ProjectName"] === this.filterProject
        );
      }

      if (this.filterDeliveryDate) {
        if (this.isAdmin || this.isManager) {
          dateFilteredData = dateFilteredData.filter((el) => {
            return (
              this.$moment(el["DeliveryDate"]).format("YYYY-MM-DD") ===
              this.filterDeliveryDate
            );
          });
        } else {
          dateFilteredData = dateFilteredData.filter((el) => {
            return (
              this.$moment(el["groupDate"]).format("YYYY-MM-DD") ===
              this.filterDeliveryDate
            );
          });
        }
      }

      if (this.filterRequestDate) {
        dateFilteredData = dateFilteredData.filter((el) => {
          return (
            this.$moment(el["RequestDate"]).format("YYYY-MM-DD") ===
            this.filterRequestDate
          );
        });
      }

      if (this.filterPerson) {
        dateFilteredData = dateFilteredData.filter(
          (el) =>
            el["Person-Primary"] === this.filterPerson ||
            el["Person-Reviewer"] === this.filterPerson
        );
      }

      if (this.filterRole) {
        dateFilteredData = dateFilteredData.filter(
          (el) =>
            el[`Person-${this.filterRole}`] ===
            (this.isAdmin || this.isManager
              ? this.filterPerson
              : this.user.Name)
        );
      }

      if (this.filterGeo) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el["GEO"] === this.filterGeo
        );
      }

      if (this.filterStatus) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el["AssignmentStatus"] === this.filterStatus
        );
      }

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: this.tableFieldValues,
      };

      if (this.filterSearch) {
        const fuse = new Fuse(dateFilteredData, searchOptions);
        dateFilteredData = fuse.search(this.filterSearch).map((el) => el.item);
      } else {
        dateFilteredData = dateFilteredData;
      }
      return dateFilteredData;
    },
    dueText() {
      if (this.isAdmin || this.isManager) {
        return "Delivery Date: ";
      } else {
        return "Your Due Date: ";
      }
    },
    totalEarnings() {
      return parseFloat(
        this.filteredData.reduce((a, b) => a + (b?.TotalInternal || 0), 0)
      ).toFixed(2);
    },
  },
  watch: {
    selectedRows(newValues) {
      this.setSelectedDashboardRows(newValues);
    },
    selectedDashboardRows(newValues) {
      this.selectedRows = newValues;
    },
    showEarnings(newValue) {
      this.adminHeaders.splice(2, 1, newValue ? "TotalInternal" : "MediaType");
      this.userHeaders.splice(2, 1, newValue ? "TotalInternal" : "MediaType");
    },
  },
  methods: {
    ...mapActions("fcBot", ["setSelectedDashboardRows"]),
    formatDate(data) {
      return this.$moment(data).format("MMM DD, YYYY");
    },
    formatDateForTableRow(data, displayTime, timeOnly) {
      return this.$moment(data).format(
        `${timeOnly ? "hh:mm A" : `MM/DD/YYYY ${displayTime ? "hh:mm A" : ""}`}`
      );
    },
    isActivePerson(person) {
      return person === this.user.Name;
    },
    ellipseText(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + "...";
      } else {
        return text;
      }
    },
    updateTableDataAccepted(index, value, role) {
      this.tableData[index][
        `${role.charAt(0) + role.slice(1).toLocaleLowerCase()}${
          role === "PRIMARY" ? "Person" : ""
        }Accepted`
      ] = value;
    },
    handleDueAlert(item, flow) {
      if (
        this.isActivePerson(
          item[
            `Person-${flow === "Reviewing" ? `${flow.slice(0, -3)}er` : flow}`
          ]
        )
      ) {
        const statuses = [],
          assgnDue = this.$moment(
            `${item[
              `${flow === "Reviewing" ? `${flow.slice(0, -3)}er` : flow}DueDate`
            ].replace(/-/g, "/")} ${
              item[
                `${
                  flow === "Reviewing" ? flow.slice(0, -3) : flow
                }Due(TimeOfDay)`
              ]
            }`
          ).format(),
          now = this.$moment(
            new Date().toLocaleString("en-US", {
              timeZone: "America/New_York",
            })
          ).format(),
          dueMeter = this.$moment(assgnDue).diff(
            this.$moment(now),
            "hour",
            true
          );
        if (flow === "Primary") statuses.push("Assigned");
        if (flow === "Reviewing")
          statuses.push("Written/Translated", "Primary Complete");
        if (
          ((dueMeter <= this.dueControl && dueMeter > 0) || dueMeter == 0) &&
          statuses.includes(item["AssignmentStatus"])
        ) {
          return "upcoming-due";
        } else if (
          dueMeter < 0 &&
          statuses.includes(item["AssignmentStatus"])
        ) {
          return "overdue";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    handleDuesStats(group) {
      const groupItems = this.filteredData.filter(
          (el) => this.$moment(el.groupDate).format("YYYY-MM-DD") === group
        ),
        flows = ["Primary", "Reviewing"],
        overdueItems = [],
        upcomingDueItems = [];
      groupItems.forEach((el) => {
        flows.forEach((flow) => {
          if (this.handleDueAlert(el, flow) === "overdue") {
            overdueItems.push(el);
          } else if (this.handleDueAlert(el, flow) === "upcoming-due") {
            upcomingDueItems.push(el);
          }
        });
      });
      return `${
        (overdueItems.length
          ? "Overdued assignments - " + overdueItems.length
          : "") +
        (overdueItems.length && upcomingDueItems.length ? " / " : "") +
        (upcomingDueItems.length
          ? `Assignments with upcoming due in ${this.dueControl} hrs - ` +
            upcomingDueItems.length
          : "")
      }`;
    },
    async handleSelectedRows(selected, item) {
      const handleUpdate = () =>
        selected
          ? this.selectedRows.push(item)
          : this.selectedRows.splice(this.selectedRows.indexOf(item), 1);
      await handleUpdate();
    },
    handleSelectAll(data) {
      data.items.forEach((item) => {
        const index = this.filteredData.indexOf(item);
        this.filteredData[index].checked = data.value;
        this.handleSelectedRows(data.value, item);
      });
    },
    openSlackForm(item) {
      this.inquiryAssignment = { ...item };
      this.slackFormModal = true;
    },
    confirmInquiry() {
      this.slackFormModal = false;
      this.successMsgTxt = "Inquiry sent!";
      this.successMessage = true;
    },
  },
};
</script>

<style scoped lang="scss">
.group-header-row {
  background-color: #9af9ef70 !important;
  color: #003a35 !important;
}

.assigned-to-user {
  .text-container {
    width: fit-content;
    font-weight: 500;
    border: 2px solid #ee79753f;
    color: #540000;
    padding: 2px 5px;
    margin: 2px 0px;
    border-radius: 10px;
    line-height: 1.5;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  }
}

::v-deep {
  .v-data-table.laptop .v-data-table__wrapper table {
    & tbody > tr:not(.v-data-table__expanded__content) > td {
      padding: 0 8px;
    }
    & .v-data-table-header tr > th {
      padding: 0 8px;
    }
  }
  .v-data-table.laptopSmaller .v-data-table__wrapper table {
    & tbody > tr:not(.v-data-table__expanded__content) > td {
      padding: 0 4px;
    }
    & .v-data-table-header tr > th {
      padding: 0 4px;
    }
  }
  .v-badge--icon .v-badge__badge {
    min-width: 23px;
    height: 23px;
    display: flex;
    border-radius: 50%;
    padding: 0;
    & .v-icon {
      font-size: 17px;
      height: auto;
      margin: auto;
    }
  }
}

.v-input--selection-controls {
  padding-left: 8px;
}

.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0;
}

.status-updating {
  pointer-events: "none";
  opacity: 0.2;
}

.reported-field {
  width: 110px;
}

.updated-field {
  width: 180px;
}

.updated-by-field,
.status-field {
  width: 220px;
}

.d-inline {
  vertical-align: middle;
}
</style>
