<template>
  <div class="fc-info-form">
    <div
      class="fc-info-form__header d-flex align-center justify-space-between pb-10"
    >
      <h2>Assign and Import</h2>
      <!-- prepopulation mode -->
      <div
        v-if="shakespeare || backstageCopywriting"
        class="d-flex align-center"
      >
        <span class="overline mr-2">Prepopulate for:</span>
        <v-btn-toggle
          class="elevation-2"
          color="accent"
          v-model="prepopulationMode"
          @change="handlePrepopulation($event)"
        >
          <v-btn>Music Playlists</v-btn>
          <v-btn>FC{{ backstageCopywriting ? "" : " Music" }}</v-btn>
          <v-btn v-if="!backstageCopywriting">FC Video</v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <FCStaffTable
      :staffNames="staffNames"
      :tableRows="tableRows"
      :workType1References="workType1References"
      :workType2References="workType2References"
      :workType3References="workType3References"
      :workType4References="workType4References"
      :workType5References="workType5References"
      :workType6References="workType6References"
    />
    <div class="fc-info-form__body">
      <v-form ref="fcInfoForm" class="fc-info-form__main-form mb-10">
        <h3 v-if="shakespeare" class="mt-2">General Info</h3>
        <!-- related project -->
        <v-row v-if="shakespeare || backstageCopywriting" class="row mt-3">
          <v-autocomplete
            filled
            class="pr-2"
            label="Related Project"
            :items="projectNameItems"
            :loading="projectNameItemsLoading"
            v-model="formValues.relatedProject"
            item-text="name"
            return-object
            :rules="[rules.required]"
            @change="updateRelatedProjectSmartFields($event)"
          ></v-autocomplete>
          <!-- related po -->
          <v-text-field
            filled
            class="pl-2"
            label="Related Purchase Order"
            v-model="formValues.po"
            :rules="[rules.required]"
            readonly
            :loading="poLoading"
          ></v-text-field>
        </v-row>
        <!-- email subject line -->
        <v-row v-if="shakespeare">
          <v-text-field
            filled
            label="Email Subject Line"
            disabled
            v-model="formValues.emailSubjectLine"
            :rules="[rules.required]"
            @change="setFormValue({ key: 'emailSubjectLine', value: $event })"
          ></v-text-field>
        </v-row>
        <!-- client requester -->
        <v-row v-if="shakespeare || shakespeareLoc" class="row">
          <v-autocomplete
            filled
            class="pr-2"
            label="Related Client Requester"
            :items="clientRequesters"
            :loading="clientRequestersLoading"
            v-model="formValues.clientRequester"
            :rules="[rules.required]"
            @change="setFormValue({ key: 'clientRequester', value: $event })"
          ></v-autocomplete>
          <!-- media type -->
          <v-autocomplete
            v-if="shakespeare"
            filled
            class="pl-2"
            label="Media Type"
            :items="mediaTypes"
            :loading="mediaTypesLoading"
            v-model="formValues.mediaType"
            :rules="[rules.required]"
            @change="setFormValue({ key: 'mediaType', value: $event })"
          ></v-autocomplete>
        </v-row>
        <!-- assignment title -->
        <v-row>
          <v-combobox
            :class="{ 'mb-4 mt-3': !shakespeare }"
            filled
            label="Assignment Title"
            :items="assignmentTitles"
            v-model="formValues.assignmentTitle"
            :rules="[rules.required]"
            @change="setFormValue({ key: 'assignmentTitle', value: $event })"
          ></v-combobox>
        </v-row>
        <!-- note -->
        <v-row class="row">
          <v-textarea
            v-model="formValues.note"
            label="Note"
            rows="2"
            clearable
            filled
            @change="setFormValue({ key: 'note', value: $event })"
          />
        </v-row>
        <h3 v-if="shakespeare" class="mt-1 mb-6">Staff Info</h3>
        <!-- client's delivery date -->
        <v-row class="row">
          <v-menu
            v-if="shakespeare || shakespeareLoc"
            v-model="clientDeliveryDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                filled
                class="pr-2"
                v-model="clientDeliveryDate"
                label="Delivery Date (Client)"
                readonly
                :error="conversionErrors"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="clientDeliveryDate"
              @change="handlePayPeriod($event)"
            ></v-date-picker>
          </v-menu>
          <!-- file location -->
          <v-autocomplete
            v-else
            filled
            class="pr-2"
            label="File Location"
            :items="fileLocations"
            :loading="fileLocationsLoading"
            v-model="formValues.fileLocation"
            @change="setFormValue({ key: 'fileLocation', value: $event })"
            :rules="[rules.required]"
          ></v-autocomplete>
          <!-- pay period -->
          <v-menu
            v-model="payPeriodMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="pl-2"
                :class="{ 'mb-4': !shakespeare }"
                v-model="payPeriod"
                label="Pay Period"
                readonly
                v-bind="attrs"
                v-on="on"
                filled
                :rules="[rules.required]"
              ></v-text-field>
            </template>
            <v-date-picker
              type="month"
              v-model="payPeriod"
              @input="payPeriodMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-row>
        <!-- delivery due conversion -->
        <v-row class="row" :class="{ 'my-4': !shakespeare }">
          <v-select
            filled
            class="pr-2"
            :items="hoursList"
            v-model="clientDeliveryDue"
            item-text="time"
            label="Delivery Due (Time of Day)"
            :error="conversionErrors"
          ></v-select>
          <v-autocomplete
            filled
            class="pl-2"
            label="Time Zone"
            :error="conversionErrors"
            v-model="timeZone"
            :items="utcTimeZones"
          ></v-autocomplete>
        </v-row>
        <v-row :class="{ 'my-4': !shakespeare }">
          <v-btn
            block
            height="56px"
            color="accent"
            outlined
            @click="convertDeliveryDue"
            >Convert Delivery Due to ET time zone</v-btn
          >
        </v-row>
        <v-row class="row my-4">
          <v-checkbox
            class="pr-2"
            v-model="formValues.override"
            label="Override"
            persistent-hint
            hint="Override Due Dates and Dues (Time of Day)"
            :disabled="!formValues.deliveryDate && !formValues.deliveryDue"
            @change="setFormValue({ key: 'override', value: $event })"
          ></v-checkbox>
          <v-checkbox
            class="pl-2"
            v-model="formValues.urgent"
            label="Urgent"
            persistent-hint
            hint="Convert Delivery data (ET) or enter it manually"
            readonly
            :color="formValues.urgent ? 'red accent-4' : ''"
          ></v-checkbox>
        </v-row>
        <!-- individual geo deadlines -->
        <v-row
          v-if="allGeos.length > 1"
          id="indiv-geo"
          class="mb-2 align-center"
        >
          <h3 class="indiv-geo-title">Individual GEO deadlines:</h3>
          <div class="d-flex">
            <v-btn
              width="48px"
              min-width="48px"
              height="48px"
              class="elevation-2 px-0 mx-2"
              color="accent"
              @click="handleIndiviadualGeoDeadlines(false, -1)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              height="48px"
              class="indiv-geo-btn px-1 mr-2"
              :color="
                validateIndiviadualGeoDeadlines(index, Object.keys(dline)[0])
                  ? 'accent'
                  : 'secondary'
              "
              :outlined="
                validateIndiviadualGeoDeadlines(index, Object.keys(dline)[0])
              "
              v-for="(dline, index) in formValues.individualGeosDeadlines"
              :key="index"
              @click="handleIndiviadualGeoDeadlines(true, index)"
            >
              {{ Object.keys(dline)[0] }}
            </v-btn>
          </div>
        </v-row>
        <!-- writer due date -->
        <v-row class="row" :class="{ 'my-4': !shakespeare }">
          <v-menu
            v-model="writerMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="writingDueDateField"
                filled
                class="pr-2"
                v-model="formValues.writingDueDate"
                label="Writer Due Date (ET)"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[rules.required, rules.dateAvailabile('writing')]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formValues.writingDueDate"
              :max="formValues.override ? null : formValues.deliveryDate"
              @input="writerMenu = false"
              @change="handleDueData($event, 'writing')"
            ></v-date-picker>
          </v-menu>
          <!-- writer due time -->
          <v-select
            filled
            class="pl-2"
            v-model="formValues.writingDue"
            :items="writingDueHours"
            item-text="time"
            label="Writer Due Time (ET)"
            :rules="[rules.required, rules.timeAvailabile('writing')]"
            @change="handleDueData($event, 'writing', true)"
          >
          </v-select>
        </v-row>
        <!-- editor due date -->
        <v-row class="row" :class="{ 'my-4': !shakespeare }">
          <v-menu
            v-model="editorMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="editingDueDateField"
                filled
                clearable
                class="pr-2"
                v-model="formValues.editingDueDate"
                label="Editor Due Date (ET)"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="handleDueData('', 'editing')"
                :rules="[rules.dateAvailabile('editing')]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formValues.editingDueDate"
              :max="formValues.override ? null : formValues.deliveryDate"
              @input="editorMenu = false"
              @change="handleDueData($event, 'editing')"
            ></v-date-picker>
          </v-menu>
          <!-- editor due time -->
          <v-select
            filled
            clearable
            class="pl-2"
            v-model="formValues.editingDue"
            :items="editingDueHours"
            item-text="time"
            label="Editor Due Time (ET)"
            :rules="[rules.timeAvailabile('editing')]"
            @change="handleDueData($event, 'editing', true)"
            @click:clear="handleDueData('', 'editing', true)"
          >
          </v-select>
        </v-row>
        <!-- reviewer due date -->
        <v-row class="row" :class="{ 'my-4': !shakespeare }">
          <v-menu
            v-model="reviewerMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="reviewingDueDateField"
                filled
                clearable
                class="pr-2"
                v-model="formValues.reviewingDueDate"
                label="Reviewer Due Date (ET)"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="handleDueData('', 'reviewing')"
                :rules="[rules.dateAvailabile('reviewing')]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formValues.reviewingDueDate"
              :max="formValues.override ? null : formValues.deliveryDate"
              @input="reviewerMenu = false"
              @change="handleDueData($event, 'reviewing')"
            ></v-date-picker>
          </v-menu>
          <!-- reviewer due time -->
          <v-select
            filled
            clearable
            class="pl-2"
            v-model="formValues.reviewingDue"
            :items="reviewingDueHours"
            item-text="time"
            label="Reviewer Due Time (ET)"
            :rules="[rules.timeAvailabile('reviewing')]"
            @change="handleDueData($event, 'reviewing', true)"
            @click:clear="handleDueData('', 'reviewing', true)"
          >
          </v-select>
        </v-row>
        <!-- delivery due date -->
        <v-row class="row">
          <v-menu
            v-model="deliveryMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="pr-2"
                v-model="formValues.deliveryDate"
                label="Delivery Due Date (ET)"
                readonly
                v-bind="attrs"
                v-on="on"
                filled
                :rules="[rules.required]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formValues.deliveryDate"
              @input="deliveryMenu = false"
              @change="setFormValue({ key: 'deliveryDate', value: $event })"
            ></v-date-picker>
          </v-menu>
          <v-select
            class="pl-2"
            v-model="formValues.deliveryDue"
            item-text="time"
            label="Delivery Due Time (ET)"
            :items="hoursList"
            filled
            :rules="[rules.required]"
            @change="setFormValue({ key: 'deliveryDue', value: $event })"
          ></v-select>
        </v-row>
      </v-form>
      <FCAssignmentVisualization v-if="!backstageCopywriting && !shakespeare" />
    </div>

    <div
      class="fc-info-form__main-form__staff-section__button-container"
      :class="{ 'justify-center': successAlert || failAlert || writersAlert }"
    >
      <v-progress-linear
        v-if="thinking"
        class="thinking-progress"
        indeterminate
      />

      <div
        v-if="
          !successAlert &&
          !failAlert &&
          !writersAlert &&
          !thinking &&
          !xContent &&
          !shakespeare &&
          !shakespeareLoc
        "
        class="left"
      >
        <v-btn
          :disabled="!formValues.overrideStaff && !overloadingValidated"
          class="import-button mr-3"
          color="accent"
          @click="handleSplitByPerson"
          :loading="splitByPersonProcessing"
          >SPLIT BY PERSON</v-btn
        >
        <v-btn
          :disabled="!formValues.overrideStaff && !overloadingValidated"
          class="import-button"
          color="accent"
          @click="handleSplitByGeo"
          >SPLIT BY GEO</v-btn
        >
      </div>

      <div
        v-if="!successAlert && !failAlert && !writersAlert && !thinking"
        class="right d-flex"
        :class="{ 'justify-end': xContent || shakespeare || shakespeareLoc }"
        :style="{
          width:
            xContent || shakespeare || shakespeareLoc ? '100%' : 'fit-content',
        }"
      >
        <v-checkbox
          class="mt-0 mr-3"
          v-model="formValues.overrideStaff"
          label="Override Availability & Capacity"
        ></v-checkbox>
        <v-btn
          :disabled="!formValues.overrideStaff && !overloadingValidated"
          class="import-button mr-3"
          color="secondary"
          @click="handleAddToQB"
          >ADD TO QB</v-btn
        >
        <v-btn
          :disabled="!formValues.overrideStaff && !overloadingValidated"
          class="import-button"
          color="secondary"
          @click="handleDoGoogle"
          >ADD TO GOOGLE SHEET</v-btn
        >
      </div>

      <v-alert
        v-model="successAlert"
        class="success-alert"
        type="success"
        dismissible
        @input="resetCallers"
      >
        {{ alertMessage }}
        <v-btn
          v-if="googleCall"
          class="ml-2"
          small
          depressed
          light
          :href="spreadsheetPath"
          target="_blank"
          >Open Tool Import</v-btn
        >
        <v-btn
          v-if="qbCall"
          class="ml-2"
          small
          depressed
          light
          :href="`${fullFCDashboardPath}?ppm=${
            formValues.payPeriod.split(' ')[0]
          }&ppy=${formValues.payPeriod.split(' ')[1]}&Date=${
            formValues.deliveryDate
          }&Search=${formValues.assignmentTitle}&Person=${
            user.Name
          }&Role=Creator`"
          target="_blank"
          >Check on Dashboard</v-btn
        >
      </v-alert>
      <v-alert
        v-model="failAlert"
        class="fail-alert"
        type="error"
        dismissible
        @input="resetCallers"
        >{{ alertMessage }}</v-alert
      >
      <v-alert
        v-model="writersAlert"
        class="writers-alert"
        type="warning"
        outlined
        color="accent"
        dismissible
        ><p>No writer was added to:</p>
        <p v-for="(item, index) in writersValidated" :key="index">
          <b>
            {{ xContent ? item.geos : item.assignment + " — " + item.geos }}</b
          >
        </p></v-alert
      >
    </div>
    <v-dialog
      max-width="650"
      v-model="indiviadualGeoDeadlines"
      @click:outside="closeIndiviadualGeoDeadlines"
      @keydown.esc="closeIndiviadualGeoDeadlines"
    >
      <GEODeadlinesForm
        ref="geoDeadlinesForm"
        :editMode="indiviadualGeoDeadlinesEdit"
        :itemToEditIndex="indiviadualGeoDeadlinesItemIndex"
        :allGeos="allGeos"
        @close-geo-deadlines-form="closeIndiviadualGeoDeadlines"
      />
    </v-dialog>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// internal
import {
  getFCStaffDatas,
  addITunesAssignmentRowsToQB,
  getAvailabilityRecords,
  getReportData,
  appIDs,
  getAllProjectNames,
  getFieldInfosFromDB,
  getiTunesFCPONumber,
  getMediaTypeChoices,
  getAssignments,
  getTasks,
  processTask,
} from "@/utils/quickbaseUtils";
import { doFileSplit, doFileSplitByPerson } from "@/utils/fcBotUtils";
import {
  addRowsToGoogleSheet,
  SPREADSHEET_ID,
} from "@/utils/googleSheetsUtils";
import { timeUnits } from "@/utils/mixins";
import { DOMAIN, ROUTES } from "@/utils/constants";
// components
import FCStaffTable from "@/components/FCBot/FCStaffTable";
import FCAssignmentVisualization from "@/components/FCBot/FCAssignmentVisualization";
import GEODeadlinesForm from "@/components/FCBot/GEODeadlinesForm";

export default {
  name: "FCInfoForm",
  components: {
    FCStaffTable,
    FCAssignmentVisualization,
    GEODeadlinesForm,
  },
  data() {
    return {
      payPeriod: "",
      allGeos: [],
      writerMenu: false,
      writingDueDateAvailable: true,
      writingDueHoursAvailable: true,
      editorMenu: false,
      editingDueDateAvailable: true,
      editingDueHoursAvailable: true,
      reviewerMenu: false,
      reviewingDueDateAvailable: true,
      reviewingDueHoursAvailable: true,
      deliveryMenu: false,
      payPeriodMenu: false,
      staffNames: null,
      successAlert: false,
      failAlert: false,
      writersAlert: false,
      qbCall: false,
      googleCall: false,
      thinking: false,
      splitByPersonProcessing: false,
      alertMessage: "",
      clientDeliveryDue: "",
      timeZone: "",
      conversionErrors: false,
      prepopulationMode: null,
      projectNameItemsLoading: false,
      projectNameItems: [],
      poLoading: false,
      clientRequestersLoading: false,
      clientRequesters: ["Shakespeare"],
      mediaTypesLoading: false,
      mediaTypes: [],
      amAssignmentTitles: [
        "P1 Playlist Copy",
        "P3 Playlist Copy",
        "Classical Playlist Copy",
        "Playlist Title",
      ],
      fcAssignmentTitles: [
        "Album Review",
        "Arcade File",
        "Artist Bio",
        "Book Review",
        "Custom Tag",
        "Custom Title",
        "Discovery File",
        "Guidelines",
        "Hero Blurb",
        "Interview Note",
        "Marketing Copy",
        "MAS File",
        "Movie Description",
        "Storefront Description",
        "Today File",
      ],
      clientDeliveryDateMenu: false,
      clientDeliveryDate: "",
      workType1References: ["1"],
      workType2References: ["2"],
      workType3References: ["3"],
      workType4References: ["37"],
      workType5References: ["41"],
      workType6References: ["75"],
      fileLocationsLoading: false,
      fileLocations: [],
      indiviadualGeoDeadlines: false,
      indiviadualGeoDeadlinesEdit: false,
      indiviadualGeoDeadlinesItemIndex: null,
      rules: {
        required: (value) => !!value || value === 0 || "Required",
        dateAvailabile: (operation) =>
          this[`${operation}DueDateAvailable`] ||
          "Selected date is after delivery date for the client.",
        timeAvailabile: (operation) =>
          this[`${operation}DueHoursAvailable`] ||
          "Selected time is after delivery due (time of day) for the client.",
      },
    };
  },
  watch: {
    tableRows(newData) {
      if (this.tableRows) {
        this.payPeriod =
          this.shakespeare || this.shakespeareLoc
            ? ""
            : this.$moment(newData[0].due_date.replace(/\./g, "/")).format(
                "YYYY-MM"
              );
        this.allGeos = [];
        this.tableRows.forEach((el) => {
          this.shakespeare
            ? this.allGeos.push(el.geo)
            : el.geos.forEach((geo) =>
                this.allGeos.push(this.shakespeareLoc ? geo.geo : geo)
              );
        });
      }
      this.$refs.fcInfoForm.resetValidation();
    },
    payPeriod(newPayPeriod) {
      this.setFormValue({
        key: "payPeriod",
        value: this.$moment(newPayPeriod).format("MMMM YYYY"),
      });
    },
    deliveryDate(newDate) {
      this.handleStaffDuesValidation();
      this.setFormValue({
        key: "urgent",
        value: this.$moment(newDate).isSameOrBefore(
          this.$moment(this.tableRows[0].request_date).add(2, "days")
        ),
      });
    },
    override() {
      this.handleStaffDuesValidation();
    },
    writingDueHours(newVal) {
      this.handleDueHoursValidation("writing", newVal);
    },
    editingDueHours(newVal) {
      this.handleDueHoursValidation("editing", newVal);
    },
    reviewingDueHours(newVal) {
      this.handleDueHoursValidation("reviewing", newVal);
    },
  },
  methods: {
    ...mapActions("fcBot", [
      "setFormValue",
      "setStaffNameGEOPairs",
      "updateCapacityData",
      "setNamesLoading",
      "setStaffAssignments",
      "setPrepopulation",
    ]),
    handleAddToQB() {
      if (!this.writersValidated.length) {
        if (this.$refs.fcInfoForm.validate()) {
          this.resetCallers();
          this.thinking = this.qbCall = true;
          const requestTime = this.$moment(
            new Date().toLocaleString("en-US", {
              timeZone: "America/New_York",
            })
          ).format("hh:mm A");
          this.setFormValue({
            key: "requestTime",
            value: requestTime,
          });
          addITunesAssignmentRowsToQB(
            this.formValues,
            this.tableRows,
            this.staffAssignments,
            this.staffNames
          ).then((response) => {
            const msg = response;
            // handle task status after upload
            const taskAddCondition = () => {
                return (
                  this.shakespeare ||
                  (this.tableRows[0].media_type === "Apps" &&
                    (this.tableRows[0].keyword === "MAS" ||
                      this.tableRows[0].keyword === "Today"))
                );
              },
              cueID =
                this.$moment(this.tableRows[0].request_date).format(
                  "MM-DD-YYYY"
                ) +
                "_" +
                (taskAddCondition()
                  ? requestTime + "_" + this.formValues.assignmentTitle
                  : this.tableRows[0].task_id),
              task = {
                RelatedProject: this.formValues.relatedProject
                  ? this.formValues.relatedProject.name
                  : "iTunes FC",
                ClientRequester: this.shakespeare
                  ? this.formValues.clientRequester
                  : this.tableRows[0].client_requester,
                DRI: this.user.Name,
                CueTaskID: cueID,
                DeliveryDate: this.formValues.deliveryDate,
                DeliveryTime: this.formValues.deliveryDue,
                Task: this.shakespeare
                  ? this.formValues.emailSubjectLine
                  : this.tableRows[0].subject_line,
                MediaType: this.shakespeare
                  ? this.formValues.mediaType
                  : this.tableRows[0].media_type,
                Priority: "",
                RequestDate: this.$moment(
                  this.tableRows[0].request_date
                ).format("YYYY-MM-DD"),
                RequestTime: requestTime,
                TaskID: taskAddCondition()
                  ? ""
                  : this.$moment(this.tableRows[0].request_date).format(
                      "MM-DD-YYYY"
                    ) +
                    "_" +
                    this.tableRows[0].task_id,
                TaskStatus: "Assigned",
                LastUpdated: new Date(),
                LastUpdatedBy: this.user.Name,
              };
            getAssignments({ cueID })
              .then((resp) => {
                // handle task creation after Shakespeare or Apps (MAS or Today) batch upload
                if (taskAddCondition()) {
                  processTask({ task })
                    .then(() => {
                      this.thinking = false;
                      this.showMessage(msg);
                    })
                    .catch((err) => {
                      console.error(err);
                      this.thinking = false;
                      this.showMessage(err);
                    });
                } else {
                  if (
                    resp.data.every((el) => el.AssignmentStatus === "Assigned")
                  ) {
                    getTasks({ cueID })
                      .then((resp) => {
                        if (
                          resp.data.length &&
                          resp.data[0].TaskStatus !== "Assigned"
                        ) {
                          processTask({
                            task: {
                              ...task,
                              ["RecordID#"]: resp.data[0]["RecordID#"],
                            },
                            status: "Assigned",
                          })
                            .then(() => {
                              this.thinking = false;
                              this.showMessage(msg);
                            })
                            .catch((err) => {
                              console.error(err);
                              this.thinking = false;
                              this.showMessage(err);
                            });
                        } else {
                          throw "next";
                        }
                      })
                      .catch((err) => {
                        if (err !== "next") console.error(err);
                        this.thinking = false;
                        this.showMessage(err === "next" ? msg : err);
                      });
                  } else {
                    this.thinking = false;
                    this.showMessage(msg);
                  }
                }
              })
              .catch((err) => {
                console.error(err);
                this.thinking = false;
                this.showMessage(err);
              });
          });
        }
      } else {
        this.writersAlert = true;
      }
    },
    handleDoGoogle() {
      if (!this.writersValidated.length) {
        if (this.$refs.fcInfoForm.validate()) {
          this.resetCallers();
          this.thinking = this.googleCall = true;
          addRowsToGoogleSheet({
            formValues: this.formValues,
            tableRows: this.tableRows,
            staffAssignments: this.staffAssignments,
          })
            .then((result) => {
              this.thinking = false;
              this.showMessage(result);
            })
            .catch((err) => {
              this.thinking = false;
              this.showMessage(err);
            });
        }
      } else {
        this.writersAlert = true;
      }
    },
    resetCallers() {
      this.qbCall = this.googleCall = false;
    },
    handleSplitByGeo() {
      doFileSplit(this.files, this.tableRows);
    },
    handleSplitByPerson() {
      this.splitByPersonProcessing = true;
      doFileSplitByPerson({
        files: this.files,
        staffAssignments: this.staffAssignments,
        staffNameGEOPairs: this.staffNameGEOPairs,
      })
        .then((msg) => {
          this.showSplitByPersonStatus(msg);
          this.splitByPersonProcessing = false;
        })
        .catch((err) => {
          console.error(err);
          this.showSplitByPersonStatus(err);
          this.splitByPersonProcessing = false;
        });
    },
    showMessage(msg) {
      if (msg === "success") {
        this.alertMessage = "Import Successful";
        this.successAlert = true;
      } else {
        this.alertMessage = msg;
        this.failAlert = true;
      }
    },
    showSplitByPersonStatus(msg) {
      this.alertMessage = msg;
      msg === "File(s) uploaded!"
        ? (this.successAlert = true)
        : (this.failAlert = true);
    },
    checkStaffInvolved(specialist) {
      const staffAssignments = Object.entries(this.staffAssignments);
      const geos = staffAssignments.map((el) => {
        return Object.entries(el[1]);
      });
      const staff = [];
      geos.forEach((geo) => {
        geo.forEach((el) => {
          if (el[0] !== "wordCount") staff.push(el[1]);
        });
      });
      return staff.some((el) => el?.[specialist]);
    },
    convertDeliveryDue() {
      if (!this.clientDeliveryDue || !this.timeZone) {
        this.conversionErrors = true;
      } else {
        this.conversionErrors = false;
        const clientDeliveryData = new Date(
          `${
            this.shakespeare || this.shakespeareLoc
              ? this.clientDeliveryDate
              : this.$moment(this.tableRows[0].due_date).format("YYYY-MM-DD")
          }T${this.$moment(this.clientDeliveryDue, "hh:mm A").format(
            "HH:mm"
          )}:00.000${this.timeZone.split(" ").at(-1)}`
        );
        const convertedDeliveryData = clientDeliveryData.toLocaleString(
          "en-US",
          {
            timeZone: "America/New_York",
          }
        );
        this.setFormValue({
          key: "deliveryDate",
          value: this.$moment(convertedDeliveryData).format("YYYY-MM-DD"),
        });
        this.setFormValue({
          key: "deliveryDue",
          value: clientDeliveryData.toLocaleString("en-US", {
            timeZone: "America/New_York",
            hour: "2-digit",
            minute: "2-digit",
          }),
        });
        if (this.formValues.urgent) {
          if (this.checkStaffInvolved("reviewer")) {
            let convertedReviewerData = this.$moment(
              convertedDeliveryData
            ).subtract(3, "hours");
            this.setFormValue({
              key: "reviewingDueDate",
              value: this.$moment(convertedReviewerData._d).format(
                "YYYY-MM-DD"
              ),
            });
            this.setFormValue({
              key: "reviewingDue",
              value: this.$moment(convertedReviewerData._d).format("hh:mm A"),
            });
          }
          if (this.checkStaffInvolved("editor")) {
            let convertedEditorData = this.$moment(
              convertedDeliveryData
            ).subtract(6, "hours");
            this.setFormValue({
              key: "editingDueDate",
              value: this.$moment(convertedEditorData._d).format("YYYY-MM-DD"),
            });
            this.setFormValue({
              key: "editingDue",
              value: this.$moment(convertedEditorData._d).format("hh:mm A"),
            });
          }
          let convertedWriterData = this.$moment(
            convertedDeliveryData
          ).subtract(9, "hours");
          this.setFormValue({
            key: "writingDueDate",
            value: this.$moment(convertedWriterData._d).format("YYYY-MM-DD"),
          });
          this.setFormValue({
            key: "writingDue",
            value: this.$moment(convertedWriterData._d).format("hh:mm A"),
          });
        } else {
          if (this.checkStaffInvolved("reviewer")) {
            let convertedReviewerData = this.$moment(
              convertedDeliveryData
            ).subtract(6, "hours");
            this.setFormValue({
              key: "reviewingDueDate",
              value: this.$moment(convertedReviewerData._d).format(
                "YYYY-MM-DD"
              ),
            });
            this.setFormValue({
              key: "reviewingDue",
              value: this.$moment(convertedReviewerData._d).format("hh:mm A"),
            });
          }
          if (this.checkStaffInvolved("editor")) {
            let convertedEditorData = this.$moment(
              convertedDeliveryData
            ).subtract(18, "hours");
            this.setFormValue({
              key: "editingDueDate",
              value: this.$moment(convertedEditorData._d).format("YYYY-MM-DD"),
            });
            this.setFormValue({
              key: "editingDue",
              value: this.$moment(convertedEditorData._d).format("hh:mm A"),
            });
          }
          let convertedWriterData = this.$moment(
            convertedDeliveryData
          ).subtract(24, "hours");
          this.setFormValue({
            key: "writingDueDate",
            value: this.$moment(convertedWriterData._d).format("YYYY-MM-DD"),
          });
          this.setFormValue({
            key: "writingDue",
            value: this.$moment(convertedWriterData._d).format("hh:mm A"),
          });
        }
      }
    },
    handleDueData(value, flow, timeMode) {
      this.setFormValue({ key: `${flow}Due${timeMode ? "" : "Date"}`, value });
      if (value)
        timeMode
          ? this.handleDueHoursValidation(flow, this[`${flow}DueHours`])
          : this.handleStaffDuesValidation();
    },
    handleDueHoursValidation(operation, updatedArray) {
      this[`${operation}DueHoursAvailable`] = !updatedArray.find(
        (el) => el.time === this.formValues[`${operation}Due`]
      )?.disabled;
    },
    handleDueHoursAvailability(operation) {
      if (
        this.formValues.deliveryDate &&
        this.formValues[`${operation}DueDate`] ===
          this.formValues.deliveryDate &&
        !this.formValues.override
      ) {
        return this.hoursList.map((el) => {
          if (
            this.hoursList.indexOf(el) >
            this.hoursList.findIndex(
              (el) => el.time === this.formValues.deliveryDue
            )
          ) {
            return Object.assign({}, { time: el.time, disabled: true });
          } else {
            return Object.assign({}, { time: el.time, disabled: false });
          }
        });
      } else if (
        this.$moment(this.formValues[`${operation}DueDate`]).isAfter(
          this.formValues.deliveryDate
        ) &&
        !this.formValues.override
      ) {
        return this.hoursList.map((el) => {
          return Object.assign({}, { time: el.time, disabled: true });
        });
      } else {
        return this.hoursList;
      }
    },
    handleStaffDuesValidation() {
      ["writing", "editing", "reviewing"].forEach((operation) => {
        if (!this.formValues.override) {
          if (
            this.formValues.deliveryDate &&
            this.formValues[`${operation}DueDate`]
          ) {
            this[`${operation}DueDateAvailable`] = this.$moment(
              this.formValues[`${operation}DueDate`]
            ).isSameOrBefore(this.formValues.deliveryDate);
            this.$refs[`${operation}DueDateField`].validate(true);
          }
        } else {
          this[`${operation}DueDateAvailable`] = true;
          this.$refs[`${operation}DueDateField`].validate(true);
        }
      });
    },
    validateIndiviadualGeoDeadlines(index, geo) {
      const source = this.formValues.individualGeosDeadlines[index][geo];
      let validationCheck = [];
      if (source)
        ["writing", "editing", "reviewing"].forEach((operation) => {
          if (!this.formValues.override) {
            if (this.formValues.deliveryDate && source[`${operation}DueDate`]) {
              validationCheck.push(
                this.$moment(source[`${operation}DueDate`]).isSameOrBefore(
                  this.formValues.deliveryDate
                )
              );
            }
          } else {
            validationCheck.push(true);
          }
          validationCheck.push(
            ![`${operation}DueHours`].find(
              (el) => el.time === source[`${operation}Due`]
            )?.disabled
          );
        });
      return validationCheck.every((el) => el === true);
    },
    getDatesRange(startDate, endDate) {
      const datesRange = [];
      for (
        const dateVar = new Date(startDate);
        dateVar <= endDate;
        dateVar.setDate(dateVar.getDate() + 1)
      ) {
        datesRange.push(this.$moment(new Date(dateVar)).format("YYYY-MM-DD"));
      }
      return datesRange;
    },
    handleWorkTypesPrepopulation(key, value, specialGeo) {
      const specailGeosData = {
          workType2Quantity: {
            "ar-SA": "1",
            "pt-PT": "1",
            "ru-RU": "1",
            "uk-UA": "1",
            "es-ES": "1",
            "es-MX": "1",
            "it-IT": "1",
            "nl-NL": "1",
            "de-DE": "1",
          },
          workType4Quantity: {
            "es-ES": "1",
            "es-MX": "1",
            "it-IT": "1",
            "nl-NL": "1",
            "de-DE": "1",
          },
        },
        handleSpecialGeosData = (key, geo, initialValue) => {
          return specailGeosData?.[key]?.[geo] ?? initialValue;
        };
      let newAssignments = { ...this.staffAssignments },
        idGeoPairs = [];
      Object.entries(newAssignments).forEach((el) => {
        const geo = Object.keys(el[1]).find(
          (key) => key !== "content_id" || key !== "task_name"
        );
        this.backstageCopywriting
          ? Object.keys(el[1]).forEach((geo) => idGeoPairs.push([el[0], geo]))
          : idGeoPairs.push([el[0], geo]);
      });
      idGeoPairs.forEach(
        (el) =>
          (newAssignments[el[0]][el[1]][key] = specialGeo
            ? handleSpecialGeosData(key, el[1], value)
            : value)
      );
      this.setStaffAssignments(newAssignments);
    },
    prepopulationProcessor(el) {
      if (el.key === "relatedProject") {
        getAllProjectNames("iTunes")
          .then((data) => {
            const projectID = data.find((item) => item.name === el.value).id;
            this.setFormValue({
              key: el.key,
              value: { name: el.value, id: projectID },
            });
            this.updateRelatedProjectSmartFields({
              name: el.value,
              id: projectID,
            });
          })
          .catch((err) => console.error(err));
      } else {
        this.setFormValue({
          key: el.key,
          value: el.value,
        });
      }
    },
    handlePrepopulation(prepopulationFlow) {
      this.setPrepopulation(prepopulationFlow);
      switch (prepopulationFlow) {
        case 0:
          return [
            {
              key: "relatedProject",
              value: "iTunes FC",
            },
            {
              key: "mediaType",
              value: "Music",
            },
            {
              key: "clientRequester",
              value: "Shakespeare",
            },
            {
              key: "workType1Reference",
              value: "1",
            },
            {
              key: "workType2Reference",
              value: "2",
            },
            {
              key: "workType2Quantity",
              value: "",
            },
            {
              key: "workType3Reference",
              value: "3",
            },
            {
              key: "workType4Reference",
              value: "",
            },
            {
              key: "workType4Quantity",
              value: "",
            },
            {
              key: "workType5Reference",
              value: "41",
            },
            {
              key: "workType6Reference",
              value: "75",
            },
          ].forEach((el) =>
            el.key.startsWith("workType")
              ? this.handleWorkTypesPrepopulation(el.key, el.value, true)
              : this.prepopulationProcessor(el)
          );
        case 1:
          return [
            {
              key: "relatedProject",
              value: "iTunes FC",
            },
            {
              key: "mediaType",
              value: "Music",
            },
            {
              key: "clientRequester",
              value: "Shakespeare",
            },
            {
              key: "workType1Reference",
              value: "1",
            },
            {
              key: "workType2Reference",
              value: "2",
            },
            {
              key: "workType2Quantity",
              value: "",
            },
            {
              key: "workType3Reference",
              value: "3",
            },
            {
              key: "workType4Reference",
              value: "37",
            },
            {
              key: "workType4Quantity",
              value: "",
            },
            {
              key: "workType5Reference",
              value: "41",
            },
            {
              key: "workType6Reference",
              value: "75",
            },
          ].forEach((el) =>
            el.key.startsWith("workType")
              ? this.handleWorkTypesPrepopulation(el.key, el.value)
              : this.prepopulationProcessor(el)
          );
        case 2:
          return [
            {
              key: "relatedProject",
              value: "iTunes FC",
            },
            {
              key: "mediaType",
              value: "Video",
            },
            {
              key: "clientRequester",
              value: "Shakespeare",
            },
            {
              key: "workType1Reference",
              value: "1",
            },
            {
              key: "workType2Reference",
              value: "2",
            },
            {
              key: "workType2Quantity",
              value: "",
            },
            {
              key: "workType3Reference",
              value: "3",
            },
            {
              key: "workType4Reference",
              value: "37",
            },
            {
              key: "workType4Quantity",
              value: "",
            },
            {
              key: "workType5Reference",
              value: "41",
            },
            {
              key: "workType6Reference",
              value: "75",
            },
          ].forEach((el) =>
            el.key.startsWith("workType")
              ? this.handleWorkTypesPrepopulation(el.key, el.value)
              : this.prepopulationProcessor(el)
          );
        default:
          return [
            "relatedProject",
            "po",
            "mediaType",
            "clientRequester",
            "emailSubjectLine",
            "workType1Reference",
            "workType2Reference",
            "workType2Quantity",
            "workType3Reference",
            "workType4Reference",
            "workType4Quantity",
            "workType5Reference",
            "workType6Reference",
          ].forEach((el) => {
            el.startsWith("workType")
              ? this.handleWorkTypesPrepopulation(el)
              : this.setFormValue({
                  key: el,
                  value: "",
                });
          });
      }
    },
    getProjectNames() {
      this.projectNameItemsLoading = true;
      getAllProjectNames("iTunes")
        .then((data) => {
          this.projectNameItems = data;
          this.projectNameItemsLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.projectNameItemsLoading = false;
        });
    },
    getFileLocations() {
      this.fileLocationsLoading = true;
      getFieldInfosFromDB({
        fieldID: 200,
        tableID: appIDs.iTunesProjectManager.assignments,
      })
        .then((data) => {
          this.fileLocations = data;
          this.fileLocationsLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.fileLocationsLoading = false;
        });
    },
    updateRelatedProjectSmartFields(selectedProject) {
      this.setFormValue({
        key: "relatedProject",
        value: selectedProject,
      });
      this.getShakespeareMediaTypes(selectedProject.id);
      this.poLoading = true;
      getiTunesFCPONumber(selectedProject.name)
        .then((data) => {
          this.setFormValue({
            key: "po",
            value: selectedProject.name
              ? Object.values(data.data[0])[0].value
              : "",
          });
          this.poLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.poLoading = false;
        });
    },
    getShakespeareMediaTypes(id) {
      this.mediaTypesLoading = true;
      getMediaTypeChoices({ instance: "iTunes", shakespeareProjectID: id })
        .then((data) => {
          this.mediaTypes = data;
          this.mediaTypesLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.mediaTypesLoading = false;
        });
    },
    handlePayPeriod(dateInput) {
      if (!this.payPeriod) {
        this.payPeriod = dateInput.slice(0, -3);
      }
    },
    setFCStaffNames() {
      this.setNamesLoading(true);
      getFCStaffDatas()
        .then((datas) => {
          this.staffNames = datas.map((el) => {
            return { ...el, disabled: false };
          });
          this.setStaffNameGEOPairs(datas);
          this.setNamesLoading(false);
        })
        .catch((err) => console.error(err));
    },
    setCapacityDatas() {
      getReportData({
        reportID: 119,
        tableID: appIDs.cueStaff.contacts,
      })
        .then((data) => {
          this.updateCapacityData({
            dataPiece: "defaultCapacityPool",
            newData: data,
          });
        })
        .catch((err) => console.error(err));
      getReportData({
        reportID: 13,
        tableID: appIDs.masterAssignments.assignments,
      })
        .then((data) => {
          this.updateCapacityData({ dataPiece: "capacityPool", newData: data });
        })
        .catch((err) => console.error(err));
      getAvailabilityRecords({
        startingFrom: this.$moment(new Date()).format("MM-DD-YYYY"),
      })
        .then((data) => {
          const availabilityRecords = data.map((el) => ({
            name: el["Name"],
            dates: this.getDatesRange(
              this.$moment(el["Start Date"]),
              this.$moment(el["End Date"])
            ),
            adjustedWritingWorkload:
              el["Adjusted Daily Workload - Localization"],
            adjustedEditingWorkload: el["Adjusted Daily Workload - Editing"],
          }));
          this.updateCapacityData({
            dataPiece: "adjustedCapacityPool",
            newData: availabilityRecords,
          });
        })
        .catch((err) => console.error(err));
    },
    handleIndiviadualGeoDeadlines(editMode, index) {
      this.indiviadualGeoDeadlinesEdit = editMode;
      this.indiviadualGeoDeadlinesItemIndex = index;
      this.$refs.geoDeadlinesForm?.$refs.form.resetValidation();
      this.indiviadualGeoDeadlines = true;
    },
    closeIndiviadualGeoDeadlines() {
      this.indiviadualGeoDeadlines = false;
      this.indiviadualGeoDeadlinesItemIndex = null;
    },
  },
  mixins: [timeUnits],
  computed: {
    ...mapGetters("fcBot", [
      "files",
      "tableRows",
      "formValues",
      "staffAssignments",
      "staffNameGEOPairs",
      "xContent",
      "shakespeare",
      "shakespeareLoc",
      "backstageCopywriting",
    ]),
    ...mapGetters("auth", ["user"]),
    assignmentTitles() {
      return this.shakespeare || this.backstageCopywriting
        ? [...this.amAssignmentTitles, ...this.fcAssignmentTitles].sort()
        : this.shakespeareLoc
        ? ["Arcade File", "Discovery File", "MAS File", "Today File"]
        : this.fcAssignmentTitles;
    },
    deliveryDate() {
      return this.formValues.deliveryDate;
    },
    override() {
      return this.formValues.override;
    },
    writingDueHours() {
      return this.handleDueHoursAvailability("writing");
    },
    editingDueHours() {
      return this.handleDueHoursAvailability("editing");
    },
    reviewingDueHours() {
      return this.handleDueHoursAvailability("reviewing");
    },
    writersValidated() {
      let writersValidation = [];
      Object.entries(this.staffAssignments).forEach((el) => {
        Object.entries(el[1]).forEach((item) => {
          if (typeof item[1] === "object" && !!!item[1].writer)
            writersValidation.push({
              assignment: this.shakespeare ? el[1].task_name : el[0],
              geos: item[0],
            });
        });
      });
      writersValidation = _.uniqWith(writersValidation, (pre, cur) => {
        if (pre.assignment == cur.assignment) {
          cur.geos = cur.geos + ", " + pre.geos;
          return true;
        }
        return false;
      });
      return writersValidation;
    },
    overloadingValidated() {
      const overloadingValidation = [];
      Object.values(this.staffAssignments).forEach((el) => {
        Object.entries(el).forEach((geo) => {
          if (
            this.shakespeare
              ? geo[0] !== "task_name" && geo[0] !== "content_id"
              : geo[0] !== "wordCount"
          )
            overloadingValidation.push(
              geo[1]?.writerOverloaded,
              geo[1]?.editorOverloaded,
              geo[1]?.reviewerOverloaded
            );
        });
      });
      return overloadingValidation.every((el) => el === false);
    },
    fullFCDashboardPath() {
      return `https://${DOMAIN}/${ROUTES.fcDashboard}`;
    },
    spreadsheetPath() {
      return `https://docs.google.com/spreadsheets/d/${SPREADSHEET_ID}/edit#gid=638440309`;
    },
  },
  mounted() {
    this.setFCStaffNames();
    this.getProjectNames();
    this.getFileLocations();
    this.setCapacityDatas();
  },
};
</script>

<style lang="scss" scoped>
.fc-info-form {
  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }

  &__main-form {
    box-sizing: border-box;
    position: relative;

    .row {
      width: 100%;
      margin-left: 0px;
      border-radius: 4px;
      & * {
        width: 50%;
      }
    }

    &__staff-section {
      &__button-container {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

#indiv-geo .indiv-geo {
  &-title {
    width: fit-content;
  }
  &-badge {
    width: unset;
  }
  &-btn {
    width: fit-content;
    text-transform: none !important;
  }
}

::v-deep {
  .success-alert {
    & .v-alert {
      &__content {
        display: flex;
        align-items: center;
      }
      &__icon {
        align-self: center;
      }
    }
  }
  .v-badge__badge .v-icon {
    margin: -2px -2px 0;
  }
}
</style>
