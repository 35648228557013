<template>
  <div class="issue-tracker my-10 px-2">
    <div class="d-flex align-end mb-4">
      <!-- left -->
      <div class="text-center">
        <span class="overline"> Report </span>
        <div>
          <v-btn
            height="38px"
            min-width="42px"
            class="elevation-2 px-0"
            color="secondary"
            :loading="showTableLoader"
            @click="downloadReport"
          >
            <v-icon>mdi-tray-arrow-down</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="d-flex align-end">
        <v-dialog
          transition="dialog-top-transition"
          max-width="1500px"
          hide-overlay
          persistent
          no-click-animation
        >
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              bordered
              color="accent"
              overlap
              :value="filtersBadge"
              :content="filtersBadge"
              ><v-btn
                height="38px"
                color="#494949"
                class="ml-3"
                dark
                v-bind="attrs"
                v-on="on"
                >Add Filters</v-btn
              ></v-badge
            >
            <v-btn
              height="38px"
              color="secondary"
              class="ml-3"
              @click="clearFilters(false)"
              :disabled="!filtersBadge"
              >Clear Filters</v-btn
            >
          </template>
          <template v-slot:default="dialog">
            <v-card
              class="d-flex justify-space-around flex-wrap px-6 pt-md-2 pt-lg-4 pb-md-4 pb-lg-6"
            >
              <!--  -->
              <div class="text-center">
                <span class="overline"> Request Date(s) </span>
                <div class="d-flex align-end mb-4">
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    solo-inverted
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filterDateRange"
                        class="issue-tracker__date-selector selector"
                        solo
                        dense
                        readonly
                        hide-details
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="clearFilters(true)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterDate"
                      range
                      @input="handleFilterQuery('Date', $event)"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>

              <!--  -->
              <div class="ml-lg-0 ml-md-2 text-center">
                <span class="overline"> Project </span>
                <div>
                  <v-btn-toggle
                    class="elevation-2"
                    color="accent"
                    multiple
                    dense
                    v-model="filterProject"
                    @change="handleFilterQuery('Project', $event)"
                  >
                    <v-btn height="38px">FC</v-btn>
                    <v-btn height="38px">Music</v-btn>
                  </v-btn-toggle>
                </div>
              </div>

              <!--  -->
              <div class="ml-lg-0 ml-md-2 text-center">
                <span class="overline"> Media Type </span>
                <div class="mb-4">
                  <v-autocomplete
                    class="issue-tracker__main-selector selector"
                    :items="mediaTypes"
                    v-model="filterMedia"
                    solo
                    dense
                    hide-details
                    @input="handleFilterQuery('Media', $event)"
                  ></v-autocomplete>
                </div>
              </div>

              <!--  -->
              <div class="ml-lg-0 ml-md-2 text-center">
                <span class="overline"> Issue Type </span>
                <div class="mb-4">
                  <v-autocomplete
                    class="issue-tracker__type-selector selector"
                    :items="types"
                    v-model="filterType"
                    solo
                    dense
                    hide-details
                    @input="handleFilterQuery('Type', $event)"
                  ></v-autocomplete>
                </div>
              </div>

              <!--  -->
              <div class="ml-lg-0 ml-md-2 text-center">
                <span class="overline"> Issue Status </span>
                <div class="mb-4">
                  <v-autocomplete
                    class="issue-tracker__status-selector selector"
                    :items="statuses"
                    v-model="filterStatus"
                    item-text="value"
                    solo
                    dense
                    hide-details
                    @input="handleFilterQuery('Status', $event)"
                  ></v-autocomplete>
                </div>
              </div>

              <!--  -->
              <div class="ml-lg-0 ml-md-2 text-center">
                <span class="overline"> GEO </span>
                <div class="mb-4">
                  <v-autocomplete
                    class="issue-tracker__geo-selector selector"
                    :items="geos"
                    v-model="filterGeo"
                    solo
                    dense
                    hide-details
                    @input="handleFilterQuery('Geo', $event)"
                  ></v-autocomplete>
                </div>
              </div>

              <!--  -->
              <div class="ml-lg-0 ml-md-2 text-center">
                <span class="overline"> Person / Role </span>
                <div class="d-flex mb-4">
                  <v-autocomplete
                    class="issue-tracker__person-selector selector mr-2"
                    :items="employees"
                    v-model="filterEmployee"
                    solo
                    dense
                    hide-details
                    @input="handleFilterQuery('Employee', $event)"
                  ></v-autocomplete>
                  <v-autocomplete
                    class="issue-tracker__role-selector selector"
                    :items="roles"
                    v-model="filterRole"
                    solo
                    dense
                    hide-details
                    :disabled="!filterEmployee"
                    @input="handleFilterQuery('Role', $event)"
                  >
                  </v-autocomplete>
                </div>
              </div>

              <!--  -->
              <div
                class="text-center"
                :class="[
                  { 'ml-2': laptop },
                  laptopSmaller ? 'ml-2' : ['d-flex', 'align-end'],
                ]"
              >
                <span v-if="laptopSmaller" class="overline"> Search </span>
                <v-text-field
                  v-model="filterSearch"
                  class="issue-tracker__search-selector selector mb-4"
                  :prepend-icon="laptopSmaller ? '' : 'mdi-magnify'"
                  solo
                  dense
                  hide-details
                  clearable
                />
              </div>
              <v-card-actions class="ml-auto pa-0 align-end">
                <v-btn
                  color="secondary"
                  @click="clearFilters(false)"
                  :disabled="!filtersBadge"
                  >Clear Filters</v-btn
                >
                <v-btn color="#494949" dark @click="dialog.value = false"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>

        <!-- bulk status assign -->
        <div class="ml-6 text-center">
          <span class="overline"> Bulk Status Assign </span>
          <div class="d-flex align-end">
            <v-autocomplete
              v-model="bulkStatusValue"
              class="issue-tracker__main-selector selector"
              solo
              dense
              hide-details
              :items="statuses"
              item-text="value"
              :disabled="!selectedDashboardRows.length"
              @input="handleBulkStatusSelect"
            ></v-autocomplete>
          </div>
        </div>
      </div>

      <!-- right -->
      <div class="ml-auto">
        <v-text-field
          v-model="filterSearch"
          class="issue-tracker__search-selector selector"
          append-outer-icon="mdi-magnify"
          solo
          dense
          hide-details
          clearable
        />
      </div>
    </div>
    <!-- table -->
    <v-card class="issue-tracker__table-card">
      <v-skeleton-loader
        v-if="showTableLoader"
        type="table"
        height="100vh"
      ></v-skeleton-loader>
      <IssueTrackerTable
        ref="issueTrackerTable"
        v-if="tableData && !showTableLoader"
        :tableData="tableData"
        :tableFields="tableFields"
        :filterDate="filterDate"
        :filterProject="filterProject"
        :filterSearch="filterSearch"
        :filterMedia="filterMedia ? filterMedia : ''"
        :filterEmployee="filterEmployee ? filterEmployee : ''"
        :filterRole="filterRole ? filterRole : ''"
        :filterGeo="filterGeo ? filterGeo : ''"
        :filterType="filterType ? filterType : ''"
        :filterStatus="filterStatus ? filterStatus : ''"
        :isStatusUpdating="isStatusUpdating"
        :issueTypes="types"
        :issueStatuses="statuses"
        :dropdownOptionsLoading="dropdownOptionsLoading"
      />
    </v-card>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import {
  getBugReports,
  updateBugReports,
  appIDs,
  getFieldInfosFromDB,
  getMediaTypeChoices,
  getEmployeesData,
} from "@/utils/quickbaseUtils";
import { generateReport } from "@/utils/issueTrackerUtils";
import { customBreakpoint } from "@/utils/mixins";
import { STATUS_COLORS, ROUTES } from "@/utils/constants";
// components
import IssueTrackerTable from "@/components/IssueTracker/IssueTrackerTable";

export default {
  name: "IssueTracker",
  components: {
    IssueTrackerTable,
  },
  data() {
    return {
      bulkStatusValue: "",
      showTableLoader: false,
      tableData: null,
      tableFields: null,
      filterDate: [],
      filterSearch: "",
      filterProject: [0, 1],
      expandMenu: false,
      employees: [],
      types: [],
      filterType: "",
      mediaTypes: [],
      filterMedia: "",
      statuses: [],
      filterStatus: "",
      employees: [],
      filterEmployee: "",
      roles: ["Writer", "Editor", "Reviewer"],
      filterRole: "",
      geos: [],
      filterGeo: "",
      isStatusUpdating: false,
      dropdownOptionsLoading: true,
    };
  },
  computed: {
    ...mapGetters("fcBot", ["selectedDashboardRows"]),
    filtersBadge() {
      return Object.entries(this.$route.query).filter((el) => el[1] !== null)
        .length;
    },
    filterDateRange: {
      get: function () {
        return this.filterDate.join(" - ");
      },
      set: function (newVal) {
        return (newVal ? [...newVal] : []).join(" - ");
      },
    },
  },
  mixins: [customBreakpoint],
  watch: {
    filterSearch(newValue) {
      const currentQuery = this.$route.query;
      const newQuery =
        newValue !== ""
          ? {
              ...currentQuery,
              Search: newValue,
            }
          : _.omit(currentQuery, ["Search"]);

      this.$router
        .push({ path: `/${ROUTES.issueTracker}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
    filterEmployee(newValue) {
      if (newValue === null || newValue === "") this.filterRole = "";
    },
  },
  mounted() {
    this.handleQuery();
    this.getTableData();
    this.getIssuesDropdowns();
    this.getMediaTypes();
    this.getEmployees();
    this.getGeos();
  },
  methods: {
    ...mapActions("fcBot", ["setSelectedDashboardRows"]),
    getTableData() {
      this.showTableLoader = true;
      getBugReports().then((resp) => {
        this.tableData = resp.data.map((el) => {
          return {
            ...el,
            DateReported: this.$moment(el.DateReported).format("MM/DD/YYYY"),
          };
        });
        this.tableFields = resp.fields;
        this.showTableLoader = false;
      });
    },
    handleFilterQuery(param, data) {
      const currentQuery = this.$route.query;

      const newQuery = {
        ...currentQuery,
        [param]: data,
      };
      this.$router
        .push({ path: `/${ROUTES.issueTracker}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler

      this[`filter${param}`] = data;
    },
    clearFilters(dateOnly) {
      let query = this.$route.query;

      if (dateOnly) {
        query = _.omit(query, ["Date"]);
      } else {
        this.filterProject = [0, 1];
        query =
          this.filterType =
          this.filterMedia =
          this.filterStatus =
          this.filterEmployee =
          this.filterGeo =
          this.filterSearch =
            "";
      }

      this.filterDate = [];

      this.$router
        .push({ path: `/${ROUTES.issueTracker}`, query })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
    handleBulkStatusSelect(status) {
      this.isStatusUpdating = true;
      updateBugReports({
        issues: this.selectedDashboardRows,
        status: status,
      })
        .then(() => {
          this.isStatusUpdating = false;
          this.bulkStatusValue = "";
          this.getTableData();
          this.setSelectedDashboardRows([]);
        })
        .catch((err) => {
          console.error(err);
          this.isStatusUpdating = false;
        });
    },
    getIssuesDropdowns() {
      getFieldInfosFromDB({
        fieldID: 14,
        tableID: appIDs.iTunesProjectManager.issueTracker,
      })
        .then((data) => {
          this.types = data;
          this.dropdownOptionsLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.dropdownOptionsLoading = false;
        });
      getFieldInfosFromDB({
        fieldID: 8,
        tableID: appIDs.iTunesProjectManager.issueTracker,
      })
        .then((data) => {
          this.statuses = data.map((el) => {
            return {
              value: el,
              color: STATUS_COLORS.find((color) => color.statuses.includes(el))
                ?.value,
            };
          });
          this.dropdownOptionsLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.dropdownOptionsLoading = false;
        });
    },
    getMediaTypes() {
      getMediaTypeChoices({ instance: "iTunes" })
        .then((data) => {
          this.mediaTypes = data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getEmployees() {
      getEmployeesData({ namesOnly: true })
        .then((names) => {
          this.employees = names;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getGeos() {
      getFieldInfosFromDB({
        fieldID: 44,
        tableID: appIDs.iTunesProjectManager.assignments,
      })
        .then((geos) => {
          this.geos = geos;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleQuery() {
      const currentQuery = this.$route.query;

      if (!_.isEmpty(currentQuery))
        Object.keys(currentQuery).forEach((param) => {
          if (param === "Date") {
            this[`filter${param}`] =
              typeof currentQuery[param] === "string"
                ? [currentQuery[param]]
                : currentQuery[param];
          } else if (param === "Project") {
            this[`filter${param}`] =
              typeof currentQuery[param] === "string"
                ? [Number(currentQuery[param])]
                : currentQuery[param].map((x) => Number(x));
          } else {
            this[`filter${param}`] = currentQuery[param];
          }
        });
    },
    downloadReport() {
      generateReport(
        this.$refs.issueTrackerTable._computedWatchers.filteredData.value
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.issue-tracker {
  max-width: 1500px;
  margin: 0 auto;

  &__date-selector {
    width: 220px;
  }

  &__type-selector {
    width: 280px;
  }

  &__status-selector {
    width: 200px;
  }

  &__main-selector {
    width: 160px;
  }

  &__person-selector {
    width: 170px;
  }

  &__role-selector {
    width: 110px;
  }

  &__geo-selector {
    width: 100px;
  }

  &__search-selector {
    width: 500px;
  }
}

.v-dialog__content {
  height: 200px;
}

::v-deep {
  .selector.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding-right: 0;
  }
  .selector.v-text-field.v-text-field--solo .v-input__append-inner {
    padding-left: 0;
  }
  .selector.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0;
  }
  .selector.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 2px 0;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'lg-and-down')} {
  .v-application .overline {
    font-size: 0.6rem !important;
  }
  .v-dialog__content {
    height: 270px;
  }
}
</style>
