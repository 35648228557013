<template>
  <div class="request">
    <div class="client-selector" v-if="isAdminView">
      <v-container class="client-selector__container">
        <v-row>
          <v-col class="d-flex selector-column" cols="12" sm="10">
            <v-select
              :items="clientNames"
              label="Client"
              v-model="selectedCompany"
              outlined
            />
          </v-col>
          <v-col class="d-flex button-column" cols="12" sm="2">
            <v-btn
              color="primary"
              class="typeforms-button"
              @click="handleTypeformsTableButton"
            >
              Typeforms
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-card v-if="selectedTypeformID">
      <Typeform :id="selectedTypeformID" />
    </v-card>
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// internal
import { getTypeformDataset } from "@/utils/dbUtils";
import { PASSTHROUGH_ENV } from "@/utils/constants";
import { ROUTES } from "@/utils/constants";
// component
import Typeform from "../components/Typeform/Typeform.vue";

export default {
  name: "Request",
  data() {
    return {
      allTypeformInfo: null,
      clientNames: [],
      selectedCompany: null,
      selectedTypeformID: null,
    };
  },
  components: { Typeform },
  watch: {
    selectedCompany: function (newValue) {
      if (!this.allTypeformInfo || !newValue) return null;
      this.selectedTypeformID = this.allTypeformInfo.find(
        (el) => el.company === this.selectedCompany
      ).typeform_id;
    },
  },
  methods: {
    handleTypeformsTableButton() {
      this.$router.push(`/${ROUTES.typeforms}`);
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isAdmin", "isManager", "company"]),
    isAdminView() {
      return (
        this.isAdmin ||
        this.isManager ||
        process.env.NODE_ENV === PASSTHROUGH_ENV
      );
    },
  },
  mounted() {
    // here is where we get all the clients with typeforms
    getTypeformDataset().then((data) => {
      this.allTypeformInfo = data;
      this.clientNames = data.map((el) => el.company);

      if (!this.isAdminView) {
        this.selectedCompany = this.company;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.request {
  max-width: 1400px;
  margin: 0 auto;

  .client-selector {
    &__container {
      display: flex;

      .button-column {
        .typeforms-button {
          height: 56px;
        }
      }
    }
  }
}
</style>
