<template>
  <v-expansion-panel class="fc-staff-expansion-panel grey lighten-4">
    <v-expansion-panel-header
      v-if="!xContent && !shakespeareLoc"
      class="fc-staff-expansion-panel__header"
    >
      <span>
        {{ groupItem }}
        <v-chip class="identifier-count-chip white" small label>
          {{ identifierCount }}
        </v-chip></span
      >
    </v-expansion-panel-header>
    <v-expansion-panel-content
      ref="fcStaffPanel"
      class="fc-staff-expansion-panel__content"
      :class="{ 'pt-5': xContent || shakespeareLoc }"
    >
      <div
        v-for="panelItem in panelGroup"
        :key="panelItem"
        class="fc-staff-expansion-panel__content__row"
        :class="[
          shakespeare || backstageCopywriting
            ? [
                'flex-wrap',
                'grey',
                'lighten-5',
                'rounded-lg',
                'elevation-1',
                'pt-6',
                'pb-2',
                'mb-4',
                'px-4',
              ]
            : '',
        ]"
      >
        <div
          v-if="shakespeare || backstageCopywriting"
          class="pb-4 d-flex justify-center align-center"
        >
          {{ panelItem }}
          <v-btn
            v-if="shakespeare"
            class="white ml-4"
            x-small
            @click="() => handleApplyToAll(panelItem)"
            >Apply to all [GEO]</v-btn
          >
          <v-btn
            v-if="shakespeare || backstageCopywriting"
            class="white"
            :class="backstageCopywriting ? 'ml-4' : 'ml-2'"
            x-small
            @click="() => handleApplyToAll(panelItem, true)"
            >Apply to all [Batch]</v-btn
          >
        </div>
        <v-row class="align-center justify-center">
          <v-col
            v-if="!shakespeare && !backstageCopywriting"
            cols="16"
            sm="1"
            class="text-center"
            >{{ panelItem }}
          </v-col>
          <v-col
            :cols="!shakespeare && !backstageCopywriting ? 16 : 12"
            :sm="!shakespeare && !backstageCopywriting ? 3 : 4"
          >
            <v-autocomplete
              label="Related Writer"
              :items="
                shakespeare
                  ? staffNamesForGeo(groupItem, formValues.writingDueDate)
                  : staffNamesForGeo(panelItem, formValues.writingDueDate)
              "
              :value="
                getValue(
                  panelItem,
                  groupItem,
                  'writer',
                  formValues.writingDueDate
                )
              "
              :loading="namesLoading"
              clearable
              item-text="name"
              @change="
                (v) =>
                  handleValueSelectChange(panelItem, 'writer', v, groupItem)
              "
              @click:clear="
                handleOverloadingReset(panelItem, 'writer', groupItem)
              "
              :rules="[rules.availabile('writer')]"
            ></v-autocomplete>
          </v-col>
          <v-col
            :cols="!shakespeare && !backstageCopywriting ? 16 : 12"
            :sm="!shakespeare && !backstageCopywriting ? 3 : 4"
          >
            <v-autocomplete
              label="Related Editor"
              :items="
                shakespeare
                  ? staffNamesForGeo(groupItem, formValues.editingDueDate)
                  : staffNamesForGeo(panelItem, formValues.editingDueDate)
              "
              :value="
                getValue(
                  panelItem,
                  groupItem,
                  'editor',
                  formValues.editingDueDate
                )
              "
              :loading="namesLoading"
              clearable
              item-text="name"
              @change="
                (v) =>
                  handleValueSelectChange(panelItem, 'editor', v, groupItem)
              "
              @click:clear="
                handleOverloadingReset(panelItem, 'editor', groupItem)
              "
              :rules="[rules.availabile('editor')]"
            ></v-autocomplete>
          </v-col>
          <v-col
            :cols="!shakespeare && !backstageCopywriting ? 16 : 12"
            :sm="!shakespeare && !backstageCopywriting ? 3 : 4"
          >
            <v-autocomplete
              label="Reviewer"
              :items="
                shakespeare
                  ? staffNamesForGeo(groupItem, formValues.reviewingDueDate)
                  : staffNamesForGeo(panelItem, formValues.reviewingDueDate)
              "
              :value="
                getValue(
                  panelItem,
                  groupItem,
                  'reviewer',
                  formValues.reviewingDueDate
                )
              "
              :loading="namesLoading"
              clearable
              item-text="name"
              @change="
                (v) =>
                  handleValueSelectChange(panelItem, 'reviewer', v, groupItem)
              "
              :rules="[rules.availabile('reviewer')]"
            ></v-autocomplete>
          </v-col>
          <v-col
            v-if="
              !xContent &&
              !shakespeareLoc &&
              !shakespeare &&
              !backstageCopywriting
            "
            cols="16"
            sm="2"
            class="text-center"
          >
            <v-btn
              class="white"
              x-small
              @click="() => handleApplyToAll(panelItem)"
              >Apply to all [GEO]</v-btn
            >
          </v-col>
        </v-row>
        <v-row v-if="shakespeare || backstageCopywriting">
          <v-col v-for="i in [1, 2, 3, 4, 5, 6]" :key="i" cols="12" sm="2">
            <v-autocomplete
              class="py-2"
              :label="`Work Type ${i} Reference`"
              :clearable="i !== 1"
              :items="getReferencesOptions(i, panelItem)"
              :value="getValue(panelItem, groupItem, `workType${i}Reference`)"
              :disabled="adaptationMode(panelItem)"
              @change="
                (v) =>
                  handleValueSelectChange(
                    panelItem,
                    `workType${i}Reference`,
                    v,
                    groupItem
                  )
              "
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="shakespeare || backstageCopywriting">
          <v-col v-for="i in [1, 2, 3, 4, 5, 6]" :key="i" cols="12" sm="2">
            <v-text-field
              class="py-2"
              :label="`Work Type ${i} Quantity`"
              type="number"
              :clearable="i !== 1"
              :value="getValue(panelItem, groupItem, `workType${i}Quantity`)"
              :disabled="adaptationMode(panelItem)"
              :rules="[rules.negativeCheck]"
              @change="
                (v) =>
                  handleValueSelectChange(
                    panelItem,
                    `workType${i}Quantity`,
                    v,
                    groupItem
                  )
              "
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// library
import _ from "lodash";
// internal
import { isAdaptation, getFCWorkType } from "@/utils/fcBotUtils";

export default {
  name: "FCStaffExpansionPanel",
  props: {
    groupItem: String,
    data: Object,
    staffNames: Array,
    identifierCount: Number,
    workType1References: Array,
    workType2References: Array,
    workType3References: Array,
    workType4References: Array,
    workType5References: Array,
    workType6References: Array,
  },
  data() {
    return {
      generatorGroupItem: "",
      generatorPanelItem: "",
      rules: {
        availabile: (specialist) =>
          !this[`${specialist}Unavailable`] ||
          `Unavailable on selected due date for ${specialist}.`,
        negativeCheck: (value) => value >= 0 || "Quantity can't be nagative.",
      },
    };
  },
  methods: {
    ...mapActions("fcBot", [
      "setStaffAssignments",
      "setStaffAssignmentOverloading",
    ]),
    getValue(panelItem, groupItem, key, date) {
      const keyItem = this.shakespeare
        ? this.staffAssignments[
            this.findShakespeareAssignment(panelItem, groupItem)
          ][groupItem]?.[key]
        : this.staffAssignments[this.groupItem][panelItem]?.[key];

      if (key === "writer" || key === "editor" || key === "reviewer")
        if (keyItem && !this.formValues.overrideStaff) {
          const staffNames = this.staffNamesForGeo(
            this.shakespeare ? this.groupItem : panelItem,
            date
          );
          this[`${key}Unavailable`] = staffNames.find(
            (el) => el.name === keyItem
          )?.disabled;
        } else this[`${key}Unavailable`] = false;
      this.setStaffAssignmentOverloading({
        taskName: this.shakespeare ? panelItem : this.groupItem,
        geo: this.shakespeare ? groupItem : panelItem,
        key: `${key}Overloaded`,
        value: this[`${key}Unavailable`],
      });

      if (this.shakespeare) {
        return this.findShakespeareAssignment(panelItem, groupItem, key, true);
      } else {
        if (this.backstageCopywriting && key.includes("workType")) {
          return isAdaptation({
            locale: panelItem,
            allLocales: this.tableRows ? this.tableRows[0].geos : [],
          })
            ? key === "workType1Reference"
              ? getFCWorkType({
                  locale: panelItem,
                  allLocales: this.tableRows ? this.tableRows[0].geos : [],
                  workType1: true,
                })
              : ""
            : keyItem;
        }
        return keyItem;
      }
    },
    referencesByRequestType(panelItem) {
      return this.adaptationMode(panelItem)
        ? [
            getFCWorkType({
              locale: panelItem,
              allLocales: this.tableRows ? this.tableRows[0].geos : [],
              workType1: true,
            }),
          ]
        : this.workType1References;
    },
    adaptationMode(panelItem) {
      return this.backstageCopywriting
        ? isAdaptation({
            locale: panelItem,
            allLocales: this.tableRows ? this.tableRows[0].geos : [],
          })
        : false;
    },
    getReferencesOptions(i, panelItem) {
      if (i === 1) return this.referencesByRequestType(panelItem);
      else if (i === 4)
        return this.prepopulationFlow === 0
          ? ["70", "71"]
          : this.workType4References;
      else return this[`workType${i}References`];
    },
    staffNamesForGeo(panelItem, date) {
      if (!this.staffNames) return ["none"];

      const retVal = this.staffNames
        .filter((el) => el.geos.includes(panelItem))
        .map(({ name, disabled }) => {
          return { name, disabled };
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      if (date)
        retVal.forEach((el) => {
          const unavailablePerson = this.adjustedCapacityPool.find(
            (item) => item.name == el.name && item.dates.includes(date)
          );
          if (unavailablePerson) {
            const workloads = [
                "adjustedEditingWorkload",
                "adjustedWritingWorkload",
              ],
              unavailabilityValidator = workloads.map((item) => {
                if (unavailablePerson[item] == 0) return true;
              });
            unavailabilityValidator.every((item) => item === true) &&
            !this.formValues.overrideStaff
              ? (el.disabled = true)
              : (el.disabled = false);
          } else {
            el.disabled = false;
          }
        });

      return retVal;
    },
    handleValueSelectChange(panelItem, key, value, groupItem) {
      // copy existing object
      const newAssignments = { ...this.staffAssignments };
      // find Shakespeare assignment
      const shakespeareAssignment = this.findShakespeareAssignment(
        panelItem,
        groupItem
      );
      // update in copy
      if (value) {
        newAssignments[
          this.shakespeare ? shakespeareAssignment : this.groupItem
        ][this.shakespeare ? groupItem : panelItem][key] = value;
      } else {
        newAssignments[
          this.shakespeare ? shakespeareAssignment : this.groupItem
        ][this.shakespeare ? groupItem : panelItem][key] = "";
      }
      // update global state object
      this.setStaffAssignments(newAssignments);
      // reset the field unavailability error
      if (this.backstageCopywriting)
        this.resetSpecialistAvailabilityStatus(panelItem, key);
    },
    handleApplyToAll(panelItem, batchMode) {
      // copy existing object
      const newAssignments = { ...this.staffAssignments };

      // update in copy
      const assignmentsList = _.keys(newAssignments);

      const keysArray = [
        "workType1Reference",
        "workType2Reference",
        "workType3Reference",
        "workType4Reference",
        "workType5Reference",
        "workType6Reference",
        "workType1Quantity",
        "workType2Quantity",
        "workType3Quantity",
        "workType4Quantity",
        "workType5Quantity",
        "workType6Quantity",
      ];

      assignmentsList.forEach((assignment) => {
        if (this.shakespeare) {
          if (batchMode) {
            const allGeos = [
              ...new Set(
                this.tableRows.map((el) => {
                  return el.geo;
                })
              ),
            ];
            allGeos.forEach((glGeo) => {
              if (newAssignments[assignment][glGeo])
                keysArray.forEach((key) => {
                  newAssignments[assignment][glGeo][key] =
                    newAssignments[
                      this.findShakespeareAssignment(panelItem, this.groupItem)
                    ][this.groupItem][key];
                });
            });
          } else {
            keysArray.push("editor", "reviewer");
            if (newAssignments[assignment][this.groupItem])
              keysArray.forEach((key) => {
                newAssignments[assignment][this.groupItem][key] =
                  newAssignments[
                    this.findShakespeareAssignment(panelItem, this.groupItem)
                  ][this.groupItem][key];
              });
          }
        } else if (this.backstageCopywriting) {
          const geos = Object.keys(newAssignments[assignment]);
          keysArray.forEach((key) => {
            geos.forEach((geo) => {
              newAssignments[assignment][geo][key] =
                this.staffAssignments[assignment][panelItem][key];
            });
          });
        } else {
          if (newAssignments[assignment][panelItem]) {
            newAssignments[assignment][panelItem]["writer"] =
              newAssignments[this.groupItem][panelItem]["writer"];
            newAssignments[assignment][panelItem]["editor"] =
              newAssignments[this.groupItem][panelItem]["editor"];
            newAssignments[assignment][panelItem]["reviewer"] =
              newAssignments[this.groupItem][panelItem]["reviewer"];
          }
        }
      });

      // update global state object
      this.setStaffAssignments(newAssignments);
    },
    findShakespeareAssignment(panelItem, groupItem, key, valueOnly) {
      let searchedAssignment;
      Object.entries(this.staffAssignments).forEach((el) => {
        if (el[1].hasOwnProperty(groupItem) && el[1].task_name === panelItem)
          searchedAssignment = el[0];
      });
      return valueOnly
        ? this.staffAssignments[searchedAssignment]?.[groupItem][key]
        : searchedAssignment;
    },
    handleOverloadingReset(panelItem, role, groupItem) {
      this.shakespeare
        ? (this.staffAssignments[
            this.findShakespeareAssignment(panelItem, groupItem)
          ][groupItem][`${role}Overloaded`] = false)
        : (this.staffAssignments[groupItem][panelItem][
            `${role}Overloaded`
          ] = false);
    },
    resetSpecialistAvailabilityStatus(geo, specialist) {
      this[`${specialist}Unavailable`] = false;
      if (
        specialist === "writer" ||
        specialist === "editor" ||
        specialist === "reviewer"
      )
        this.setStaffAssignmentOverloading({
          taskName: this.shakespeare ? geo : this.groupItem,
          geo: this.shakespeare ? this.groupItem : geo,
          key: `${specialist}Overloaded`,
          value: this[`${specialist}Unavailable`],
        });
    },
  },
  computed: {
    ...mapGetters("fcBot", [
      "staffAssignments",
      "namesLoading",
      "tableRows",
      "formValues",
      "adjustedCapacityPool",
      "xContent",
      "shakespeare",
      "shakespeareLoc",
      "backstageCopywriting",
      "prepopulationFlow",
    ]),
    panelGroup() {
      return this.shakespeare
        ? _.keys(this.data)
        : _.without(_.keys(this.data), "wordCount");
    },
  },
  mounted() {
    if (this.xContent || this.shakespeareLoc)
      this.$refs.fcStaffPanel?.isActive
        ? (this.$refs.fcStaffPanel.isActive = !this.$refs.fcStaffPanel.isActive)
        : (this.$refs.fcStaffPanel.isActive = true);
  },
};
</script>

<style lang="scss" scoped>
.fc-staff-expansion-panel {
  &__header {
    font-weight: 500;
    .identifier-count-chip {
      margin-left: 10px;
    }
  }
}
</style>
