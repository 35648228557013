<template>
  <div class="invoice-report-table">
    <v-tabs v-model="selectedPM">
      <v-tab v-for="(pm, i) in invoiceTablePMs" :key="`tab-${i}`">{{
        pm
      }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedPM">
      <v-tab-item v-for="(pm, i) in invoiceTablePMs" :key="`tab-${i}`">
        <InvoiceReportTableView :selectedPM="selectedPM" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import InvoiceReportTableView from "@/components/InvoiceBot/InvoiceReportTableView.vue";
export default {
  components: {
    InvoiceReportTableView,
  },
  data() {
    return {
      selectedPM: null,
    };
  },
  computed: {
    ...mapGetters("invoiceBot", ["invoiceTablePMs"]),
  },
  mounted() {
    this.selectedPM = this.invoiceTablePMs[0];
  },
};
</script>
<style lang="scss" scoped></style>
