export const customBreakpoint = {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    laptop() {
      return this.windowWidth < 1500;
    },
    laptopSmaller() {
      return this.windowWidth < 1280;
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};

export const timeUnits = {
  data() {
    return {
      hoursList: [
        { time: "12:00 AM", disabled: false },
        { time: "01:00 AM", disabled: false },
        { time: "02:00 AM", disabled: false },
        { time: "03:00 AM", disabled: false },
        { time: "04:00 AM", disabled: false },
        { time: "05:00 AM", disabled: false },
        { time: "06:00 AM", disabled: false },
        { time: "07:00 AM", disabled: false },
        { time: "08:00 AM", disabled: false },
        { time: "09:00 AM", disabled: false },
        { time: "10:00 AM", disabled: false },
        { time: "11:00 AM", disabled: false },
        { time: "12:00 PM", disabled: false },
        { time: "01:00 PM", disabled: false },
        { time: "02:00 PM", disabled: false },
        { time: "03:00 PM", disabled: false },
        { time: "04:00 PM", disabled: false },
        { time: "05:00 PM", disabled: false },
        { time: "06:00 PM", disabled: false },
        { time: "07:00 PM", disabled: false },
        { time: "08:00 PM", disabled: false },
        { time: "09:00 PM", disabled: false },
        { time: "10:00 PM", disabled: false },
        { time: "11:00 PM", disabled: false },
      ],
      utcTimeZones: [
        "PST — Pacific Standard Time UTC -08:00",
        "PDT — Pacific Daylight Time UTC -07:00",
        "CST — Central Standard Time/CDMX UTC -06:00",
        "EST — Eastern Standard Time UTC -05:00",
        "EDT — Eastern Daylight Time UTC -04:00",
        "GMT — Greenwich Mean Time UTC +00:00",
        "CET — Central European Time UTC +01:00",
        "CEST — Central European Summer Time UTC +02:00",
        "MSK — Moscow Standard Time UTC +03:00",
        "GST — Gulf Standard Time UTC +04:00",
        "HKT — Hong Kong Time/CST UTC +08:00",
        "KST — Korea Standard Time/JST UTC +09:00",
      ],
      monthOptions: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      yearOptions: [
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
        "2031",
        "2032",
        "2033",
        "2034",
        "2035",
        "2036",
        "2037",
        "2038",
        "2039",
        "2040",
        "2041",
        "2042",
        "2043",
        "2044",
        "2045",
        "2046",
        "2047",
        "2048",
        "2049",
        "2050",
      ],
    };
  },
};
