<template>
  <div class="nav-list-section-header">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.nav-list-section-header {
  font-weight: 500;
  background-color: #f0f0f0;
  margin-bottom: 0px;
}
</style>
