import _ from "lodash";

export const uniqueTerms = {
  TuneIn: 15,
  "Apple US": 45,
};

export const defaultTerms = 30;

function addDays(date, days) {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);
  return copy;
}

export const getTerms = (customer) => {
  let net;
  if (_.keys(uniqueTerms).includes(customer)) {
    net = uniqueTerms[customer];
  } else {
    net = defaultTerms;
  }

  return net;
};

export const getDueDate = (customer) => {
  const net = getTerms(customer);
  const now = new Date(Date.now());
  const due = addDays(now, net);

  return due;
};

const csvColumns = [
  "*InvoiceNo",
  "Customer",
  "Memo",
  "*InvoiceDate",
  "*DueDate",
  "Terms",
  "Item(Product/Service)",
  "ItemDescription",
  "ItemQuantity",
  "ItemRate",
  "ItemAmount",
  "Taxable",
  "Email",
];

const acc = (field, lookup, row) => {
  return row[lookup[field]].value;
};

export const getHeaderIDLookup = (header) => {
  const headerIDLookup = {};
  header.forEach((el) => {
    headerIDLookup[el.label] = el.id;
  });

  return headerIDLookup;
};

const getInvoiceNumberLookup = (rows, headerIDLookup) => {
  const clientPOCombos = rows.map((el) => {
    return `${acc("Quickbooks Customer Name", headerIDLookup, el)}${acc(
      "Related Purchase Order - Purchase order Number",
      headerIDLookup,
      el
    )}`;
  });

  const uniqueList = _.uniq(clientPOCombos);

  const retVal = {};
  uniqueList.forEach((el, i) => {
    retVal[el] = 1001 + i;
  });

  return retVal;
};

export const doCSVGenerate = ({ rows, header, clientEmails }) => {
  return new Promise((resolve, reject) => {
    console.log(clientEmails);
    const headerIDLookup = getHeaderIDLookup(header);
    const invoiceNumberLookup = getInvoiceNumberLookup(rows, headerIDLookup);

    const formattedObj = rows.map((row) => {
      const customer = acc("Quickbooks Customer Name", headerIDLookup, row);
      const invoiceNo =
        invoiceNumberLookup[
          `${customer}${acc(
            "Related Purchase Order - Purchase order Number",
            headerIDLookup,
            row
          )}`
        ];

      const currentDate = new Date(Date.now());
      const dueDate = getDueDate(customer);
      const net = getTerms(customer);
      const worktypePrice = acc("WorktypePrice", headerIDLookup, row);
      const worktype = worktypePrice.split(";")[0];
      const itemRate = parseFloat(worktypePrice.split(";")[1]);
      const quanitity = parseInt(acc("Quantity (tot)", headerIDLookup, row));
      const clientTotal = parseFloat(
        acc("Client Total (tot)", headerIDLookup, row)
      );
      const title = acc("Assignment Title", headerIDLookup, row);
      const clientEmailInfo = clientEmails.find((el) => el.client === customer);
      const clientPrimaryEmail = clientEmailInfo?.primary_email || "";
      let po = acc(
        "Related Purchase Order - Purchase order Number",
        headerIDLookup,
        row
      );

      if (po === "") {
        po = "N/A";
      }

      return {
        "*InvoiceNo": invoiceNo,
        Customer: `"${customer}"`,
        Memo: po,
        "*InvoiceDate": currentDate.toLocaleDateString(),
        "*DueDate": dueDate.toLocaleDateString(),
        Terms: `Net ${net}`,
        "Item(Product/Service)": worktype,
        ItemDescription: `"${title}"`,
        ItemQuantity: quanitity,
        ItemRate: itemRate.toFixed(2),
        ItemAmount: clientTotal.toFixed(2),
        Taxable: "N",
        Email: clientPrimaryEmail,
      };
    });

    console.log(formattedObj);
    const csvString = createCSVString(formattedObj);
    const blob = new Blob([csvString], {
      type: "text/plain",
    });
    saveAs(blob, "output.csv");
  });
};

const createCSVString = (obj) => {
  let retVal = "";
  const headerRow = _.keys(obj[0]);

  retVal = retVal.concat(headerRow.join(","), "\n");
  // retVal = retVal.concat(testRow.join(","), "\n");

  obj.forEach((row) => {
    retVal = retVal.concat(_.values(row).join(","), "\n");
  });

  return retVal;
};
