<template>
  <div>
    <v-progress-circular v-if="isUpdating" indeterminate :size="24" />
    <v-select
      v-if="!isUpdating"
      class="caption status-selector"
      :style="{
        backgroundColor: statusColor,
      }"
      :items="options"
      item-text="value"
      dense
      hide-details
      outlined
      :disabled="isAdmin || isManager ? false : currentStatus === 'Delivered'"
      :value="currentStatus"
      :loading="optionsLoading"
      @change="(selected) => onChange({ item, selected })"
    />
    <v-alert v-if="isError" type="error" class="alert" dismissible>
      Error updating status. Please try again.
    </v-alert>
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// internal
import { updateDBRowField } from "@/utils/quickbaseUtils";

export default {
  name: "CPMDashboardStatusSelector",
  props: {
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    optionsLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      currentStatus: "",
      isUpdating: false,
      isError: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["isAdmin", "isManager"]),
    statusColor() {
      return this.options.find((el) => el.value === this.currentStatus)?.color;
    },
  },
  watch: {
    value(newValue) {
      this.currentStatus = newValue;
    },
  },
  mounted() {
    this.currentStatus = this.value;
  },
  methods: {
    onChange({ item, selected }) {
      this.currentStatus = selected;
      // do call to QB to change in db
      this.isUpdating = true;
      updateDBRowField({
        rid: item["RecordID#"],
        status: selected,
        cpmMode: true,
      })
        .then(() => {
          this.isUpdating = false;
        })
        .catch((err) => {
          this.isUpdating = false;
          this.isError = true;
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.status-selector {
  width: 120px;
}

.alert {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100;
}
</style>
