<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="fcdashboard-new-assignment v-card v-sheet theme--light ma-4"
  >
    <v-tabs v-model="tab" background-color="transparent" grow>
      <v-tab v-for="step in steps" :key="step">
        {{ step }}
      </v-tab>
      <v-btn
        v-if="newFCAssignmentDatas.length > 1"
        class="ma-1"
        height="40px"
        small
        color="secondary"
        @click="handleAssignmentItemDeletion"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Related Project"
                :items="projectNameItems"
                :loading="projectNameItemsLoading"
                v-model="relatedProject"
                item-text="name"
                return-object
                :rules="[rules.required]"
                @change="updateRelatedProjectSmartFields($event)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-menu
                v-model="requestDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="requestDate"
                    :rules="[rules.required]"
                    label="Request Date"
                    prepend-icon="mdi-calendar-plus"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="requestDateMenu = false"
                  v-model="requestDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-textarea
                outlined
                clearable
                rows="1"
                clear-icon="mdi-close-circle-outline"
                label="Email Subject Line"
                v-model="emailSubjectLine"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Related Client Requester"
                :items="clientRequesters"
                :loading="clientRequestersLoading"
                v-model="clientRequester"
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Request Type"
                :items="requestTypes"
                :loading="requestTypesLoading"
                v-model="requestType"
                :rules="[rules.required]"
                @change="handleWorkType1Quantity"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Media Type"
                :items="mediaTypes"
                :loading="mediaTypesLoading"
                v-model="mediaType"
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Keyword"
                :items="keywords"
                :loading="keywordsLoading"
                v-model="keyword"
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                outlined
                label="Task ID"
                v-model="taskID"
                :rules="requestType === 'Meeting' ? [] : [rules.required]"
              />
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                outlined
                label="Assignment ID"
                v-model="assignmentID"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-combobox
                outlined
                label="Assignment Title"
                :items="assignmentTitles"
                v-model="assignmentTitle"
                :rules="[rules.required]"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                outlined
                label="Assignment Detail"
                v-model="assignmentDetails"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3" class="pb-0">
              <v-autocomplete
                outlined
                label="Source GEO"
                :items="sourceGeos"
                v-model="sourceGeo"
                :loading="sourceGeosLoading"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" class="pb-0">
              <v-autocomplete
                outlined
                label="GEO"
                :items="geoOptions"
                v-model="geo"
                :rules="[rules.required]"
                @change="getStaffByGeo($event)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" class="pb-0">
              <v-text-field
                outlined
                label="Word Count"
                type="number"
                v-model="wordCount"
                :loading="loadWritingDueDate || loadEditingDueDate"
                :disabled="loadWritingDueDate || loadEditingDueDate"
                :rules="requestType === 'Localization' ? [rules.required] : []"
                @change="handleWorkType1Quantity"
              />
            </v-col>
            <v-col cols="12" sm="3" class="pb-0">
              <v-text-field
                outlined
                label="Related Purchase Order"
                v-model="po"
                :rules="[rules.required]"
                readonly
                :loading="poLoading"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item eager>
        <v-container>
          <v-row>
            <v-col cols="12" sm="4" class="pb-0">
              <v-menu
                v-model="clientDeliveryDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="clientDeliveryDate"
                    label="Delivery Date (Client)"
                    prepend-icon="mdi-calendar"
                    readonly
                    :error="conversionErrors"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="clientDeliveryDate"
                  @input="clientDeliveryDateMenu = false"
                  @change="handlePayPeriod($event)"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-select
                :items="hoursList"
                v-model="clientDeliveryDue"
                item-text="time"
                label="Delivery Due (Time of Day)"
                prepend-icon="mdi-clock-time-five-outline"
                :error="conversionErrors"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-autocomplete
                outlined
                append-icon="mdi-airplane-clock"
                label="Time Zone"
                :error="conversionErrors"
                v-model="timeZone"
                :items="utcTimeZones"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" class="pb-0">
              <v-menu
                v-model="deliveryDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="deliveryDate"
                    :rules="[rules.required]"
                    label="Delivery Date (ET)"
                    prepend-icon="mdi-calendar-check"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="deliveryDateMenu = false"
                  v-model="deliveryDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-select
                :items="hoursList"
                v-model="deliveryDue"
                :rules="[rules.required]"
                item-text="time"
                label="Delivery Due (ET)"
                prepend-icon="mdi-clock-check-outline"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-btn
                class="px-3"
                height="56px"
                color="accent"
                outlined
                @click="convertDeliveryDue"
                >Convert Delivery Due to EST time zone</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" class="pb-0">
              <v-autocomplete
                outlined
                label="Related Writer"
                item-text="name"
                :items="relatedWriters"
                :loading="staffLoading"
                return-object
                v-model="writer"
                :class="{ capacityNote: errorForWritingCapacity }"
                :error="errorForWritingCapacity"
                :error-messages="
                  errorForWritingCapacity
                    ? ['Person\'s daily workload limit reached.']
                    : []
                "
                :rules="[rules.requiredObject, rules.availabile('writer')]"
                @input="getCapacity($event, 'Writing')"
                @change="resetSpecialistAvailabilityStatus('writer')"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-menu
                v-model="writingDueDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    ref="writingDueDateField"
                    v-model="writingDueDate"
                    :rules="[rules.required, rules.dateAvailabile('writing')]"
                    label="For Writer"
                    prepend-icon="mdi-calendar-edit"
                    readonly
                    hint="Writing Due Date (ET)"
                    persistent-hint
                    :loading="loadWritingDueDate"
                    :disabled="loadWritingDueDate"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="writingDueDate"
                  :max="override ? null : deliveryDate"
                  @input="writingDueDateMenu = false"
                  @change="handleDueDate('writing', 'relatedWriters', 'writer')"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-select
                :items="writingDueHours"
                v-model="writingDue"
                :rules="[rules.required, rules.timeAvailabile('writing')]"
                item-text="time"
                label="For Writer"
                prepend-icon="mdi-clock-time-eight-outline"
                hint="Writing Due (ET)"
                persistent-hint
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" class="pb-0">
              <v-autocomplete
                outlined
                label="Related Editor"
                item-text="name"
                :items="relatedEditors"
                :loading="staffLoading"
                return-object
                v-model="editor"
                :class="{ capacityNote: errorForEditingCapacity }"
                :error="errorForEditingCapacity"
                :error-messages="
                  errorForEditingCapacity
                    ? ['Person\'s daily workload limit reached.']
                    : []
                "
                :rules="[rules.availabile('editor')]"
                @input="getCapacity($event, 'Editing')"
                @change="resetSpecialistAvailabilityStatus('editor')"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-menu
                v-model="editingDueDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    ref="editingDueDateField"
                    v-model="editingDueDate"
                    :rules="[rules.dateAvailabile('editing')]"
                    label="For Editor"
                    prepend-icon="mdi-calendar-star"
                    readonly
                    clearable
                    hint="Editing Due Date (ET)"
                    persistent-hint
                    :loading="loadEditingDueDate"
                    :disabled="loadEditingDueDate"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editingDueDate"
                  :max="override ? null : deliveryDate"
                  @input="editingDueDateMenu = false"
                  @change="handleDueDate('editing', 'relatedEditors', 'editor')"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-select
                :items="editingDueHours"
                v-model="editingDue"
                :rules="[rules.timeAvailabile('editing')]"
                item-text="time"
                label="For Editor"
                prepend-icon="mdi-clock-time-eleven-outline"
                hint="Editing Due (ET)"
                persistent-hint
                clearable
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" class="pb-0">
              <v-autocomplete
                outlined
                label="Reviewer"
                item-text="name"
                :items="reviewers"
                :loading="staffLoading"
                return-object
                v-model="reviewer"
                :rules="[rules.availabile('reviewer')]"
                @change="resetSpecialistAvailabilityStatus('reviewer')"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-menu
                v-model="reviewingDueDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    ref="reviewingDueDateField"
                    v-model="reviewingDueDate"
                    :rules="[rules.dateAvailabile('reviewing')]"
                    label="For Reviewer"
                    prepend-icon="mdi-calendar-search"
                    readonly
                    clearable
                    hint="Reviewing Due Date (ET)"
                    persistent-hint
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="reviewingDueDate"
                  :max="override ? null : deliveryDate"
                  @input="reviewingDueDateMenu = false"
                  @change="handleDueDate('reviewing', 'reviewers', 'reviewer')"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-select
                :items="reviewingDueHours"
                v-model="reviewingDue"
                :rules="[rules.timeAvailabile('reviewing')]"
                item-text="time"
                label="For Reviewer"
                prepend-icon="mdi-clock-time-two-outline"
                hint="Reviewing Due (ET)"
                persistent-hint
                clearable
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4">
              <v-checkbox
                class="mt-0"
                v-model="overrideStaff"
                label="Override Staff Availability"
                prepend-icon="mdi-account-multiple-remove"
                persistent-hint
                hint="If person agreed to perform while unavailable"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="4">
              <v-checkbox
                class="mt-0"
                v-model="urgent"
                label="Urgent"
                prepend-icon="mdi-clock-fast"
                persistent-hint
                hint="Convert Delivery data (ET) or enter it manually"
                readonly
                :color="urgent ? 'red accent-4' : ''"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="4">
              <v-checkbox
                class="mt-0"
                v-model="override"
                label="Override"
                prepend-icon="mdi-calendar-remove"
                persistent-hint
                hint="Override Due Dates and Dues (Time of Day)"
                :disabled="!deliveryDate && !deliveryDue"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-menu
                v-model="payPeriodMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="payPeriod"
                    :rules="[rules.required]"
                    label="Pay Period"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="payPeriodMenu = false"
                  v-model="payPeriod"
                  type="month"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="File Location"
                :items="fileLocations"
                :loading="fileLocationsLoading"
                v-model="fileLocation"
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item eager>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                outlined
                label="Assignment Status"
                value="Assigned"
                readonly
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field outlined label="Link" v-model="link"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3" class="pb-0">
              <v-autocomplete
                outlined
                label="Work Type 1 Reference"
                :items="workType1References"
                :loading="workTypeReferencesLoading"
                v-model="workType1Reference"
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" class="pb-0">
              <v-text-field
                outlined
                label="Work Type 1 Quantity"
                type="number"
                v-model="workType1Quantity"
                :readonly="requestType === 'Localization'"
                :rules="[rules.required, rules.negativeCheck]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" class="pb-0">
              <v-autocomplete
                outlined
                label="Work Type 2 Reference"
                :items="workType2References"
                v-model="workType2Reference"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" class="pb-0">
              <v-text-field
                outlined
                label="Work Type 2 Quantity"
                type="number"
                v-model="workType2Quantity"
                :rules="[rules.negativeCheck]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3" class="pb-0">
              <v-autocomplete
                outlined
                label="Work Type 3 Reference"
                :items="workType3References"
                v-model="workType3Reference"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" class="pb-0">
              <v-text-field
                outlined
                label="Work Type 3 Quantity"
                type="number"
                v-model="workType3Quantity"
                :rules="[rules.negativeCheck]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" class="pb-0">
              <v-autocomplete
                outlined
                label="Work Type 4 Reference"
                :items="workType4References"
                v-model="workType4Reference"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" class="pb-0">
              <v-text-field
                outlined
                label="Work Type 4 Quantity"
                type="number"
                v-model="workType4Quantity"
                :rules="[rules.negativeCheck]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3" class="pb-0">
              <v-autocomplete
                outlined
                label="Work Type 5 Reference"
                :items="workType5References"
                v-model="workType5Reference"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" class="pb-0">
              <v-text-field
                outlined
                label="Work Type 5 Quantity"
                type="number"
                v-model="workType5Quantity"
                :rules="[rules.negativeCheck]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" class="pb-0">
              <v-autocomplete
                outlined
                label="Work Type 6 Reference"
                :items="workType6References"
                v-model="workType6Reference"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" class="pb-0">
              <v-text-field
                outlined
                label="Work Type 6 Quantity"
                type="number"
                v-model="workType6Quantity"
                :rules="[rules.negativeCheck]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-textarea
                outlined
                clearable
                rows="1"
                clear-icon="mdi-close-circle-outline"
                label="Note"
                v-model="note"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-form>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// library
import _ from "lodash";
// internal
import {
  getAllProjectNames,
  getClientRequestersForProject,
  appIDs,
  getFieldInfosFromDB,
  getMediaTypeChoices,
  getiTunesFCPONumber,
  getStaffNameFieldID,
  getFCStaffDatasFromID,
  getAvailabilityRecords,
  getDefaultCapacityByEmployee,
  getWorkloadByEmployee,
  getPriceCodeWithWorkTypeCategory,
} from "@/utils/quickbaseUtils";
import { timeUnits } from "@/utils/mixins";
import { workTypeReference } from "@/utils/fcBotUtils";

export default {
  name: "FCDashboardNewAssignmentItem",
  props: {
    datasIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tab: null,
      steps: ["General Info", "Staff Info", "Work Type Info"],
      projectNameItems: [],
      projectNameItemsLoading: false,
      requestDateMenu: false,
      clientRequestersLoading: false,
      clientRequesters: [],
      requestTypesLoading: false,
      requestTypes: [],
      clientDeliveryDateMenu: false,
      deliveryDateMenu: false,
      mediaTypesLoading: false,
      mediaTypes: [],
      keywordsLoading: false,
      keywords: [],
      poLoading: false,
      amAssignmentTitles: [
        "P1 Playlist Copy",
        "P3 Playlist Copy",
        "Classical Playlist Copy",
        "Playlist Title",
      ],
      fcAssignmentTitles: [
        "Album Review",
        "Arcade File",
        "Artist Bio",
        "Book Review",
        "Custom Tag",
        "Custom Title",
        "Discovery File",
        "Guidelines",
        "Hero Blurb",
        "Interview Note",
        "Marketing Copy",
        "Meeting",
        "MAS File",
        "Movie Description",
        "Storefront Description",
        "Today File",
      ],
      availabilityRecords: [],
      staffLoading: false,
      relatedWriters: [],
      writingDueDateMenu: false,
      writingDueDateAvailable: true,
      writingDueHoursAvailable: true,
      relatedEditors: [],
      editingDueDateMenu: false,
      editingDueDateAvailable: true,
      editingDueHoursAvailable: true,
      reviewers: [],
      reviewingDueDateMenu: false,
      reviewingDueDateAvailable: true,
      reviewingDueHoursAvailable: true,
      overrideStaff: false,
      loadWritingDueDate: false,
      dataForWriting: [],
      defaultWritingCapacity: [],
      adjustedWritingCapacity: [],
      dataForWritingCapacity: [],
      loadEditingDueDate: false,
      dataForEditing: [],
      defaultEditingCapacity: [],
      adjustedEditingCapacity: [],
      dataForEditingCapacity: [],
      payPeriodMenu: false,
      fileLocationsLoading: false,
      fileLocations: [],
      sourceGeosLoading: false,
      sourceGeos: [],
      workTypeReferencesLoading: false,
      workTypeReferences: [],
      workType1ReferencesConditions: {
        Localization: workTypeReference,
        Adaptation: {
          "de-DE": {
            "de-CH": "64",
          },
          "en-US": {
            "en-AU": "78",
            "en-GB": "78",
            "en-CA": "78",
          },
        },
      },
      workType2References: ["2"],
      workType3References: ["3"],
      workType4References: ["37", "70", "71"],
      workType5References: ["41"],
      workType6References: ["75"],
      conversionErrors: false,
      valid: true,
      rules: {
        required: (value) => !!value || value === 0 || "Required",
        requiredObject: (value) =>
          (value ? !!Object.keys(value).length : !!value) || "Required",
        availabile: (specialist) =>
          !this[`${specialist}Unavailable`] ||
          `Unavailable on selected due date for ${specialist}.`,
        dateAvailabile: (operation) =>
          this[`${operation}DueDateAvailable`] ||
          "Selected date is after delivery date for the client.",
        timeAvailabile: (operation) =>
          this[`${operation}DueHoursAvailable`] ||
          "Selected time is after delivery due (time of day) for the client.",
        negativeCheck: (value) => value >= 0 || "Quantity can't be nagative.",
      },
    };
  },
  mixins: [timeUnits],
  computed: {
    ...mapGetters("fcAssignment", [
      "newFCAssignmentDatas",
      "newFCAssignmentModalOpen",
      "geoOptions",
    ]),
    workType1References() {
      return this.requestType === "Copywriting"
        ? ["1"]
        : this.workType1ReferencesConditions[this.requestType]?.[
            this.sourceGeo
          ]?.[this.geo]
        ? [
            this.workType1ReferencesConditions[this.requestType][
              this.sourceGeo
            ][this.geo],
          ]
        : this.workTypeReferences;
    },
    relatedProject: {
      get: function () {
        return this.getStartingValues("relatedProject");
      },
      set: function (newVal) {
        return this.updateField(newVal, "relatedProject");
      },
    },
    requestDate: {
      get: function () {
        return this.getStartingValues("requestDate");
      },
      set: function (newVal) {
        return this.updateField(newVal, "requestDate");
      },
    },
    emailSubjectLine: {
      get: function () {
        return this.getStartingValues("emailSubjectLine");
      },
      set: function (newVal) {
        return this.updateField(newVal, "emailSubjectLine");
      },
    },
    clientRequester: {
      get: function () {
        return this.getStartingValues("clientRequester");
      },
      set: function (newVal) {
        return this.updateField(newVal, "clientRequester");
      },
    },
    requestType: {
      get: function () {
        return this.getStartingValues("requestType");
      },
      set: function (newVal) {
        return this.updateField(newVal, "requestType");
      },
    },
    mediaType: {
      get: function () {
        return this.getStartingValues("mediaType");
      },
      set: function (newVal) {
        return this.updateField(newVal, "mediaType");
      },
    },
    keyword: {
      get: function () {
        return this.getStartingValues("keyword");
      },
      set: function (newVal) {
        return this.updateField(newVal, "keyword");
      },
    },
    clientDeliveryDate: {
      get: function () {
        return this.getStartingValues("clientDeliveryDate");
      },
      set: function (newVal) {
        return this.updateField(newVal, "clientDeliveryDate");
      },
    },
    deliveryDate: {
      get: function () {
        return this.getStartingValues("deliveryDate");
      },
      set: function (newVal) {
        this.updateField(newVal, "deliveryDate");
      },
    },
    clientDeliveryDue: {
      get: function () {
        return this.getStartingValues("clientDeliveryDue");
      },
      set: function (newVal) {
        return this.updateField(newVal, "clientDeliveryDue");
      },
    },
    deliveryDue: {
      get: function () {
        return this.getStartingValues("deliveryDue");
      },
      set: function (newVal) {
        this.updateField(newVal, "deliveryDue");
      },
    },
    timeZone: {
      get: function () {
        return this.getStartingValues("timeZone");
      },
      set: function (newVal) {
        return this.updateField(newVal, "timeZone");
      },
    },
    urgent: {
      get: function () {
        return this.getStartingValues("urgent");
      },
      set: function (newVal) {
        this.updateField(newVal, "urgent");
      },
    },
    override: {
      get: function () {
        return this.getStartingValues("override");
      },
      set: function (newVal) {
        this.updateField(newVal, "override");
      },
    },
    errorForWritingCapacity: {
      get: function () {
        return this.getStartingValues("writerOverloaded");
      },
      set: function (newVal) {
        this.updateField(newVal, "writerOverloaded");
      },
    },
    errorForEditingCapacity: {
      get: function () {
        return this.getStartingValues("editorOverloaded");
      },
      set: function (newVal) {
        this.updateField(newVal, "editorOverloaded");
      },
    },
    sourceGeo: {
      get: function () {
        return this.getStartingValues("sourceGeo");
      },
      set: function (newVal) {
        return this.updateField(newVal, "sourceGeo");
      },
    },
    geo: {
      get: function () {
        return this.getStartingValues("geo");
      },
      set: function (newVal) {
        return this.updateField(newVal, "geo");
      },
    },
    po: {
      get: function () {
        return this.getStartingValues("po");
      },
      set: function (newVal) {
        this.updateField(newVal, "po");
      },
    },
    wordCount: {
      get: function () {
        return this.getStartingValues("wordCount");
      },
      set: function (newVal) {
        return this.updateField(newVal, "wordCount");
      },
    },
    taskID: {
      get: function () {
        return this.getStartingValues("taskID");
      },
      set: function (newVal) {
        return this.updateField(newVal, "taskID");
      },
    },
    assignmentID: {
      get: function () {
        return this.getStartingValues("assignmentID");
      },
      set: function (newVal) {
        return this.updateField(newVal, "assignmentID");
      },
    },
    assignmentDetails: {
      get: function () {
        return this.getStartingValues("assignmentDetails");
      },
      set: function (newVal) {
        return this.updateField(newVal, "assignmentDetails");
      },
    },
    assignmentTitle: {
      get: function () {
        return this.getStartingValues("assignmentName");
      },
      set: function (newVal) {
        return this.updateField(newVal, "assignmentName");
      },
    },
    writer: {
      get: function () {
        return this.getStartingValues("writer");
      },
      set: function (newVal) {
        return this.updateField(newVal, "writer");
      },
    },
    writingDueDate: {
      get: function () {
        return this.getStartingValues("writingDueDate");
      },
      set: function (newVal) {
        this.updateField(newVal, "writingDueDate");
      },
    },
    writingDue: {
      get: function () {
        return this.getStartingValues("writingDue");
      },
      set: function (newVal) {
        this.updateField(newVal, "writingDue");
      },
    },
    editor: {
      get: function () {
        return this.getStartingValues("editor");
      },
      set: function (newVal) {
        return this.updateField(newVal, "editor");
      },
    },
    editingDueDate: {
      get: function () {
        return this.getStartingValues("editingDueDate");
      },
      set: function (newVal) {
        this.updateField(newVal, "editingDueDate");
      },
    },
    editingDue: {
      get: function () {
        return this.getStartingValues("editingDue");
      },
      set: function (newVal) {
        this.updateField(newVal, "editingDue");
      },
    },
    reviewer: {
      get: function () {
        return this.getStartingValues("reviewer");
      },
      set: function (newVal) {
        return this.updateField(newVal, "reviewer");
      },
    },
    reviewingDueDate: {
      get: function () {
        return this.getStartingValues("reviewingDueDate");
      },
      set: function (newVal) {
        this.updateField(newVal, "reviewingDueDate");
      },
    },
    reviewingDue: {
      get: function () {
        return this.getStartingValues("reviewingDue");
      },
      set: function (newVal) {
        this.updateField(newVal, "reviewingDue");
      },
    },
    payPeriod: {
      get: function () {
        return this.getStartingValues("payPeriod");
      },
      set: function (newVal) {
        this.updateField(newVal, "payPeriod");
      },
    },
    fileLocation: {
      get: function () {
        return this.getStartingValues("fileLocation");
      },
      set: function (newVal) {
        return this.updateField(newVal, "fileLocation");
      },
    },
    link: {
      get: function () {
        return this.getStartingValues("link");
      },
      set: function (newVal) {
        return this.updateField(newVal, "link");
      },
    },
    workType1Reference: {
      get: function () {
        return this.getStartingValues("workType1Reference");
      },
      set: function (newVal) {
        return this.updateField(newVal, "workType1Reference");
      },
    },
    workType1Quantity: {
      get: function () {
        return this.getStartingValues("workType1Quantity");
      },
      set: function (newVal) {
        this.updateField(newVal, "workType1Quantity");
      },
    },
    workType2Reference: {
      get: function () {
        return this.getStartingValues("workType2Reference");
      },
      set: function (newVal) {
        return this.updateField(newVal, "workType2Reference");
      },
    },
    workType2Quantity: {
      get: function () {
        return this.getStartingValues("workType2Quantity");
      },
      set: function (newVal) {
        return this.updateField(newVal, "workType2Quantity");
      },
    },
    workType3Reference: {
      get: function () {
        return this.getStartingValues("workType3Reference");
      },
      set: function (newVal) {
        return this.updateField(newVal, "workType3Reference");
      },
    },
    workType3Quantity: {
      get: function () {
        return this.getStartingValues("workType3Quantity");
      },
      set: function (newVal) {
        return this.updateField(newVal, "workType3Quantity");
      },
    },
    workType4Reference: {
      get: function () {
        return this.getStartingValues("workType4Reference");
      },
      set: function (newVal) {
        return this.updateField(newVal, "workType4Reference");
      },
    },
    workType4Quantity: {
      get: function () {
        return this.getStartingValues("workType4Quantity");
      },
      set: function (newVal) {
        return this.updateField(newVal, "workType4Quantity");
      },
    },
    workType5Reference: {
      get: function () {
        return this.getStartingValues("workType5Reference");
      },
      set: function (newVal) {
        return this.updateField(newVal, "workType5Reference");
      },
    },
    workType5Quantity: {
      get: function () {
        return this.getStartingValues("workType5Quantity");
      },
      set: function (newVal) {
        return this.updateField(newVal, "workType5Quantity");
      },
    },
    workType6Reference: {
      get: function () {
        return this.getStartingValues("workType6Reference");
      },
      set: function (newVal) {
        return this.updateField(newVal, "workType6Reference");
      },
    },
    workType6Quantity: {
      get: function () {
        return this.getStartingValues("workType6Quantity");
      },
      set: function (newVal) {
        return this.updateField(newVal, "workType6Quantity");
      },
    },
    note: {
      get: function () {
        return this.getStartingValues("note");
      },
      set: function (newVal) {
        return this.updateField(newVal, "note");
      },
    },
    writingDueHours() {
      return this.handleDueHoursAvailability("writing");
    },
    editingDueHours() {
      return this.handleDueHoursAvailability("editing");
    },
    reviewingDueHours() {
      return this.handleDueHoursAvailability("reviewing");
    },
    assignmentTitles() {
      if (this.relatedProject) {
        if (this.relatedProject.name === "iTunes FC")
          return this.fcAssignmentTitles;
        if (this.relatedProject.name === "iTunes Music")
          return this.amAssignmentTitles;
      } else {
        return [...this.amAssignmentTitles, ...this.fcAssignmentTitles].sort();
      }
    },
  },
  watch: {
    writingDueHours(newVal) {
      this.handleDueHoursValidation("writing", newVal);
    },
    editingDueHours(newVal) {
      this.handleDueHoursValidation("editing", newVal);
    },
    reviewingDueHours(newVal) {
      this.handleDueHoursValidation("reviewing", newVal);
    },
    override() {
      this.handleStaffDuesValidation();
    },
    overrideStaff() {
      if (this.editor?.name)
        this.handleStaffAvailability("relatedEditors", "editing", "editor");
      if (this.writer?.name)
        this.handleStaffAvailability("relatedWriters", "writing", "writer");
      if (this.reviewer?.name)
        this.handleStaffAvailability("reviewers", "reviewing", "reviewer");
    },
    deliveryDate(newVal) {
      this.handleStaffDuesValidation();
      this.urgent = this.$moment(newVal).isSameOrBefore(
        this.$moment(this.requestDate).add(2, "days")
      );
    },
    wordCount() {
      this.handleCapacityCalculation("Writing");
      this.handleCapacityCalculation("Editing");
    },
    writingDueDate() {
      this.handleCapacityCalculation("Writing");
    },
    editingDueDate() {
      this.handleCapacityCalculation("Editing");
    },
    newFCAssignmentModalOpen(newVal) {
      if (newVal) this.$refs.form.resetValidation();
    },
  },
  mounted() {
    this.getProjectNames();
    this.getActiveClientRequesters();
    this.getRequestTypes();
    this.getMediaTypes();
    this.getKeywords();
    this.getWorkTypeReferences();
    this.getAvailabilityData();
    this.getFileLocations();
    this.getSourceGeos();
  },
  methods: {
    ...mapActions("fcAssignment", [
      "setNewFCAssignmentModalOpen",
      "setNewFCAssignmentDatas",
      "deleteNewFCAssignmentDatas",
    ]),
    updateField(value, field) {
      const newPayload = _.cloneDeep(this.newFCAssignmentDatas);
      const indexCopy = _.cloneDeep(newPayload[this.datasIndex]);
      indexCopy[field] = value;
      newPayload[this.datasIndex] = indexCopy;
      this.setNewFCAssignmentDatas(newPayload);
    },
    getStartingValues(field) {
      return this.newFCAssignmentDatas[this.datasIndex][field];
    },
    updateRelatedProjectSmartFields(selectedProject) {
      if (selectedProject) {
        this.poLoading = true;
        getiTunesFCPONumber(selectedProject.name)
          .then((data) => {
            this.po = selectedProject.name
              ? Object.values(data.data[0])[0].value
              : "";
            this.poLoading = false;
          })
          .catch((err) => {
            console.error(err);
            this.poLoading = false;
          });
      }
    },
    getActiveClientRequesters() {
      this.clientRequestersLoading = true;
      getClientRequestersForProject({ instance: "iTunes" })
        .then((data) => {
          this.clientRequesters = data;
          this.clientRequestersLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.clientRequestersLoading = false;
        });
    },
    getProjectNames() {
      this.projectNameItemsLoading = true;
      getAllProjectNames("iTunes")
        .then((data) => {
          this.projectNameItems = data;
          this.projectNameItemsLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.projectNameItemsLoading = false;
        });
    },
    getRequestTypes() {
      this.requestTypesLoading = true;
      getFieldInfosFromDB({
        fieldID: 164,
        tableID: appIDs.iTunesProjectManager.assignments,
      })
        .then((data) => {
          this.requestTypes = data.sort();
          this.requestTypesLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.requestTypesLoading = false;
        });
    },
    getMediaTypes() {
      this.mediaTypesLoading = true;
      getMediaTypeChoices({ instance: "iTunes" })
        .then((data) => {
          this.mediaTypes = data;
          this.mediaTypesLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.mediaTypesLoading = false;
        });
    },
    getKeywords() {
      this.keywordsLoading = true;
      getFieldInfosFromDB({
        fieldID: 163,
        tableID: appIDs.iTunesProjectManager.assignments,
      })
        .then((data) => {
          this.keywords = data;
          this.keywordsLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.keywordsLoading = false;
        });
    },
    getWorkTypeReferences() {
      this.workTypeReferencesLoading = true;
      getPriceCodeWithWorkTypeCategory({
        iTunes: true,
        getAll: true,
      })
        .then((data) => {
          this.workTypeReferences = data.map((el) => el["Record ID#"]);
          this.workTypeReferencesLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.workTypeReferencesLoading = false;
        });
    },
    handlePayPeriod(dateInput) {
      if (!this.payPeriod) {
        this.payPeriod = dateInput.slice(0, -3);
      }
    },
    convertDeliveryDue() {
      if (
        !this.requestDate ||
        !this.clientDeliveryDate ||
        !this.clientDeliveryDue ||
        !this.timeZone
      ) {
        this.conversionErrors = true;
      } else {
        this.conversionErrors = false;
        let clientDeliveryData = new Date(
          `${this.clientDeliveryDate}T${this.$moment(
            this.clientDeliveryDue,
            "hh:mm A"
          ).format("HH:mm")}:00.000${this.timeZone.split(" ").at(-1)}`
        );
        let convertedDeliveryData = clientDeliveryData.toLocaleString("en-US", {
          timeZone: "America/New_York",
        });
        this.deliveryDate = this.$moment(convertedDeliveryData).format(
          "YYYY-MM-DD"
        );
        this.deliveryDue = clientDeliveryData.toLocaleString("en-US", {
          timeZone: "America/New_York",
          hour: "2-digit",
          minute: "2-digit",
        });
        if (this.urgent) {
          if (this.reviewer?.name) {
            let convertedReviewerData = this.$moment(
              convertedDeliveryData
            ).subtract(3, "hours");
            this.reviewingDueDate = this.$moment(
              convertedReviewerData._d
            ).format("YYYY-MM-DD");
            this.reviewingDue = this.$moment(convertedReviewerData._d).format(
              "hh:mm A"
            );
            this.handleStaffAvailability("reviewers", "reviewing", "reviewer");
          }
          if (this.editor?.name) {
            let convertedEditorData = this.$moment(
              convertedDeliveryData
            ).subtract(6, "hours");
            this.editingDueDate = this.$moment(convertedEditorData._d).format(
              "YYYY-MM-DD"
            );
            this.editingDue = this.$moment(convertedEditorData._d).format(
              "hh:mm A"
            );
            this.handleStaffAvailability("relatedEditors", "editing", "editor");
          }
          let convertedWriterData = this.$moment(
            convertedDeliveryData
          ).subtract(9, "hours");
          this.writingDueDate = this.$moment(convertedWriterData._d).format(
            "YYYY-MM-DD"
          );
          this.writingDue = this.$moment(convertedWriterData._d).format(
            "hh:mm A"
          );
        } else {
          if (this.reviewer?.name) {
            let convertedReviewerData = this.$moment(
              convertedDeliveryData
            ).subtract(6, "hours");
            this.reviewingDueDate = this.$moment(
              convertedReviewerData._d
            ).format("YYYY-MM-DD");
            this.reviewingDue = this.$moment(convertedReviewerData._d).format(
              "hh:mm A"
            );
            this.handleStaffAvailability("reviewers", "reviewing", "reviewer");
          }
          if (this.editor?.name) {
            let convertedEditorData = this.$moment(
              convertedDeliveryData
            ).subtract(18, "hours");
            this.editingDueDate = this.$moment(convertedEditorData._d).format(
              "YYYY-MM-DD"
            );
            this.editingDue = this.$moment(convertedEditorData._d).format(
              "hh:mm A"
            );
            this.handleStaffAvailability("relatedEditors", "editing", "editor");
          }
          let convertedWriterData = this.$moment(
            convertedDeliveryData
          ).subtract(24, "hours");
          this.writingDueDate = this.$moment(convertedWriterData._d).format(
            "YYYY-MM-DD"
          );
          this.writingDue = this.$moment(convertedWriterData._d).format(
            "hh:mm A"
          );
        }
        this.handleStaffAvailability("relatedWriters", "writing", "writer");
      }
    },
    getDatesRange(startDate, endDate) {
      let datesRange = [];
      for (
        let dateVar = new Date(startDate);
        dateVar <= endDate;
        dateVar.setDate(dateVar.getDate() + 1)
      ) {
        datesRange.push(this.$moment(new Date(dateVar)).format("YYYY-MM-DD"));
      }
      return datesRange;
    },
    getAvailabilityData() {
      getAvailabilityRecords()
        .then((data) => {
          this.availabilityRecords = data.map((el) => ({
            name: el["Name"],
            dates: this.getDatesRange(
              this.$moment(el["Start Date"]),
              this.$moment(el["End Date"])
            ),
            writingAdjustedWorkload:
              el["Adjusted Daily Workload - Localization"],
            editingAdjustedWorkload: el["Adjusted Daily Workload - Editing"],
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleStaffAvailability(staff, operation, specialist) {
      if (this[`${operation}DueDate`])
        this[staff].forEach((el) => {
          let unavailablePerson = this.availabilityRecords.find(
            (item) =>
              item.name === el.name &&
              item.dates.includes(this[`${operation}DueDate`])
          );
          unavailablePerson
            ? unavailablePerson.writingAdjustedWorkload == 0 &&
              unavailablePerson.editingAdjustedWorkload == 0 &&
              !this.overrideStaff
              ? (el.disabled = true)
              : (el.disabled = false)
            : (el.disabled = false);
        });
      if (specialist)
        if (this[specialist] && !this.overrideStaff) {
          if (
            this[staff].find((el) => el.name === this[specialist].name)
              ?.disabled
          ) {
            this[`${specialist}Unavailable`] = true;
          } else {
            this[`${specialist}Unavailable`] = false;
          }
        } else {
          this[`${specialist}Unavailable`] = false;
        }
    },
    resetSpecialistAvailabilityStatus(specialist) {
      this[`${specialist}Unavailable`] = false;
    },
    mergeRecordsByDate(initialArray, operation) {
      initialArray.forEach((el) => {
        const existingItems = this[`dataFor${operation}`].filter((item) => {
          return item[`${operation} Due Date`] === el[`${operation} Due Date`];
        });
        if (existingItems.length) {
          const existingItemIndex = this[`dataFor${operation}`].indexOf(
            existingItems[0]
          );
          this[`dataFor${operation}`][existingItemIndex]["Word Count"] =
            Number(
              this[`dataFor${operation}`][existingItemIndex]["Word Count"]
            ) + el["Word Count"];
        } else {
          this[`dataFor${operation}`].push(el);
        }
      });
    },
    getCapacity(capacityEmployee, operation) {
      if (capacityEmployee?.name) {
        this[`load${operation}DueDate`] = true;
        let workloadCondition = {
          employeeName: capacityEmployee?.name,
        };
        operation === "Writing"
          ? Object.assign(workloadCondition, { writing: true })
          : Object.assign(workloadCondition, { editing: true });
        getDefaultCapacityByEmployee(capacityEmployee?.name).then((data) => {
          this[`default${operation}Capacity`] = Object.assign(
            {},
            {
              capacity:
                data[0][
                  `FC LOC Availability (${
                    operation === "Writing" ? "Translating" : operation
                  })`
                ],
            }
          );
          return getWorkloadByEmployee(workloadCondition)
            .then((data) => {
              this.mergeRecordsByDate(data, operation);
              return getAvailabilityRecords({
                employeeName: capacityEmployee?.name,
              }).then((data) => {
                this[`adjusted${operation}Capacity`] = data.map((el) => ({
                  dates: this.getDatesRange(
                    this.$moment(el["Start Date"]),
                    this.$moment(el["End Date"])
                  ),
                  capacity:
                    el[
                      `Adjusted Daily Workload - ${
                        operation === "Writing" ? "Localization" : operation
                      }`
                    ],
                }));
                this[`dataFor${operation}Capacity`] = this[
                  `dataFor${operation}`
                ].map((el) => {
                  const adjustedOperationCondition = this[
                    `adjusted${operation}Capacity`
                  ].find((acr) =>
                    acr.dates.includes(el[`${operation} Due Date`])
                  );
                  const adjustedOperationRecord = this[
                    `adjusted${operation}Capacity`
                  ].find((acr) =>
                    acr.dates.includes(el[`${operation} Due Date`])
                  );
                  return {
                    operationCapacity: adjustedOperationCondition
                      ? Number(adjustedOperationRecord.capacity) -
                        el["Word Count"]
                      : Number(this[`default${operation}Capacity`].capacity) -
                        el["Word Count"],
                    date: el[`${operation} Due Date`],
                  };
                });
                this.handleCapacityCalculation(operation);
              });
            })
            .catch((err) => {
              console.error(err);
              this[`load${operation}DueDate`] = false;
            });
        });
      }
    },
    handleCapacityCalculation(operation) {
      const capacityEmployee =
        operation === "Writing" ? this.writer?.name : this.editor?.name;
      if (capacityEmployee) {
        if (this[`${operation.toLowerCase()}DueDate`] && this.wordCount) {
          this[`load${operation}DueDate`] = true;
          const actualCapacity = this[`dataFor${operation}Capacity`].find(
            (el) => el.date === this[`${operation.toLowerCase()}DueDate`]
          );
          const adjustedCapacity = this[`adjusted${operation}Capacity`].find(
            (el) => el.dates.includes(this[`${operation.toLowerCase()}DueDate`])
          );
          if (actualCapacity) {
            this[`errorFor${operation}Capacity`] =
              actualCapacity.operationCapacity - this.wordCount >= 0
                ? false
                : true;
            this[`load${operation}DueDate`] = false;
          } else if (adjustedCapacity) {
            adjustedCapacity.capacity > 0
              ? (this[`errorFor${operation}Capacity`] =
                  adjustedCapacity.capacity - this.wordCount >= 0
                    ? false
                    : true)
              : (this[`errorFor${operation}Capacity`] = false);
            this[`load${operation}DueDate`] = false;
          } else {
            this[`errorFor${operation}Capacity`] =
              this[`default${operation}Capacity`].capacity - this.wordCount >= 0
                ? false
                : true;
            this[`load${operation}DueDate`] = false;
          }
        } else {
          this[`load${operation}DueDate`] = false;
        }
      }
    },
    handleDueHoursAvailability(operation) {
      if (this[`${operation}DueDate`] === this.deliveryDate && !this.override) {
        return this.hoursList.map((el) => {
          if (
            this.hoursList.indexOf(el) >
            this.hoursList.findIndex((el) => el.time === this.deliveryDue)
          ) {
            return Object.assign({}, { time: el.time, disabled: true });
          } else {
            return Object.assign({}, { time: el.time, disabled: false });
          }
        });
      } else if (
        this.$moment(this[`${operation}DueDate`]).isAfter(this.deliveryDate) &&
        !this.override
      ) {
        return this.hoursList.map((el) => {
          return Object.assign({}, { time: el.time, disabled: true });
        });
      } else {
        return this.hoursList;
      }
    },
    handleDueHoursValidation(operation, updatedArray) {
      this[`${operation}DueHoursAvailable`] = !updatedArray.find(
        (el) => el.time === this[`${operation}Due`]
      )?.disabled;
    },
    handleStaffDuesValidation() {
      ["writing", "editing", "reviewing"].forEach((operation) => {
        if (!this.override) {
          if (this.deliveryDate && this[`${operation}DueDate`]) {
            this[`${operation}DueDateAvailable`] = this.$moment(
              this[`${operation}DueDate`]
            ).isSameOrBefore(this.deliveryDate);
            this.$refs[`${operation}DueDateField`].validate(true);
          }
        } else {
          this[`${operation}DueDateAvailable`] = true;
          this.$refs[`${operation}DueDateField`].validate(true);
        }
      });
    },
    handleWorkType1Quantity() {
      if (this.requestType === "Localization")
        this.workType1Quantity = this.wordCount;
    },
    getStaffByGeo(selectedGeo) {
      this.staffLoading = true;
      getStaffNameFieldID()
        .then((fieldID) => {
          return getFCStaffDatasFromID(fieldID, selectedGeo);
        })
        .then((data) => {
          this.relatedWriters = data.data.data
            .map((el) => ({
              name: Object.values(el)[0].value,
              slackID: Object.values(el)[3].value,
              disabled: false,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
          this.relatedEditors = data.data.data
            .map((el) => ({
              name: Object.values(el)[0].value,
              slackID: Object.values(el)[3].value,
              disabled: false,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
          this.reviewers = data.data.data
            .map((el) => ({
              name: Object.values(el)[0].value,
              slackID: Object.values(el)[3].value,
              disabled: false,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
          this.handleStaffAvailability("relatedWriters", "writing");
          this.handleStaffAvailability("relatedEditors", "editing");
          this.handleStaffAvailability("reviewers", "reviewing");
          this.staffLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.staffLoading = false;
        });
    },
    handleDueDate(flow, staff, specialist) {
      this.handleStaffAvailability(staff, flow, specialist);
      this[`${flow}DueDateAvailable`] = true;
    },
    getFileLocations() {
      this.fileLocationsLoading = true;
      getFieldInfosFromDB({
        fieldID: 200,
        tableID: appIDs.iTunesProjectManager.assignments,
      })
        .then((data) => {
          this.fileLocations = data;
          this.fileLocationsLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.fileLocationsLoading = false;
        });
    },
    getSourceGeos() {
      this.sourceGeosLoading = true;
      getFieldInfosFromDB({
        fieldID: 323,
        tableID: appIDs.iTunesProjectManager.assignments,
      })
        .then((data) => {
          this.sourceGeos = data.sort();
          this.sourceGeosLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.sourceGeosLoading = false;
        });
    },
    validateForm() {
      return this.$refs.form.validate();
    },
    handleAssignmentItemDeletion() {
      this.deleteNewFCAssignmentDatas(this.datasIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
.fcdashboard-new-assignment {
  border-bottom: 1px solid #e0e0e0;
}
::v-deep {
  .capacityNote.v-autocomplete,
  .capacityNote .v-label,
  .capacityNote .v-messages,
  .capacityNote .v-icon {
    color: #ff8f00 !important;
    caret-color: #ff8f00 !important;
  }
}
</style>
