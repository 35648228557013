<template>
  <div class="po-vizualization-chart-container">
    <v-container>
      <v-row class="d-flex flex-row justify-center">
        <v-col
          cols="12"
          sm="4"
          class="d-flex flex-column justify-start align-center"
        >
          <div class="overline">Amount in PO</div>
          <div class="font-weight-black" :style="{ fontSize: '1.4em' }">
            {{ amountInPoString }}
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="d-flex flex-column justify-start align-center"
        >
          <div class="overline">Total Amount Billed</div>
          <div
            class="font-weight-black"
            :style="{ fontSize: '1.4em', color: chartColors.pink }"
          >
            {{ amountUsedString }}
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="d-flex flex-column justify-start align-center"
        >
          <div class="overline">Total Amount Remaining</div>
          <div
            class="font-weight-black"
            :style="{ fontSize: '1.4em', color: '#666666' }"
          >
            {{ amountRemainingString }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <svg id="svg-po-vizualization" ref="svg">
      <g
        v-if="domLoaded"
        :style="{
          transform: `translate(${margin.left}px,${margin.top}px)`,
        }"
      >
        <g>
          <!-- background fill -->
          <rect
            x="0"
            :y="height / 2 - barHeight / 2"
            :width="xScale(amountInPO)"
            :height="barHeight"
            fill="#666666"
          />
          <!-- data fill -->
          <rect
            x="0"
            :y="height / 2 - barHeight / 2"
            :width="xScale(billedAmount)"
            :height="barHeight"
            :fill="chartColors.pink"
          />
          <!-- overflow fill -->
          <rect
            v-if="billedIsGreaterThanAmount"
            :x="xScale(amountInPO)"
            :y="height / 2 - barHeight / 2"
            :width="xScale(billedAmount) - xScale(amountInPO)"
            :height="barHeight"
            :fill="texture.url()"
          />
        </g>
        <g>
          <g
            :style="{
              transform: `translate(${chartLabelXOffset}px,${-5}px)`,
            }"
          >
            <foreignObject
              :height="barHeight"
              width="130px"
              class="chart-label-fo"
            >
              <div class="chart-label-div">
                <span>
                  <span class="billed-label">billed</span>
                  <span class="billed-amount">{{ billedPercentString }}</span>
                </span>
              </div>
            </foreignObject>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import * as d3 from "d3";
import colors from "@/styles/colors.scss";
import textures from "textures";
const numeral = require("numeral");
const TOTAL_AMOUNT_FIELD = "Total Amount";
const TOTAL_AMOUNT_BILLED_FIELD = "Total Billed to Purchase order";
export default {
  name: "POVisualization",
  props: {
    data: Object,
  },
  data: function () {
    return {
      colors,
      margin: { top: 0, right: 20, bottom: 50, left: 20 },
      domLoaded: false,
      width: 0,
      height: 0,
      barHeight: 50,
      texture: null,
      chartColors: {
        darkerGray: "#BDBDBD",
        gray: "#E0E0E0",
        pink: colors.cuePink,
      },
    };
  },
  methods: {
    onResize() {
      this.width =
        +d3.select("#svg-po-vizualization").style("width").replace("px", "") -
        this.margin.left -
        this.margin.right;
      this.height =
        +d3.select("#svg-po-vizualization").style("height").replace("px", "") -
        this.margin.top -
        this.margin.bottom;
    },
    formatDollarAmount(value) {
      return numeral(value).format("$0,0.00");
    },
  },
  computed: {
    amountInPO() {
      return this.data[TOTAL_AMOUNT_FIELD];
    },
    billedAmount() {
      return this.data[TOTAL_AMOUNT_BILLED_FIELD];
    },
    amountInPoString() {
      return this.formatDollarAmount(this.data[TOTAL_AMOUNT_FIELD]);
    },
    amountUsedString() {
      return this.formatDollarAmount(this.data[TOTAL_AMOUNT_BILLED_FIELD]);
    },
    amountRemainingString() {
      return this.formatDollarAmount(
        this.data[TOTAL_AMOUNT_FIELD] - this.data[TOTAL_AMOUNT_BILLED_FIELD]
      );
    },
    billedIsGreaterThanAmount() {
      return this.billedAmount > this.amountInPO;
    },
    billedPercent() {
      return this.billedAmount / this.amountInPO;
    },
    billedPercentString() {
      return numeral(this.billedPercent).format("0.0%");
    },
    xScale() {
      const maxValue = Math.max(this.amountInPO, this.billedAmount);

      return d3.scaleLinear().domain([0, maxValue]).range([0, this.width]);
    },
    chartLabelXOffset() {
      if (this.billedPercent > 0.2) {
        return this.xScale(this.billedAmount) - 135;
      } else {
        return this.xScale(this.billedAmount);
      }
    },
  },
  mounted() {
    this.domLoaded = true;
    const svg = d3.select(this.$refs.svg);
    this.texture = textures.lines().thicker(1.2).stroke("white");

    svg.call(this.texture);
    this.onResize();

    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss" scoped>
.po-vizualization-chart-container {
  width: 100%;
  height: 200px;
  display: flex;
  flex-flow: column;

  .po-visualization-info-row {
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    .po-visualization-info {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      &__top {
        text-transform: uppercase;
        font-size: 10px;
        font-weight: bold;
        color: #757575;
      }

      &__bottom {
        font-weight: 700;
        font-size: 18px;
      }
    }
  }

  #svg-po-vizualization {
    width: 100%;
    flex: 1 1 auto;

    .chart-label-fo {
      .chart-label-div {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        align-items: flex-end;
        color: white;

        .billed-label {
          text-transform: uppercase;
          font-weight: 500;
          font-size: 12px;
          margin-right: 4px;
        }
        .billed-amount {
          font-weight: 700;
          font-size: 1.4em;
        }
      }
    }
  }
}
</style>
