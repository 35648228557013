import { render, staticRenderFns } from "./FCStaffTable.vue?vue&type=template&id=7bdcd736&scoped=true&"
import script from "./FCStaffTable.vue?vue&type=script&lang=js&"
export * from "./FCStaffTable.vue?vue&type=script&lang=js&"
import style0 from "./FCStaffTable.vue?vue&type=style&index=0&id=7bdcd736&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bdcd736",
  null
  
)

export default component.exports