<template>
  <div>
    <v-progress-circular v-if="isUpdating" indeterminate :size="24" />
    <v-checkbox
      v-else
      class="pl-2"
      v-model="QC"
      @click.native.stop.prevent="handleChange"
    />
    <v-alert v-if="isError" type="error" class="alert" dismissible>
      Error validating QC. Please try again.
    </v-alert>
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// internal
import { processTask } from "@/utils/quickbaseUtils";

export default {
  name: "PMDashboardValidationSelector",
  props: {
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    leadPMEmail: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      QC: null,
      isUpdating: false,
      isError: false,
    };
  },
  methods: {
    handleChange() {
      this.isUpdating = true;
      let taskUpdateParams = {
          task: {
            ...this.item,
            LastUpdated: new Date(),
            LastUpdatedBy: this.user.Name,
          },
          QC: this.QC,
        },
        emitParams = [
          "refresh-table-data",
          this.item.index,
          "QC",
          this.QC,
          `${new Date()} by ${this.user.Name}`,
        ];
      if (
        this.QC &&
        this.item.TaskStatus === "Delivered" &&
        this.user.email === this.leadPMEmail
      ) {
        Object.assign(taskUpdateParams, { archiving: true });
        emitParams.push(true);
      }
      processTask(taskUpdateParams)
        .then(() => {
          this.item.TaskStatus === "Archived"
            ? this.$emit("get-table-data")
            : this.$emit(...emitParams);
          this.isUpdating = false;
        })
        .catch((err) => {
          this.isUpdating = false;
          this.isError = true;
          console.error(err);
        });
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  mounted() {
    this.QC = this.value;
  },
};
</script>

<style lang="scss" scoped>
.alert {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100;
}
.v-progress-circular {
  margin: 21px 0;
}
</style>
