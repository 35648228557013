<template>
  <div class="mycue">
    <div class="client-selector" v-if="isAdminView">
      <v-container>
        <v-row align="center">
          <v-col class="d-flex" cols="12" sm="12">
            <v-select
              :items="clientNames"
              :value="myCueSelectedClient"
              @change="handleClientSelect"
              label="Client"
              outlined
            />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-alert
      v-if="showAlert"
      :type="emailAlertDatas[emailAlert].type"
      transition="slide-y-transition"
    >
      Email to Company Cue project manager sent
    </v-alert>

    <v-card v-if="myCueSelectedClient">
      <POView />
      <MyCueAssignmentsTable />
    </v-card>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import { getAllClientNames } from "@/utils/quickbaseUtils";
import { PASSTHROUGH_ENV } from "@/utils/constants";
// components
import MyCueAssignmentsTable from "@/components/MyCue/MyCueAssignmentsTable";
import POView from "@/components/MyCue/POView";

export default {
  name: "MyCue",
  components: { MyCueAssignmentsTable, POView },
  data() {
    return {
      clientNames: [],
      showAlert: false,
      emailAlertDatas: {
        SUCCESS: {
          type: "success",
          msg: "Email to Company Cue project manager sent",
        },
        FAIL: {
          type: "error",
          msg: "Problem sending email. Please email your project manager directly.",
        },
      },
    };
  },
  watch: {
    emailAlert(newValue) {
      if (newValue === "SUCCESS" || newValue === "FAIL") {
        this.showAlert = true;
        setTimeout(() => {
          this.setEmailAlert(null);
        }, 4000);
      } else {
        this.showAlert = false;
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isAdmin", "isManager", "company"]),
    ...mapGetters("myCue", ["myCueSelectedClient", "emailAlert"]),
    isAdminView() {
      return (
        this.isAdmin ||
        this.isManager ||
        process.env.NODE_ENV === PASSTHROUGH_ENV
      );
    },
  },
  methods: {
    ...mapActions("myCue", ["setMyCueSelectedClient", "setEmailAlert"]),
    handleClientSelect(value) {
      this.setMyCueSelectedClient(value);
    },
  },
  mounted() {
    getAllClientNames().then((names) => {
      this.clientNames = names;
    });

    if (!this.isAdmin && !this.isManager && this.company) {
      this.setMyCueSelectedClient(this.company);
    }
  },
};
</script>

<style lang="scss" scoped>
.mycue {
  max-width: 1400px;
  margin: 0 auto;
  height: 100%;
  position: relative;

  .email-alert {
    position: absolute;
    z-index: 999;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}
</style>
