// imports
const Excel = require("exceljs");
import { saveAs } from "file-saver";
import moment from "moment";

// report creation
export const ledReport = async (data) => {
  const workbook = new Excel.Workbook(),
    font = {
      name: "Arial",
      size: 13,
    },
    headerStyler = ({ worksheet, row, fill, size }) => {
      worksheet.getRow(row).font = {
        name: "Arial",
        bold: true,
        size: size ? size : 13,
      };
      if (fill)
        worksheet.getRow(row).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: fill },
        };
      worksheet.getRow(row).height = 30;
      worksheet.getRow(row).alignment = { vertical: "middle" };
    },
    cellDyer = ({ worksheet, address, color, border }) => {
      if (color)
        worksheet.getCell(address).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: color },
        };
      if (border)
        worksheet.getCell(address).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
    };

  // process report data
  const geos = [
    ...new Set(
      data.map((el) => {
        return el.GEO;
      })
    ),
  ].sort();
  geos.forEach((geo) => {
    // split data by GEO worksheets
    const geoWorksheet = workbook.addWorksheet(`${data[0].PayPeriod}_${geo}`),
      geoData = data.filter((el) => el.GEO === geo);

    // table headers
    geoWorksheet.columns = [
      {
        header: "Date",
        key: "date",
        width: 50,
        style: { font },
      },
      {
        header: "HRS",
        key: "hrs",
        width: 8,
        style: { font },
      },
      {
        header: "Work Type",
        key: "work_type",
        width: 25,
        style: { font },
      },
      {
        header: "Assignment Title",
        key: "assgn_title",
        width: 30,
        style: { font },
      },
      {
        header: "Assignment Detail",
        key: "assgn_detail",
        width: 40,
        style: { font },
      },
      { header: "Media Type", key: "media_type", width: 15, style: { font } },
      {
        header: "Keyword",
        key: "keyword",
        width: 10,
        style: { font },
      },
      {
        header: "Word Count",
        key: "word_count",
        width: 15,
        style: { font },
      },
      {
        header: "Comments",
        key: "comments",
        width: 80,
        style: { font },
      },
    ];
    headerStyler({
      worksheet: geoWorksheet,
      row: 1,
      fill: "c9daf8",
      size: 11,
    });

    // highlight Comments header
    cellDyer({ worksheet: geoWorksheet, address: "I1", color: "fef2cd" });

    // populate weekly data
    const groupWeeks = [
      ...new Set(
        geoData.map((el) => {
          return el.groupWeek;
        })
      ),
    ].sort();
    groupWeeks.forEach((week) => {
      let totalWeekHrs = 0;
      geoData
        .filter((el) => el.groupWeek === week)
        .sort((a, b) =>
          a.RequestDate > b.RequestDate
            ? 1
            : b.RequestDate > a.RequestDate
            ? -1
            : 0
        )
        .forEach((record) => {
          totalWeekHrs += record.WorkType1Quantity;
          const row = geoWorksheet.addRow({
            date: moment(record.RequestDate).format("dddd, MMMM DD, YYYY"),
            hrs: record.WorkType1Quantity,
            work_type: record.RequestType,
            assgn_title: record.AssignmentTitle,
            assgn_detail: record.AssignmentDetail,
            media_type: record.MediaType,
            keyword: record.Keyword,
            word_count: record.WordCount,
            comments: record.Note,
          });
          row._cells.forEach((cell) =>
            cellDyer({
              worksheet: geoWorksheet,
              address: cell._address,
              border: true,
            })
          );
        });
      const totalRow = geoWorksheet.addRow({
        date: `TOTAL HRS WK ${moment(week.split(", ")[0]).format(
          "MMM DD, YYYY"
        )} - ${moment(week.split(", ")[4]).format("MMM DD, YYYY")}`,
        hrs: totalWeekHrs,
      });
      headerStyler({
        worksheet: geoWorksheet,
        row: totalRow.number,
        fill: "d9d9d9",
        size: 12,
      });
      totalRow._cells.forEach((cell) =>
        cellDyer({
          worksheet: geoWorksheet,
          address: cell._address,
          border: true,
        })
      );
    });

    // center columns data
    ["B", "F", "G", "H"].forEach(
      (col) =>
        (geoWorksheet.getColumn(col).alignment = {
          vertical: "middle",
          horizontal: "center",
        })
    );
  });

  // save and download file
  workbook.xlsx
    .writeBuffer()
    .then((buffer) =>
      saveAs(
        new Blob([buffer]),
        `Cue_${geos.join("_")}_Lead Editor_Weekly Report_${moment(
          new Date()
        ).format("MM.DD.YYYY")}.xlsx`
      )
    )
    .catch((err) => console.error("Error writing Excel export", err));
};
